export const GetContent = (config: any) => (arg: any) => {
  if (config[arg]) {
    return config[arg]
  }

  if (!config[arg] && config.default) {
    return config.default
  }

  return null
}
