import React, { ReactElement, useEffect } from "react"
import { useParams } from "react-router-dom"
import { Card } from "components/atoms/Card"
import styled from "styled-components"
import { AnyColourBackgroundWave } from "templates/BackgroundWave"
import { ContentArea } from "templates/ContentArea"
import { Button } from "components/atoms"
import { TrustSignals } from "components/organisms/TrustSignals"
import devices from "styles/devices"
// @ts-ignore
import MojoLogo from "url:assets/images/mojo-logo.png"
// @ts-ignore
import MojoMiniLogo from "url:assets/images/mojo-mini-logo.svg"
import { LogGaEvent } from "lib/GoogleAnalytics"
import { EventAction, EventCategory, EventLabel } from "models/GoogleAnalytics"
import { useTrackNavigatedToPage } from "hooks/useTrackPageView"
import lenders from "components/lenders"
import axios from "axios"
import { AVATAR_CLOUDFRONT_URL } from "../../Config"
import { axiosInstance } from "lib/Api"

interface Deal {
  logo: string
  title: string
  benefits: string[]
  monthlyPayment: number
  initialRate: number
  initialCostsAndFees: number
  standardVariableRate: number
  upFrontFees: number
  lenderUrl: string
  lender: string
  lenderCode: string
  notes?: string
  initialPeriodMonths: number
  mortgageClass: string
  recommended: boolean
  duration: string
}

interface Base64JSON {
  timestamp: number
  applicantName: string
  propertyValue: number
  loanAmount: number
  mortgageTerm: string
  adviserName: string
  adviserEmail: string
  adviserRole: string
  opportunityId: string
  deals: Deal[]
}

interface SFData {
  Opp_Id__c: string
  Viewed_Date_Time__c?: string
  Feedback_Confidence__c?: string
  Feedback_Easy__c?: string
  Feedback_Useful__c?: string
}

const DEFAULT_VALUE = "Unknown"
const CHROME_EXTENSION_ID = "foffhdpjjnmidkaimgganegilhoanmog"

const TextBox = styled.div`
  padding: 24px;
  border-radius: 24px 24px 24px 0;
  margin-top: 16px;
  background: #fbbb5b;
  p {
    margin: 0;
  }
`

const ChatText = styled.div`
  p {
    margin: 0;
    &:nth-child(2) {
      font-size: 12px;
    }
  }
`

const CoachWrapper = styled.div`
  display: flex;
  margin-top: 1em;

  align-items: center;
  img {
    margin-right: 16px;
  }
`

const Logo = styled.img`
  width: 144px;
  margin: 20px;

  @media screen and (min-width: 1700px) {
    position: fixed;
  }
`

const MiniLogo = styled.div`
  width: 50px;
  height: 50px;
  margin-right: 20px;
  background-image: url(${({
    backgroundImage,
  }: {
    backgroundImage: string
    borderRadius: boolean
  }) => backgroundImage});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: ${({ borderRadius }) => (borderRadius ? "50%" : "0%")};
`

const Coach = ({ adviserName, adviserRole = "Mojo Mortgages", adviserEmail }) => {
  const [adviserAvatar, setAdviserAvatar] = React.useState<string>(MojoMiniLogo)
  useEffect(() => {
    const req = `${AVATAR_CLOUDFRONT_URL}/${adviserEmail}.jpg`
    doesAdviserHaveAvatar(req).then((valid) => (valid ? setAdviserAvatar(req) : null))
  }, [setAdviserAvatar, adviserEmail])

  return (
    <CoachWrapper>
      <MiniLogo backgroundImage={adviserAvatar} borderRadius={adviserAvatar !== MojoMiniLogo} />
      <ChatText>
        <p>
          <strong>{adviserName || DEFAULT_VALUE}</strong>
        </p>
        <p>{adviserRole} – here to help you get a mortgage </p>
      </ChatText>
    </CoachWrapper>
  )
}

const AdviserView = ({
  text,
  adviserName,
  adviserRole,
  adviserEmail,
}: {
  text: string
  adviserName: string
  adviserRole: string
  adviserEmail: string
}) => {
  return (
    <div style={{ marginBottom: 40 }}>
      <TextBox>
        <p>{text}</p>
      </TextBox>
      <Coach adviserName={adviserName} adviserRole={adviserRole} adviserEmail={adviserEmail} />
    </div>
  )
}

const Main = styled.main`
  position: relative;
  background: #fcdec8;

  p,
  h1,
  h2,
  h3,
  h4,
  h5 {
    line-height: 150%;
  }
`

const Container = styled.div`
  display: flex;
  justify-items: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: ${({ paddingTop }: { paddingTop: boolean }) => (paddingTop ? "20px" : 0)};
`

const Benefits = styled.ul<{ display: boolean }>`
  padding: 0;
  display: ${({ display }) => (display ? "flex" : "none")};
  list-style: none;
  justify-items: center;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
`

const Benefit = styled.li`
  background: rgba(0, 176, 113, 0.1);
  margin: 0 6px;
  padding: 10px 12px 6px;
  border-radius: 24px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
`

const MonthlyPayment = styled.div`
  background: #e9edf5;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  min-height: 111px;
  border-radius: 4px;
`

const GridInfo = styled.div`
  margin-top: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  font-size: 12px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    border: solid thin white;
    top: 0;
    bottom: -1px;
    left: -1px;
    right: 0;
    pointer-events: none;
  }

  > div {
    border: 1px solid #e9edf5;
    margin-left: -1px;
    margin-bottom: -1px;
  }
`

const Cost = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 120%;
  margin: 8px 0;
`

const GridSquareContainer = styled.div`
  padding: 10px 18px;
`

const Value = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
`

const Deals = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 20px;
  align-items: end;
  grid-column-gap: 15px;

  @media screen and ${devices.bp_sm} {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and ${devices.bp_xl} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`

const GridSquare = ({ title, value }: { title: string | ReactElement; value: string }) => {
  return (
    <GridSquareContainer>
      {title}
      <Value>{value}</Value>
    </GridSquareContainer>
  )
}

const DealTitle = styled.h3`
  font-weight: 700;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 24px;
`

const DealCardContainer = styled.div`
  margin-bottom: 40px;

  &:last-of-type {
    margin-bottom: 0;
  }

  @media screen and ${devices.bp_sm} {
    margin-bottom: 0;
  }

  @media screen and ${devices.bp_lg} {
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`

const DealLogoArea = styled.div`
  display: flex;
  align-items: center;
  height: 80px;
  margin-bottom: 10px;
`

const SmallPrint = styled.p`
  font-size: 12px;
  text-align: left;
  width: 100%;
  color: #787878;
  margin-bottom: 0;
`

const Recommended = styled.div`
  border: solid ${({ active }: { active: boolean }) => (active ? "3px #fbbb5b" : "0 transparent")};
  max-width: 350px;
  border-radius: 15px;
  background: ${({ active }) => (active ? "white" : "transparent")};
  display: flex;
  flex-direction: column;
  align-items: center;
`

const RecommendedTitle = styled.span`
  display: inline-block;
  background: #fbbb5b;
  text-align: center;
  padding: 10px 15px;
  border-radius: 0 0 10px 10px;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 0.15rem;
`

const DealClass = styled.div`
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  margin: 5px 0;
`

const DealCard = (props: Deal) => {
  return (
    <DealCardContainer>
      <DealTitle>{props.title}</DealTitle>
      <Recommended active={props.recommended}>
        {props.recommended && <RecommendedTitle>Mortgage Expert's Top Pick</RecommendedTitle>}
        <Card
          style={{
            paddingTop: props.recommended && 15,
            maxWidth: 350,
            boxShadow: "0px 6px 14px rgba(0, 0, 0, 0.05)",
          }}
          content={
            <Container paddingTop={!props.recommended}>
              {props.mortgageClass && props.duration ? (
                <DealClass>
                  {props.mortgageClass} {props.duration}
                </DealClass>
              ) : (
                <React.Fragment />
              )}
              <DealLogoArea>
                {props.lenderCode && lenders[props.lenderCode] ? (
                  <img src={lenders[props.lenderCode]} alt={props.title} />
                ) : props.lender ? (
                  <h4>{props.lender}</h4>
                ) : (
                  <h4>{DEFAULT_VALUE}</h4>
                )}
              </DealLogoArea>
              <Benefits display={!!props.benefits?.length || false}>
                {props.benefits?.map((benefit: string) => (
                  <Benefit>{benefit}</Benefit>
                ))}
              </Benefits>
              <MonthlyPayment>
                <span>Monthly payment</span>
                <Cost>{currencyFormat(props.monthlyPayment)}</Cost>
              </MonthlyPayment>
              <GridInfo>
                <GridSquare
                  title={
                    <span>
                      Initial
                      <br /> rate
                    </span>
                  }
                  value={String(percentageFormat(props.initialRate))}
                />
                <GridSquare
                  title={<span>Overall initial cost (incl fees)*</span>}
                  value={String(currencyFormat(props.initialCostsAndFees))}
                />
                <GridSquare
                  title={<span>Standard Variable Rate</span>}
                  value={String(percentageFormat(props.standardVariableRate))}
                />
                <GridSquare
                  title={<span>Arrangement fees</span>}
                  value={String(currencyFormat(props.upFrontFees))}
                />
              </GridInfo>
              {props.initialPeriodMonths && (
                <SmallPrint>
                  *over an initial period of {props.initialPeriodMonths} months.
                </SmallPrint>
              )}
              {props.notes && <p>{props.notes}</p>}
            </Container>
          }
        />
      </Recommended>
    </DealCardContainer>
  )
}

const BackgroundBlock = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: #ffebdc;
  z-index: -999;
`

const BigSection = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  @media screen and ${devices.bp_md} {
    grid-template-columns: 1fr 1fr;

    > div {
      max-width: 668px;
      padding-right: 20px;
    }
  }

  @media screen and ${devices.bp_xl} {
    grid-template-columns: 2fr 1fr;
  }
`

const Title = styled.span`
  display: block;
  font-weight: 600;
  margin-bottom: 8px;
`
const Data = styled.span`
  display: block;
`

const DetailsContainer = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  margin-top: 20px;
`

const SmallText = styled.p`
  font-size: 12px;
  text-align: center;
`

const Footer = styled.div`
  text-align: center;
  margin-top: -80px;
`

const SideGradiant = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(291.54deg, #ffdabe 25.64%, rgba(255, 235, 220, 0) 55.41%);
  width: 600px;
  pointer-events: none;
  z-index: -1;
`

const BackgroundsWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(90deg, #fee2cd 0%, #ffdabe 100%);
  z-index: -9999;
`

const Backgrounds = styled.div`
  position: absolute;
  top: 0;
  height: 100vh;
  left: 0;
  right: 0;
`

const EmailNow = styled.div`
  margin: 50px 0;

  @media screen and ${devices.bp_md} {
    margin-top: -34px;
  }
`

const Trust = styled.div`
  @media screen and ${devices.bp_md} {
    margin-top: 20px;
  }
`

const RatesContainer = styled.div`
  max-width: 650px;
  text-align: center;
  margin: 100px auto;

  @media screen and ${devices.bp_md} {
    padding-right: 80px;
  }
`

const CardContainer = styled.div`
  padding: 15px;

  @media screen and ${devices.bp_md} {
    padding: 0;
  }
`

const UserDetail = ({ data, title }) => {
  return (
    <div style={{ marginBottom: 28 }}>
      <Title>{title}</Title>
      <Data>{data}</Data>
    </div>
  )
}

const preparePageData = (base64: string): Base64JSON => {
  try {
    return JSON.parse(window.atob(base64)) as Base64JSON
  } catch (e) {
    console.log(e)
    return {} as Base64JSON
  }
}

const checkImageURLisValid = (url: string) => {
  return new Promise<boolean>((resolve) => {
    const image = document.createElement("img")
    image.src = url
    image.onload = () => resolve(true)
    image.onerror = () => resolve(false)
  })
}

const isChromeExtensionInstalled = (id: string) =>
  checkImageURLisValid(`chrome-extension://${id}/icon-128.png`)
const doesAdviserHaveAvatar = (url: string) => checkImageURLisValid(url)

const sendDataToSalesforce = async (sfData: SFData) => {
  const UPDATE_RECORD = "b06ogeu"
  try {
    await axiosInstance.post(
      `${process.env.MY_MOJO_API}/request/zapier-webhook-mop-tracking`,
      {
        data: sfData,
        webhookId: UPDATE_RECORD,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
  } catch (error) {
    console.log("error", error)
  }
}

export const currencyFormat = (
  v: number,
  noCurrency = false,
  minDigits = 2,
  maxDigits = 2
): string => {
  const formatted = new Intl.NumberFormat("en-GB", {
    minimumFractionDigits: minDigits,
    maximumFractionDigits: maxDigits,
  }).format(v)

  return noCurrency ? formatted : `£${formatted}`
}

export const percentageFormat = (v: number): string => `${v}%`

export const CallRecommendations = () => {
  useTrackNavigatedToPage("/recommended")
  const { id } = useParams() as { id: string }
  const pageData = preparePageData(id)

  useEffect(() => {
    if (!pageData?.deals?.length) return
    LogGaEvent({
      action: EventAction.numberOfProductsShown,
      event_category: EventCategory.siteInteraction,
      event_label: EventLabel.number,
      value: pageData.deals.length,
    })
  }, [pageData])

  useEffect(() => {
    const scriptTag = document.createElement("script")

    scriptTag.src = "//l.getsitecontrol.com/y79nm31w.js"
    scriptTag.type = "text/javascript"
    scriptTag.async = true

    document.body.appendChild(scriptTag)
    return () => {
      document.body.removeChild(scriptTag)
    }
  }, [])

  useEffect(() => {
    isChromeExtensionInstalled(CHROME_EXTENSION_ID).then((isInstalled) => {
      // Can infer that it is not the advisor based on the fact that the extension is not installed.
      // We can assume it is most likely the customer.
      if (!isInstalled) {
        sendDataToSalesforce({
          Opp_Id__c: pageData.opportunityId,
          Viewed_Date_Time__c: new Date().toISOString(),
        })
      }
    })
  }, [])

  return (
    <Main>
      <Logo src={MojoLogo} alt="Mojo Mortgages" />
      <ContentArea>
        <div>
          <div>
            <h1 style={{ marginBottom: 20, display: "block" }}>
              {pageData?.applicantName ? pageData?.applicantName.split(" ")[0] + "'s" : "Your"}{" "}
              remortgage options
            </h1>
            <BigSection>
              <AdviserView
                text="I’ve assessed your circumstances and searched thousands of deals across the entire mortgage market to find your best options."
                adviserName={pageData?.adviserName || DEFAULT_VALUE}
                adviserRole={pageData?.adviserRole}
                adviserEmail={pageData?.adviserEmail}
              />
              <div>
                <DetailsContainer>
                  <UserDetail
                    data={pageData?.applicantName || DEFAULT_VALUE}
                    title="Applicant name"
                  />
                  <UserDetail
                    data={
                      pageData?.propertyValue
                        ? currencyFormat(pageData?.propertyValue)
                        : DEFAULT_VALUE
                    }
                    title="Property value"
                  />
                </DetailsContainer>
              </div>
            </BigSection>
            <Deals>
              {pageData?.deals?.map((deal: Deal) => (
                <DealCard key={deal.monthlyPayment} {...deal} />
              ))}
            </Deals>
            <RatesContainer>
              <SmallText>
                These rates are subject to change. Lenders may withdraw product rates at short
                notice.
              </SmallText>
            </RatesContainer>
            <BigSection>
              <div>
                <React.Fragment>
                  <h4>We’ll sort the mortgage, you make yourself at home</h4>
                  <p>Mortgages can be a right slog, but we’re really, really good at them.</p>
                  <p>
                    Deciding between different lenders, initial periods, monthly costs, overall
                    costs, fees, product features and more can make this a daunting process whether
                    you’re a mortgage newbie or seasoned veteran. But don’t worry, we’ll guide you
                    through it from start to finish.
                  </p>
                  <p>
                    We sort it all; the advice, the paperwork, the application, the bank poking, we
                    handle all the stress. So turn that home-owner dream into a real mortgage offer.
                    Or if you’re remortgaging, just get yourself the best deal possible. Period.
                  </p>
                </React.Fragment>
              </div>
              <EmailNow>
                <Card
                  style={{ boxShadow: "0px 6px 14px rgba(0, 0, 0, 0.05)" }}
                  content={
                    <CardContainer>
                      <h4>Ready to proceed?</h4>
                      <p>
                        If you’ve chosen a deal or want to discuss more options, email me and we’ll
                        cover the next steps.
                      </p>
                      <Button
                        text="Email me"
                        onClick={() => (location.href = `mailto:${pageData.adviserEmail}`)}
                      />
                      <Coach
                        adviserName={pageData?.adviserName}
                        adviserRole={pageData?.adviserRole}
                        adviserEmail={pageData?.adviserEmail}
                      />
                    </CardContainer>
                  }
                />
              </EmailNow>
            </BigSection>
            <Trust>
              <TrustSignals />
            </Trust>
          </div>
          <Footer>
            <SmallText>
              Mojo is a trading style of Life’s Great Limited which is registered in England and
              Wales (06246376).
            </SmallText>
            <SmallText>
              Our registered office is The Cooperage, 5 Copper Row, London SE1 2LH. We
              are authorised and regulated by the Financial Conduct Authority and are on the
              Financial Services Register (478215)
            </SmallText>
            <SmallText>
              Your home/property may be repossessed if you do not keep up repayments on your
              mortgage.
            </SmallText>
          </Footer>
        </div>
      </ContentArea>
    </Main>
  )
}
