import React from "react"
import styled from "styled-components"

const Container = styled.div`
  display: flex;
  background: #f3f5fa;
  border-radius: 5px;
  padding: 17px;
  font-size: 1em;
  font-weight: bold;
  height: 20px;
  align-items: center;
  font-family: Helvetica, Arial, sans-serif;
  box-sizing: border-box;
`

const TextWrapper = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`

export function ValueView({ value }) {
  return (
    <Container>
      <TextWrapper>{value}</TextWrapper>
    </Container>
  )
}
