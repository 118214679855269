import React from "react"
import { PickerOverlay } from "filestack-react"
import {
  FILESTACK_POLICY,
  FILESTACK_SIGNATURE,
  FILESTACK_REGION,
  FILESTACK_API_KEY,
} from "../../../../../Config"

export interface FilesUploaded {
  key: string
  originalFile: {
    name: string
  }
}

interface File {
  filename: string
  mimetype: string
  originalPath: string
  size: number
  source: string
  uploadId: string
  originalFile: {
    name: string
    type: string
    size: number
  }
}

interface StoreTo {
  location: string
  container: string
  region: string
  path: string
}

interface Props {
  close: () => void
  container: string
  filename?: string
  uploadedFilePath?: string
  onSuccessFunction?: (filesUploaded: FilesUploaded[]) => void
  customOptions?: {
    onFileSelected?: (file: File) => void
    accept?: string[]
    fromSources?: string[]
    maxFiles?: number
    storeTo?: {
      location: string
      container: string
      region: string
      path: string
    }
    onClose?: () => void
    disableStorageKey?: boolean
  }
}

export function FilestackUploadPicker({
  close,
  container = "mojo-customer-docs-filestack-upload-test",
  filename,
  uploadedFilePath,
  onSuccessFunction,
  customOptions,
}: Props) {
  return (
    <PickerOverlay
      apikey={FILESTACK_API_KEY}
      clientOptions={{
        security: {
          policy: FILESTACK_POLICY,
          signature: FILESTACK_SIGNATURE,
        },
      }}
      pickerOptions={{
        onFileSelected(file) {
          if (filename) {
            const fileExtension = file.originalPath.split(".").pop()
            return { ...file, name: `${filename}.${fileExtension}` }
          } else {
            return file
          }
        },
        accept: ["application/pdf", "image/*"],
        fromSources: ["local_file_system"],
        maxFiles: 10,
        storeTo: {
          location: "s3",
          container,
          region: FILESTACK_REGION,
          path: uploadedFilePath,
        },
        onClose() {
          close()
        },
        ...customOptions,
      }}
      onSuccess={(result) => {
        const { filesUploaded } = result

        if (onSuccessFunction) {
          onSuccessFunction(filesUploaded)
        }
      }}
    />
  )
}
