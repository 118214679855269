import React from "react"
import styled from "styled-components"
import { Bike } from "components/Illustrations"
import { AccordionItem } from "pages/Application/Eligibility/Coach/AccordionItem"

const BikeImage = styled.img`
  object-fit: contain;
  width: 200px;
  justify-self: center;
  align-self: center;

  @media (min-width: 1250px) {
    height: 300px;
    width: 300px;
  }
`

export interface MortgageCoachCard {
  cardTitle: string
  cardText: string
  cardBulletPoints: (JSX.Element[] | JSX.Element | string)[]
  children?: JSX.Element[] | JSX.Element
}

export const YourCreditHistory = ({
  children,
  cardTitle,
  cardText,
  cardBulletPoints = [],
}: MortgageCoachCard) => {
  return (
    <>
      <AccordionItem
        cardTitle={cardTitle}
        cardText={cardText}
        cardImage={<BikeImage src={Bike} />}
        cardBulletPoints={cardBulletPoints}
      >
        {children}
      </AccordionItem>
    </>
  )
}
