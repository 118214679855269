import React, { useEffect, useState } from "react"
import { GetUrlParameter } from "lib/UrlParams"
import styled from "styled-components"
import { InfoPage } from "components/organisms/InfoPage"
import { FeatureTest } from "components/organisms/FeatureTest"
import { useApplicationFriendlyId } from "hooks/useApplication"
import { ApprovedSection } from "./Approved/Approved"
import { CoachSection } from "./Coach/Coach"
import { EligibilityLoader } from "./EligibilityLoader"
import { useTrackNavigatedToPage } from "hooks/useTrackPageView"
import { IsPreOffer } from "lib/Applications"
import { ResultPage } from "models/ExperienceTLS"
import { NoHelpSection } from "pages/Application/Eligibility/Together/NoHelp"
import { Route, Switch, useHistory, useParams, useRouteMatch } from "react-router-dom"
import { ComparisonTableApproved } from "pages/Application/Eligibility/Approved/ComparisonTableApproved"
import { useExperienceTLS } from "hooks/useExperienceTLS"
import useHasAdblocker from "hooks/HasAdBlocker"
import EligibilityBook from "./Book/Book"
import { useConfig } from "hooks/useConfig"
import { EligibilityContextProvider, useEligibility } from "hooks/useEligibility/useEligibility"
import { IS_DEV } from "../../../Config"
import { PhoneCheck } from "./PhoneCheck"
import { RemoERC } from "pages/RemoERC/RemoERC"
import { NoAvailability } from "./NoAvailability"
import { AdviceApptLater } from "./Approved/AdviceApptLater"
import { GetPartnerFromUrl } from "lib/GetPartnerFromUrl"
import { SentryErrorer } from "components/atoms/SentryErrorer"

const Logo = styled.div`
  margin: 1em 0 1.25em 2.25em;
  img {
    width: 6.875em;
  }
`

/**
 * Component that crunches fact-find responses and redirects user to the correct page for their application
 */
export function Eligibility() {
  const history = useHistory()
  const match = useRouteMatch()

  const hasAdBlocker = useHasAdblocker()

  const { friendlyId } = useParams<{ friendlyId: string }>()

  const { theme } = useConfig()

  const doUpdate = GetUrlParameter("doUpdate")
  const { application, refreshApplication } = useApplicationFriendlyId(friendlyId)

  const { experienceTLS, isLoading } = useExperienceTLS(friendlyId, doUpdate === "true")
  const [resultsLoading, setResultsLoading] = useState(true)
  const [showLogo, setShowLogo] = useState<boolean>(true)

  useTrackNavigatedToPage("/application/eligibility")

  // will run when everything is done loading
  // redirects to correct page
  useEffect(() => {
    const doRedirect = () => {
      const path = resultPageToPath[experienceTLS.resultPage]
      history.replace(match.url + path)
    }

    // always redirects users to the correct page in production
    if (!isLoading) {
      const { availability, resultPage, reviewPhoneNumber, rule } = experienceTLS

      // TODO: [FUN-399]: temporary solution, fix `brand` in TLS so we can use rule exclusion filter
      const partner = GetPartnerFromUrl()
      if (
        partner === "money" &&
        rule.color === "GREEN" &&
        (rule.tier === "A" || rule.tier === "B" || rule.tier === "C")
      ) {
        history.replace(`${match.url}/mortgage-rates`)
      } else if (
        reviewPhoneNumber &&
        (resultPage === ResultPage.AdviceAppt || resultPage === ResultPage.AdviceCallback)
      ) {
        // If we want to review their phone number, short circuit the redirect
        // and send them to review-number instead.
        history.replace(`${match.url}/review-number`)
      } else if (
        availability === false &&
        (resultPage === ResultPage.AdviceAppt ||
          resultPage === ResultPage.AdviceCallback ||
          resultPage === ResultPage.CSEAppt ||
          resultPage === ResultPage.CSEFutureAppt)
      ) {
        // If they are due to hit a booking page, check if there is availability
        // and if not send them to the no availability page instead instead.
        history.replace(`${match.url}/no-availability`)
      } else if (history.location.pathname === `/application/${friendlyId}/eligibility`) {
        doRedirect()
      } else {
        // if in dev or test only redirects in /eligibility
        // this allows us to visit pages for testing purposes without being redirected

        if (!IS_DEV) {
          doRedirect()
        }
      }
    }
  }, [isLoading])

  useEffect(() => {
    // Give time for the Crunching Your Numbers animation to complete
    setTimeout(() => setResultsLoading(false), 8500)
  }, [])

  // display loader until everything is loaded
  if (isLoading || resultsLoading) return <EligibilityLoader />

  return (
    // Need to pass state/values/anything down to these routes?
    // add them to the context!!! Don't prop drill 🛑✋
    // The routes after the eligibility loader are just in case any customers have old links and can be removed after 7/3/23
    <EligibilityContextProvider logoState={[showLogo, setShowLogo]}>
      {showLogo && (
        <Logo>
          <a href="/dashboard">
            <img src={theme.mainLogoURL} alt="logo" />
          </a>
        </Logo>
      )}
      <Switch>
        <Route path={`${match.path}/mortgage-advice-appt`}>
          <ApprovedWrapper route="mortgage-advice-appt" />
        </Route>
        <Route path={`${match.path}/mortgage-advice-callback`}>
          <SentryErrorer path="/mortgage-advice-callback" redirect="/dashboard" />
          {/* <ApprovedWrapper route="mortgage-advice-callback" /> */}
        </Route>
        {/* Not used */}
        <Route path={`${match.path}/post-offer`}>
          <SentryErrorer path="/post-offer" redirect="/dashboard" />
          {/* <ApprovedWrapper route="post-offer" /> */}
        </Route>
        <Route path={`${match.path}/cse-advice-appt`}>
          <ApprovedWrapper route="cse-advice-appt" />
        </Route>
        <Route path={`${match.path}/cse-advice-appt-later`}>
          <NurtureOfferWrapper />
        </Route>
        <Route path={`${match.path}/early-repayment-charge`}>
          <RemoERC friendlyId={friendlyId} trafficLightResult={experienceTLS} />
        </Route>
        <Route path={`${match.path}/mortgage-rates`}>
          <ComparisonTableApproved
            isPreOffer={IsPreOffer(application)}
            hasAdBlocker={hasAdBlocker}
            trafficLightResult={experienceTLS}
            refreshApplication={refreshApplication}
          />
        </Route>
        {/* Not used */}
        <Route path={`${match.path}/together`}>
          <SentryErrorer path="/together" redirect="/dashboard" />
          {/* <TogetherSection friendlyId={application.friendlyId} /> */}
        </Route>
        <Route path={`${match.path}/no-help`}>
          <NoHelpSection />
        </Route>
        <Route path={`${match.path}/mortgage-coach`}>
          <CoachSection friendlyId={friendlyId} isPreOffer={IsPreOffer(application)} />
        </Route>
        {/* Not used */}
        <Route path={`${match.path}/nurture-hub`}>
          <SentryErrorer path="/nurture-hub" redirect="/dashboard" />
          {/* <CoachSection friendlyId={friendlyId} isPreOffer={IsPreOffer(application)} /> */}
        </Route>
        {/* Not used */}
        <Route path={`${match.path}/its-free`}>
          <SentryErrorer path="/its-free" redirect="/dashboard" />
          {/* <InfoPage>
            <FreeHBE friendlyId={friendlyId} refreshApplication={refreshApplication} />
          </InfoPage> */}
        </Route>
        <Route path={`${match.path}/feature-in-test`}>
          <InfoPage>
            <FeatureTest />
          </InfoPage>
        </Route>
        {/* Not used */}
        <Route path={`${match.path}/book-pro`}>
          <SentryErrorer path="/book-pro" redirect="/dashboard" />
          {/* <EligibilityBookPro /> */}
        </Route>
        {/* Not used */}
        <Route path={`${match.path}/book`}>
          <SentryErrorer path="/book" redirect="/dashboard" />
          <EligibilityBook />
        </Route>
        <Route exact path={match.path}>
          <EligibilityLoader />
        </Route>
        {/* Not used */}
        <Route path={`${match.path}/approved`}>
          <SentryErrorer path="/approved" redirect="/dashboard" />
          {/* <ApprovedWrapper route="approved" /> */}
        </Route>
        {/* Not used */}
        <Route path={`${match.path}/pre-offer`}>
          <SentryErrorer path="/pre-offer" redirect="/dashboard" />
          {/* <ApprovedWrapper route="pre-offer" /> */}
        </Route>
        {/* Not used */}
        <Route path={`${match.path}/coach`}>
          <SentryErrorer path="/coach" redirect="/dashboard" />
          {/* <CoachSection friendlyId={friendlyId} isPreOffer={IsPreOffer(application)} /> */}
        </Route>
        {/* Duplicate of /mortgage-rates ? */}
        <Route path={`${match.path}/results`}>
          <ComparisonTableApproved
            isPreOffer={IsPreOffer(application)}
            hasAdBlocker={hasAdBlocker}
            trafficLightResult={experienceTLS}
            refreshApplication={refreshApplication}
          />
        </Route>
        <Route path={`${match.path}/review-number`}>
          <PhoneCheck />
        </Route>
        <Route path={`${match.path}/no-availability`}>
          <NoAvailability trafficLightResult={experienceTLS} />
        </Route>
      </Switch>
    </EligibilityContextProvider>
  )
}

function hideLogo() {
  const {
    logoState: [_, setShowLogo],
  } = useEligibility()
  useEffect(() => {
    setShowLogo(false)
  }, [])
}

function NurtureOfferWrapper() {
  const { friendlyId } = useParams<{ friendlyId: string }>()
  const { experienceTLS: trafficLightResult } = useExperienceTLS(friendlyId)

  hideLogo()
  useTrackNavigatedToPage("/application/eligibility/cse-advice-appt-later")

  return <AdviceApptLater trafficLightResult={trafficLightResult} />
}

function ApprovedWrapper({ route }) {
  const hasAdBlocker = useHasAdblocker()

  const { friendlyId } = useParams<{ friendlyId: string }>()

  hideLogo()

  useTrackNavigatedToPage(`/application/eligibility/${route}`)

  const { application: fullApplication, refreshApplication } = useApplicationFriendlyId(friendlyId)

  const { experienceTLS: trafficLightResult } = useExperienceTLS(friendlyId)

  return (
    <ApprovedSection
      fullApplication={fullApplication}
      isPreOffer={IsPreOffer(fullApplication)}
      hasAdBlocker={hasAdBlocker}
      trafficLightResult={trafficLightResult}
      refreshApplication={refreshApplication}
    />
  )
}

export const resultPageToPath: Record<ResultPage, string> = {
  [ResultPage.AdviceAppt]: "/mortgage-advice-appt",
  [ResultPage.AdviceCallback]: "/mortgage-advice-callback",
  [ResultPage.DefaultApprovedPostOffer]: "/post-offer",
  [ResultPage.CSEAppt]: "/cse-advice-appt",
  [ResultPage.CSEFutureAppt]: "/cse-advice-appt-later",
  [ResultPage.ERC]: "/early-repayment-charge",
  [ResultPage.Rates]: "/mortgage-rates",
  [ResultPage.TableBrokerOnly]: "/mortgage-rates",
  [ResultPage.TableNoClickout]: "/mortgage-rates",
  [ResultPage.Together]: "/together",
  [ResultPage.BadCredit]: "/no-help",
  [ResultPage.Coach]: "/mortgage-coach",
  [ResultPage.Unaffordable]: "/mortgage-coach", // TODO: make coach variant with unaffordable messaging
  [ResultPage.Nurture]: "/nurture-hub",
}
