import { space } from "components/_blueprint/Layout"
import styled from "styled-components"
import devices from "styles/devices"
import WaveSvg from "url:assets/images/background-wave-2.svg"

export const Wave = styled.div`
  background: linear-gradient(137.52deg, #fff1e8 19.57%, #ffe2cd 79.66%);
  @media screen and ${devices.bp_lg} {
    flex-direction: row;
    background-image: url(${WaveSvg}), linear-gradient(137.52deg, #fff1e8 19.57%, #ffe2cd 79.66%);
    background-repeat: no-repeat, no-repeat;
    background-position: bottom, bottom;
    background-size: 200rem, cover;
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const InnerContainer = styled.div`
  display: flex;
  max-width: 80rem;
  margin: 5em auto 0;
  @media screen and ${devices.bp_lg} {
    margin-top: 6.5rem;
    position: relative;
    left: 5.2rem;
  }
`

export const Content = styled.div`
  max-width: 22rem;
  @media screen and ${devices.bp_lg} {
    display: flex;
    flex: 1;
    flex-direction: column;
    max-width: unset;
    margin-left: -5rem;
  }
`

export const HeroImageContainer = styled.div`
  display: none;
  flex: 0.8;
  height: 34rem;
  position: relative;
  @media screen and ${devices.bp_lg} {
    display: flex;
  }
`

export const HeroImage = styled.img`
  display: block;
  height: auto;
  transform: rotate(3.6deg);
  object-fit: contain;
  position: absolute;
  bottom: 7rem;
  width: 36rem;
  right: 2.5em;
`

export const Header = styled.h2`
  font-weight: 550;
  font-size: 1.75rem;
  line-height: 2rem;
  margin: 0 1.25rem 1rem;
  @media screen and ${devices.bp_lg} {
    margin: 0 0 1rem;
  }
`

export const Text = styled.p<{ bold?: boolean }>`
  margin: 0 1.25rem 1rem;
  line-height: 1.625rem;
  font-weight: ${(props) => (props.bold ? 550 : 0)};
  @media screen and ${devices.bp_lg} {
    margin: 0 1.25rem 1rem 0;
  }
`

export const GhostWarning = styled.p`
  font-size: 0.875rem;
  margin: 1rem 1.25rem 1rem 1.25rem;
  line-height: 1.625rem;
  @media screen and ${devices.bp_lg} {
    margin: 1rem 1.25rem 1rem 0;
  }
`

export const PriceEm = styled.span`
  padding: 0.188rem 0.75rem;
  font-weight: 550;
  border-radius: 38px;
  background-color: ${(props) => props.theme.primaryTwo};
`

export const ButtonContainer = styled.div`
  margin: 0 1.25rem;
  @media screen and ${devices.bp_lg} {
    margin: 0;
    &:first-of-type {
      margin-right: 1.25em;
    }
  }
`

export const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  > div {
    width: 100%;
  }
  > div:first-child {
    margin-right: 1em;
  }
  @media screen and ${devices.bp_lg} {
    flex-direction: row;
    font-size: 16px;
    width: unset;
  }
`

export const Wrapper = styled.div`
  background: #fafbfd;
  background-size: 100%;
  padding: 3.125rem 0;
`

export const InnerWrapper = styled.div<{ hasBooked: boolean }>`
  @media screen and ${devices.bp_lg} {
    margin-top: ${(props) => (props.hasBooked ? "0" : "-130px")};
  }
`

export const CarouselTitle = styled.h2`
  text-align: center;
  margin-top: 64px;

  @media screen and ${devices.bp_lg} {
    margin-top: 100px;
    margin-bottom: 32px;
  }
`

export const SmallPrint = styled.div`
  color: ${(props) => props.theme.shade20};
  line-height: 24px;
  display: flex;
  justify-content: center;
  margin: 0 1em 5em;
  p {
    max-width: 72em;
  }
  @media screen and ${devices.bp_lg} {
    margin: 0 1em;
  }
`

export const Pill = styled.div((props) => ({
  backgroundColor: props.theme.colors.white,
  borderRadius: space,
  border: "none",
  padding: space * 2,
  textAlign: "left",
}))
