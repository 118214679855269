import React, { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { DecodeJwt } from "lib/Jwt"
import { SetToken } from "lib/Authentication"
import { LoadingTemplate } from "templates/LoadingTemplate"

interface Token {
  emailAddress: string
  userId: number
  exp: number
  iat: number
}

export function LoginFromSession() {
  const { token, redirect } = useParams<{ token: string; redirect: string }>()
  const history = useHistory()
  const [hasExpired, setHasExpired] = useState(false)

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search).toString()

    const decoded = DecodeJwt<Token>(token)

    if (decoded.exp < Math.floor(Date.now() / 1000)) {
      setHasExpired(true)
      return
    }

    SetToken(token)

    if (redirect) {
      const page = Buffer.from(redirect, "base64").toString()
      history.push(`${page}?${urlSearchParams}`)
    }
  }, [])

  let isLoading = true
  let message = null

  if (hasExpired) {
    message = (
      <strong>
        Your login token has expired. <a href={"/login"}>Click here</a> to go to the login page and
        try again.
      </strong>
    )
    isLoading = false
  }

  return <LoadingTemplate message={message} isLoading={isLoading} />
}
