import React from "react"
import { Route, Switch, useRouteMatch } from "react-router"
import TableRedirectPage from "./Table"

/**
 * The /redirect route contains any number of routes that only exist to send the user to an
 * outside resource that require information from our internal applications.
 *
 * The CRM team can send out a magic link that logs the user in, sends them to one of these pages,
 * fetches their personal info, and then sends them wherever they need to go.
 */
const RedirectPage = () => {
  const match = useRouteMatch()
  return (
    <Switch>
      <Route path={`${match.path}/products`}>
        <TableRedirectPage />
      </Route>
    </Switch>
  )
}

export default RedirectPage
