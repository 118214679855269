import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { SelectInput } from "components/atoms/SelectInput"
import { Modal } from "components/_blueprint/Modal"
import { Heading, Paragraph } from "../../_blueprint/Typography"

const Layout = styled.div({
  display: "grid",
  gap: 8,
})

export type EmploymentOption =
  | "permanent_employment"
  | "temporary"
  | "agency"
  | "sole_trader"
  | "director_of_a_ltd_company"
  | "partner_in_an_llp"
  | "retired"
  | "not_working"
  | "contractor"
  | ""

export const isSelfEmployed = (status: EmploymentOption | null): boolean =>
  ["sole_trader", "director_of_a_ltd_company", "partner_in_an_llp"].includes(status)

export const isNotEmployed = (status: EmploymentOption | null): boolean =>
  ["retired", "not_working"].includes(status)

const options: Array<{ text: string; value: EmploymentOption }> = [
  { text: "Employed — Permanent", value: "permanent_employment" },
  { text: "Employed — Temporary", value: "temporary" },
  { text: "Employed — Agency", value: "agency" },
  { text: "Self Employed — Sole trader", value: "sole_trader" },
  { text: "Self Employed — Director of Ltd company", value: "director_of_a_ltd_company" },
  { text: "Self Employed — Partner in an LLP", value: "partner_in_an_llp" },
  { text: "Contractor", value: "contractor" },
  { text: "Retired", value: "retired" },
  { text: "Not working", value: "not_working" },
]

type EmploymentInfo = {
  firstApplicant: EmploymentOption
  secondApplicant: EmploymentOption
}

type Props = {
  id: string
  onDone: (employmentInfo: EmploymentInfo) => void
  secondApplicantName?: string
  value?: EmploymentInfo
}

const Trigger = styled.button((props) => ({
  background: "transparent",
  border: "1px solid transparent",
  color: props.theme.primary,
  cursor: "pointer",
  margin: 8,
  textDecoration: "underline",
}))

export const EmploymentStatus = ({ id, onDone, secondApplicantName, value }: Props) => {
  const [firstApplicant, setFirstApplicant] = useState<EmploymentOption>(
    value?.firstApplicant ?? ""
  )
  const [secondApplicant, setSecondApplicant] = useState<EmploymentOption>(
    value?.secondApplicant ?? ""
  )
  useEffect(() => {
    handleComplete()
  }, [firstApplicant, secondApplicant])

  const answered = firstApplicant && (secondApplicantName ? secondApplicant : true)
  const handleComplete = () => {
    if (answered) {
      onDone({ firstApplicant, secondApplicant })
    } else {
      onDone(null)
    }
  }

  return (
    <Layout>
      <SelectInput<EmploymentOption>
        defaultOptionText="Please select"
        label="Your employment status"
        id={id + "_firstApplicant"}
        onChange={setFirstApplicant}
        options={options}
        value={firstApplicant}
      />
      {secondApplicantName && (
        <SelectInput<EmploymentOption>
          defaultOptionText="Please select"
          label={secondApplicantName + "’s employment status"}
          id={id + "_secondApplicant"}
          onChange={setSecondApplicant}
          options={options}
          value={secondApplicant}
        />
      )}
      <EmploymentTypesExplanation />
    </Layout>
  )
}

const EmploymentTypesExplanation = () => (
  <Modal
    description="An explanation of the different employment types"
    triggerElement={<Trigger>Employment types explained</Trigger>}
  >
    <Heading as="h2" color="white" size="h2">
      Employment types
    </Heading>
    <Heading as="h4" color="white" size="h4">
      Permanent employment
    </Heading>
    <Paragraph color="white">
      You’re hired by a company to work for an indefinite period. You receive regular pay and are
      subject to company policies.
    </Paragraph>
    <Heading as="h4" color="white" size="h4">
      Temporary employment
    </Heading>
    <Paragraph color="white">
      You’re hired by a company, as an employee, to work for a specific period only, e.g. for a
      fixed term, seasonal work or a particular project.
    </Paragraph>
    <Heading as="h4" color="white" size="h4">
      Agency employment
    </Heading>
    <Paragraph color="white">
      You work for a staffing agency, which then places you with a company to work on a temporary or
      permanent basis.
    </Paragraph>
    <Heading as="h4" color="white" size="h4">
      Sole trader
    </Heading>
    <Paragraph color="white">
      You own and operate your own business. You’re responsible for all aspects of the business,
      including taxes, accounting, and legal issues.
    </Paragraph>
    <Heading as="h4" color="white" size="h4">
      Director of LTD company
    </Heading>
    <Paragraph color="white">
      You’re responsible for managing the company and ensuring that it operates in compliance with
      the law.
    </Paragraph>
    <Heading as="h4" color="white" size="h4">
      Partner in an LLP
    </Heading>
    <Paragraph color="white">
      You’re a co-owner of a business and share in the profits and losses. Each partner is
      responsible for their own taxes, and the LLP provides limited liability protection for the
      partners.
    </Paragraph>
    <Heading as="h4" color="white" size="h4">
      Contractor
    </Heading>
    <Paragraph color="white">
      You are hired to provide your services to companies on a contract by contract basis for a
      specified period of time.
    </Paragraph>
  </Modal>
)
