import React, { useEffect } from "react"
import styled from "styled-components"
import { Button, ButtonType } from "components/atoms"
import devices from "styles/devices"
import { PaperworkNoShadow } from "components/Illustrations"
// @ts-ignore
import WaveSvg from "url:assets/images/background-wave-2.svg"
import { LogGaEvent } from "lib/GoogleAnalytics"
import { GetContent } from "lib/GetContent"
import {
  ButtonContainer,
  Container,
  Content,
  GhostWarning,
  Header,
  HeroImage,
  HeroImageContainer,
  InnerContainer,
  PriceEm,
  Text,
  Wave,
} from "../../pages/Application/Eligibility/IncreaseBookings/Shared"
import { EventAction, EventCategory, EventLabel } from "models/GoogleAnalytics"
import { RatesBanner } from "components/molecules/RatesBanner"

type Props = {
  title: string
  pricingCopyType: PricingCopyType
  onBookAppointment: () => void
  mip: string
  bookingComplete: boolean
  isGhostAppt: boolean
}

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  @media screen and ${devices.bp_lg} {
    flex-direction: row;
    font-size: 16px;
  }
`

const Bold = styled.span`
  font-weight: 0;
  @media screen and ${devices.bp_lg} {
    font-weight: 550;
  }
`

export enum PricingCopyType {
  Free1,
  Free2,
  Cost49,
  Cost99,
}

export const QualifyForHBE = ({
  title,
  pricingCopyType,
  onBookAppointment,
  mip,
  bookingComplete,
  isGhostAppt,
}: Props) => {
  const content = GetContent({
    default: {
      tracking: {
        label: "HBE Appointment Cost",
        value: 0,
      },
      pricingCopy: {
        text: (
          <>
            <Text>
              As you're still searching for that <Bold>perfect home</Bold>, we think it'd be really
              useful for you to speak to our Home Buying Expert team and let them take care of
              everything
            </Text>
            <Text bold={true}>
              It's totally <PriceEm>free</PriceEm> and could save you £1000's.
            </Text>
          </>
        ),
      },
    },
    [PricingCopyType.Free2]: {
      tracking: {
        label: "HBE Appointment Cost - Get your Mortgage in Principle completed by an expert",
        value: 0,
      },
      pricingCopy: {
        text: (
          <>
            <Text>
              You can download a basic Mortgage in Principle right now, but that's only half the
              job. Let's finalise it and get the{" "}
              <Bold>ultimate reassurance with a verified Mortgage in Principle.</Bold> Simply speak
              to our Home Buying Experts. 😊
            </Text>
            <Text bold={true}>
              It's totally <PriceEm>free</PriceEm> and could save you £1000's.
            </Text>
          </>
        ),
      },
    },
    [PricingCopyType.Cost49]: {
      tracking: {
        label: "HBE Appointment Cost",
        value: 49,
      },
      pricingCopy: {
        text: (
          <>
            <Text>
              As you're still searching for that <Bold>perfect home</Bold>, we think it'd be really
              useful for you to speak to our Home Buying Expert team and let them take care of
              everything
            </Text>
            <Text bold={true}>
              It costs a one off <PriceEm>£49</PriceEm> payment, and could save you £1000’s.
            </Text>
          </>
        ),
      },
    },
    [PricingCopyType.Cost99]: {
      tracking: {
        label: "HBE Appointment Cost",
        value: 99,
      },
      pricingCopy: {
        text: (
          <>
            <Text>
              As you're still searching for that <Bold>perfect home</Bold>, we think it'd be really
              useful for you to speak to our Home Buying Expert team and let them take care of
              everything
            </Text>
            <Text bold={true}>
              It costs a one off <PriceEm>£99</PriceEm> payment, and could save you £1000’s.
            </Text>
          </>
        ),
      },
    },
  })(pricingCopyType)

  useEffect(() => {
    LogGaEvent({
      action: EventAction.componentDisplay,
      event_category: EventCategory.siteInteraction,
      event_label: content.tracking.label,
      value: content.tracking.value,
    })
    LogGaEvent({
      action: EventAction.componentDisplay,
      event_category: EventCategory.siteInteraction,
      event_label: EventLabel.bookAnAppointment,
    })
    LogGaEvent({
      action: EventAction.componentDisplay,
      event_category: EventCategory.siteInteraction,
      event_label: EventLabel.downloadMip,
    })
  }, [])
  return (
    <Wave>
      <RatesBanner />
      <Container>
        <InnerContainer>
          <Content>
            <Header>{title}</Header>
            {content.pricingCopy.text}
            <Buttons>
              <ButtonContainer>
                <Button
                  disabled={bookingComplete}
                  onClick={() => {
                    LogGaEvent({
                      action: EventAction.buttonClicked,
                      event_category: EventCategory.functionalInteraction,
                      event_label: EventLabel.bookAnAppointment,
                    })
                    onBookAppointment()
                  }}
                  text={"Book an Appointment"}
                />
              </ButtonContainer>
              <ButtonContainer>
                {mip && (
                  <Button
                    type={ButtonType.Outline}
                    onClick={() => {
                      LogGaEvent({
                        action: EventAction.buttonClicked,
                        event_category: EventCategory.functionalInteraction,
                        event_label: EventLabel.downloadMip,
                      })
                      window.open(mip, "_blank")
                    }}
                    text={"Download a Mortgage in Principle"}
                  />
                )}
              </ButtonContainer>
            </Buttons>
            {isGhostAppt && (
              <GhostWarning>
                This is a high demand callback slot. We aim to call within the 30 minute slot. If we
                cannot, we will email you to re-arrange.
              </GhostWarning>
            )}
          </Content>
          <HeroImageContainer>
            <HeroImage src={PaperworkNoShadow} alt="Paperwork" />
          </HeroImageContainer>
        </InnerContainer>
      </Container>
    </Wave>
  )
}
