import React from "react"
import { Card } from "components/atoms/Card"
import styled from "styled-components"
import { useApplicationSummaries } from "hooks/useApplicationSummaries"
import { Button } from "components/atoms"
import { Trustpilot } from "components/atoms/Trustpilot"
import { HasApplicationExpired } from "lib/Applications"
import { useHistory } from "react-router-dom"
import { Chat } from "components/Illustrations"
import { ButtonType } from "components/atoms/Button"
import { Loader, LoaderTheme } from "components/atoms/Loader"
import { LogGaEvent } from "lib/GoogleAnalytics"
import { EventAction, EventCategory, EventLabel } from "models/GoogleAnalytics"
import { redirectToDiscovery } from "lib/discovery"

const Container = styled.div`
  display: grid;
  flex-direction: column;
  justify-content: space-between;
  height: 270px;
  margin-top: 40px;
  justify-content: center;
`

const WelcomeBack = styled.p`
  font-weight: 800;
  font-size: 32px;
  text-align: center;
  color: #444444;
  margin: 0;
  padding: 0;
`

const ActiveApplications = styled.p`
  text-align: center;
`

const FooterContainer = styled.div`
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column-gap: 10px;
`

const TrustpilotWrapper = styled.div`
  height: 47px;
  width: 130px;
`

const TrustpilotSideText = styled.div`
  align-self: center;
  font-size: 0.83em;
  padding: 0 0 0 10px;
`

const SittingPeopleImage = styled.img`
  width: 200px;
  position: absolute;
  top: -135px;
  right: 10px;
`

const LoaderContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`

export function WelcomeBackCard() {
  const { applicationSummaries, hasFetchedSummaries } = useApplicationSummaries()
  const history = useHistory()

  const activeCount = applicationSummaries.reduce((total, summary) => {
    return HasApplicationExpired(summary) ? total : total + 1
  }, 0)

  return (
    <Card
      content={
        <Container>
          <SittingPeopleImage src={Chat} alt="" />
          <WelcomeBack>Welcome back</WelcomeBack>
          {hasFetchedSummaries ? (
            <ActiveApplications>
              You've got <strong>{activeCount}</strong> active application
              {applicationSummaries.length === 1 ? "" : "s"}.
            </ActiveApplications>
          ) : (
            <LoaderContainer>
              <Loader size={30} theme={LoaderTheme.Outline} />
            </LoaderContainer>
          )}

          <Button
            id="button-new-application"
            type={ButtonType.Outline}
            onClick={() => {
              LogGaEvent({
                action: EventAction.buttonClicked,
                event_category: EventCategory.functionalInteraction,
                event_label: EventLabel.startApplication,
              })
              redirectToDiscovery()
            }}
            text="Start a new application"
          />
          <FooterContainer>
            <TrustpilotWrapper>
              <Trustpilot height={"47px"} />
            </TrustpilotWrapper>
            <TrustpilotSideText>Join the other 67,000 happy mojo'ers</TrustpilotSideText>
          </FooterContainer>
        </Container>
      }
    />
  )
}
