import { useEffect, useLayoutEffect, useState } from "react"
import { LogGaEvent, PageView } from "lib/GoogleAnalytics"
import { useLocation } from "react-router-dom"
import { EventAction, EventCategory } from "models/GoogleAnalytics"

export function useTrackNavigatedToPage(page: string) {
  const location = useLocation()
  const [hasSet, setHasSet] = useState(false)

  useEffect(() => {
    setHasSet(false)
  }, [location])

  useLayoutEffect(() => {
    if (hasSet) return

    PageView(page)
    setHasSet(true)
  }, [hasSet])

  return { hasSet }
}

export function useTrackNavigatedToSection(section: string) {
  const [hasSet, setHasSet] = useState(false)

  useLayoutEffect(() => {
    LogGaEvent({
      action: EventAction.sectionStarted,
      event_category: EventCategory.siteInteraction,
      event_label: section,
    })
    setHasSet(true)
  }, [])

  return { hasSet }
}
