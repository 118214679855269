import React, { useState, useEffect, useRef } from "react"
import { Input } from "../atoms/Input"

type Props = {
  value?: number
  onDone?: (value: number, errors: string[]) => void
  id?: string
  onKeyDown?: (e: any) => void
  placeholder?: string
}

export default function NumberInput({
  value,
  onDone,
  id = "",
  onKeyDown = (e) => {},
  placeholder,
}: Props) {
  const [input, setInput] = useState<number>(value)
  const mounted = useRef(false)

  useEffect(() => {
    const errors: string[] = []
    if (mounted.current) {
      onDone(+input, errors)
    } else {
      mounted.current = true
    }
  }, [input])

  return (
    <Input
      value={input}
      inputMode="decimal"
      onKeyDown={(e) => onKeyDown(e)}
      onChange={(e) => handleChange(e.target.value)}
      id={`number-${id.toLowerCase()}`}
      autoComplete="off"
      placeholder={placeholder}
    />
  )

  function handleChange(newValue: number) {
    const numberRegex = /^[0-9]*$/

    if (numberRegex.test(newValue.toString())) {
      setInput(newValue)
    }
  }
}
