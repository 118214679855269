import { BookingCalendar } from "components/organisms/Calendar/BookingCalendar"
import { useApplicationFriendlyId } from "hooks/useApplication"
import React, { useEffect } from "react"
import { useHistory, useParams, useRouteMatch } from "react-router-dom"
import styled, { useTheme } from "styled-components"
import devices from "styles/devices"
import { HeaderStyle, MyMojoTemplate, PageTheme } from "templates/MyMojoTemplate"
import { LogGaEvent } from "lib/GoogleAnalytics"
import { EventAction, EventCategory } from "models/GoogleAnalytics"
import { useTrackNavigatedToPage } from "hooks/useTrackPageView"
import Typography from "components/_blueprint/Typography"
import advisors from "assets/images/advisors2.png"
import { IconStyleable, IconType } from "components/atoms/Icon"

const ContentArea = styled.div`
  max-width: 1500px;
  padding: 3.2rem 1rem;
  margin: 0 auto;
  margin-bottom: 8rem;
  width: 100%;
  height: fit-content;

  @media ${devices.bp_md} {
    padding: 2.4rem;
  }

  @media ${devices.bp_lg} {
    padding: 5rem 7rem 7rem;
  }
`

const AdvisorsImage = styled.img`
  display: none;
  align-self: flex-start;

  @media ${devices.bp_lg} {
    display: block;
  }
`

const Backbutton = styled.button`
  background: none;
  border: none;
  display: flex;
  gap: 0.25rem;
  cursor: pointer;
`

/**
 * Renders a calendar that allows users to book an appointment
 */
const EligibilityBook = () => {
  const { friendlyId } = useParams<{ friendlyId: string }>()
  const { application, refreshApplication, hasLoaded } = useApplicationFriendlyId(friendlyId)
  const match = useRouteMatch()
  const history = useHistory()
  const theme = useTheme()

  useTrackNavigatedToPage("/application/eligibility/book")

  /**
   * When the user loads the page
   */
  useEffect(() => {
    LogGaEvent({
      action: EventAction.componentDisplay,
      event_category: EventCategory.siteInteraction,
      event_label: "Book an appointment",
    })
  }, [])

  return (
    <MyMojoTemplate
      backgroundWave
      stickyHeader={false}
      colourTheme={PageTheme.Alternate}
      headerStyle={HeaderStyle.Transparent}
      content={
        <ContentArea>
          <Backbutton onClick={() => history.goBack()}>
            <span
              style={{
                transform: "rotate(90deg)",
                display: "block",
              }}
            >
              <IconStyleable
                fillDefault={theme.primaryThree}
                fillHover={theme.primaryThree}
                width={20}
                height={20}
                type={IconType.ArrowDown}
              />
            </span>
            <Typography.Text>Back</Typography.Text>
          </Backbutton>
          <Typography.H2>Book your free appointment</Typography.H2>
          <Typography.Body weight={500}>
            It's the easiest way to be sure you’ve found your best deal from over 90 mortgage
            lenders.
          </Typography.Body>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <BookingCalendar
              skipToCalendar
              friendlyId={friendlyId}
              application={application}
              calendarId={null}
              refreshApplication={refreshApplication}
              customHeader={
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 1,
                    }}
                  >
                    <Typography.H5>Get your recommendation</Typography.H5>
                    <Typography.SmallBody>
                      Choose a day and time that suits. It’ll only be a short call.
                    </Typography.SmallBody>
                  </div>
                  <AdvisorsImage src={advisors} />
                </div>
              }
            />
          </div>
        </ContentArea>
      }
    />
  )
}

export default EligibilityBook
