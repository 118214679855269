import React, { Fragment } from "react"
import styled from "styled-components"
import { Icon, IconType } from "components/atoms/Icon"
import { ErrorDisplay } from "components/molecules"
import { Loader, LoaderTheme } from "../atoms/Loader"
import devices from "styles/devices"

const CalendarContainer = styled.div`
  border-radius: 8px;
  margin: 0 auto;
  position: relative;
`

const CalendarTable = styled.table`
  margin-bottom: 0;
`

const WeekdayHeaders = styled.thead`
  border-bottom: none;
`

const WeekdayHeader = styled.th`
  font-size: 0.75em;
  color: ${(props) => props.theme.shade20};
  text-transform: uppercase;
  text-align: center;
  font-weight: 400;
  border-bottom: none;
  padding: 0 8px;
  @media screen and ${devices.bp_lg} {
    padding: 0 16px 16px;
  }
`

const MonthChangers = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`

const MonthChanger = styled.div<{ active: boolean; disableClicks: boolean }>`
  display: flex;
  width: 1.5em;
  height: 1.5em;
  text-align: center;
  cursor: ${(props) => (props.disableClicks ? "default" : "pointer")};
  background: none;
  border: none;
  ${({ active, theme }) =>
    active &&
    `& .dropdown_svg__fill {
            fill: ${theme.cta};
        }`}
`

const MonthLeft = styled(MonthChanger)`
  transform: rotate(90deg);
`

const MonthRight = styled(MonthChanger)`
  transform: rotate(-90deg);
`
const MonthHeader = styled.div`
  font-size: 1em;
  line-height: 1.5em;
  font-weight: 600;
  color: ${(props) => props.theme.shade20};
  position: relative;
  left: 0.5em;
`

const CalendarCell = styled.td`
  font-size: 0.75em;
  text-align: center;
  color: ${(props) => props.theme.shade20};
  border-bottom: none;
  @media screen and ${devices.bp_lg} {
    padding-bottom: 12px;
  }
`

const TodayCell = styled(CalendarCell)`
  color: ${(props) => props.theme.shade10};
  font-weight: bold;
`
const NoSlots = styled.div`
  width: 2.75em;
`

const Day = styled.div<{
  active: boolean
  available: boolean
  disableClicks: boolean
}>`
  width: 2.75em;
  height: 2.75em;
  cursor: ${(props) => (props.disableClicks ? "default" : props.available ? "pointer" : "default")};
  border: 1px solid ${(props) => (props.available ? props.theme.shade70 : "transparent")};
  border-radius: 2.75em;
  line-height: 2.5em;
  background: ${(props) => (props.active ? props.theme.primaryOne : "transparent")};
  color: ${(props) =>
    props.active
      ? props.theme.shade100
      : props.available
      ? props.theme.shade20
      : props.theme.shade60};
`

const CalendarHeaderContainer = styled.div`
  display: flex;
  margin-bottom: 0.5em;
`

const LoaderContainer = styled.div<{ isLoading: boolean }>`
  position: absolute;
  top: 0;
  display: ${(props) => (props.isLoading ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;
  height: 100%;
`

export interface CalendarHeaderProps {
  month: Date
  onSelectNextMonth: (currentMonth: Date) => void
  onSelectPreviousMonth: (currentMonth: Date) => void
  disableClicks: boolean
}

const CalendarHeader = ({
  month,
  onSelectNextMonth,
  onSelectPreviousMonth,
  disableClicks,
}: CalendarHeaderProps) => {
  return (
    <CalendarHeaderContainer>
      <MonthHeader data-testid="calendar-header">
        {month.toLocaleString("default", {
          month: "long",
          year: "numeric",
        })}
      </MonthHeader>
      <MonthChangers>
        <MonthLeft
          role="button"
          data-testid="month-left"
          active={true}
          disableClicks={disableClicks}
          onClick={() => {
            if (!disableClicks) {
              onSelectPreviousMonth(month)
            }
          }}
        >
          <Icon type={IconType.ArrowDown} />
        </MonthLeft>
        <MonthRight
          role="button"
          data-testid="month-right"
          active={true}
          disableClicks={disableClicks}
          onClick={() => {
            if (!disableClicks) {
              onSelectNextMonth(month)
            }
          }}
        >
          <Icon type={IconType.ArrowDown} />
        </MonthRight>
      </MonthChangers>
    </CalendarHeaderContainer>
  )
}

const Weekdays = () => {
  const weekdayShort = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
  return (
    <WeekdayHeaders>
      <tr>
        {weekdayShort.map((day) => {
          return <WeekdayHeader key={day}>{day}</WeekdayHeader>
        })}
      </tr>
    </WeekdayHeaders>
  )
}

export interface CalendarProps {
  errorMessage: string
  /**
   * Only dates specified in this array will be made clickable
   */
  dates: string[]
  /**
   * Callback when user selects the next month on the calendar
   */
  onSelectNextMonth: CalendarHeaderProps["onSelectNextMonth"]
  /**
   * Callback when user selects the previous month on the calendar
   */
  onSelectPreviousMonth: CalendarHeaderProps["onSelectPreviousMonth"]
  /**
   * The month currently selected by the user.
   * UPDATE THIS MANUALLY ON EVERY MONTH CHANGE!
   */
  currentDisplayedMonth: Date
  /**
   * Callback when user clicks on a date
   */
  onSelectDate: (date: Date) => void
  /**
   * The user's currently selected date
   * UPDATE THIS MANUALLY ON EVERY DATE CHANGE!
   */
  selectedDate: Date
  /**
   * Disables clicking on the day calendar cells
   */
  disableClicks: boolean
  /**
   * Displays a loading spinner instead of the calendar
   */
  isLoading: boolean
}

/**
 * This is the central UI for calendars, actual calendar functionality should be built on top of this and displayed using this component.
 */
const Calendar = ({
  errorMessage,
  dates,
  onSelectNextMonth,
  onSelectPreviousMonth,
  currentDisplayedMonth,
  onSelectDate,
  selectedDate,
  disableClicks,
  isLoading,
}: CalendarProps) => {
  const getDaysInMonth = (date) => {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()
  }

  const isToday = (month: Date, day: number) => {
    const today = new Date()
    return (
      today.getFullYear() === month.getFullYear() &&
      today.getMonth() === month.getMonth() &&
      today.getDate() === day
    )
  }

  const blanks = []
  for (let i = 0; i < currentDisplayedMonth.getDay(); i++) {
    blanks.push(<CalendarCell key={`blank-${i}`} />)
  }

  const daysInMonth = []
  for (let d = 1; d <= getDaysInMonth(currentDisplayedMonth); d++) {
    const dateString = `${currentDisplayedMonth.getFullYear()}-${currentDisplayedMonth.toLocaleString(
      "default",
      {
        month: "2-digit",
      }
    )}-${d.toLocaleString("default", { minimumIntegerDigits: 2 })}`
    const dayRender = (
      <Day
        data-testid={dates.includes(dateString) ? "active" : "inactive"}
        id={`day-${d}`}
        data-dayId={`day-${d}`}
        available={dates.includes(dateString)}
        active={
          selectedDate &&
          `${selectedDate.getFullYear()}-${selectedDate.toLocaleString("default", {
            month: "2-digit",
          })}-${selectedDate.toLocaleString("default", { day: "2-digit" })}` === dateString
        }
        disableClicks={disableClicks}
        onClick={() => {
          if (!disableClicks && dates.includes(dateString)) {
            onSelectDate(new Date(dateString))
          }
        }}
      >
        {d}
      </Day>
    )

    daysInMonth.push(
      <>
        {isToday(currentDisplayedMonth, d) ? (
          <TodayCell key={d}>{dayRender}</TodayCell>
        ) : (
          <CalendarCell key={d}>{dayRender}</CalendarCell>
        )}
      </>
    )
  }

  const totalSlots = [...blanks, ...daysInMonth]

  if (errorMessage) {
    return <ErrorDisplay children={errorMessage} />
  }

  return (
    <CalendarContainer>
      <CalendarHeader
        month={currentDisplayedMonth}
        onSelectNextMonth={onSelectNextMonth}
        onSelectPreviousMonth={onSelectPreviousMonth}
        disableClicks={disableClicks}
      />
      <CalendarTable>
        <Weekdays />
        <tbody>
          {totalSlots.map((_, i) => {
            return (
              i % 7 === 0 && (
                <tr key={`week-${i}`}>
                  {totalSlots.slice(i, i + 7).map((item, i) => {
                    return <Fragment key={`day-${i}`}>{item}</Fragment>
                  })}
                </tr>
              )
            )
          })}
        </tbody>
      </CalendarTable>
      <LoaderContainer isLoading={isLoading}>
        <Loader theme={LoaderTheme.Outline} size={100} />
      </LoaderContainer>
    </CalendarContainer>
  )
}

export default Calendar
