import React from "react"
import { useApplicationFriendlyId } from "hooks/useApplication"
import styled from "styled-components"
import { ValueView } from "components/atoms/ValueView"
import { OpportunityStage } from "models/FullApplication"

const AppStageText = styled.div`
  text-align: center;
  width: 100%;
  color: ${(props) => props.theme.primary};
  font-size: 0.9em;
`

export function AppStageBadge({ friendlyId }) {
  const { application, hasLoaded } = useApplicationFriendlyId(friendlyId)

  function GetStatusText(): string {
    if (!hasLoaded) return ""

    const status = application?.metadata?.edeFlag || application?.metadata?.opportunity?.status

    if (status) {
      if (status === OpportunityStage.dip) return "Decision in Principle"
      if (status === OpportunityStage.ineligable) return "Can’t Help"
      if (status === OpportunityStage.quoted) return "Recommendation ready"
      return status
    }
    return OpportunityStage.factfind
  }

  return <ValueView value={<AppStageText>{GetStatusText()}</AppStageText>} />
}
