import React, { useEffect, useRef } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import NumberFormat from "react-number-format"

const Container = styled.div`
  border: 1px solid ${(props) => props.theme.shade[60]};
  background: ${(props) => (props.disabled ? props.theme.shade[80] : props.theme.shade[100])};
  position: relative;
  border-radius: ${(props) => props.theme.radius.default};
  :focus-within {
    border: 1px solid ${(props) => props.theme.cta};
    box-shadow: 0 0 0 1px ${(props) => props.theme.cta};
  }
`
const Label = styled.label`
  position: absolute;
  width: 100%;
  display: block;
  transition: 0.4s;
  padding-left: 16px;
  padding-right: 16px;
  top: 0;
  bottom: 0;
  margin: auto;
  line-height: 44px;
  pointer-events: none;
  user-select: none;
  color: ${(props) => props.theme.shade[20]};
  z-index: 1;
  white-space: nowrap;
  text-align: right;
  p {
    display: inline-block;
    transition: 0.4s;
  }
`
const InnerInput = styled(NumberFormat)`
  display: block;
  border: none;
  background: transparent;
  width: 100%;
  height: 100%;
  padding: 12px 16px;
  font-size: 16px;
  box-sizing: border-box;
  outline: none;
  color: ${(props) => (props.label == undefined ? props.theme.shade[0] : props.theme.shade[100])};
  transition: 0.4s;
  &:empty {
    + ${Label} {
      width: 0;
    }
  }
  &:focus,
  &:not(:placeholder-shown) {
    color: ${(props) => props.theme.shade[0]};
    + ${Label} {
      width: 100%;
      p {
        transform-origin: right;
        transform: scale(0.8);
      }
      /* The 0.5 is to fix a bug with overflow */
    }
  }
`

export function FormatInput(props) {
  const [dimensions, setDimensions] = React.useState({
    labelWidth: 0,
  })
  const labelRef = useRef(null)

  useEffect(() => {
    setDimensions({
      labelWidth: labelRef.current.clientWidth,
    })
  }, [])
  return (
    <Container disabled={props.disabled}>
      <InnerInput
        type="text"
        id={props.id}
        disabled={props.disabled}
        placeholder={props.placeholder}
        value={props.value}
        formattedValue={props.formattedValue}
        floatValue={props.floatValue}
        onChange={props.onChange}
        onClick={props.onClick}
        labelOffset={dimensions.labelWidth}
        {...props}
      />
      <Label htmlFor={props.id} disabled={props.disabled} ref={labelRef}>
        {props.label}
      </Label>
    </Container>
  )
}

FormatInput.PropTypes = {
  placeholder: PropTypes.string,
}
