import { BenefitsBox } from "components/organisms/Benefits"
import Carousel from "components/organisms/Carousel"
import { TrustSignals } from "components/organisms/TrustSignals"
import React, { Dispatch, SetStateAction } from "react"
import { PricingCopyType, QualifyForHBE } from "components/organisms/QualifyForHBE"
import styled from "styled-components"
import devices from "styles/devices"
import { Booking } from "pages/Application/Eligibility/Approved/Booking"
import { FullApplication } from "models/FullApplication"
import { Variant } from "pages/Application/Eligibility/Approved/Approved"

type Props = {
  title: string
  pricingCopyType: PricingCopyType
  onBookAppointment: () => void
  mip: string
  fullApplication: FullApplication
  isBookingVisible: boolean
  setIsBookingVisible: Dispatch<SetStateAction<boolean>>
  bookingComplete: boolean
  setBookingComplete: Dispatch<SetStateAction<boolean>>
  variant?: Variant
  refreshApplication: () => void
  isGhostAppt: boolean
}

const Root = styled.div``
const Wrapper = styled.div`
  margin-top: 2rem;
  @media screen and ${devices.bp_lg} {
    margin-top: -150px;
  }
`
export const HbePage = ({
  title,
  pricingCopyType,
  onBookAppointment,
  mip,
  fullApplication,
  isBookingVisible,
  setIsBookingVisible,
  bookingComplete,
  setBookingComplete,
  variant,
  refreshApplication,
  isGhostAppt,
}: Props) => (
  <Root>
    <QualifyForHBE
      title={title}
      pricingCopyType={pricingCopyType}
      onBookAppointment={onBookAppointment}
      mip={mip}
      bookingComplete={bookingComplete}
      isGhostAppt={isGhostAppt}
    />
    <Wrapper>
      <Booking
        isBookingVisible={isBookingVisible}
        setIsBookingVisible={setIsBookingVisible}
        bookingComplete={bookingComplete}
        setBookingComplete={setBookingComplete}
        fullApplication={fullApplication}
        refreshApplication={refreshApplication}
      />
      <BenefitsBox variant={variant} />
      <TrustSignals />
      <Carousel />
    </Wrapper>
  </Root>
)
