export type ApplicationError = {
  errorId?: string
  code: ErrorCode
  data: any
}

export enum ErrorCode {
  Unknown,
  FetchingAllApplications,
  FetchingExperienceTLS,
}
