import React from "react"
import styled from "styled-components"

// @ts-ignore
import MojoLogo from "url:assets/images/mojo-logo.png"
import { Loader, LoaderTheme } from "components/atoms/Loader"

const MojoLogoImage = styled.img`
  align-self: center;
  width: 200px;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 45vh;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 3em;
`

export function LoadingTemplate({ message, isLoading }) {
  return (
    <Container>
      <MojoLogoImage src={MojoLogo} alt="mojo logo" />
      {message && message}
      {isLoading && <Loader size={70} theme={LoaderTheme.Outline} />}
    </Container>
  )
}
