import React, { useEffect } from "react"
import styled from "styled-components"
import { GetContent } from "lib/GetContent"

const TitleState = styled.div<{ backgroundColor: string; textColor: string }>`
  background: ${(props) => props.backgroundColor};
  color: ${(props) => props.textColor};
  border-radius: 8px;
  padding: 8px 12px;
  font-weight: 550;
`

export const enum CoachRatings {
  Great = "Great",
  AlmostThere = "Almost there",
  NeedsWork = "Needs work",
  default = "default",
}

interface Colors {
  backgroundColor: string
  textColor: string
}

interface Props {
  titleState: string
  track?: () => void
}

const CoachRating = ({ titleState, track }: Props) => {
  useEffect(track, [])

  const colors: Colors = GetContent({
    [CoachRatings.Great]: {
      backgroundColor: "rgba(39, 209, 138, 0.1);",
      textColor: "#18C47C",
    },
    [CoachRatings.AlmostThere]: {
      backgroundColor: "rgba(251, 187, 91, 0.1);",
      textColor: "#FAAC37",
    },
    [CoachRatings.NeedsWork]: {
      backgroundColor: "rgba(240, 65, 65, 0.1);",
      textColor: "#F04141",
    },
    [CoachRatings.default]: {
      backgroundColor: "rgba(39, 209, 138, 0.1);",
      textColor: "#18C47C",
    },
  })(titleState)

  return <TitleState {...colors}>{titleState}</TitleState>
}

export default CoachRating
