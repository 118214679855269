import React from "react"
import { CheckButton } from "mojo-storybook-v2-library"
import styled from "styled-components"
import { questionMarkColors, Tooltip } from "./Tooltip"

const ButtonWrapper = styled.div`
  margin: 0.5rem 0;
  position: relative;

  ${({ hasTooltip }) =>
    hasTooltip &&
    `
	label {
		padding-right: 45px;
	}`}

  > * * {
    font-size: 14px;
  }
`

type Option = {
  value: string
  text: string
  tooltip?: {
    title?: string
    content?: string
  }
}

type Props = {
  id: string
  options: Option[]
  value: string[]
  onClick: (value: string[]) => void
}

export function MultiSelect({ id, options, value = [], onClick }: Props) {
  const handleClick = (e) => {
    const val = e.target.value
    const values = new Set(value)

    if (values.has(val)) {
      values.delete(val)
    } else {
      val === "unknown" ? values.clear() : values.delete("unknown")
      val === "none" ? values.clear() : values.delete("none")
      values.add(val)
    }

    onClick(Array.from(values))
  }

  return (
    <>
      {options.map((option, index) => (
        <ButtonWrapper hasTooltip={!!option.tooltip} key={id + option.text + index}>
          {option.tooltip && (
            <Tooltip
              id={id + "tooltip"}
              questionMarkColor={
                value.includes(option.value) ? questionMarkColors.WHITE : questionMarkColors.NAVY
              }
              option={option}
            />
          )}
          <CheckButton
            name={`${id}${index}`}
            value={option.value}
            checked={value?.includes(option.value)}
            onClick={handleClick}
            id={`radio-${id}-${index}-${option.value}`}
          >
            {option.text}
          </CheckButton>
        </ButtonWrapper>
      ))}
    </>
  )
}
