import React from "react"
import { Button } from "components/atoms"
import { ButtonType } from "components/atoms/Button"
import { BackArrowIcon } from "components/atoms/BackArrowIcon"
import styled from "styled-components"

const ButtonTextLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 75px;
`

export type Props = {
  id?: string
  onClick?: () => void
  customText?: string
}

export function BackButton({ id, onClick = () => {}, customText }: Props) {
  return (
    <Button
      id={id}
      type={ButtonType.Outline}
      onClick={onClick}
      text={
        <ButtonTextLayout>
          <BackArrowIcon />
          {customText ? customText : "Back"}
        </ButtonTextLayout>
      }
    />
  )
}
