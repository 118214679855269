import React, { useState, useEffect } from "react"
import { FormatInput } from "../atoms/FormatInput"
import styled from "styled-components"

const ErrorStyles = styled.div`
  color: ${({ theme }) => theme.error};
  margin-bottom: 1rem;
`

const CurrencyPrefix = styled.div`
  background: #000928;
  position: relative;
  height: 50px;
  width: 50px;
  background: #000928;
  position: relative;
  top: 50px;
  left: -45px;
  z-index: 10;
  border-radius: 8px 0px 0px 8px;
  margin-top: -50px;

  p {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: white;
  }
`

const InputWrapper = styled.div`
  position: relative;
`

export type CurrencyProps = {
  value?: string | number
  onBlur?: () => void
  onDone: (a, b, c) => void
  onChange?: (value) => void
  id?: string
  label?: string
  error?: string
  min?: number
  max?: number
  allowEmpty?: boolean
  onKeyDown?: (e: any) => void
  placeholder?: string
  currencyPrefix?: boolean
  disabled?: boolean
}

export default function CurrencyInput({
  value,
  onBlur = () => {},
  onDone = (value, errors) => {},
  onChange = () => {},
  id,
  label,
  error,
  min = null,
  max = null,
  allowEmpty = true,
  onKeyDown = (e) => {},
  placeholder = "",
  currencyPrefix = false,
  disabled = false,
}: CurrencyProps) {
  const [input, setInput] = useState(value?.toString?.()?.length > 0 ? value : "")

  useEffect(() => {
    let clamped = input

    if (allowEmpty === false && !input) {
      clamped = 0
    }

    if (min !== null && input < min) {
      clamped = min
    }

    if (max !== null && input > max) {
      clamped = max
    }

    if (clamped !== input) {
      setInput(clamped)
    }

    handleDone(false)
  }, [input])

  function handleDone(submit) {
    if (input === null || isNaN(parseFloat(input?.toString()))) {
      onDone(null, null, false)
      return
    }

    onDone(input, null, submit)
  }

  function handleInput(numeric) {
    setInput(numeric)
    onChange(numeric)
  }

  return (
    <InputWrapper>
      {error && <ErrorStyles>{error}</ErrorStyles>}
      {currencyPrefix && (
        <CurrencyPrefix>
          <p>£</p>
        </CurrencyPrefix>
      )}
      <FormatInput
        disabled={disabled}
        prefix={"£"}
        placeholder={placeholder}
        value={input}
        onBlur={onBlur}
        label={label}
        autoComplete="off"
        onKeyDown={(e) => onKeyDown(e)}
        onValueChange={(e) => handleInput(e.floatValue)}
        thousandSeparator={true}
        id={id ?? `currency-${id}`}
      />
    </InputWrapper>
  )
}
