import React from "react"
import styled from "styled-components"
import { lighten } from "polished"

export enum StarStatus {
  Filled = "Filled",
  FilledDisabled = "FilledDisabled",
  Outline = "Outline",
  None = "None",
}

const StarElement = styled("div")<Props>`
  &.${StarStatus.Filled}, &.${StarStatus.FilledDisabled} {
    ::before {
      content: "★";
    }
  }

  &.${StarStatus.Outline} {
    ::before {
      content: "☆";
    }
  }

  color: ${(props) => props.theme.starColour};
`

type Props = {
  color?: string
  status?: StarStatus
}

export const Star = ({
  color = `${(props) => props.theme.starColour}`,
  status = StarStatus.Filled,
}: Props) => {
  const classes = [status]

  if (status === StarStatus.FilledDisabled) {
    color = lighten(0.4, color)
  }

  return <StarElement color={color} status={status} className={classes.join(" ")} />
}
