import { useEffect, useState } from "react"
import { GetApplication } from "lib/Applications"
import { useApplicationSummaries } from "hooks/useApplicationSummaries"
import { FullApplication } from "models/FullApplication"
import { useRecoilState } from "recoil"
import { applicationLoadStateAccess, fullApplications } from "state"

export function useApplicationFriendlyId(friendlyId: string, invalidateCache = true) {
  const { applicationSummaries, hasFetchedSummaries } = useApplicationSummaries()
  const [application, setApplicationState] = useRecoilState(fullApplications(friendlyId))
  const [hasLoaded, setHasLoaded] = useRecoilState(applicationLoadStateAccess(friendlyId))
  const [shouldInvalidateCache, setShouldInvalidateCache] = useState(invalidateCache)
  const [applicationNotFound, setApplicationNotFound] = useState(false)

  async function refreshApplication(applicationToUpdate: FullApplication = null, silent = false) {
    setShouldInvalidateCache(true)

    if (applicationToUpdate) {
      setApplication(applicationToUpdate)
      return
    }

    if (!silent) {
      setHasLoaded(false)
    }

    const applicationUpdated = await GetApplication(application.applicationId, true)
    setApplication(applicationUpdated)
    setHasLoaded(true)
  }

  useEffect(() => {
    init(shouldInvalidateCache)

    if (hasFetchedSummaries) {
      const matchedSummary = applicationSummaries.find((x) => x.friendlyId === friendlyId)

      if (!matchedSummary) {
        setApplicationNotFound(true)
      }
    }
  }, [hasFetchedSummaries])

  async function init(refreshCache = false) {
    const app = applicationSummaries?.find((app) => app.friendlyId === friendlyId)

    if (!app) {
      return
    }

    if (application?.applicationId) {
      return
    }

    if (fetchingApplications.includes(friendlyId)) {
      return
    }

    LockFetch(friendlyId)

    try {
      const applicationData = await GetApplication(app.applicationId, refreshCache)
      setApplication(applicationData)
    } finally {
      UnlockFetch(friendlyId)

      if (shouldInvalidateCache) {
        setShouldInvalidateCache(false)
      }
    }
  }

  function setApplication(application: FullApplication) {
    if (!application) {
      return
    }

    setHasLoaded(true)
    setApplicationState(application)
  }

  return { application, hasLoaded, applicationNotFound, refreshApplication }
}

// Used to block concurrent requests to fetch the same application by
// multiple instances of the useApplicationFriendlyId hook
const fetchingApplications = []

function LockFetch(friendlyId: string) {
  if (fetchingApplications.includes(friendlyId)) return
  fetchingApplications.push(friendlyId)
}

function UnlockFetch(friendlyId: string) {
  const index = fetchingApplications.indexOf(friendlyId)

  if (index > -1) {
    fetchingApplications.splice(index, 1)
  }
}
