import { desktop } from "components/_blueprint/Layout"
import * as React from "react"
import styled from "styled-components"

type Props = {
  formattedTotalLoanAmount: number
  termYears: number
  formattedFeesTotal: number
  formattedPropertyValue: number
  productLTV: number
}

export const SummaryDetails = ({
  formattedTotalLoanAmount,
  termYears,
  formattedFeesTotal,
  formattedPropertyValue,
  productLTV,
}: Props) => {
  return (
    <SummaryDetailsContainer>
      <SummaryDetail>
        Borrowing <span>{formattedTotalLoanAmount}</span> over <b>{termYears} years</b>
      </SummaryDetail>
      <SummaryDetail>
        Fees <b>{formattedFeesTotal}</b> added to the loan
      </SummaryDetail>
      <SummaryDetail>
        Property value <span>{formattedPropertyValue}</span>
      </SummaryDetail>
      <SummaryDetail>
        Loan to value <span>{productLTV} %</span>
      </SummaryDetail>
    </SummaryDetailsContainer>
  )
}

const SummaryDetailsContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  gap: "8px",
  margin: "0 0 2rem 0",

  [desktop]: {
    flexDirection: "row",
    gap: "24px",
    padding: "1rem 0",

    borderBottom: "1px solid #B5BEDC",
    borderTop: "1px solid #B5BEDC",
  },
})

const SummaryDetail = styled.div`
  display: flex;
  gap: 8px;

  & > span {
    font-weight: 600;
  }
`
