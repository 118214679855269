import React from "react"
import styled from "styled-components"

import ArrowDown from "assets/images/icons/ArrowDown.svg"
import ArrowRight from "assets/images/icons/ArrowRight.svg"
import Exclamation from "assets/images/icons/Exclamation.svg"
import Back from "assets/images/icons/Back.svg"
import Cross from "assets/images/icons/Cross.svg"
import Locked from "assets/images/icons/Locked.svg"
import Menu from "assets/images/icons/Menu.svg"
import Shield from "assets/images/icons/Shield.svg"
import SmallArrow from "assets/images/icons/SmallArrow.svg"
import Cross2 from "assets/images/icons/Cross2.svg"
import QuestionMark from "assets/images/icons/question-mark.svg"
import ExternalLink from "assets/images/icons/ExternalLink.svg"
import IconTick from "assets/images/icons/Icon_Tick.svg"

import ChevronDown from "assets/images/icons/chevron-down.svg"
import ChevronUp from "assets/images/icons/chevron-up.svg"
import ChevronLeft from "assets/images/icons/chevron-left.svg"
import ChevronRight from "assets/images/icons/chevron-right.svg"

const images = {
  ArrowDown,
  ArrowRight,
  Back,
  Cross,
  Exclamation,
  Locked,
  Menu,
  Shield,
  SmallArrow,
  Cross2,
  QuestionMark,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  ExternalLink,
  IconTick,
}

export enum IconType {
  ArrowDown,
  ArrowRight,
  Back,
  Cross,
  Exclamation,
  Locked,
  Menu,
  Shield,
  SmallArrow,
  Cross2,
  QuestionMark,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  ExternalLink,
  IconTick,
}

interface Props {
  type: IconType
  onClick?: () => void
}

export interface IconComponentProps {
  type: IconType
  onClick?: () => void
  active?: boolean
  fillDefault?: string
  fillHover?: string
  width?: number
  height?: number
}

const IconComponent = styled.div`
  display: inline-block;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  mask: url(${({ icon }) => icon}) no-repeat center / contain;
  -webkit-mask: url(${({ icon }) => icon}) no-repeat center / contain;
  background-color: ${({ fillDefault, active, fillHover }) => (active ? fillHover : fillDefault)};
  &:hover {
    background-color: ${({ fillHover }) => fillHover};
  }
`

export function IconStyleable({
  type,
  onClick,
  fillDefault,
  fillHover,
  width,
  height,
  active,
}: IconComponentProps) {
  const icon = IconType[type]
  return (
    <IconComponent
      onClick={onClick && onClick}
      active={active}
      icon={images[icon]}
      fillDefault={fillDefault}
      fillHover={fillHover}
      width={width}
      height={height}
    />
  )
}

export function Icon({ type, onClick }: Props) {
  const icon = IconType[type]
  return <img src={images[icon]} alt="icon" onClick={onClick} />
}
