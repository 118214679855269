import React from "react"
import styled from "styled-components"
import Experian from "url:assets/images/experian.svg"

const ExperianSig = styled.div`
  display: flex;
  align-items: center;
`

const ExperianCustom = styled.img`
  width: 80px;
  height: 26px;
  margin-left: 16px;
`

export function EligibilityByExperian() {
  return (
    <ExperianSig>
      Eligibility check by
      <ExperianCustom src={Experian} />
    </ExperianSig>
  )
}
