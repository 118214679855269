import React from "react"
import styled from "styled-components"
import { IS_DEV } from "../../Config"

/* May need better styling */

const ErrorContainer = styled.div`
  text-align: center;
  width: 100%;
  display: inherit;
`

interface Props {
  children: React.ReactNode
  resetError?: () => void
}

export function ErrorDisplay({ children, resetError }: Props) {
  return (
    <ErrorContainer>
      {IS_DEV ? <div>{children}</div> : <p>Something went wrong</p>}
      {resetError && (
        <button
          onClick={() => {
            history.back()
            resetError()
          }}
        >
          Go Back
        </button>
      )}
    </ErrorContainer>
  )
}
