import React from "react"
import { useParams } from "react-router-dom"
import { Loader, LoaderTheme } from "components/atoms/Loader"
import { axiosInstance } from "lib/Api"

export const ShortCodeRedirect = () => {
  const { code } = useParams()

  React.useEffect(() => {
    const sendTo404 = () => (window.location.href = `${window.location.origin}/500`)
    ;(async () => {
      axiosInstance
        .get(`${process.env.MY_MOJO_API}/short-url/get/${code}`)
        .then((res) => {
          if (res.status === 200) {
            const url = new URL(res.data.url)
            const queryString = url.searchParams

            queryString.append("code", code)

            return (window.location.href = url.toString())
          }
          return sendTo404()
        })
        .catch(() => {
          return sendTo404()
        })
    })()
  }, [code])

  return (
    <div
      style={{
        display: "flex",
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Loader theme={LoaderTheme.Outline} size={50} />
    </div>
  )
}
