import { useSWRConfig } from "swr"
import useSWRImmutable from "swr/immutable"
import { FullApplication } from "models/FullApplication"
export interface SmooveResponse {
  goalRef: string
  clientRefs: string
  quotes: SmooveQuote[]
}

export interface SmooveQuote {
  CreatedDate: string
  CaseTotals: SmooveQuoteCaseTotal[]
  CostsTotal: number
  DepositAmount: number
  DisbursementsTotal: number
  Notes: string[]
  QuoteItems: SmooveQuoteItem[]
  QuoteRef: string
  QuoteTotal: number
  QuoteURL: string
  ServiceProviderName: string
  ServiceProviderRef: string
  ServiceLevelCode: string
  ServiceLevelName: string
  SmallPrint: string[]
  VATTotal: number
  SupplementsTotal: number
  IsInterested: boolean
  Distance: number
  ServiceProviderHasRecommendations: boolean
  ServiceProviderIsPreferred: boolean
  ServiceProviderRating: number
  ServiceProviderRatingIsPartial: boolean
  ServiceProviderRatingString: string
  ServiceProviderRatingVariance: number
  AdjustedDisbursementCost: number
  AdjustedTotalCost: number
  AdjustedOtherFees: number
  AdjustedConveyancingCost: number
}

export interface SmooveQuoteCaseTotal {
  CaseCostsTotal: number
  CaseDisbursementsTotal: number
  CaseIndex: number
  CaseSubTotal: number
  CaseTotal: number
  CaseVATTotal: number
}

export interface SmooveQuoteItem {
  Amount: number
  CaseIndex: number
  QuoteItemType: number
  Description?: string
}

const fetchQuotes = async (applicationId: string): Promise<SmooveResponse> => {
  const res = await fetch(`${process.env.MY_MOJO_API}/conveyancing/${applicationId}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    credentials: "include",
  })

  if (res.ok) {
    return await res.json()
  }

  throw new Error(await res.text())
}

/**
 * Returns a list of conveyancing quotes for a specific application id.
 * @param applicationId - The friendly application id associated with each application.
 */
export const useConveyancingQuotes = (applicationId?: string) => {
  const query = useSWRImmutable<SmooveResponse>(
    () => {
      // this will stop data fetching until applicationId is defined
      if (!applicationId) {
        throw new Error()
      }
      return `/conveyancing/${applicationId}`
    },
    async () => fetchQuotes(applicationId),
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  )

  return {
    ...query,
    isError: query.error,
    isLoading: !query.error && !query.data,
  }
}

/**
 * Will register interest for a specific quote in Salesforce
 * @param quoteReference - The Smoove quote Id that the user is expressing interest in
 * @param application - The application that the user is expressing an interest for a conveyancing quote against
 */
const registerConveyancingInterest = async (
  quoteReference: string,
  application: FullApplication
) => {
  const res = await fetch(`${process.env.MY_MOJO_API}/conveyancing/set-interested`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      applicationId: application.applicationId,
      friendlyId: application.friendlyId,
      quoteReference,
    }),
    credentials: "include",
  })

  if (res.ok) {
    return res
  }

  throw new Error(await res.text())
}

export const useInterestedInQuote = (application: FullApplication) => {
  const { mutate } = useSWRConfig()

  return {
    mutate: async (quoteReference: string) => {
      return await mutate(async () => {
        await registerConveyancingInterest(quoteReference, application)
      })
    },
  }
}
