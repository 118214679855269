import * as React from "react"
import styled, { keyframes } from "styled-components"
import { fillDuration } from "./AnimatedCheck"

const Track = styled.div(({ theme }) => ({
  backgroundColor: theme.name === "uswitch" ? "#E3E3E3" : theme.colors.neutral[200],
  borderRadius: theme.name === "uswitch" ? 0 : 200,
  height: 8,
  width: "100%",
}))

const Fill = styled.div(({ theme }) => ({
  backgroundColor:
    theme.name === "mojo"
      ? theme.colors.secondary.three
      : theme.name === "uswitch"
      ? "#84A6FF"
      : theme.primary,
  borderRadius: theme.name === "uswitch" ? 0 : 200,
  height: 8,
  width: 0,
}))

const fill = keyframes`
  to {
    width: 100%;
  }
`

// I can't get the keyframe interpolation to work with the object notation. If
// you can get it to work, these can be combined.
const FillAnimationName = styled(Fill)`
  animation-name: ${fill};
`

const AnimatedInner = styled(FillAnimationName)<{ initialDelay: number }>(({ initialDelay }) => ({
  animationDelay: `${initialDelay}s`,
  animationDuration: `${fillDuration}s`,
  animationFillMode: "forwards",
  animationTimingFunction: "ease",
}))

export const AnimatedProgressBar = ({ initialDelay }: { initialDelay: number }) => (
  <Track role="progressbar">
    <AnimatedInner initialDelay={initialDelay} />
  </Track>
)
