export const APP_NAME = "my.mojo.ui"
export const MY_MOJO_API = process.env.MY_MOJO_API
export const MOJO_COOKIE_URL = process.env.MOJO_COOKIE_URL

export const FILESTACK_POLICY = process.env.FILESTACK_POLICY
export const FILESTACK_SIGNATURE = process.env.FILESTACK_SIGNATURE
export const FILESTACK_API_KEY = process.env.FILESTACK_API_KEY
export const FILESTACK_CONTAINER = process.env.FILESTACK_CONTAINER
export const FILESTACK_REGION = process.env.FILESTACK_REGION

export const IS_DEV: boolean = process.env.IS_DEV == "true"

export const INTERCOM_APP_ID = process.env.INTERCOM_APP_ID

export const GOOGLE_ANALYTICS_TRACKING_ID = process.env.GOOGLE_ANALYTICS_TRACKING_ID
export const GA4_MEASUREMENT_ID = process.env.GA4_MEASUREMENT_ID

export const COMPARISON_TABLE_URL_V2 = process.env.COMPARISON_TABLE_URL_V2

export const SENTRY_DSN = process.env.SENTRY_DSN
export const SENTRY_ENVIRONMENT = process.env.SENTRY_ENVIRONMENT

export const AVATAR_CLOUDFRONT_URL = process.env.AVATAR_CLOUDFRONT_URL
