import React from "react"
import { ValueView } from "components/atoms/ValueView"
import styled from "styled-components"

const Label = styled.div`
  margin-bottom: 6px;
`

type Props = {
  label?: string
  value: string
}

export function LabeledValueView({ label, value }: Props) {
  return (
    <div>
      <Label>{label}</Label>
      <ValueView value={value} />
    </div>
  )
}
