import React, { useEffect, useState } from "react"
import { useRecoilState } from "recoil"
import { tooltipState } from "state"
import styled from "styled-components"
import { IS_DEV } from "../../Config"
import { TooltipState } from "models/Tooltips"
import { Icon, IconStyleable, IconType } from "components/atoms/Icon"
import devices from "styles/devices"
import { LogGaEvent } from "lib/GoogleAnalytics"
import { EventCategory, EventAction, EventLabel } from "models/GoogleAnalytics"

const TooltipWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: #000928;
  z-index: 100000000;
  color: white;

  font-family: ${(props) => props.theme.fontFamily};
  font-style: normal;
  line-height: 150%;
  font-feature-settings: "pnum" on, "lnum" on;

  h2 {
    font-weight: bold;
    font-size: 1.1em;
    margin: 0;
    margin-top: 8px;
    margin-bottom: 16px;
  }

  p {
    font-weight: 500;
    font-size: 0.9rem;
    margin: 0;
  }

  box-shadow: 0px 16.25px 97.5px rgba(0, 0, 0, 0.12);
  padding: 20px;

  border-top-left-radius: 9.75px;
  border-top-right-radius: 9.75px;

  .cross-icon {
    position: absolute;
    top: 22px;
    right: 19px;
    cursor: pointer;
  }

  @media ${devices.bp_sm} {
    position: absolute;

    bottom: initial;
    margin: initial;

    top: 100%;
    margin-top: 5px;
    margin-left: 293px;

    border-radius: 9.75px;

    &:after {
      content: "";
      position: absolute;

      right: 100%;
      margin-right: -40px;

      bottom: 100%;
      transform: translateY(-50%);
      transform: rotate(90deg);

      border: 10px solid #000;
      border-color: transparent black transparent transparent;
    }
  }

  @media (min-width: 768px) and (max-width: 800px) {
    margin-left: 281px;
  }

  @media ${devices.bp_md} {
    margin-left: 281px;
  }

  @media ${devices.bp_xl} {
    margin-left: 293px;
  }
`

const QuestionMarkWrapper = styled.div`
  width: 24px;
  height: 24px;
  padding-right: 32px;
`

const Button = styled.button`
  position: absolute;
  cursor: pointer;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 1px;
  margin: auto;
  right: 6px;
  background: transparent;
  border: 0;
`

const TooltipButton = styled.a`
  display: inline-block;
  border: #ffffff 2px solid;
  border-radius: 10px;
  color: white;
  margin-top: 20px;

  font-family: ${(props) => props.theme.fontFamily};
  font-style: normal;
  font-weight: 500;
  font-size: 1em;
  line-height: 150%;
  text-decoration: none;

  padding: 8px 10px 7px 10px;

  &:visited,
  &:link,
  &:hover,
  &:active {
    text-decoration: none;
  }
`

type Props = {
  id?: string
  // TODO: can't this come from the general place??
  option?: {
    text?: string
    value?: string
    tooltip?: {
      title?: string
      content?: string
      button?: {
        text: string
        link: string
      }
    }
  }
  buttonContent?: {}
}

export const questionMarkColors = {
  WHITE: "white",
  NAVY: "#666B7E",
}

export const Tooltip = ({ id, option, questionMarkColor }: Props) => {
  // Id is needed so the tooltip disables all other tooltips when you click it.
  if (!id) {
    throw new Error("id property has to be set on Tooltip components!")
  }

  const [tooltips, setTooltipState] = useRecoilState<TooltipState[]>(tooltipState)
  const [show, setShow] = useState(false)

  useEffect(() => {
    if (tooltips.find((state) => state.id === id)) {
      return
    }

    setTooltipState((oldTooltipState) => [
      ...oldTooltipState,
      {
        id: id,
        closeFn: () => setShow(false),
      },
    ])
  })

  useEffect(() => {
    if (!window) return

    const hideOnClick = () => {
      if (!show) return
      setShow(false)
    }

    // hide the tooltip when you click anywhere.
    window.addEventListener("click", hideOnClick)

    // remove the eventlistener when we unmount component.
    return function cleanup() {
      window.removeEventListener("click", hideOnClick)
    }
  })

  return (
    <>
      <Button
        onClick={(e) => {
          e.stopPropagation()
          tooltips.forEach(({ closeFn }) => closeFn())

          LogGaEvent({
            action: EventAction.tooltipClicked,
            event_category: EventCategory.siteInteraction,
            event_label: option.tooltip.title,
          })

          setShow(!show)
        }}
        style={{ padding: 0 }}
      >
        <QuestionMarkWrapper>
          <IconStyleable
            active={show}
            fillDefault={questionMarkColor || "#666B7E"}
            fillHover={questionMarkColor === questionMarkColors.WHITE ? "#ffffff" : "#073ED9"}
            width={24}
            height={24}
            type={IconType.QuestionMark}
          />
        </QuestionMarkWrapper>
      </Button>
      {show && (
        <TooltipWrapper onClick={(e) => e.stopPropagation()}>
          <div className="cross-icon">
            <IconStyleable
              active={show}
              fillDefault="#ffffff"
              fillHover="#ffffff"
              width={32}
              height={32}
              type={IconType.Cross}
              onClick={() => setShow(false)}
            />
          </div>
          <h2>{option.tooltip.title}</h2>
          <p>{option.tooltip.content}</p>
          {option.tooltip.button && (
            <TooltipButton href={option.tooltip.button.link}>
              {option.tooltip.button.text}
            </TooltipButton>
          )}
        </TooltipWrapper>
      )}
    </>
  )
}
