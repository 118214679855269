import styled from "styled-components"

// @ts-ignore
import WaveSvg from "url:assets/images/background-wave.svg"

const BackgroundWave = styled.div`
  position: absolute;
  background: url(${WaveSvg}) no-repeat top center;
  background-size: cover;
  z-index: -999;
  bottom: 0;
  left: 0;
  right: 0;
  top: 30%;
`

export const AnyColourBackgroundWave = styled(BackgroundWave)`
  -webkit-mask-image: url(${WaveSvg});
  mask-image: url(${WaveSvg});
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  mask-size: cover;
  -webkit-mask-size: cover;
  background: "initial";
  z-index: -1;
`

export default BackgroundWave
