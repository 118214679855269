import axios from "axios"
import { MY_MOJO_API } from "../Config"
import { Document, FullApplication } from "models/FullApplication"
import { captureMessage } from "@sentry/browser"

export interface DocumentUploaded {
  key: string
  originalFile: {
    name: string
  }
}

export async function UploadDocumentMetaData(
  filesUploaded: DocumentUploaded[],
  applicationId: string,
  applicantId: number,
  docType: string
): Promise<Document[]> {
  // TODO: change the update document metadata endpoint to be a single batch POST

  if (filesUploaded.length < 1) {
    throw Error("Unreachable: UploadDocumentMetaData called without files.")
  }

  let res
  for (const file of filesUploaded) {
    const currentFilename = file.key.split("/")[1]
    const originalFilename = file.originalFile.name

    res = await axios.put(
      `${MY_MOJO_API}/applications/${applicationId}/document?applicantId=${applicantId}&currentFilename=${currentFilename}&originalFilename=${originalFilename}&docType=${docType}`,
      null,
      {
        withCredentials: true,
      }
    )
  }

  const data = res.data as FullApplication
  return data.metadata.documents
}
