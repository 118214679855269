import React, { useEffect, useLayoutEffect, useState } from "react"
import { MyMojoTemplate } from "templates/MyMojoTemplate"
import { ContentArea } from "templates/ContentArea"
import styled from "styled-components"
import { DateInput } from "components/molecules"
import { TextInput } from "components/atoms/TextInput"
import { Button, ButtonType } from "components/atoms"
import { axiosInstance } from "lib/Api"
import { MY_MOJO_API } from "../../Config"
import { FormatDate } from "lib/Date"
import { ValidateEmailAddress } from "lib/EmailAddress"
import { DateFlag } from "components/molecules/DateInput"
import { LogGaEvent } from "lib/GoogleAnalytics"
import { EventAction, EventCategory } from "models/GoogleAnalytics"

const FormContainer = styled.form`
  font-size: 0.9rem;
  margin-top: 2rem;

  > * + * {
    margin-top: 2.3rem;
  }
`

const InputLabel = styled.label`
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  display: block;
`

const Hint = styled.div`
  padding-top: 0.1rem;
  padding-bottom: 0.3rem;
  margin-top: 0;
  color: #6c6f7a;
  font-weight: 450;
`

const Error = styled.div`
  margin-top: 0.4rem;
  color: #ef1717;
`

const Tos = styled.div`
  a {
    color: #0a48f7;
  }
`

export function RateCheckRequest() {
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [emailAddress, setEmailAddress] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [postcode, setPostcode] = useState("")
  const [completionDate, setCompletionDate] = useState("")

  const [isFirstNameValid, setIsFirstNameValid] = useState<boolean | null>(null)
  const [isLastNameValid, setIsLastNameValid] = useState<boolean | null>(null)
  const [isEmailValid, setIsEmailValid] = useState<boolean | null>(null)
  const [isPostcodeValid, setIsPostcodeValid] = useState<boolean | null>(null)
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState<boolean | null>(null)
  const [dateErrors, setDateErrors] = useState<string[]>([])

  const [isBusy, setIsBusy] = useState(false)
  const [error, setError] = useState(false)
  const [isDone, setIsDone] = useState(false)
  const [hasNotFoundError, setHasNotFoundError] = useState<boolean>(false)

  // GA Events
  useLayoutEffect(() => {
    LogGaEvent({
      action: EventAction.componentDisplay,
      event_category: EventCategory.siteInteraction,
      event_label: "Rate Check Request Form",
    })
  }, [])

  useEffect(() => {
    if (error) {
      LogGaEvent({
        action: EventAction.componentDisplay,
        event_category: EventCategory.siteInteraction,
        event_label: "Rate Check Request Error",
      })
    }
  }, [error])

  useEffect(() => {
    if (hasNotFoundError) {
      LogGaEvent({
        action: EventAction.componentDisplay,
        event_category: EventCategory.siteInteraction,
        event_label: "Error Matching Rate Check Application",
      })
    }
  }, [hasNotFoundError]);

  function ValidateFirstName() {
    if (firstName && firstName.length > 0) {
      setIsFirstNameValid(true)
      return
    }

    setIsFirstNameValid(false)
  }

  function ValidateLastName() {
    if (firstName && firstName.length > 0) {
      setIsLastNameValid(true)
      return
    }

    setIsLastNameValid(false)
  }

  function ValidateEmail() {
    if (!emailAddress) {
      setIsEmailValid(false)
      return
    }

    if (emailAddress) {
      const isValid = ValidateEmailAddress(emailAddress)
      setIsEmailValid(isValid)
      return
    }

    setIsEmailValid(false)
  }

  function ValidatePostcode() {
    if (!postcode) {
      setIsPostcodeValid(false)
      return
    }

    if (postcode) {
      const regex = /^[a-z]{1,2}\d[a-z\d]?\s*\d[a-z]{2}$/i
      setIsPostcodeValid(regex.test(postcode))
      return
    }

    setIsPostcodeValid(false)
  }

  function ValidatePhoneNumber() {
    if (!phoneNumber) {
      setIsPhoneNumberValid(false)
    }

    const regex = /^(?:0|\+?44)(?:\d\s?){9,10}$/
    setIsPhoneNumberValid(regex.test(phoneNumber))
  }

  async function HandleSubmit() {
    if (!isFirstNameValid) setIsFirstNameValid(false)
    if (!isLastNameValid) setIsLastNameValid(false)
    if (!isEmailValid) setIsEmailValid(false)
    if (!isPostcodeValid) setIsPostcodeValid(false)
    if (!isPhoneNumberValid) setIsPhoneNumberValid(false)
    if (!completionDate) setDateErrors(dateErrors.length > 0 ? dateErrors : ["Required"])
    if (hasNotFoundError) setHasNotFoundError(false);

    if (
      !isFirstNameValid ||
      !isLastNameValid ||
      !isEmailValid ||
      !isPostcodeValid ||
      !isPhoneNumberValid ||
      dateErrors?.length > 0
    ) {
      return
    }

    LogGaEvent({
      action: EventAction.buttonClicked,
      event_category: EventCategory.functionalInteraction,
      event_label: "Rate Check Request Submitted",
    })

    setIsBusy(true)
    setError(false)

    // Check whether an application can be matched given the provided information
    try {
      await axiosInstance.post(`${MY_MOJO_API}/rate-check-request/has-match`, {
        fullName: `${firstName} ${lastName}`,
        emailAddress,
        phoneNumber,
        postcode,
        completionDate: FormatDate(completionDate, "YYYY-MM-DD"),
      })
    } catch (err) {
      setIsBusy(false);
      setHasNotFoundError(true);
      return;
    }

    // Submit the full rate-check-request
    try {
      await axiosInstance.post(`${MY_MOJO_API}/rate-check-request`, {
        fullName: `${firstName} ${lastName}`,
        emailAddress,
        phoneNumber,
        postcode,
        completionDate: FormatDate(completionDate, "YYYY-MM-DD"),
      })

      setIsDone(true)
    } catch (err) {
      setError(true)
    } finally {
      setIsBusy(false)
    }
  }

  return (
    <MyMojoTemplate
      content={
        <ContentArea maxWidth={900}>
          {!isDone && (
            <>
              <h3>Request a Rate Check</h3>
              <p>
                Fill out this form to request a Rate Check and find out if you're still on the best rate.
              </p>
              <p>
                After completing the form, your Mortgage Expert will review the whole of market and let you know if a cheaper rate is available.
              </p>
              <p>
                Rate Check requests received during normal business hours (Monday to Friday, 9 AM to 5 PM) will be responded to within two business days. For example, if a request is submitted on a Monday, you can expect a response by the close of business on Wednesday.
              </p>
              <p>
                Please note that the ability to change your rate will depend on the lender and how close you are to your completion date. Your Mortgage Expert will advise you on this.
              </p>
              <FormContainer>
                <div>
                  <InputLabel htmlFor="firstname">First Name</InputLabel>
                  <TextInput
                    id="firstname"
                    value={firstName}
                    onChange={(value) => setFirstName(value)}
                    onBlur={ValidateFirstName}
                  />
                  {isFirstNameValid === false && <Error>Please enter a valid first name</Error>}
                </div>

                <div>
                  <InputLabel htmlFor="lastname">Last Name</InputLabel>
                  <TextInput
                    id="lastname"
                    value={lastName}
                    onChange={(value) => setLastName(value)}
                    onBlur={ValidateLastName}
                  />
                  {isLastNameValid === false && <Error>Please enter a valid last name</Error>}
                </div>

                <div>
                  <InputLabel htmlFor="emailAddress">Email Address</InputLabel>
                  <Hint>The email address associated with your application</Hint>
                  <TextInput
                    id="emailAddress"
                    value={emailAddress}
                    onChange={(value) => setEmailAddress(value)}
                    onBlur={ValidateEmail}
                  />
                  {isEmailValid === false && <Error>Please enter a valid email</Error>}
                </div>

                <div>
                  <InputLabel htmlFor="postcode" onBlur={ValidatePostcode}>
                    Postcode
                  </InputLabel>
                  <Hint>The postcode of the property your application is for</Hint>
                  <TextInput
                    id="postcode"
                    value={postcode}
                    onChange={(value) => setPostcode(value)}
                    onBlur={ValidatePostcode}
                  />
                  {isPostcodeValid === false && <Error>Please enter a valid postcode</Error>}
                </div>

                <div>
                  <InputLabel htmlFor="phoneNumber">Phone Number</InputLabel>
                  <Hint>So we can get in touch if there's any problems with your request</Hint>
                  <TextInput
                    id="phoneNumber"
                    value={phoneNumber}
                    onChange={(value) => setPhoneNumber(value)}
                    onBlur={ValidatePhoneNumber}
                  />
                  {isPhoneNumberValid === false && <Error>Please enter a valid phone number</Error>}
                </div>

                <div>
                  <InputLabel htmlFor="completionDate">Completion Date</InputLabel>
                  <Hint>You can estimate if you're unsure</Hint>
                  <DateInput
                    dateFlags={[DateFlag.FutureDate]}
                    id="completionDate"
                    value={completionDate}
                    onDone={(value, errors) => {
                      setCompletionDate(value?.toString() ?? "")
                      setDateErrors(errors)
                    }}
                  />
                  {dateErrors && <Error>{dateErrors.join(". ")}</Error>}
                </div>

                <Button
                  onClick={HandleSubmit}
                  showLoader={isBusy}
                  type={ButtonType.Solid}
                  text={"Submit"}
                />

                { hasNotFoundError && (
                    <>
                      <Error>
                        We were unable to find your application based on these details.
                      </Error>
                      <Error>
                        Please check again, ensuring you're using the same details as you provided for your application with us.
                      </Error>
                      <Error>
                        Alternatively, please contact your Case Manager for further assistance, <br /> or contact us on 0333 123 0012.
                      </Error>
                    </>
                )}

                {error && (
                    <>
                      <Error>
                        It looks like something went wrong submitting your request. Please try again,
                        or let us know.
                      </Error>
                      <Error>
                        You can email us at hello@mojomortgages.com or ring us on 0333 123 0012
                      </Error>
                    </>
                )}

                <Tos>
                  By submitting this form, I confirm I've read and accepted Mojo's{" "}
                  <a
                    title="Terms of Advisory Services"
                    target="_blank"
                    href="https://hosted-assets.mojomortgages.com/documents/Terms%20of%20our%20Advisory%20Services.pdf"
                  >
                    Terms of Advisory Services
                  </a>{" "}
                  and{" "}
                  <a
                    title="Privacy Policy"
                    target="_blank"
                    href="https://mojomortgages.com/privacy-policy/"
                  >
                    Privacy Policy
                  </a>
                  .
                </Tos>
              </FormContainer>
            </>
          )}
          {isDone && (
            <div>
              <h3>Your Rate Check request has been submitted</h3>
              <p style={{ fontWeight: "500" }}>
                Your Mortgage Expert will be in touch within two working days to let you know if a
                cheaper rate is available.
              </p>
            </div>
          )}
        </ContentArea>
      }
    />
  )
}
