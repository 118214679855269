import axios from "axios"
import { MY_MOJO_API } from "../Config"

export const UpdateSalesforceExperiment = async (
  applicationId: string,
  experiments: CurrentExperiments[]
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    withCredentials: true,
  }
  const data = { applicationId, experiments }
  const url = `${MY_MOJO_API}/experiments/update`
  try {
    return await axios.post(url, data, config)
  } catch (err) {
    console.error(err)
  }
}

export enum CurrentExperiments {
  AVAILABLE_TIMES_EXPERIMENT_CONTROL = "AVAILABLE_TIMES_EXPERIMENT_CONTROL",
  AVAILABLE_TIMES_EXPERIMENT_VARIANT = "AVAILABLE_TIMES_EXPERIMENT_VARIANT",
}
