import React from "react"
import styled from "styled-components"
import { ThemeConfig } from "styles/DefaultTheme"
import { desktop, FooterBackground, Gap, mobile, tablet } from "components/_blueprint/Layout"

export const ComplianceFooter = ({ theme }: { theme: ThemeConfig }) => (
  <>
    <FooterBackground />
    <Footer>
      <FooterStrong>
        Your home/property may be repossessed if you do not keep up repayments on your mortgage. You
        may have to pay a charge to exit an existing mortgage deal early. Think carefully before
        securing other debts against your property.
      </FooterStrong>
      <Gap x={1} />
      {theme.footerCopy
        ? theme.footerCopy.map((str, idx) => <FooterText key={idx}>{str}</FooterText>)
        : defaultFooterCopy(theme).map((str, idx) => <FooterText key={idx}>{str}</FooterText>)}
    </Footer>
  </>
)

// This can't go in DefaultTheme.ts, otherwise it'll get merged in at the beginning of the array.
const defaultFooterCopy = (theme: ThemeConfig): string[] => {
  const partner =
    theme.name === "Default"
      ? []
      : [
          `${capitalise(
            theme.name
          )} makes introductions to Mojo Mortgages to provide mortgage solutions.`,
        ]
  return partner.concat([
    "Mojo Mortgages is a trading style of Life’s Great Limited which is registered in England and Wales (06246376). Mojo are authorised and regulated by the Financial Conduct Authority and are on the Financial Services Register (478215).",
    "Mojo’s registered office is The Cooperage, 5 Copper Row, London, SE1 2LH. To contact Mojo by phone, please call 0333 123 0012.",
  ])
}

const capitalise = (s: string): string => s[0].toUpperCase() + s.slice(1)

const Footer = styled.footer({
  alignItems: "center",
  display: "grid",
  gap: "1rem",
  gridColumnStart: 2,
  gridRowStart: 2,
  padding: "4rem 0",
  [mobile]: {
    padding: "2rem",
  },
  [tablet]: { padding: "3rem" },
})

const FooterStrong = styled.strong(({ theme }) => ({
  color: theme.footerText,
  fontSize: "0.875em",
  margin: 0,
  [mobile]: {
    textAlign: "center",
  },
  [tablet]: {
    textAlign: "left",
  },
  [desktop]: {
    textAlign: "left",
    paddingLeft: 40,
  },
}))

const FooterText = styled.p(({ theme }) => ({
  color: theme.footerText,
  fontSize: "0.875em",
  margin: 0,
  [mobile]: {
    textAlign: "center",
  },
  [tablet]: {
    textAlign: "left",
  },
  [desktop]: {
    textAlign: "left",
    paddingLeft: 40,
  },
}))
