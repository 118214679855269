import * as Smartlook from "lib/Smartlook"
import React from "react"
import { readCookie, setCookie } from "lib/Cookie"
import { useEffect, useState } from "react"
import { createPortal } from "react-dom"
import styled from "styled-components"
import { desktop, mobile, tablet } from "components/_blueprint/Layout"
import { Button, ButtonType } from "components/atoms"
import { LogGaEvent, initialiseGA } from "lib/GoogleAnalytics"
import { EventAction, EventCategory } from "models/GoogleAnalytics"
import { initIntercom } from "lib/intercom"

export const CookieBanner = () => {
  const [isOpen, setIsOpen] = useState(false)

  const initEssential = () => {
    initialiseGA()
    initIntercom()
  }

  const initNonessential = () => {
    Smartlook.Init()
  }

  const acceptEssential = () => {
    initEssential()
    setCookie("allow-cookies", "essential-only")
    setIsOpen(false)
    LogGaEvent({
      action: EventAction.buttonClicked,
      event_category: EventCategory.functionalInteraction,
      event_label: "Essential cookies only",
    })
  }
  const acceptAll = () => {
    initEssential()
    initNonessential()
    setCookie("allow-cookies", "all")
    setIsOpen(false)
    LogGaEvent({
      action: EventAction.buttonClicked,
      event_category: EventCategory.functionalInteraction,
      event_label: "Accept all cookies",
    })
  }

  useEffect(() => {
    const preferences = readCookie("allow-cookies")
    switch (preferences) {
      case "all":
        initEssential()
        initNonessential()
        break
      case "essential-only":
        initEssential()
        break
      default:
        setIsOpen(true)
        break
    }
  }, [])

  useEffect(() => {
    const cookieBanner = document.querySelector("#cookieBanner") as HTMLInputElement
    if (cookieBanner) cookieBanner.focus()
  }, [isOpen])

  return isOpen
    ? createPortal(
        <Overlay>
          <Banner id={"cookieBanner"} role="region" aria-label="Cookie banner" tabIndex={0}>
            <Text role="dialog" aria-label="Cookie disclaimer">
              By clicking ‘Accept all cookies’, you agree to the storing of cookies on your device
              to support your user experience, personalisation of ads and our analysis of site
              usage. Read more on our{" "}
              <Link href="https://mojomortgages.com/cookies/" target="_blank" rel="noreferrer">
                Cookie Policy page
              </Link>{" "}
              and on{" "}
              <a
                href="https://business.safety.google/privacy/"
                target="_blank"
                rel="noreferrer noopener"
              >
                Google's Privacy & Terms site
              </a>
              .
            </Text>
            <Buttons role="group" aria-label="Cookie buttons">
              <Button
                type={ButtonType.Outline}
                onClick={acceptEssential}
                text={"Essential cookies only"}
              />
              <Button type={ButtonType.Solid} onClick={acceptAll} text={"Accept all cookies"} />
            </Buttons>
          </Banner>
        </Overlay>,
        document.body
      )
    : null
}

const Overlay = styled.div({
  backgroundColor: "hsl(0deg 0% 0% / 30%)",
  position: "fixed",
  inset: 0,
  zIndex: 9998,
})

const Banner = styled.div({
  background: "white",
  borderRadius: "1.5rem 1.5rem 0 0",
  bottom: 0,
  display: "flex",
  flexWrap: "nowrap",
  gap: "1.5rem",
  justifyContent: "space-between",
  left: "50%",
  maxWidth: 1280,
  padding: "1.5rem",
  position: "fixed",
  transform: "translateX(-50%)",
  width: "calc(100% - 16px)",
  zIndex: 9999,
  "&:focus": { outline: "none" },
  [mobile]: {
    flexDirection: "column",
  },
})

const Text = styled.p({
  alignSelf: "center",
  maxWidth: 576,
})

const Buttons = styled.div({
  display: "flex",
  [mobile]: {
    flexDirection: "column",
  },
  [tablet]: {
    flexDirection: "column",
  },
  [desktop]: {
    flexDirection: "row",
    gap: "1rem",
  },
})

const Link = styled.a(({ theme }) => ({
  color: theme.primary,
}))
