import React, { useState } from "react"
import { PaymentAmountGroup, Group } from "components/molecules/PaymentAmountGroup"

type Props = {
  value: Group[]
  onDone: (values: Group[], errors: string[]) => void
  id: string
}
export function IncomeSupplements({ value, onDone, id }: Props) {
  const [input, setInput] = useState(value || [])

  function HandleOnChange(values, errors) {
    setInput(values)
    onDone(values, errors)
  }

  return (
    <PaymentAmountGroup
      id={id}
      onChange={(values, errors) => HandleOnChange(values, errors)}
      value={input}
      needsProving={true}
      categories={[
        { key: "Bonus", value: "Bonus" },
        { key: "CarAllowance", value: "Car allowance" },
        { key: "Commission", value: "Commission" },
        { key: "Overtime", value: "Overtime" },
        { key: "RegionalAllowance", value: "Regional allowance" },
        { key: "EmployerHousingAllowance", value: "Employer housing allowance" },
        { key: "Other", value: "Other" },
      ]}
    />
  )
}
