import React, { useEffect, useState } from "react"
import {
  ApplicationDataProps,
  Recommendation,
} from "pages/Application/Eligibility/Status/Recommendation"
import styled from "styled-components"
import axios, { AxiosResponse } from "axios"
import { useParams } from "react-router-dom"
import { ErrorDisplay } from "components/molecules"
import { Loader, LoaderTheme } from "components/atoms/Loader"
import devices from "styles/devices"
import { MY_MOJO_API } from "../../Config"

const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 64px 16px 0 16px;
  max-width: 848px;

  @media screen and ${devices.bp_xl} {
    min-width: 768px;
    margin: 8.5em auto 0;
  }
`

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  @media screen and ${devices.bp_xl} {
    width: 34em;
  }
`

const ErrorContainer = styled.div`
  margin-top: 100px;
`

export const RecommendationPreview = () => {
  const { token, timestamp } = useParams<{ token: string; timestamp: string }>()
  const [isLoading, setIsLoading] = useState(true)
  const [applicationData, setApplicationData] = useState<ApplicationDataProps | null>(null)
  const [isError, setIsError] = useState(false)
  const [isDownloading, setIsDownloading] = useState(false)

  useEffect(() => {
    getApplicationData(token, timestamp)
      .then((data) => {
        setApplicationData(data)
        setIsLoading(false)
      })
      .catch(() => {
        setIsError(true)
        setIsLoading(false)
      })
  }, [])

  const downloadEsisDocument = async (): Promise<void> => {
    const body = {
      s3Key: applicationData.esisS3Key,
    }

    setIsDownloading(true)
    try {
      const response = await axios.post(`${MY_MOJO_API}/documents/download-url`, body)
      if (response.status !== 200) {
        setIsDownloading(false)
        throw new Error("Error downloading Mortgage Illustration")
      }
      const url = response.data.downloadUrl
      setIsDownloading(false)
      window.open(url)
    } catch (error) {
      console.error(error)
      setIsDownloading(false)
      alert("Error downloading Mortgage Illustration")
    }
  }

  const RenderError = () => (
    <ErrorContainer>
      <ErrorDisplay children={"Could not find preview token"} />
    </ErrorContainer>
  )

  const RenderLoading = () => (
    <LoaderContainer>
      <Loader size={150} theme={LoaderTheme.Outline} />
    </LoaderContainer>
  )

  return (
    <Root>
      {isError ? (
        <RenderError />
      ) : isLoading ? (
        <RenderLoading />
      ) : (
        <Recommendation
          applicationData={applicationData}
          downloadEsisDocument={downloadEsisDocument}
          isDownloading={isDownloading}
          isPreview={true}
          acceptRecommendationOpportunity={() => {}}
          acceptRecommendationQuote={() => {}}
        />
      )}
    </Root>
  )
}

const getApplicationData = async (
  token: string,
  timestamp: string
): Promise<ApplicationDataProps | null> => {
  let response: AxiosResponse<ApplicationDataProps>
  if (timestamp) {
    response = await axios.get(
      `${process.env.MY_MOJO_API}/specific-recommendation/${token}/${timestamp}`
    )
  } else {
    response = await axios.get(`${process.env.MY_MOJO_API}/rec-preview/${token}`)
  }
  if (response.status !== 200) {
    throw new Error("Could not find preview token")
  }
  return response.data
}
