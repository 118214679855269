import React from "react"

type Props = {
  checked: boolean
  onChange?: () => void
  id?: string
}

export function Checkbox({ checked, onChange = () => {}, id = "" }: Props) {
  return (
    <input
      id={id}
      checked={checked}
      onChange={onChange}
      style={{ cursor: "pointer", minWidth: "15px" }}
      type="checkbox"
      autoComplete="off"
    />
  )
}
