import { AxiosResponse } from "axios"
import useSWRMutation from "swr/mutation"
import { logout } from "../client/logout"
import { useSession } from "./session"

/**
 * Logout as a React hook, refreshes session on success.
 */
export const useLogout = ({
  onSuccess,
}: {
  onSuccess?: (data: AxiosResponse<void>) => void
} = {}) => {
  const { mutate } = useSession()
  const { trigger, data, error } = useSWRMutation("logout", () => logout(), {
    onSuccess: (data) => {
      mutate(null, {
        revalidate: false,
      })
      onSuccess?.(data)
    },
  })

  const mutation = async () => {
    await trigger()
  }

  return {
    logout: mutation,
    data,
    error,
  }
}
