import React, { useState } from "react"
import styled from "styled-components"
import devices from "styles/devices"
import { Sidebar } from "./Sidebar"
import { LogGaEvent } from "lib/GoogleAnalytics"
import { EventAction, EventCategory } from "models/GoogleAnalytics"
import { Status } from "pages/Application/Eligibility/Status/Status"

// @ts-ignore
import MojoLogo from "url:assets/images/mojo-logo.png"
// @ts-ignore
import HamburgerIcon from "url:assets/images/icons/Menu.svg"
// @ts-ignore
import CloseHamburgerIcon from "url:assets/images/icons/Cross.svg"
// @ts-ignore
import ProudLean from "url:assets/images/illustrations/Proud lean.png"
// @ts-ignore
import Paperwork from "url:assets/images/illustrations/Paperwork.png"
import { OpportunityStage } from "models/FullApplication"
import { RecommendationWrapper } from "pages/Application/Eligibility/Status/Recommendation"
import { RateCheckerPage } from "pages/Application/Eligibility/Status/RateCheckPromise/RateCheckerPage"
import { Documents } from "pages/Application/Eligibility/Status/Documents"
import BackgroundWave from "templates/BackgroundWave"
import { useApplicationFriendlyId } from "hooks/useApplication"

const Page = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  @media screen and ${devices.bp_xl} {
    flex-direction: row;
  }
`

const MobileNav = styled.div`
  height: 56px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;

  @media screen and ${devices.bp_xl} {
    display: none;
  }
`

const Hamburger = ({ isOpen, onClick }) => (
  <div onClick={onClick}>
    <img src={isOpen ? CloseHamburgerIcon : HamburgerIcon} alt="nav icon" />
  </div>
)

export enum StatusPageNavSelected {
  Status = "Status",
  Recommendation = "Recommendation",
  RateChecker = "Rate Checker",
  Documents = "Documents",
}

interface Props {
  friendlyId: string
  applicationStatus: OpportunityStage
  address?: string
  selected?: StatusPageNavSelected
}

export const StatusPage = ({
  friendlyId,
  applicationStatus,
  address = "",
  selected = StatusPageNavSelected.Status,
}: Props) => {
  const { application, refreshApplication } = useApplicationFriendlyId(friendlyId)
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Page>
      <BackgroundWave />
      <MobileNav>
        <img src={MojoLogo} alt="mojo logo" style={{ height: "28px" }} />
        <Hamburger
          isOpen={isOpen}
          onClick={() => {
            LogGaEvent({
              action: EventAction.navigation,
              event_category: EventCategory.functionalInteraction,
              event_label: isOpen ? "Close" : "Open",
            })
            setIsOpen(!isOpen)
          }}
        />
      </MobileNav>
      <Sidebar
        friendlyId={friendlyId}
        address={address}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        selected={selected}
      />
      {selected === StatusPageNavSelected.Status && (
        <Status
          friendlyId={friendlyId}
          applicationStatus={applicationStatus}
          application={application}
          refreshApplication={refreshApplication}
        />
      )}
      {selected === StatusPageNavSelected.Recommendation && (
        <RecommendationWrapper
          applicationId={application?.applicationId}
          friendlyId={application?.friendlyId}
        />
      )}
      {selected === StatusPageNavSelected.RateChecker && (
        <RateCheckerPage applicationId={application?.applicationId}/>
      )}
      {selected === StatusPageNavSelected.Documents && (
        <Documents friendlyId={friendlyId} application={application} />
      )}
    </Page>
  )
}
