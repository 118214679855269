import React, { useEffect } from "react"

export function useOutsideClick(ref, handler = () => {}) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        handler()
      }
    }

    document.addEventListener("mousedown", handleClickOutside)

    return function () {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [ref])
}
