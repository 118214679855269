import React from "react"
import styled from "styled-components"
import Confused from "url:assets/images/partners/Confused.svg"
import Money from "url:assets/images/partners/Money.svg"
import Uswitch from "url:assets/images/partners/Uswitch.svg"
import Advisors6 from "url:assets/images/advisors6.png"
import { Trustpilot } from "components/atoms"
import { Paragraph } from "components/_blueprint/Typography"
import { desktop, mobile, space, tablet, Gap } from "components/_blueprint/Layout"

type Props = {
  mortgageType: "mortgage" | "remortgage"
}

export const ConfidenceFooter: React.FC<Props> = ({ mortgageType }) => (
  <Footer>
    <Paragraph as="strong" color="white" size="large" weight={600}>
      Mojo is a free mortgage broker working alongside Uswitch, Confused & Money
    </Paragraph>
    <Gap x={1} />
    <LogoRow>
      <PartnerImage src={Uswitch} alt="Uswitch logo" />
      <PartnerImage src={Confused} alt="Confused logo" />
      <PartnerImage src={Money} alt="Money logo" />
    </LogoRow>
    <Gap x={1} />
    <Paragraph color="white">
      {`We’re here to make you feel more confident about ${mortgageType}s at an uncertain time.
        We don’t charge you a penny. Like other mortgage brokers, we get a fee from the
        lender on completion of any ${mortgageType} — this will be detailed in any illustration
        we send you.`}
    </Paragraph>
    <ImgRow>
      <Advisors src={Advisors6} alt="" />
      <TrustPilotWrapper>
        <Trustpilot theme="dark" />
      </TrustPilotWrapper>
    </ImgRow>
  </Footer>
)

const Footer = styled.footer({
  alignSelf: "center",
  gridColumnStart: 2,
  gridRowStart: 2,
  [mobile]: {
    padding: "64px 24px",
  },
  [tablet]: {
    padding: "64px 40px",
  },
  [desktop]: {
    padding: "64px 40px",
  },
})

const LogoRow = styled.div({
  alignItems: "center",
  display: "flex",
})

const ImgRow = styled.div({
  alignItems: "center",
  display: "flex",
  [mobile]: {
    alignItems: "flex-start",
    flexDirection: "column",
  },
  [tablet]: {
    justifyContent: "space-between",
  },
  [desktop]: {
    justifyContent: "space-between",
  },
})

const Advisors = styled.img({
  width: 240,
  [mobile]: { marginBottom: space },
})

const PartnerImage = styled.img({
  [mobile]: {
    marginRight: space * 2,
  },
  [tablet]: {
    marginRight: space * 3,
  },
  [desktop]: {
    marginRight: space * 3,
  },
})

// The height on the widget is off, and it doesn't seem to be honoring the data props.
const TrustPilotWrapper = styled.div({
  height: 64,
  overflowY: "clip",
})
