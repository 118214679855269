import React, { useState, useEffect } from "react"
import axios from "axios"
import styled, { css } from "styled-components"
import { LogGaEvent } from "lib/GoogleAnalytics"
import { EventAction, EventCategory, EventLabel } from "models/GoogleAnalytics"
import devices from "styles/devices"
import TickBlack from "assets/images/icons/TickBlack.svg"
import IconTick from "assets/images/icons/Icon_Tick.svg"
import ListItem from "url:assets/images/icons/ListItem.svg"
import BoxesPerson from "assets/images/illustrations/BoxesPerson.png"
import BoxesDog from "assets/images/illustrations/BoxesDog.png"
import Mojo from "assets/images/Logo_Mojo.svg"
import RateCheckerImg from "assets/images/rateChecker-latest.png"
import { Loader, LoaderTheme } from "components/atoms/Loader"
import { LenderIcon } from "./LenderIcon"
import { useTrackNavigatedToPage } from "hooks/useTrackPageView"
import { MY_MOJO_API } from "../../../../Config"
import { useGetDigitalRecommendationAccept } from "hooks/useAdminControls"
import { ToCurrency } from "lib/CurrencyFormatter"
import { convertDecimalToYearsMonths, convertMonthsToYearsAndMonths } from "lib/Date"
import PhoneRateCheckNotification from "assets/images/phone-rate-check-notification.svg"
import useRateCheck from "hooks/useRateCheck"

const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 848px;
  margin-top: 16px;

  @media screen and ${devices.bp_xs} {
    margin: 16px 16px 0 16px;
    gap: 16px;
  }

  @media screen and ${devices.bp_xl} {
    min-width: 768px;
    margin: 8.5em 12.5em 0 6.25em;
  }
`

const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #ffebdc;
  text-align: center;
  border-radius: 24px;
  padding: 32px 24px;
`

const Header = styled.h1`
  font-size: 32px;
  font-weight: 700;
  max-width: 380px;
  @media screen and ${devices.bp_xl} {
    margin-top: 3em;
  }
`

const SubHeader = styled.p`
  line-height: 1.5em;
  z-index: 1000;
  margin: 32px 0;
  text-align: center;
  font-size: 14px;
  max-width: 380px;
  padding: 0 32px;
  @media screen and ${devices.bp_xl} {
    margin-bottom: 128px;
  }
`

const EmptySubHeader = styled.div`
  line-height: 25.2px;
  z-index: 1000;
  margin: 32px 0;
  text-align: center;
  font-size: 14px;
  max-width: 380px;
  padding: 0 32px;
  display: flex;
  font-weight: 500;
  flex-direction: column;
  gap: 48px;
  @media screen and ${devices.bp_xl} {
    margin-bottom: 128px;
  }
`

const PersonImage = styled.img`
  display: none;

  @media ${devices.bp_xl} {
    width: 256px;
    display: block;
    position: absolute;
    top: -221px;
    left: 0;
  }
`

const DogImage = styled.img`
  display: none;

  @media ${devices.bp_xl} {
    width: 196px;
    display: block;
    position: absolute;
    top: -171px;
    right: 0;
  }
`

const SummaryContainer = styled.div`
  position: relative;
  display: none;
  flex-direction: column;
  width: 100%;
  justify-content: left;
  background: #000928;
  border-radius: 16px;
  text-align: left;
  padding: 32px;
  color: #fff;
  gap: 48px;

  @media screen and ${devices.bp_xl} {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`

const SummaryImagesContainer = styled.div`
  position: relative;
  bottom: -8px;
  width: 100%;
`

const SummaryLendersContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  flex: 5;

  @media screen and ${devices.bp_xl} {
    flex-direction: row;
  }
`

const SummaryIcon = styled.div`
  margin-bottom: 16px;
`

const SummaryInfo = styled.div`
  font-weight: 600;
  font-size: 20px;
`

const SummaryDetailsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  color: #eef0f8;
  gap: 24px;
  flex: 3;
`

const SummaryDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
  gap: 8px;
`
const SummaryDetailsTitle = styled.div`
  font-weight: 500;
  font-size: 14px;
`

const SummaryDetailsDigits = styled.div`
  font-weight: 600;
  font-size: 24px;
`

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  @media screen and ${devices.bp_xl} {
    width: 34em;
  }
`

const KeyDetails = styled.div`
  background: #fff;
  border-radius: 24px;
`

const RateCheckPromiseTitle = styled.h2`
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
`

const KeyDetailsTitle = styled.div`
  padding: 24px 32px 8px 32px;

  & > h2 {
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
  }

  & > span {
    font-weight: 600;
    font-size: 24px;
  }

  & > p {
    font-weight: 500;
    font-size: 14px;
    color: rgba(59, 66, 88, 1);
    line-height: 24px;
  }

  @media screen and ${devices.bp_xl} {
    padding: 24px 32px 8px 32px;
  }
`

const KeyDetailsList = styled.ul`
  padding: 24px;
  background: rgba(7, 62, 217, 0.05);
  background-opacity: 5%;
  border-radius: 16px;
  margin: 8px;
`

const AdditionalNotes = styled.p`
  margin: 8px 32px 24px;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
`

const KeyDetailsListItem = styled.li`
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  border-bottom: 1px solid rgba(181, 190, 220, 1);
  padding-bottom: 16px;
  margin-top: 16px;

  &:first-of-type {
    margin-top: 0;
  }

  &:last-of-type {
    border-bottom: none;
    padding-bottom: 0px;
  }

  & > span:first-of-type {
    font-weight: 500;
    font-size: 16px;
    flex: 1;
    text-align: left;
    line-height: 28px;
  }

  & > span {
    font-weight: 600;
    font-size: 16px;
    text-align: right;
    flex: 1;
    line-height: 28px;
  }
`

const KeyDetailsBoldText = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  align-self: center;
`

const KeyDetailsWithSubText_SharedStyles = css`
  display: flex;
  gap: 16px;

  & > span:first-of-type {
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
  }

  & > p {
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    margin-top: 8px;
    margin-bottom: 0px;
    color: #000928;

    @media screen and ${devices.bp_xl} {
      margin-top: 8px;
    }
  }
`

const KeyDetailsWithSubText = styled.span<{ direction?: string }>`
  display: flex;
  flex-direction: column;
  width: 100%;

  & > div:first-of-type {
    ${KeyDetailsWithSubText_SharedStyles}
    justify-content: space-between;
  }

  & > div:last-of-type {
    ${KeyDetailsWithSubText_SharedStyles}
    justify-content: ${({ direction }) => (direction === "right" ? "flex-end" : "space-between")};
  }

  & > p {
    font-size: 12px;
    line-height: 18px;
    margin-top: 8px;
    margin-bottom: 0px;
    color: #000928;

    @media screen and ${devices.bp_xl} {
      margin-top: 8px;
    }
  }
`

const FixedPeriodContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;

  & > div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    font-size: 16px;
    flex: 1;
    text-align: left;
    line-height: 28px;

    & > span:first-of-type {
      font-weight: 500;
    }
  }
`

const BrokerFee = styled.div`
  background: #073ed9;
  border-radius: 16px;
  color: white;
  display: flex;
  flex-direction: column;
  padding: 24px;
  margin: 0 8px 8px 8px;

  & > div {
    display: flex;
    justify-content: space-between;

    & > span {
      font-weight: 600;
      font-size: 16px;
      line-height: 28.8px;
    }
  }

  & > p {
    padding: 0;
    margin: 0;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
  }
`

const Extras = styled.div`
  padding: 16px 32px 0 32px;

  & > span {
    font-weight: 600;
    font-size: 18px;
  }

  & > p {
    font-weight: 500;
    font-size: 14px;
    color: rgba(59, 66, 88, 1);
    line-height: 24px;
  }
`

const ExtraPill = styled.li`
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 24px;
  padding: 12px 8px;

  & > span:first-of-type {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(251, 187, 91, 1);
    border-radius: 100%;
    width: 24px;
    height: 24px;

    ::after {
      content: url(${TickBlack});
      stroke: black;
      transform: scale(1);
      position: relative;
      top: 2px;
    }
  }

  & > span {
    font-weight: 600;
    font-size: 16px;
  }
`

const ExtrasList = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 12px;
  background: rgba(255, 247, 235, 1);
  border-radius: 16px;
  margin: 8px;

  @media screen and ${devices.bp_sm} {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 32px;
  }
`

const Documents = styled.div`
  background: #fff;
  border-radius: 24px;
`

const DocumentsTitle = styled.div`
  padding: 24px 32px 8px 32px;

  & > span {
    font-weight: 600;
    font-size: 24px;
  }

  & > p {
    font-weight: 500;
    font-size: 14px;
    color: rgba(59, 66, 88, 1);
    line-height: 24px;
    margin-bottom: 0;
  }
`

const DocumentsList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 0;
  padding: 8px;
`

const Document = styled.li`
  display: flex;
  flex-direction: column;
  align-items: end;
  background: rgba(7, 62, 217, 0.05);
  border-radius: 16px;
  list-style-type: none;
  padding: 24px;

  @media screen and ${devices.bp_sm} {
    align-items: initial;
  }

  @media screen and ${devices.bp_xl} {
    align-items: end;
    flex-direction: row;
  }
`

const DocumentDetails = styled.div`
  display: flex;
  flex-direction: column;

  & > span {
    font-weight: 600;
    font-size: 18px;
  }

  & > p {
    font-weight: 500;
    font-size: 14px;
    color: rgba(59, 66, 88, 1);
    line-height: 24px;
    flex: 1;
    margin: 16px 0 0 0;

    @media screen and ${devices.bp_xl} {
      align-items: center;
      flex: 8;
    }
  }

  @media screen and ${devices.bp_xl} {
    margin-right: 64px;
  }
`

const DownloadLoadingSpinner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 8px 8px 0;
  margin-top: 16px;
  width: 100%;
  font-weight: 600;
  font-size: 16px;
  flex: 1;

  @media screen and ${devices.bp_xl} {
    flex: 2;
    margin-top: 0px;
    padding: 0 64px;
    height: 56px;
  }
`

const DocumentDownload = styled.button`
  cursor: pointer;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 8px 8px 0;
  margin-top: 16px;
  width: 100%;
  border-radius: 8px;
  border: 2px solid black;
  font-weight: 600;
  font-size: 16px;
  flex: 1;

  @media screen and ${devices.bp_xl} {
    flex: 2;
    margin-top: 0px;
    padding: 0 24px 0 16px;
    height: 56px;
  }

  ::before {
    content: url(${ListItem});
    fill: black;
    color: black;
    transform: scale(1);
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    padding: 4px 2px 0px 0px;
    margin: 8px;
    border-right: 2px solid black;
  }
`

const DocumentDownloadLink = styled.a`
  cursor: pointer;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 8px 8px 0;
  margin-top: 16px;
  width: 100%;
  border-radius: 8px;
  border: 2px solid black;
  font-weight: 600;
  font-size: 16px;
  flex: 1;

  @media screen and ${devices.bp_xl} {
    flex: 2;
    margin-top: 0px;
    padding: 0 24px 0 16px;
    height: 56px;
  }

  ::before {
    content: url(${ListItem});
    fill: black;
    color: black;
    transform: scale(1);
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    padding: 4px 2px 0px 0px;
    margin: 8px;
    border-right: 2px solid black;
  }
`

const Ready = styled.div`
  background: #ffebdc;
  border-radius: 24px;
  padding: 8px;
`

const ReadyDetailsInfo = styled.div`
  padding: 24px;

  & > span {
    font-weight: 600;
    font-size: 24px;
  }

  & > p {
    font-weight: 500;
    font-size: 14px;
    color: rgba(59, 66, 88, 1);
    line-height: 24px;
  }
`

const ReadyDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  width: 100%;

  & > div {
    color: #3b4258;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
  }
`

const ReadyDetailsAcceptance = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;

  & > p {
    font-weight: 500;
    font-size: 14px;
    color: rgba(59, 66, 88, 1);
    line-height: 24px;
    flex: 1;

    @media screen and ${devices.bp_sm} {
      align-items: center;
    }
  }

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-width: 300px;
    gap: 8px;

    @media screen and ${devices.bp_sm} {
      width: auto;
    }
  }

  @media screen and ${devices.bp_sm} {
    flex-direction: row;
  }
`

const UploadDocumentsContainer = styled.div`
  padding: 32px;
  border-radius: 16px;
  background: #fff;

  & > span {
    font-weight: 600;
    font-size: 18px;
    text-align: center;
  }

  & > div {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    & > span {
      flex: 8;
    }

    @media screen and ${devices.bp_sm} {
      flex-direction: row;
    }
  }
`

const DocumentsUploadLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  color: #fff;
  padding: 8px 16px 0 16px;
  width: 100%;
  height: 54px;
  border: none;
  background: rgba(7, 62, 217, 1);
  box-shadow: 0px 8px 0px 0px rgba(0, 27, 190, 1);
  min-width: 234px;
  text-decoration: none;

  @media screen and ${devices.bp_sm} {
    width: auto;
  }

  &:hover {
    opacity: 90%;
  }
`

const Disclaimer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  text-align: center;
  padding: 32px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
`

const RecommendationAccepted = styled.div`
  color: #398056;
  background: #eef5f3;
  padding: 8px 16px 0px 16px;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 54px;
  border-radius: 8px;

  ::before {
    content: url(${IconTick});
  }
`

const AcceptButton = styled.button`
  border-radius: 8px;
  cursor: pointer;
  color: #fff;
  padding: 8px 16px 0 16px;
  width: 100%;
  height: 54px;
  border: none;
  background: rgba(7, 62, 217, 1);
  box-shadow: 0px 8px 0px 0px rgba(0, 27, 190, 1);

  &:hover {
    opacity: 90%;
  }
`

const OverpaymentAreaContainer = styled.div`
  & > h2 {
    padding: 16px 32px 8px 32px;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
  }
`

const DealEndInfoContainer = styled.div`
  background: #fff;
  border-radius: 24px;

  & > h2 {
    padding: 24px 32px 8px 32px;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
  }

  & > p {
    padding: 24px 32px 24px 8px;
    font-weight: 500;
    font-size: 14px;
    color: rgba(59, 66, 88, 1);
    line-height: 24px;
    margin: 0;
  }
`

const KeyDetailsListItemSubText = styled.span`
  display: flex;
  font-size: 12px;
  line-height: 18px;
  font-weight: normal;
  color: #000928;
  width: 100%;
  text-align: right;
`

const MortgageExpertContainer = styled.div`
  display: flex;
  background: #fff;
  border-radius: 24px;
  padding: 32px;

  & > img {
    display: none;
    width: 56px;
    height: 56px;
    margin-right: 16px;

    @media screen and ${devices.bp_xs} {
      display: block;
    }
  }
`

const MortgageExpertContactDetails = styled.div`
  display: flex;
  flex-direction: column;

  & > p {
    font-weight: 400;
    font-size: 16px;
    margin: 8px 0;
  }

  & > div {
    display: flex;
    flex-direction: column;
    gap: 8px;

    @media screen and ${devices.bp_sm} {
      flex-direction: row;
      gap: 32px;
    }
  }
`

const RateChecker = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 24px;
  margin-top: 16px;
  padding: 8px;
  @media screen and ${devices.bp_sm} {
    flex-direction: row;
  }
`
const RateCheckerDetails = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 32px 32px 24px;
  gap: 16px;
  & > span {
    font-weight: 600;
    font-size: 18px;
  }
  & > p {
    margin: 0;
    font-weight: 500;
    font-size: 14px;
    color: rgba(59, 66, 88, 1);
    line-height: 24px;
  }
  @media screen and ${devices.bp_xl} {
    padding: 32px 32px 8px 24px;
  }
`
const MojoLogo = styled.img`
  height: 24px;
  width: 84px;
`
const RateCheckerTitle = styled.div`
  display: flex;
  align-items: end;
  margin-bottom: 16px;
  & > span {
    font-size: 16px;
    margin-left: 16px;
  }
`

const RateCheckerPromiseImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 16px;
  @media screen and ${devices.bp_sm} {
    max-width: 280px;
    max-height: 280px;
  }
`

const RateCheckerImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 16px;
  @media screen and ${devices.bp_sm} {
    max-width: 360px;
    max-height: 360px;
  }
`

const RateCheckerFAQLink = styled.a`
  color: #073ed9;
`

interface WrapperProps {
  applicationId: string | null
  friendlyId: string | null
}

interface Props {
  applicationData: ApplicationDataProps | null
  downloadEsisDocument: () => Promise<void> | void
  acceptRecommendationOpportunity?: () => Promise<void> | void
  acceptRecommendationQuote?: () => Promise<void> | void
  isDownloading: boolean
  isAccepting?: boolean
  isPreview?: boolean
  friendlyId?: string
}

export interface ApplicationDataProps {
  applicationId: string
  leadApplicantFirstName: string
  secondaryApplicantFirstName: string
  numberOfApplicants: number
  lenderName: string
  quoteId: string | undefined
  productName: string
  initialMonthlyPayment: number
  initialRate: number
  productType: string
  productEndDate: string
  mortgageEndsBeforeProduct: boolean | undefined
  mortgageExpertName: string
  mortgageExpertImage?: string
  mortgageExpertPhone?: string
  mortgageExpertEmail?: string
  initialFixedPeriod: number
  arrangementFee: number
  paymentType: string
  previewEsisURL: string | undefined
  esisS3Key: string
  accepted: boolean
  acceptedVia: string
  acceptedDateTime: string
  aprc: number
  followOnMonthlyPayment: number
  followOnMonthlyRate: number
  earlyRepaymentCharge: string
  earlyRepaymentChargesApply: boolean
  totalCostOverPeriod: number
  ltv: number
  termYears: number
  totalLoanAmount: number
  currentMortgageBalance: number
  additionalBorrowingAmount: number
  propertyValue: number
  purchasePrice: number
  productTransfer: boolean
  addArrangementFeeToLoan: boolean
  additionalNotes?: string
  interestOnlyAmount?: number
  repaymentAmount?: number
  extras: {
    offset: boolean
    portable: boolean
    overpayments: boolean
    freeLegals: boolean
    freeValuation: boolean
    cashback: number
  }
}

export const RecommendationWrapper = ({ applicationId, friendlyId }: WrapperProps) => {
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)
  const [isDownloading, setIsDownloading] = useState(false)
  const [applicationData, setApplicationData] = useState<ApplicationDataProps | null>(null)
  const [isAccepting, setIsAccepting] = useState(false)
  useTrackNavigatedToPage("/application/recommendation")

  const customDimensions = (data: ApplicationDataProps) => ({
    friendlyId,
    lender_name: data.lenderName,
    product_name: data.productName,
    monthly_cost: data.initialMonthlyPayment?.toString(),
    initial_rate: data.initialRate?.toString(),
    mortgage_product_type: data.productType,
    initial_period_end_date: data.productEndDate,
    initial_fixed_period: data.initialFixedPeriod?.toString(),
    arrangement_fee: data.arrangementFee?.toString(),
    mortgage_payment_type: data.paymentType,
    offset: data.extras?.offset ? "Yes" : "No",
    portable: data.extras?.portable ? "Yes" : "No",
    overpayments_allowed: data.extras?.overpayments ? "Yes" : "No",
    free_legals: data.extras?.freeLegals ? "Yes" : "No",
    free_valuation: data.extras?.freeValuation ? "Yes" : "No",
    cashback: data.extras?.cashback?.toString(),
    full_page_url: `/application/${friendlyId}/recommendation`,
  })

  useEffect(() => {
    if (applicationId) {
      axios
        .get(`${process.env.MY_MOJO_API}/recommendation/${applicationId}`, {
          withCredentials: true,
        })
        .then(({ data }) => {
          setApplicationData(data)
          setIsError(false)
          return setupScrollListener(loadCustomerFeedbackForm)
        })
        .catch((error) => {
          console.error(error)
          setIsError(true)
          setIsLoading(false)
        })
    }
  }, [applicationId])

  useEffect(() => {
    if (isError) {
      LogGaEvent({
        action: EventAction.recommendationNotShown,
        event_category: EventCategory.siteInteraction,
        event_label: EventLabel.digiRecNotReady,
        friendlyId,
        full_page_url: `/application/${friendlyId}/recommendation`,
      })
    }
  }, [isError])

  useEffect(() => {
    if (applicationData) {
      LogGaEvent({
        action: EventAction.recommendationShown,
        event_category: EventCategory.siteInteraction,
        event_label: EventLabel.digiRec,
        ...customDimensions(applicationData),
      })
      setIsLoading(false)
    }
  }, [applicationData])

  const downloadEsisDocument = async (): Promise<void> => {
    const body = {
      s3Key: applicationData.esisS3Key,
      applicationId,
    }

    setIsDownloading(true)
    LogGaEvent({
      action: EventAction.documentDownload,
      event_category: EventCategory.functionalInteraction,
      event_label: EventLabel.digiRecEsis,
      ...customDimensions(applicationData),
    })
    try {
      const response = await axios.post(`${MY_MOJO_API}/documents/download-url`, body, {
        withCredentials: true,
      })
      if (response.status !== 200) {
        setIsDownloading(false)
        throw new Error("Error downloading Mortgage Illustration")
      }
      const url = response.data.downloadUrl
      setIsDownloading(false)
      window.location.assign(url)
    } catch (error) {
      console.error(error)
      setIsDownloading(false)
      alert("Error downloading Mortgage Illustration")
    }
  }

  const acceptRecommendationOpportunity = async () => {
    LogGaEvent({
      action: EventAction.buttonClicked,
      event_category: EventCategory.siteInteraction,
      event_label: EventLabel.digiRecAccepted,
      ...customDimensions(applicationData),
    })

    setIsAccepting(true)
    await axios
      .put(
        `${process.env.MY_MOJO_API}/recommendation/update-recommendation-accepted`,
        { applicationId, friendlyId },
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        console.error(error)
      })
      .finally(() => {
        setIsAccepting(false)
      })
  }

  const acceptRecommendationQuote = async () => {
    LogGaEvent({
      action: EventAction.buttonClicked,
      event_category: EventCategory.siteInteraction,
      event_label: EventLabel.digiRecAccepted,
      ...customDimensions(applicationData),
    })

    const quoteId = applicationData?.quoteId

    setIsAccepting(true)
    await axios
      .put(
        `${process.env.MY_MOJO_API}/recommendation/update-recommendation-quote-accepted`,
        { applicationId, quoteId },
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        console.error(error)
      })
      .finally(() => {
        setIsAccepting(false)
      })
  }

  const loadCustomerFeedbackForm = () => {
    const scriptTag: HTMLScriptElement = document.createElement("script")
    scriptTag.src = "//l.getsitecontrol.com/y79nm31w.js"
    scriptTag.type = "text/javascript"
    scriptTag.async = true
    document.body.appendChild(scriptTag)
    return () => {
      document.body.removeChild(scriptTag)
    }
  }

  const setupScrollListener = (callback: () => void) => {
    const handleScroll = () => {
      const scrollPosition: number = window.scrollY + window.innerHeight
      const totalHeight: number = document.documentElement.scrollHeight

      if (scrollPosition >= totalHeight / 2) {
        // Check if scrolled more than 50%
        callback()
        // Remove the event listener once the condition is met to prevent it from triggering multiple times
        window.removeEventListener("scroll", handleScroll)
      }
    }
    window.addEventListener("scroll", handleScroll)
    // Return a cleanup function to remove the event listener
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }

  return (
    <Root>
      {isLoading ? (
        <LoaderContainer>
          <Loader size={150} theme={LoaderTheme.Outline} />
        </LoaderContainer>
      ) : (
        <>
          {isError ? (
            <>
              <HeaderContent>
                <Header>Your recommendation isn’t ready just yet</Header>
                <EmptySubHeader>
                  <span>
                    Your Mortgage Expert will provide a recommendation after they have spoken to you
                    and have gained a better understanding of your situation and needs.
                  </span>
                  <span>You’ll be notified when your recommendation is ready.</span>
                </EmptySubHeader>
                <SummaryImagesContainer>
                  <PersonImage src={BoxesPerson} />
                  <DogImage src={BoxesDog} />
                </SummaryImagesContainer>
              </HeaderContent>
              <Disclaimer>
                <span>
                  Mojo is a trading style of Life's Great Limited which is registered in England and
                  Wales (06246376). We are authorised and regulated by the Financial Conduct
                  Authority and are on the Financial Services Register (478215){" "}
                </span>
                <span>Our registered office is The Cooperage, 5 Copper Row, London, SE1 2LH.</span>
                <span>To contact us by phone, please call 0333 123 0012.</span>
                <span>
                  YOUR HOME/PROPERTY MAY BE REPOSSESSED IF YOU DO NOT KEEP UP REPAYMENTS ON YOUR
                  MORTGAGE
                </span>
              </Disclaimer>
            </>
          ) : (
            <Recommendation
              applicationData={applicationData}
              downloadEsisDocument={downloadEsisDocument}
              acceptRecommendationOpportunity={acceptRecommendationOpportunity}
              acceptRecommendationQuote={acceptRecommendationQuote}
              isDownloading={isDownloading}
              isPreview={false}
              isAccepting={isAccepting}
              friendlyId={friendlyId}
            />
          )}
        </>
      )}
    </Root>
  )
}

export const Recommendation = ({
  applicationData,
  downloadEsisDocument,
  acceptRecommendationOpportunity,
  acceptRecommendationQuote,
  isDownloading,
  isAccepting,
  isPreview,
  friendlyId,
}: Props) => {
  const {
    leadApplicantFirstName,
    secondaryApplicantFirstName,
    lenderName,
    productName,
    initialMonthlyPayment,
    initialRate,
    productType,
    productEndDate,
    mortgageEndsBeforeProduct = false,
    mortgageExpertName,
    mortgageExpertImage,
    mortgageExpertPhone,
    mortgageExpertEmail,
    initialFixedPeriod,
    arrangementFee,
    quoteId,
    paymentType,
    previewEsisURL,
    accepted,
    acceptedVia,
    acceptedDateTime,
    totalCostOverPeriod,
    earlyRepaymentChargesApply,
    earlyRepaymentCharge,
    followOnMonthlyRate,
    followOnMonthlyPayment,
    aprc,
    ltv = null,
    termYears = null,
    totalLoanAmount = null,
    currentMortgageBalance = null,
    additionalBorrowingAmount = null,
    propertyValue = null,
    purchasePrice = null,
    productTransfer,
    addArrangementFeeToLoan = false,
    additionalNotes,
    interestOnlyAmount,
    repaymentAmount,
    extras: { offset, portable, overpayments, freeLegals, freeValuation, cashback },
  } = applicationData
  const formattedArrangementFee = currencyFormat(arrangementFee)
  const formattedMonthlyPayment = currencyFormat(initialMonthlyPayment)
  const formattedCashback = currencyFormat(cashback)
  const formattedFollowOnMonthlyPayment = currencyFormat(followOnMonthlyPayment)
  const formattedInterestOnlyAmount = currencyFormat(interestOnlyAmount)
  const formattedRepaymentAmount = currencyFormat(repaymentAmount)

  const formattedPaymentType = paymentType.charAt(0).toUpperCase() + paymentType.slice(1)

  const { recommendationAcceptActive } = useGetDigitalRecommendationAccept()
  const [showRecommendationAccept, setShowRecommendationAccept] = useState(false)
  const [hasAccepted, setHasAccepted] = useState(false)

  useEffect(() => {
    setShowRecommendationAccept(
      recommendationAcceptActive && !!acceptRecommendationOpportunity && !!acceptRecommendationQuote
    )
  }, [recommendationAcceptActive, acceptRecommendationOpportunity, acceptRecommendationQuote])

  const customDimensions = (data: ApplicationDataProps) => ({
    friendlyId,
    lender_name: data.lenderName,
    product_name: data.productName,
    monthly_cost: data.initialMonthlyPayment?.toString(),
    initial_rate: data.initialRate?.toString(),
    mortgage_product_type: data.productType,
    initial_period_end_date: data.productEndDate,
    initial_fixed_period: data.initialFixedPeriod?.toString(),
    arrangement_fee: data.arrangementFee?.toString(),
    mortgage_payment_type: data.paymentType,
    offset: data.extras?.offset ? "Yes" : "No",
    portable: data.extras?.portable ? "Yes" : "No",
    overpayments_allowed: data.extras?.overpayments ? "Yes" : "No",
    free_legals: data.extras?.freeLegals ? "Yes" : "No",
    free_valuation: data.extras?.freeValuation ? "Yes" : "No",
    cashback: data.extras?.cashback?.toString(),
    full_page_url: `/application/${friendlyId}/recommendation`,
  })

  const newFieldsNumber = [
    ltv,
    termYears,
    totalLoanAmount,
    currentMortgageBalance,
    additionalBorrowingAmount,
    propertyValue,
  ].filter((item) => item).length

  const RecommendationAcceptedInfo = () => {
    const recommendationAcceptedDateTime = acceptedDateTime
      ? new Date(acceptedDateTime)
          ?.toLocaleString("en-US", {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: false,
          })
          .replace(/,/g, "")
      : null

    const acceptedViaEnum = {
      MY_MOJO: "My Mojo",
      VERBALLY: "Verbally",
      SMS: "SMS",
      EMAIL: "Email",
    }

    // acceptance requires salesforce data via a page refresh or another query setup
    const clientsideDate = new Date()?.toLocaleString("en-US", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: false,
    })

    const formattedVia = acceptedVia ? acceptedVia?.toLowerCase() : null
    return (
      <div>
        <>
          Accepted{" "}
          {(acceptedVia === acceptedViaEnum.SMS || acceptedVia === acceptedViaEnum.EMAIL) &&
            `via ${formattedVia} `}
          {acceptedVia === acceptedViaEnum.VERBALLY && "over the phone "}
          {!recommendationAcceptedDateTime && clientsideDate}
        </>
        {!!recommendationAcceptedDateTime && recommendationAcceptedDateTime}
      </div>
    )
  }

  const isInRateCheckExperiment = useRateCheck(friendlyId)
  console.log("isInRateCheckExperiment:", isInRateCheckExperiment)

  const { years, months } = convertDecimalToYearsMonths(termYears)
  return (
    <>
      <HeaderContent>
        <Header>
          Hey {leadApplicantFirstName && leadApplicantFirstName}
          {secondaryApplicantFirstName && ` & ${secondaryApplicantFirstName}`}, here’s your mortgage
          recommendation
        </Header>
        <SubHeader>
          This deal has been selected based on your needs and preferences as discussed with your
          Mortgage Expert at Mojo Mortgages.
        </SubHeader>
        <SummaryContainer>
          <PersonImage src={BoxesPerson} role="presentation" />
          <DogImage src={BoxesDog} role="presentation" />
          <SummaryLendersContainer>
            <SummaryIcon>
              <LenderIcon white={true} lenderName={lenderName} />
            </SummaryIcon>
            <SummaryInfo>{productName}</SummaryInfo>
          </SummaryLendersContainer>
          <SummaryDetailsContainer>
            <SummaryDetails>
              <SummaryDetailsTitle>Initial Rate</SummaryDetailsTitle>
              <SummaryDetailsDigits>{initialRate}%</SummaryDetailsDigits>
            </SummaryDetails>
            <SummaryDetails>
              <SummaryDetailsTitle>Monthly Payment</SummaryDetailsTitle>
              <SummaryDetailsDigits>£{formattedMonthlyPayment}</SummaryDetailsDigits>
            </SummaryDetails>
          </SummaryDetailsContainer>
        </SummaryContainer>
      </HeaderContent>
      <KeyDetails>
        <KeyDetailsBoldText>
          <KeyDetailsTitle>
            <h2>Key deal details</h2>
          </KeyDetailsTitle>
        </KeyDetailsBoldText>
        <KeyDetailsList aria-label="Key details">
          <KeyDetailsListItem>
            <KeyDetailsBoldText>Lender</KeyDetailsBoldText>
            <KeyDetailsBoldText>{lenderName}</KeyDetailsBoldText>
          </KeyDetailsListItem>
          <KeyDetailsListItem>
            <KeyDetailsBoldText>Monthly payment</KeyDetailsBoldText>
            <KeyDetailsBoldText>£{formattedMonthlyPayment}</KeyDetailsBoldText>
          </KeyDetailsListItem>
          <KeyDetailsListItem>
            <KeyDetailsBoldText>Initial rate</KeyDetailsBoldText>
            <KeyDetailsBoldText>{initialRate}%</KeyDetailsBoldText>
          </KeyDetailsListItem>
          <KeyDetailsListItem>
            <KeyDetailsWithSubText>
              <div>
                <KeyDetailsBoldText>Arrangement fee</KeyDetailsBoldText>
                <KeyDetailsBoldText>£{formattedArrangementFee}</KeyDetailsBoldText>
              </div>
              <div>
                <p>Please refer to your mortgage illustration for a full breakdown of fees</p>
                {addArrangementFeeToLoan && <p>Added to loan</p>}
              </div>
            </KeyDetailsWithSubText>
          </KeyDetailsListItem>
          <KeyDetailsListItem>
            <FixedPeriodContainer>
              <div>
                <KeyDetailsBoldText>Initial period</KeyDetailsBoldText>
                <KeyDetailsBoldText>
                  {convertMonthsToYearsAndMonths(initialFixedPeriod)}
                </KeyDetailsBoldText>
              </div>
            </FixedPeriodContainer>
          </KeyDetailsListItem>
          <KeyDetailsListItem>
            <KeyDetailsBoldText>Product type</KeyDetailsBoldText>
            <KeyDetailsBoldText>{productType}</KeyDetailsBoldText>
          </KeyDetailsListItem>
          <KeyDetailsListItem>
            {formattedPaymentType === "Part and part" ? (
              <KeyDetailsWithSubText direction="right">
                <div>
                  <KeyDetailsBoldText>Payment type</KeyDetailsBoldText>
                  <KeyDetailsBoldText>{formattedPaymentType}</KeyDetailsBoldText>
                </div>
                <div>
                  <p>
                    (£{formattedInterestOnlyAmount} interest-only, £{formattedRepaymentAmount}{" "}
                    capital & interest)
                  </p>
                </div>
              </KeyDetailsWithSubText>
            ) : (
              <>
                <KeyDetailsBoldText>Payment type</KeyDetailsBoldText>
                <KeyDetailsBoldText>{formattedPaymentType}</KeyDetailsBoldText>
              </>
            )}
          </KeyDetailsListItem>
        </KeyDetailsList>
        <OverpaymentAreaContainer>
          <h2>Over and early repayments</h2>
          <KeyDetailsList>
            <KeyDetailsListItem>
              <KeyDetailsBoldText>Overpayments</KeyDetailsBoldText>
              <KeyDetailsBoldText>{overpayments ? "Yes" : "No"}</KeyDetailsBoldText>
            </KeyDetailsListItem>
            <KeyDetailsListItem>
              <KeyDetailsWithSubText>
                <div>
                  <KeyDetailsBoldText>Early repayment charge (ERC)</KeyDetailsBoldText>
                  <KeyDetailsBoldText>
                    {earlyRepaymentChargesApply ? "Yes" : "No"}
                  </KeyDetailsBoldText>
                </div>
                <p>{earlyRepaymentCharge}</p>
              </KeyDetailsWithSubText>
            </KeyDetailsListItem>
          </KeyDetailsList>
        </OverpaymentAreaContainer>
        <BrokerFee>
          <div>
            <span>Mojo's broker fee</span>
            <span>£0</span>
          </div>
          <p>
            We don't charge you a penny, all our costs are covered by the lender, these fees never
            affect our advice. Our reputation and livelihood depend on us always giving our
            customers the best possible advice.
          </p>
        </BrokerFee>
      </KeyDetails>
      <KeyDetails>
        <RateChecker>
          {isInRateCheckExperiment ? (
            <>
              <RateCheckerDetails>
                <RateCheckPromiseTitle>Rate Check Promise</RateCheckPromiseTitle>
                <p>
                  Once you have had an offer accepted by a lender, we’ll check you’re still on the
                  lowest rate for the mortgage deal you’ve chosen and will let you know of changes
                  all the way until you complete. If we find a lower rate for your deal, we’ll let
                  you know.
                </p>
              </RateCheckerDetails>
              <RateCheckerPromiseImage
                src={PhoneRateCheckNotification}
                alt="Example of a Rate Check Promise"
              />
            </>
          ) : (
            <>
              <RateCheckerDetails>
                <RateCheckerTitle>
                  <MojoLogo src={Mojo} />
                  <span>Rate Checker</span>
                </RateCheckerTitle>
                <span>Waiting for rates to fall?</span>
                <p>
                  {`Applying now secures today’s recommended rate. If rates drop later, ask us to re-check 70+ lenders and we’ll help you reapply for a lower rate—anytime between the offer and 30 days before completion`}
                </p>
                <RateCheckerFAQLink href={"https://mojomortgages.com/mojo-rate-checker"}>
                  View the FAQs
                </RateCheckerFAQLink>
              </RateCheckerDetails>
              <RateCheckerImage src={RateCheckerImg} />{" "}
            </>
          )}
        </RateChecker>
      </KeyDetails>
      <KeyDetails>
        {(cashback || freeLegals || freeValuation || offset || portable) && (
          <>
            <Extras>
              <span>Extras</span>
            </Extras>
            <ExtrasList aria-label="Extras">
              {offset && (
                <ExtraPill>
                  <span></span>
                  <span>Offset</span>
                </ExtraPill>
              )}
              {portable && (
                <ExtraPill>
                  <span></span>
                  <span>Portable</span>
                </ExtraPill>
              )}
              {(cashback && cashback > 0) === true && (
                <ExtraPill>
                  <span></span>
                  <span>£{formattedCashback} Cashback</span>
                </ExtraPill>
              )}
              {freeLegals && (
                <ExtraPill>
                  <span></span>
                  <span>Free standard legal costs*</span>
                </ExtraPill>
              )}
              {freeValuation && (
                <ExtraPill>
                  <span></span>
                  <span>Free valuation</span>
                </ExtraPill>
              )}
            </ExtrasList>
          </>
        )}
      </KeyDetails>
      <DealEndInfoContainer>
        <h2>When this deal ends</h2>
        {mortgageEndsBeforeProduct ? (
          <KeyDetailsList>
            <KeyDetailsListItem>
              <KeyDetailsWithSubText>
                <div>
                  <span>You'll have repaid the whole of your mortgage</span>
                </div>
                <p>Congratualations! Your mortgage will be repaid by the time this deal ends.</p>
              </KeyDetailsWithSubText>
            </KeyDetailsListItem>
          </KeyDetailsList>
        ) : (
          <>
            <KeyDetailsList>
              <KeyDetailsListItem>
                <KeyDetailsWithSubText>
                  <div>
                    <span>Deal end date</span>
                    <span>{formatProductEndDate(new Date(productEndDate))}</span>
                  </div>
                  <p>At this point you will be moved onto the standard variable rate.</p>
                </KeyDetailsWithSubText>
              </KeyDetailsListItem>
              <KeyDetailsListItem>
                <span>Standard variable rate (SVR)</span>
                <span>{followOnMonthlyRate}%</span>
              </KeyDetailsListItem>
              <KeyDetailsListItem>
                <span>Monthly payments after initial period</span>
                <span>£{formattedFollowOnMonthlyPayment}</span>
              </KeyDetailsListItem>
              <KeyDetailsListItem>
                <KeyDetailsWithSubText>
                  <div>
                    <span>Annual Percentage Rate of Charge (APRC)</span>
                    <span>{aprc}%</span>
                  </div>
                  <p>
                    APRC assumes the total cost of a mortgage, including fees, over the entire term
                    of the loan.
                  </p>
                </KeyDetailsWithSubText>
              </KeyDetailsListItem>
            </KeyDetailsList>
          </>
        )}
      </DealEndInfoContainer>
      {newFieldsNumber > 0 && (
        <KeyDetails>
          <KeyDetailsTitle>
            <h2>Your mortgage</h2>
          </KeyDetailsTitle>
          <KeyDetailsList aria-label="Your mortgage">
            {!!propertyValue && !purchasePrice && (
              <KeyDetailsListItem>
                <KeyDetailsBoldText>Property value</KeyDetailsBoldText>
                <KeyDetailsBoldText>
                  {propertyValue ? ToCurrency(propertyValue) : ToCurrency(purchasePrice)}
                </KeyDetailsBoldText>
              </KeyDetailsListItem>
            )}
            {!!purchasePrice && (
              <KeyDetailsListItem>
                <KeyDetailsBoldText>Property value</KeyDetailsBoldText>
                <KeyDetailsBoldText>
                  {purchasePrice && ToCurrency(purchasePrice)}
                </KeyDetailsBoldText>
              </KeyDetailsListItem>
            )}
            {!!currentMortgageBalance && (
              <KeyDetailsListItem>
                <KeyDetailsBoldText>Balance</KeyDetailsBoldText>
                <KeyDetailsBoldText>{ToCurrency(currentMortgageBalance)}</KeyDetailsBoldText>
              </KeyDetailsListItem>
            )}
            {!!additionalBorrowingAmount && !purchasePrice && (
              <KeyDetailsListItem>
                <KeyDetailsBoldText>Additional borrowing</KeyDetailsBoldText>
                <KeyDetailsBoldText>{ToCurrency(additionalBorrowingAmount)}</KeyDetailsBoldText>
              </KeyDetailsListItem>
            )}
            {!!totalLoanAmount && (
              <KeyDetailsListItem>
                <KeyDetailsWithSubText>
                  <div>
                    <KeyDetailsBoldText>Borrowing total</KeyDetailsBoldText>
                    <KeyDetailsBoldText>
                      {ToCurrency(
                        addArrangementFeeToLoan ? totalLoanAmount + arrangementFee : totalLoanAmount
                      )}
                    </KeyDetailsBoldText>
                  </div>
                  <div>
                    <p></p>
                    {addArrangementFeeToLoan && (
                      <p>
                        <KeyDetailsListItemSubText>
                          Includes £{formattedArrangementFee} arrangement fee
                        </KeyDetailsListItemSubText>
                      </p>
                    )}
                  </div>
                </KeyDetailsWithSubText>
              </KeyDetailsListItem>
            )}
            {!!ltv && typeof ltv === "number" && (
              <KeyDetailsListItem>
                <KeyDetailsBoldText>Loan to value</KeyDetailsBoldText>
                <KeyDetailsBoldText>{Math.floor(ltv)}%</KeyDetailsBoldText>
              </KeyDetailsListItem>
            )}
            {!!termYears && (
              <KeyDetailsListItem>
                <KeyDetailsBoldText>Term</KeyDetailsBoldText>
                <KeyDetailsBoldText>
                  {years ? <span>{years} years</span> : null}
                  {months ? <span> {months} months</span> : null}
                </KeyDetailsBoldText>
              </KeyDetailsListItem>
            )}
          </KeyDetailsList>
        </KeyDetails>
      )}
      {additionalNotes && additionalNotes?.replace(/\s/g, "")?.length > 0 && (
        <KeyDetails>
          <KeyDetailsTitle>
            <h2>Additional information</h2>
          </KeyDetailsTitle>
          <AdditionalNotes>{additionalNotes}</AdditionalNotes>
        </KeyDetails>
      )}
      <Documents>
        <DocumentsTitle>
          <span>Documents</span>
        </DocumentsTitle>
        <DocumentsList aria-label="Documents">
          <Document>
            <DocumentDetails>
              <span>Mortgage Illustration</span>
              <p>
                This document outlines the full details and risks of the mortgage product being
                recommended, please ensure you take the time to read through.
              </p>
            </DocumentDetails>
            {isDownloading ? (
              <DownloadLoadingSpinner>
                <Loader size={32} theme={LoaderTheme.Outline} />
              </DownloadLoadingSpinner>
            ) : (
              <>
                {isPreview ? (
                  <DocumentDownloadLink href={previewEsisURL}>
                    <span>Download</span>
                  </DocumentDownloadLink>
                ) : (
                  <DocumentDownload onClick={downloadEsisDocument}>
                    <span>Download</span>
                  </DocumentDownload>
                )}
              </>
            )}
          </Document>
        </DocumentsList>
      </Documents>
      {showRecommendationAccept && (
        <Ready>
          <ReadyDetailsInfo>
            <span>Ready to go?</span>
            <ReadyDetails>
              <ReadyDetailsAcceptance>
                {productTransfer ? (
                  <p>
                    Great news, that's everything we need right now we'll confirm when your
                    application has been submitted.
                  </p>
                ) : (
                  <p>
                    If you're happy with your recommendation then click to accept and we'll get the
                    wheels in motion to secure your rate.
                  </p>
                )}
                <div>
                  {isAccepting ? (
                    <Loader size={32} theme={LoaderTheme.Outline} />
                  ) : (
                    <>
                      {accepted || hasAccepted ? (
                        <RecommendationAccepted>
                          <span>Accepted</span>
                        </RecommendationAccepted>
                      ) : (
                        <>
                          <AcceptButton
                            type="button"
                            onClick={() => {
                              setHasAccepted(true)
                              if (quoteId) {
                                acceptRecommendationQuote()
                              } else {
                                acceptRecommendationOpportunity()
                              }
                            }}
                          >
                            Accept Recommendation
                          </AcceptButton>
                        </>
                      )}
                    </>
                  )}

                  {(accepted || hasAccepted) && !isAccepting && <RecommendationAcceptedInfo />}
                </div>
              </ReadyDetailsAcceptance>
              <div>
                By accepting, you are giving consent for your selected lender to conduct a hard
                credit check (this does not apply if you are transferring to a new product with your
                existing lender)
              </div>
            </ReadyDetails>
          </ReadyDetailsInfo>

          {hasAccepted && !isAccepting && !productTransfer && (
            <UploadDocumentsContainer>
              <span>Great! You’re now one step closer</span>
              <div>
                <span>
                  Please check we have all the correct documents for you and we'll get the wheels in
                  motion.
                </span>
                <DocumentsUploadLink
                  href={`/application/${friendlyId}/documents`}
                  onClick={() => {
                    LogGaEvent({
                      action: EventAction.buttonClicked,
                      event_category: EventCategory.functionalInteraction,
                      event_label: EventLabel.digiRecDocUpload,
                      ...customDimensions(applicationData),
                    })
                  }}
                >
                  Upload Documents
                </DocumentsUploadLink>
              </div>
            </UploadDocumentsContainer>
          )}
        </Ready>
      )}
      <MortgageExpertContainer>
        {mortgageExpertImage && (
          <img
            src={mortgageExpertImage}
            alt="your mortgage expert"
            aria-label={"your mortgage expert"}
          ></img>
        )}
        <MortgageExpertContactDetails>
          <p>
            This recommendation was created by your Mortgage Expert <b>{mortgageExpertName}</b>
          </p>
          <div>
            <div>{mortgageExpertPhone}</div>
            <a href={`mailto:${mortgageExpertEmail}`}>{mortgageExpertEmail}</a>
          </div>
        </MortgageExpertContactDetails>
      </MortgageExpertContainer>
      <Disclaimer>
        {freeLegals && <span>*There may additional charges for non-standard transaction.</span>}
        <span>
          Mojo is a trading style of Life's Great Limited which is registered in England and Wales
          (06246376). We are authorised and regulated by the Financial Conduct Authority and are on
          the Financial Services Register (478215){" "}
        </span>
        <span>Our registered office is The Cooperage, 5 Copper Row, London, SE1 2LH.</span>
        <span>To contact us by phone, please call 0333 123 0012.</span>
        <span>
          YOUR HOME/PROPERTY MAY BE REPOSSESSED IF YOU DO NOT KEEP UP REPAYMENTS ON YOUR MORTGAGE
        </span>
      </Disclaimer>
    </>
  )
}

const currencyFormat = (num: number) => {
  const formattedNum = num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  // If number after decimal point is single, add a 0 to it.
  return formattedNum?.replace(/(\.\d)$/, "$10")
}

const formatProductEndDate = (date: Date) => {
  const month = date.toLocaleString("default", { month: "short" })
  const year = date.getFullYear()
  return `${month} ${year}`
}

const getFullInitialFixedPeriod = (totalMonths: number): { years: number; months: number } => {
  const years = Math.floor(totalMonths / 12)
  const months = totalMonths % 12
  return { years, months }
}
