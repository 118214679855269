export type ApplicationSummary = {
  mortgageApplicationId?: number
  applicationId?: string
  friendlyId?: string
  applicationReason?: ApplicationReason
  term?: number
  createdOn?: string
  userId?: number
  isWhitelabel?: boolean
}

export enum ApplicationReason {
  Unknown = "Unknown",
  FoundProperty = "Found_a_Property",
  LookingForProperty = "Looking_for_a_Property",
  Remortgage = "Remortgage",
}
