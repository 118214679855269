import React from "react"
import styled from "styled-components"
import { transparentize } from "polished"

const Circle = styled.div`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  display: block;

  // Loader themes
  &.solid {
    &::before {
      border-top-color: ${({ theme, transparencyAmount }) =>
        transparentize(transparencyAmount, theme.primaryFour)};
      border-right-color: ${({ theme, transparencyAmount }) =>
        transparentize(transparencyAmount, theme.primaryFour)};
    }

    ::after {
      border-bottom-color: ${(props) => props.theme.primaryFour};
      border-left-color: ${(props) => props.theme.primaryFour};
    }
  }

  &.outline {
    &::before {
      border-top-color: ${({ transparencyAmount }) => transparentize(transparencyAmount, "black")};
      border-right-color: ${({ transparencyAmount }) =>
        transparentize(transparencyAmount, "black")};
    }

    ::after {
      border-bottom-color: black;
      border-left-color: black;
    }
  }

  ::before {
    content: "";
    position: absolute;
    width: ${({ size }) => size}px;
    height: ${({ size }) => size}px;
    border: ${({ loaderThickness }) => loaderThickness}px solid transparent;
    border-radius: 50%;
    animation: rotate ${({ animationSeconds }) => animationSeconds}s infinite linear;
  }

  ::after {
    content: "";
    position: absolute;
    width: ${({ size }) => size}px;
    height: ${({ size }) => size}px;
    border: ${({ loaderThickness }) => loaderThickness}px solid transparent;
    border-radius: 50%;
    animation: rotate ${({ animationSeconds }) => animationSeconds}s infinite linear;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`

export function Loader({
  theme = LoaderTheme.Solid,
  size = 29,
}: {
  theme: LoaderTheme
  size: number
}) {
  const LoaderThickness = (0.5 * size) / 3
  const TransparencyAmount = 0.88
  const AnimationSeconds = 1.3

  const classes = []

  switch (theme) {
    case LoaderTheme.Solid:
      classes.push("solid")
      break
    case LoaderTheme.Outline:
      classes.push("outline")
      break
  }

  return (
    <Circle
      size={size}
      loaderThickness={LoaderThickness}
      animationSeconds={AnimationSeconds}
      transparencyAmount={TransparencyAmount}
      className={classes.join(" ")}
    />
  )
}

export const LoaderWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

export enum LoaderTheme {
  Solid = 1,
  Outline = 2,
}
