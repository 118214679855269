import React from "react"
import styled from "styled-components"
import { Button, ButtonType } from "components/atoms"
import { UploadedItem } from "components/molecules/UploadedItem"
import { LogGaEvent } from "lib/GoogleAnalytics"
import { EventAction, EventCategory } from "models/GoogleAnalytics"

const Root = styled.div<{ overHeightThreshold: boolean }>`
  background: ${(props) => props.theme.secondaryOne};
  border-radius: 12px;
  height: ${(props) => (props.overHeightThreshold ? "unset" : "18.75em;")};
  padding: 1em;
`

const UploadBoxInnerBorder = styled.div<{ hasUploadedItems: boolean }>`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px dashed #f3b88b;
  border-radius: 12px;
`

const UploadedItemContainer = styled.div`
  padding: 0 1.5em;
  width: 100%;
  margin-bottom: 0.5em;
  &:first-child {
    margin-top: 1.5em;
  }
`

const ButtonContainer = styled.div<{ hasUploadedItems: boolean }>`
  width: 7.5em;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: ${(props) => (props.hasUploadedItems ? 1 : "unset")};
  margin-bottom: ${(props) => (props.hasUploadedItems ? "1.5em" : 0)};
  // override the button styles
  & > div {
    height: unset;
    padding: 0;
    & > div {
      padding: 0;
      height: unset;
      border-radius: 8px;
      font-size: 14px;
    }
  }
`

interface Props {
  buttonText: string
  applicantNumber: number
  docType: string
  onClick: () => void
  uploadedItems?: { originalFilename: string }[]
}

export const UploadBox = ({
  buttonText,
  applicantNumber,
  docType,
  onClick,
  uploadedItems = [],
}: Props) => {
  return (
    <Root overHeightThreshold={uploadedItems.length >= 3}>
      <UploadBoxInnerBorder hasUploadedItems={!!uploadedItems.length}>
        {uploadedItems.map(({ originalFilename }, idx) => (
          <UploadedItemContainer key={idx}>
            <UploadedItem name={originalFilename} onClickDelete={() => {}} />
          </UploadedItemContainer>
        ))}
        <ButtonContainer hasUploadedItems={!!uploadedItems.length}>
          <Button
            type={ButtonType.Outline}
            onClick={() => {
              LogGaEvent({
                action: EventAction.buttonClicked,
                event_category: EventCategory.functionalInteraction,
                event_label: `Choose files - ${docType} - applicant${applicantNumber}`,
              })
              onClick()
            }}
            text={buttonText}
          />
        </ButtonContainer>
      </UploadBoxInnerBorder>
    </Root>
  )
}
