import Cookies from "js-cookie"
import { MOJO_COOKIE_URL } from "../Config"

export const readCookie = (key: string): string | undefined =>
  document.cookie
    .split("; ")
    .find((row) => row.startsWith(`${key}=`))
    ?.split("=")[1]

export const setCookie = (
  key: string,
  value: string,
  expirySeconds = 31556926 // one year
) => {
  const now = new Date()
  const ms = expirySeconds * 1000
  const expireTime = new Date(now.getTime() + ms)
  document.cookie = `${key}=${value}; expires=${expireTime.toUTCString()}; domain=${MOJO_COOKIE_URL}`
}

// Deprecated functions, remove when possible
export function GetCookie(cookieName: string): string | null {
  return Cookies.get(cookieName)
}

export function SetCookie(cookieName: string, value: string, lifetimeSeconds: number = null) {
  const cookieConf = { expires: null, domain: null }

  if (lifetimeSeconds) {
    cookieConf.expires = lifetimeSeconds / 86400
  }

  cookieConf.domain = MOJO_COOKIE_URL

  try {
    Cookies.set(cookieName, value, cookieConf)
  } catch (e) {
    console.log(e)
  }
}

export function DeleteCookie(cookieName: string) {
  Cookies.remove(cookieName, { path: "", domain: MOJO_COOKIE_URL })
}
