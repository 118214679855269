import React from "react"
import { HeaderStyle, MyMojoTemplate } from "templates/MyMojoTemplate"
import { ContentArea } from "templates/ContentArea"
import { Heading, Paragraph } from "components/_blueprint/Typography"
import PhoneRateCheckNotification from "assets/images/phone-rate-check-notification.svg"
import styled from "styled-components"
import { desktop, mobile, tablet } from "components/_blueprint/Layout"
import { RateCheckPromiseFaqs } from "./FAQs"
import { HowItWorksRateCheckPromise } from "./HowItWorks"
import { useTrackNavigatedToPage } from "hooks/useTrackPageView"

export const RateCheckPromise = () => {
  useTrackNavigatedToPage("/rate-check-promise")
  return (
    <MyMojoTemplate
      backgroundWave={true}
      headerStyle={HeaderStyle.None}
      content={
        <ContentArea maxWidth={1000}>
          <RateCheckPromiseHero />
          <HowItWorksRateCheckPromise />
          <RateCheckPromiseFaqs />
          <DisclaimerFooter />
        </ContentArea>
      }
    />
  )
}

export const RateCheckPromiseHero = () => (
  <>
    <PromiseContainer style={{ display: "flex" }}>
      <PromiseText>
        <Heading>Rate Check Promise</Heading>
        <Paragraph>
          <b>Giving you peace of mind</b>
        </Paragraph>
        <Paragraph>
          Once you have had an offer accepted by a lender, we’ll continue to check that you’re still
          on the lowest rate for the mortgage deal you’ve chosen and will let you know of changes
          all the way until you complete.
        </Paragraph>
        <Paragraph>If we find a lower rate for your deal, we’ll let you know.</Paragraph>
      </PromiseText>
      <PromiseImageContainer>
        <PromiseImage src={PhoneRateCheckNotification} alt="Example of a Rate Check Promise" />
      </PromiseImageContainer>
    </PromiseContainer>
  </>
)

export const DisclaimerFooter = () => (
  <>
    <Disclaimer size="caption">
      You can read about our Rate Check Promise terms and conditions within our{" "}
      <ExternalLink
        title="Terms of Advisory Services"
        target="_blank"
        href="https://hosted-assets.mojomortgages.com/documents/Terms%20of%20our%20Advisory%20Services.pdf"
      >
        Terms of Advisory Service.
      </ExternalLink>
    </Disclaimer>
    <Disclaimer size="caption">
      Mojo is a trading style of Life's Great Limited which is registered in England and Wales
      (06246376). We are authorised and regulated by the Financial Conduct Authority and are on the
      Financial Services Register (478215)
    </Disclaimer>
    <Disclaimer size="caption">
      Our registered office is The Cooperage, 5 Copper Row, London, SE1 2LH,{" "}
    </Disclaimer>
    <Disclaimer size="caption">To contact us by phone, please call 0333 123 0012.</Disclaimer>
    <Disclaimer size="caption">
      YOUR HOME/PROPERTY MAY BE REPOSSESSED IF YOU DO NOT KEEP UP REPAYMENTS ON YOUR MORTGAGE
    </Disclaimer>
  </>
)

const ExternalLink = styled.a({
  textDecoration: "none",
  color: "blue",
})

const Disclaimer = styled(Paragraph)({
  textAlign: "center",
})

const PromiseContainer = styled.div({
  display: "flex",
  [mobile]: {
    flexDirection: "column",
  },
  [tablet]: {
    flexDirection: "column",
  },
  [desktop]: {
    flexDirection: "row",
  },
})

const PromiseText = styled.div({
  [mobile]: {},
  [tablet]: {},
  [desktop]: {
    flex: 7,
  },
})

const PromiseImageContainer = styled.div({
  [mobile]: {
    textAlign: "center",
    padding: "2rem",
  },
  [tablet]: {
    textAlign: "center",
    padding: "2rem",
  },
  [desktop]: {
    flex: 1,
    width: 250,
  },
})

const PromiseImage = styled.img({
  [mobile]: {
    // shrink image if there's not enough width
    maxWidth: "100%",
    height: "auto",
  },
  [tablet]: {
    // shrink image if there's not enough width
    maxWidth: "100%",
    height: "auto",
  },
  [desktop]: {},
})
