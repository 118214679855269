import React from "react"
import Caret from "assets/images/icons/select-caret.svg"
import styled from "styled-components"

const Label = styled.label`
  display: block;
  font-weight: 500;
  margin: 16px 0 0 0;
`

const Select = styled.select`
  border: 1px solid ${(props) => props.theme.shade[60]};
  color: ${(props) => (props.value === "" ? props.theme.shade[20] : props.theme.shade[0])};
  border-radius: ${(props) => props.theme.radius.default};
  display: block;
  width: 100%;
  padding: 13px 35px 13px 17px;
  font-family: Arial, sans-serif;
  font-size: 16px;
  box-sizing: border-box;
  outline: none;
  background-color: ${(props) => (props.disabled ? props.theme.shade[80] : props.theme.shade[100])};
  background-image: url("${Caret}");
  background-position: right 20px top calc(50% - 2px);
  background-repeat: no-repeat;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: ellipsis;
  &:focus {
    border: 1px solid ${(props) => props.theme.cta};
    box-shadow: 0 0 0 1px ${(props) => props.theme.cta};
  }
`

type Props<Option extends string> = {
  defaultOptionText?: string
  id: string
  label?: string
  onBlur?: () => void
  onChange: (value: Option) => void
  options: Array<{ text: string; value: Option }>
  value: Option
}

export const SelectInput = <Option extends string>({
  defaultOptionText,
  label,
  id,
  onBlur = () => {},
  onChange,
  options,
  value,
}: Props<Option>) => (
  <>
    {label && <Label htmlFor={id}>{label}</Label>}
    <Select
      id={id}
      value={value}
      onBlur={onBlur}
      onChange={(e) => onChange(e.target.value as Option)}
      style={{ height: null }}
    >
      {defaultOptionText && (
        <option value="" disabled>
          {defaultOptionText}
        </option>
      )}
      {options.map(({ text, value }) => (
        <option key={value} value={value}>
          {text}
        </option>
      ))}
    </Select>
  </>
)
