interface Shades {
  0: string
  50: string
  100: string
  200: string
  300: string
  400: string
  500: string
  600: string
  700: string
  800: string
  900: string
}

export interface Colors {
  /**
   * A striking blue
   */
  vivid: Shades
  /**
   * A dark grayish blue
   */
  neutral: Shades
  /**
   * A light yellow
   */
  peach: Shades
  /**
   * A strong yellow
   */
  yellow: Shades
  /**
   * Shades of green
   */
  success: Shades
  /**
   * Shades of red
   */
  error: Shades
  /**
   * Plain white
   */
  white: string
  /**
   * Plain black
   */
  black: string
}

export const colors: Colors = {
  white: "#fff",
  black: "#000",
  vivid: {
    0: "#F0F4FF",
    50: "#D1DDFC",
    100: "#B2C7FA",
    200: "#7C9FF5",
    300: "#4C7AEE",
    400: "#255AE5",
    500: "#073ED9",
    600: "#0A287D",
    700: "#001BBE",
    800: "#000CAB",
    900: "#010616",
  },
  neutral: {
    0: "#F8F9FC",
    50: "#F8F9FC",
    100: "#EEF0F8",
    200: "#B5BEDC",
    300: "#A1AAC7",
    400: "#8E95AD",
    500: "#7C7F8B",
    600: "#5A6075",
    700: "#3B4258",
    800: "#1C243F",
    900: "#000928",
  },
  peach: {
    0: "#FDF4ED",
    50: "#FBE8D7",
    100: "#FAE4D1",
    200: "#FBE8D6",
    300: "#FADFC8",
    400: "#F9D8BD",
    500: "#F8D3B4",
    600: "#F7CFAE",
    700: "#F7CCA8",
    800: "#F7CAA6",
    900: "#EBB990",
  },
  yellow: {
    0: "#fff7eb",
    50: "#feeed6",
    100: "#fee6c2",
    200: "#fddeaf",
    300: "#fcd59b",
    400: "#fcc778",
    500: "#fbbb5b",
    600: "#fcb141",
    700: "#fcaa2d",
    800: "#fda41e",
    900: "#E18801",
  },
  success: {
    0: "#edf5f3",
    50: "#cdf0ee",
    100: "#92e9d7",
    200: "#56d0ac",
    300: "#1db37c",
    400: "#149a52",
    500: "#038352",
    600: "#12853d",
    700: "#126732",
    800: "#0e4627",
    900: "#0a2b1f",
  },
  error: {
    0: "#fdfcfb",
    50: "#fcf1ee",
    100: "#f9cedc",
    200: "#f0a0b8",
    300: "#ee6e8f",
    400: "#e34a6e",
    500: "#D0070D",
    600: "#BA0C12",
    700: "#a72538",
    800: "#7a1a24",
    900: "#4a1013",
  },
}
