import * as React from "react"
import { createPortal } from "react-dom"
import styled from "styled-components"
import { desktop, mobile, space, tablet } from "./Layout"
import Cross2 from "../../assets/images/icons/Cross2.svg"

interface Props {
  ariaLabel: string // a description of the modal’s content
  children: React.ReactNode
  onClose: () => void
}

const Background = styled.div({
  alignItems: "center",
  backgroundColor: "#00000033",
  display: "flex",
  height: "100%",
  justifyContent: "center",
  position: "fixed",
  top: 0,
  transform: "translateZ(0)",
  width: "100%",
  zIndex: 1002,
})

const Box = styled.div((props) => ({
  backgroundColor: "#000928",
  overflowY: "auto",
  overscrollBehavior: "none",
  padding: 2 * space,
  position: "relative",
  [mobile]: {
    borderRadius: 0,
    height: "100%",
    width: "100%",
  },
  [tablet]: {
    borderRadius: props.theme.borderRadius || 10,
    maxHeight: "75%",
    maxWidth: "75%",
  },
  [desktop]: {
    borderRadius: props.theme.borderRadius || 10,
    maxHeight: "95%",
    maxWidth: 800,
  },
}))

const CloseButton = styled.button({
  background: "transparent",
  border: "1px solid transparent",
  cursor: "pointer",
  lineHeight: 1,
  padding: space,
  position: "absolute",
  right: 0,
  top: 0,
  "&:focus": {
    border: `1px solid white`,
  },
})

const Overlay: React.FC<Props> = ({ ariaLabel, children, onClose }) => {
  const mouseDownTargetRef = React.useRef<Node | null>(null)
  const mouseUpTargetRef = React.useRef<Node | null>(null)
  const backgroundRef = React.useRef<HTMLDivElement | null>(null)
  const closeButton = React.useRef<HTMLButtonElement | null>(null)

  // Focus on the close button when it opens
  React.useEffect(() => {
    closeButton.current.focus()
  }, [closeButton])

  // Only close if the click down and up are both outside the box
  const onBackgroundMouseDown = (event: React.MouseEvent<HTMLElement>) => {
    if (event.target instanceof Node) {
      mouseDownTargetRef.current = event.target
    }
  }
  const onBackgroundMouseUp = (event: React.MouseEvent<HTMLElement>) => {
    if (event.target instanceof Node) {
      mouseUpTargetRef.current = event.target
    }
  }
  const onBackgroundClick = () => {
    if (
      mouseDownTargetRef.current === backgroundRef.current &&
      mouseUpTargetRef.current === backgroundRef.current
    )
      onClose()
  }

  // Close if they press escape
  const onKeyDown = (event: React.KeyboardEvent<HTMLElement>): void => {
    if (event.key.startsWith("Esc")) onClose()
    event.stopPropagation()
  }

  return createPortal(
    <Background
      aria-label={ariaLabel}
      aria-modal="true"
      onMouseDown={onBackgroundMouseDown}
      onMouseUp={onBackgroundMouseUp}
      onClick={onBackgroundClick}
      onKeyDown={onKeyDown}
      ref={backgroundRef}
      role="dialog"
      tabIndex={-1}
    >
      <Box>
        <CloseButton aria-label="Close Modal" onClick={onClose} ref={closeButton}>
          <img alt="Close Modal" src={Cross2} />
        </CloseButton>
        {children}
      </Box>
    </Background>,
    document.body
  )
}

interface ModalProps {
  children: React.ReactNode
  description: string
  triggerElement: React.ReactElement
}

export const Modal = ({ children, description, triggerElement }: ModalProps) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const trigger = React.cloneElement(triggerElement, {
    onClick: (event: MouseEvent) => {
      event.preventDefault()
      document.querySelector("html").style.overflow = "hidden"
      setIsOpen(true)
    },
  })
  const onClose = () => {
    document.querySelector("html").style.overflow = "auto"
    setIsOpen(false)
  }
  return (
    <>
      {trigger}
      {isOpen && (
        <Overlay ariaLabel={description} onClose={onClose}>
          {children}
        </Overlay>
      )}
    </>
  )
}
