import React from "react"
import styled, { css, DefaultTheme } from "styled-components"

type HeadingTypes = "h1" | "h2" | "h3" | "h4" | "h5" | "h6"

export type ColorTypes = "primary" | "secondary" | "tertiary" | "neutral" | "white" | "error"

export interface HeadingProps extends React.HTMLAttributes<HTMLHeadingElement> {
  /**
   * The size we'd like the heading to be
   * @default h1
   */
  size?: HeadingTypes
  /**
   * What element should the heading render as?
   * @default h1
   */
  as?: HeadingTypes
  /**
   * The color of the font.
   * @default "secondary"
   */
  color?: ColorTypes
  children: React.ReactNode
  margin?: string | number
}

const StyledHeading = styled.span<{
  $headingType: HeadingTypes
  $color: ColorTypes
  $margin: string | number
}>`
  margin: ${({ $margin }) => $margin ?? "0px 0px 16px 0px"};
  ${({ theme, $headingType }) => TypeVariantStyles(theme, $headingType)};
  ${({ theme, $color }) => ColorVariantStyles(theme, $color)};
`

/**
 * Renders a heading element. Its color and size can ebe customised.
 */
export const Heading = ({
  size = "h1",
  as = "h1",
  color = "secondary",
  children,
  margin = undefined,
  ...props
}: HeadingProps) => {
  return (
    <StyledHeading $headingType={size} as={as} $color={color} {...props} $margin={margin}>
      {children}
    </StyledHeading>
  )
}

const TypeVariantStyles = (theme: DefaultTheme, variant: HeadingTypes) =>
  ({
    h1: css`
      & {
        font-size: 2em;
        line-height: 120%;
        font-weight: 700;
      }
    `,
    h2: css`
      & {
        font-size: 1.75em;
        line-height: 200%;
        font-weight: 600;
      }
    `,
    h3: css`
      & {
        font-size: 1.625em;
        line-height: 135%;
        font-weight: 600;
      }
    `,
    h4: css`
      & {
        font-size: 1.4375em;
        line-height: 135%;
        font-weight: 600;
      }
    `,
    h5: css`
      & {
        font-size: 1.25em;
        line-height: 150%;
        font-weight: 600;
      }
    `,
    h6: css`
      & {
        font-size: 1.125em;
        line-height: 150%;
        font-weight: 600;
      }
    `,
  }[variant])

export const ColorVariantStyles = (theme: DefaultTheme, variant: ColorTypes) =>
  ({
    secondary: css`
      & {
        color: ${({ theme }) => theme.colors.primary.two};
      }
    `,
    primary: css`
      & {
        color: ${({ theme }) => theme.colors.primary.one};
      }
    `,
    neutral: css`
      & {
        color: ${({ theme }) => theme.colors.neutral[500]};
      }
    `,
    tertiary: css`
      & {
        color: ${({ theme }) => theme.colors.yellow[500]};
      }
    `,
    error: css`
      & {
        color: ${({ theme }) => theme.colors.error[500]};
      }
    `,
    white: css`
      & {
        color: ${({ theme }) => theme.colors.white};
      }
    `,
  }[variant])
