import React, { useEffect, useState } from "react"
import { Banner } from "components/atoms"
import Exclamation from "assets/images/icons/Exclamation.svg"
import styled from "styled-components"
import { Paragraph } from "components/_blueprint/Typography"
import { LogGaEvent } from "lib/GoogleAnalytics"
import { EventAction, EventCategory } from "models/GoogleAnalytics"
import { mobile } from "components/_blueprint/Layout"
import { Bucket } from "lib/Experiments"

const TopText = styled(Paragraph)(({ theme }) => ({
  fontSize: "1rem", // 16px
  color: theme.colors.neutral[900],
  fontWeight: 600,
}))

const BottomText = styled(Paragraph)(({ theme }) => ({
  fontSize: "0.875rem", // 14px
  color: theme.colors.neutral[700],
  fontWeight: 500,
}))

// https://www.bankofengland.co.uk/monetary-policy/upcoming-mpc-dates
const dates = [
  new Date(Date.UTC(2023, 10, 2, 23, 59, 59)),
  new Date(Date.UTC(2023, 11, 14, 23, 59, 59)),
  new Date(Date.UTC(2024, 1, 1, 23, 59, 59)),
  new Date(Date.UTC(2024, 2, 21, 23, 59, 59)),
  new Date(Date.UTC(2024, 4, 9, 23, 59, 59)),
  new Date(Date.UTC(2024, 5, 20, 23, 59, 59)),
  new Date(Date.UTC(2024, 7, 1, 23, 59, 59)),
  new Date(Date.UTC(2024, 8, 19, 23, 59, 59)),
  new Date(Date.UTC(2024, 10, 7, 23, 59, 59)),
  new Date(Date.UTC(2024, 11, 19, 23, 59, 59)),
]

// Show the banner on the day itself, and the 21 days before the date, so up to
// 22 days before the end of the day.
const findNextDate = () => {
  const today = new Date()
  const nextDate = dates.find((d) => d > today)
  const twentyTwoDaysMs = 22 * 86400000
  return nextDate.getTime() - today.getTime() < twentyTwoDaysMs ? nextDate : null
}

const BannerWrapper = styled.div({
  display: "flex",
  justifyContent: "center",
  padding: "2rem 0 1rem 0",
  [mobile]: {
    padding: "2rem 0 0 0",
  },
})

enum RatesBannerVariant {
  HideBanner = 0,
  ShowBanner = 1,
}

export const RatesBanner = () => {
  const [variant, setVariant] = useState<RatesBannerVariant>(RatesBannerVariant.HideBanner)
  const nextDate = findNextDate()
  useEffect(() => {
    if (nextDate) {
      setVariant(
        Bucket<RatesBannerVariant>(
          "rates-banner-2023-09-25",
          [RatesBannerVariant.HideBanner, RatesBannerVariant.ShowBanner],
          [50, 50]
        )
      )
    }
  }, [])
  useEffect(() => {
    if (variant === RatesBannerVariant.ShowBanner) {
      LogGaEvent({
        action: EventAction.componentDisplay,
        event_category: EventCategory.siteInteraction,
        event_label: "BoE Notification Displayed",
      })
    }
  }, [variant])

  return variant === RatesBannerVariant.ShowBanner ? (
    <BannerWrapper>
      <Banner leftIcon={<img src={Exclamation} />}>
        <TopText>Rates could change soon. Get clarity from an expert</TopText>
        <BottomText>
          The next Bank of England review will take place on{" "}
          <strong>{nextDate.toLocaleDateString()}</strong>. This could affect available mortgage
          rates.
        </BottomText>
      </Banner>
    </BannerWrapper>
  ) : null
}
