import React from "react"
import { desktop, mobile, tablet } from "components/_blueprint/Layout"
import styled from "styled-components"

// These elements are in a separate shared.tsx file because they're used for
// lander page experiments.

export const Text = styled.div({
  gridColumnStart: 1,
  [mobile]: {
    gridColumnEnd: "span 3",
  },
  [tablet]: {
    gridColumnEnd: "span 3",
  },
  [desktop]: {
    gridColumnEnd: "span 6",
  },
})

export const P = styled.p({
  fontWeight: 500,
  margin: 0,
})

const MojoPrivacyPolicyLink = styled.a(({ theme }) => ({
  color: theme.primary,
}))

type SmallPrintProps = {
  complianceText: string | undefined
}

export const SmallText = ({ complianceText }: SmallPrintProps) => (
  <small>
    {complianceText ?? ""} Read Mojo’s{" "}
    <MojoPrivacyPolicyLink href={mojoPrivacyPolicyUrl} target="_blank">
      privacy policy
    </MojoPrivacyPolicyLink>{" "}
    and terms of{" "}
    <MojoPrivacyPolicyLink href={advisoryServicesUrl} target="_blank">
      advisory services
    </MojoPrivacyPolicyLink>{" "}
    for more information.
  </small>
)

const advisoryServicesUrl =
  "https://hosted-assets.mojomortgages.com/documents/Terms%20of%20our%20Advisory%20Services.pdf"

const mojoPrivacyPolicyUrl = "https://mojomortgages.com/privacy-policy"
