import React, { useState } from "react"
import styled from "styled-components"
import { CurrencyInput } from "components/molecules/index"

const Layout = styled.div`
  display: grid;
  gap: 8px;
`
const Label = styled.label`
  font-weight: 600;

  &:not(:first-of-type) {
    margin-top: 16px;
  }
`

type Value = {
  amount?: number
}

type Props = {
  id?: string
  value?: Value[]
  onDone: (value: any, errors: string[]) => void
}

export function TwoYearInput({ id, value, onDone }: Props) {
  const [yearOne, setYearOne] = useState(elementAt(value, 0)?.amount || "")
  const [yearTwo, setYearTwo] = useState(elementAt(value, 1)?.amount || "")

  function elementAt(array, index) {
    if (array?.length >= index + 1) {
      return array[index]
    }

    return null
  }

  function HandleInputs(yearOneValue = yearOne, yearTwoValue = yearTwo) {
    if (!yearOneValue || yearOneValue?.length < 1) {
      onDone(null, ["You need to provide at least your first year of income."])
      return
    }

    const incomes = []

    if (yearOneValue) {
      incomes.push({ amount: yearOneValue })
    }

    if (yearTwoValue) {
      incomes.push({ amount: yearTwoValue })
    }

    onDone(incomes, null)
  }

  return (
    <Layout>
      <Label>Year 1</Label>
      <CurrencyInput
        value={yearOne}
        id={`${id}-year-one`}
        onChange={(value) => HandleInputs(value, yearTwo)}
        onDone={(value) => setYearOne(value)}
        min={0}
        max={10000000}
      />
      <Label>Year 2</Label>
      <CurrencyInput
        value={yearTwo}
        id={`${id}-year-two`}
        onChange={(value) => HandleInputs(yearOne, value)}
        onDone={(value) => setYearTwo(value)}
        min={0}
        max={10000000}
      />
    </Layout>
  )
}
