import dayjs from "dayjs"
import advancedFormat from "dayjs/plugin/advancedFormat"
import customParseFormat from "dayjs/plugin/customParseFormat"
import utc from "dayjs/plugin/utc"

dayjs.extend(advancedFormat)
dayjs.extend(customParseFormat)
dayjs.extend(utc)

export function DateDiff(
  dateOne: Date | string,
  dateTwo: Date | string,
  unit: "year" | "day" | "month" | "hour"
): number {
  const d1 = dayjs.utc(dateOne)
  const d2 = dayjs.utc(dateTwo)

  return d2.diff(d1, unit)
}

export function ParseDateAuto(date: string): Date {
  return dayjs.utc(date).toDate()
}

export function ParseDate(date: string, format: string): Date {
  return dayjs.utc(date, format).toDate()
}

export function FormatDate(date: Date | string, format: string): string {
  return dayjs.utc(date).format(format)
}

export function Subtract(date: Date, amount: number, unit: "day" | "month"): Date {
  return dayjs.utc(date).subtract(amount, unit).toDate()
}

export function IsValid(input: any, format = "DD/MM/YYYY", strict = true): boolean {
  return dayjs.utc(input, format, strict).isValid()
}

export function MonthsAgo(months: number): Date {
  const date = new Date()
  date.setHours(0, 0, 0, 0)
  date.setMonth(date.getMonth() - months)
  return date
}

export function convertDecimalToYearsMonths(decimalYears) {
  // Extract the whole number part for years
  const years = Math.floor(decimalYears);

  // Extract the decimal part and convert it to months
  // Assuming .1 year is equivalent to 1 month
  const monthsDecimal = decimalYears - years;
  const months = Math.round(monthsDecimal * 12);

  return { years, months };
}

export function convertMonthsToYearsAndMonths(totalMonths: number) {
  const years: number = Math.floor(totalMonths / 12);
  const months: number = totalMonths % 12;

  if (months === 0) {
    const yearStr: "year" | "years" = years === 1 ? 'year' : 'years';
    return `${years} ${yearStr}`;
  }

  const yearStr: "year" | "years" = years === 1 ? 'year' : 'years';
  const monthStr: "month" | "months" = months === 1 ? 'month' : 'months';

  return `${years} ${yearStr} and ${months} ${monthStr}`;
}
