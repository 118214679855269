import { useEffect } from "react"
import { useHistory } from "react-router-dom"
import { GetPartnerFromUrl } from "lib/GetPartnerFromUrl"
import { useSession } from "lib/auth/react"

export function useRequireAuthentication(
  /**
   * We define a "partialLogin" as when a user has started a fact find and has been
   * given a {guid}@@mojowhitelabel.com email. They are logged in, but technically don't have an account
   */
  allowPartialLogin = false
) {
  const history = useHistory()

  const { data, isLoading } = useSession()

  useEffect(() => {
    if (!isLoading) {
      // If GetPartnerFromUrl is null we're not in a partner site
      const isFromPartner = !!GetPartnerFromUrl()

      // special logic if user comes from a partner domain
      if (isFromPartner) {
        if (!data) {
          history.replace(`/get-started${window.location.search}`)
        }
        // normal logic for my.mojomortgages.com
      } else {
        // if they have a full account and their token is expired/invalid
        // send them to the login with the correct redirect value
        if (!data) {
          history.replace(
            `/login?redirect=${encodeURIComponent(
              window.location.pathname + window.location.search
            )}`
          )
        }
      }
    }
  }, [isLoading])
}
