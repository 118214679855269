import React, { useState } from "react"
import { FormatInput } from "../atoms/FormatInput"
import usePostRenderEffect from "hooks/usePostRenderEffect"
import { DateDiff, FormatDate, ParseDate, IsValid } from "lib/Date"

export enum DateFlag {
  DateOfBirth,
  FutureDate,
  PastDate,
}

export enum DateFormat {
  Normal = "DD/MM/YYYY",
  Short = "MM/YYYY",
}

type Props = {
  id?: string
  value?: string
  onDone?: (newValue: string | Date, errors?: string[]) => void
  format?: DateFormat
  dateFlags?: DateFlag[]
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
  disabled?: boolean
}

export function DateInput({
  value,
  onDone,
  format = DateFormat.Normal,
  id = "",
  dateFlags = [],
  onKeyDown,
  disabled,
}: Props) {
  const placeholder = format
  const inputFormat = format.replace(new RegExp("[A-Za-z]", "g"), "#")
  const inputMask = new Array(format.replace("/", "")?.length).fill("_")

  const [date, setDate] = useState<string | Date>(value || null)
  const [friendlyDate, setFriendlyDate] = useState<string>(value ? parseDateObject(value) : "")

  usePostRenderEffect(() => {
    const parsedDate = parseFriendlyString(friendlyDate)
    saveDate(parsedDate)
  }, [friendlyDate])

  function saveDate(saveDate: string | Date) {
    const foundErrors = []

    if (friendlyDate.length < 1 || friendlyDate.includes("_")) {
      setDate(null)
      onDone(null, [])
      return
    } else if (!IsValid(friendlyDate, format, true)) {
      foundErrors.push("Please enter a valid date")
    } else {
      const yearDiff = DateDiff(new Date(), saveDate, "year")
      const dayDiff = DateDiff(new Date(), saveDate, "day")

      if (yearDiff < -100) {
        foundErrors.push("Please enter a date within the last 100 years")
      }

      if (dateFlags.includes(DateFlag.DateOfBirth)) {
        const age = DateDiff(saveDate, new Date(), "year")

        if (age < 18 || age > 75) {
          foundErrors.push("Applicants must be aged between 18 and 75 years old")
        }
      }

      if (dateFlags.includes(DateFlag.PastDate)) {
        if (dayDiff > 0) {
          foundErrors.push("Please enter a date in the past")
        }
      }

      if (dateFlags.includes(DateFlag.FutureDate)) {
        if (dayDiff < 0) {
          foundErrors.push("Please enter a date in the future")
        }

        if (dayDiff > 36500) {
          foundErrors.push("Please enter a date within the next 100 years")
        }
      }
    }

    if (foundErrors.length > 0 && !friendlyDate.includes("_") && friendlyDate.length) {
      setDate(null)
      onDone(null, [foundErrors[0]])
      return
    }

    setDate(saveDate)
    onDone(saveDate)
  }

  function parseDateObject(date: string | Date): string {
    return FormatDate(date, placeholder)
  }

  function parseFriendlyString(friendly: string): Date {
    return ParseDate(friendly, format)
  }

  return (
    <FormatInput
      disabled={disabled}
      format={inputFormat}
      placeholder={placeholder}
      mask={inputMask}
      autoComplete="off"
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFriendlyDate(e.target.value)}
      onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => onKeyDown && onKeyDown(e)}
      value={friendlyDate}
      id={id}
    />
  )
}
