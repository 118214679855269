import { useRef, useEffect } from "react"

export default function useDidMount() {
  const didMount = useRef(false)

  useEffect(() => {
    didMount.current = true
  }, [])

  return didMount.current
}
