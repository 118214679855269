import React, { useEffect } from "react"
import { useHistory } from "react-router-dom"
import styled from "styled-components"
import devices from "styles/devices"
import { Button } from "components/atoms"
import { Icon, IconType } from "components/atoms/Icon"
import { formatDate } from "lib/FormatDate"
import { LogGaEvent } from "lib/GoogleAnalytics"
import { EventAction, EventCategory, EventLabel } from "models/GoogleAnalytics"

const Container = styled.div`
  color: ${(props) => props.theme.shade80};
  background: ${(props) => props.theme.primaryThree};
  min-height: 400px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  @media screen and ${devices.bp_sm} {
    flex-direction: row;
  }
`

const Column = styled.div`
  position: relative;
  padding: 40px;
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  @media screen and ${devices.bp_sm} {
    width: 50%;
  }
`

const Divider = styled.div`
  display: none;
  position: absolute;
  top: 10%;
  left: -20px;
  background-color: ${(props) => props.theme.shade20};
  height: 80%;
  width: 2px;
  @media screen and ${devices.bp_sm} {
    display: block;
  }
`

const List = styled.ul`
  li {
    margin-bottom: 1rem;
  }
`

const ButtonContent = styled.span`
  img {
    display: inline;
    vertical-align: middle;
    padding-left: 10px;
  }
`

const Text = styled.p`
  line-height: 1.625rem;
`

const SmallerText = styled.p`
  font-size: 0.875rem;
  line-height: 1.625rem;
`

interface Props {
  adviser: string
  appointmentDate: Date
  appointmentDuration: number
  friendlyId: string
  isGhostAppt: boolean
}

const formatGhostDateTime = (apptTime: Date, apptDurationMinutes: number): string => {
  // Had one instance of appointmentDate being null while testing, and I don't
  // trust the types in my.mojo.ui, so being defensive here.
  if (!(apptTime && apptDurationMinutes)) return ""

  const durationMs = apptDurationMinutes * 60000
  const ghostEndTime = new Date(apptTime.getTime() + durationMs)
  const date = formatDate(apptTime, {
    dateStyle: "long",
  })
  const startTime = formatDate(apptTime, {
    timeStyle: "short",
  })
  const endTime = formatDate(ghostEndTime, { timeStyle: "short" })
  return `${date} | ${startTime} – ${endTime}`
}

export function AppointmentCard({
  adviser,
  appointmentDate,
  appointmentDuration,
  friendlyId,
  isGhostAppt,
}: Props) {
  const history = useHistory()
  const date = formatDate(new Date(appointmentDate), {
    dateStyle: "long",
    timeStyle: "short",
  })

  useEffect(() => {
    LogGaEvent({
      action: EventAction.componentDisplay,
      event_category: EventCategory.siteInteraction,
      event_label: EventLabel.appointmentCard,
    })
  }, [])

  return (
    <Container>
      <Column>
        <p>About your next appointment</p>
        {isGhostAppt ? (
          <>
            <h2>{formatGhostDateTime(appointmentDate, appointmentDuration)}</h2>
            <SmallerText>
              This is a high demand callback slot. We aim to call within the 30 minute slot. If we
              cannot, we will email you to re-arrange.
            </SmallerText>
          </>
        ) : (
          <h2>
            You’re booked in with {adviser} on {date}
          </h2>
        )}
      </Column>
      <Column>
        <Divider />
        <Text>
          To get you the best recommendation upload some documents for your Mortgage Expert
        </Text>
        <List>
          <li>Proof of ID (Passport or driving license)</li>
          <li>Most recent payslip or SA302</li>
          <li>Most recent bank statement</li>
        </List>

        <Button
          id="sidebar-button-documents"
          onClick={() => {
            LogGaEvent({
              action: EventAction.buttonClicked,
              event_category: EventCategory.functionalInteraction,
              event_label: EventLabel.uploadDocs,
            })
            history.push(`/application/${friendlyId}/documents`)
          }}
          text={
            <ButtonContent>
              Upload your documents
              <Icon type={IconType.Shield} />
            </ButtonContent>
          }
        />
        <SmallerText>
          It may result in an inappropriate recommendation or slow down any future mortgage
          application if you do not upload these for review.
        </SmallerText>
      </Column>
    </Container>
  )
}
