import React, { useState, useEffect } from "react"
import PostcodeLookup, { AddressLookupResponse } from "components/molecules/PostcodeLookup"
import styled from "styled-components"
import { Input } from "../../atoms/Input"
import { validPostcode } from "lib/postcodes"

const Layout = styled.div`
  display: grid;
  grid-gap: 8px;
`

const PostcodeContainer = styled.div`
  display: block;
`

const PostcodeResults = styled.div`
  display: grid;
  grid-gap: 8px;
`

const SubtleLink = styled.button`
  text-decoration: underline;
  cursor: pointer;
  align-self: flex-start;
  padding: 0;
  background: none;
  border: none;
`

const StyledComboBox = styled.div`
  label {
    top: -24px !important;
  }
`

const QuestionInfo = styled.div`
  background-color: ${({ theme }) => theme.infoColor};
  border-radius: ${({ theme }) => theme.radius.large};
  font-weight: 300;
  padding: 1.625rem 1.3rem;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;

  span {
    padding-bottom: 1rem;
  }
`

export type AddressInput = {
  id?: string
  postcode: string
  nameNumber: string
  street: string
  city: string
  country?: string
}

export type AddressOutput = {
  addressId?: string
  postcode: string
  nameNumber: string
  street: string
  city: string
  country?: string
}

export type AddressProps = {
  /**
   * Default values for the inputs
   */
  value?: AddressInput
  /**
   * Called on input change
   */
  onDone?: (address: AddressOutput, isValid?: boolean, errors?: string[]) => void
  id?: string
  /**
   * Whether the manual inputs for address should be disaplayed by default
   */
  isOpen?: boolean
  /**
   * Runs when postcode search has completed
   */
  onPostcodeLookup?: (addresses: AddressLookupResponse[]) => void
  /**
   * Runs when a user clicks an item from the address list
   */
  onAddressSelected?: () => void
  disabled?: boolean
}

export default function Address({
  value,
  onDone = () => {},
  id = "",
  isOpen = false,
  onPostcodeLookup = () => {},
  onAddressSelected = () => {},
  disabled = false,
}: AddressProps) {
  const [addressId, setAddressId] = useState("")
  const [postcode, setPostcode] = useState(value?.postcode || "")
  const [nameNumber, setNameNumber] = useState(value?.nameNumber || "")
  const [street, setStreet] = useState(value?.street || "")
  const [city, setCity] = useState(value?.city || "")
  const [country, setCountry] = useState(value?.country || "")
  const [manualAddressOpen, setOpen] = useState(isOpen)
  const [nameNumberLabel, setNameNumberLabel] = useState("Name/Number")
  const [hasErrored, setHasErrored] = useState(false)

  /**
   * Triggers manual inputs when the postcode endpoint errors
   */
  useEffect(() => {
    if (hasErrored) {
      setOpen(true)
    }
  }, [hasErrored])

  useEffect(() => {
    if (value?.postcode) {
      setPostcode(value.postcode)
      setNameNumber(value.nameNumber)
      setStreet(value.street)
      setCity(value.city)
      setOpen(true)
      setCountry(value.country)
    }
  }, [])

  useEffect(() => {
    const errors = []

    if (postcode?.length > 0 && !validPostcode.test(postcode)) {
      errors.push("Postcode is invalid")
    }

    setNameNumberLabel(nameNumber.length > 28 ? "" : "Name/Number")

    const isValid =
      nameNumber?.length > 0 &&
      city?.length > 0 &&
      postcode?.length > 0 &&
      (!errors || errors.length === 0)

    onDone({ addressId, postcode, nameNumber, street, city, country }, isValid, errors)
  }, [addressId, postcode, nameNumber, street, city, country])

  return (
    <StyledComboBox>
      <Layout>
        <PostcodeContainer>
          {!manualAddressOpen && (
            <PostcodeLookup
              id={`postcode-search-${id}`}
              onAddressSelected={handleAddressSelected}
              onPostcodeLookup={(addresses) => onPostcodeLookup(addresses)}
              onEndpointError={() => setHasErrored(true)}
              disabled={disabled}
            />
          )}
        </PostcodeContainer>
        {manualAddressOpen && (
          <PostcodeResults>
            <Input
              label={nameNumberLabel}
              value={nameNumber}
              onChange={(e) => setNameNumber(e.target.value)}
              id={`address-namenumber-${id}`}
              autoComplete="off"
              disabled={disabled}
            />
            <Input
              label="Street"
              value={street}
              onChange={(e) => setStreet(e.target.value)}
              id={`address-street-${id}`}
              autoComplete="off"
              disabled={disabled}
            />
            <Input
              label="City"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              id={`address-city-${id}`}
              autoComplete="off"
              disabled={disabled}
            />
            <Input
              label="Postcode"
              value={postcode}
              onChange={(e) => setPostcode(e.target.value)}
              id={`address-postcode-${id}`}
              autoComplete="off"
              disabled={disabled}
            />
          </PostcodeResults>
        )}
        <QuestionInfo>
          {!manualAddressOpen && <span>Can't find it? Use our manual address input instead</span>}
          {hasErrored && (
            <span>
              We're having trouble finding your postcode, please enter your address manually.
            </span>
          )}
          <SubtleLink id={`${id}-enter-manually`} onClick={() => setOpen(!manualAddressOpen)}>
            {manualAddressOpen ? "Back to postcode search" : "Enter address manually"}
          </SubtleLink>
        </QuestionInfo>
      </Layout>
    </StyledComboBox>
  )

  function handleAddressSelected(address: AddressInput) {
    onAddressSelected()
    setAddressId(address.id)
    setPostcode(address.postcode)
    setNameNumber(address.nameNumber)
    setStreet(address.street)
    setCity(address.city)
    setCountry(address.country)
    setOpen(true)
  }
}
