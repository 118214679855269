import React, { useState } from "react"
import { PaymentAmountGroup, Group } from "components/molecules/PaymentAmountGroup"

type Props = {
  value: Group[]
  onDone: (values: Group[], errors: string[]) => void
  id: string
}
export function IncomeBenefits({ value, onDone, id }: Props) {
  const [input, setInput] = useState(value || [])

  function HandleOnChange(values, errors) {
    setInput(values)
    onDone(values, errors)
  }

  return (
    <PaymentAmountGroup
      id={id}
      onChange={(values, errors) => HandleOnChange(values, errors)}
      value={input}
      needsProving={true}
      categories={[
        { key: "CarersAllowance", value: "Carers allowance" },
        { key: "DisabilityLivingAllowance", value: "Disability living allowance" },
        { key: "FosterCarerAllowance", value: "Foster carer allowance" },
        { key: "PersonalIndependencePayment", value: "Personal independence payment" },
        { key: "StatePension", value: "State pension" },
        { key: "WarWidowPension", value: "War widow(er) pension" },
        { key: "AdoptionAllowance", value: "Adoption allowance" },
        { key: "ChildBenefit", value: "Child benefit" },
        { key: "ChildTaxCredit", value: "Child tax credit" },
        { key: "ConstantAttendanceAllowance", value: "Constant attendance allowance" },
        { key: "GuardiansAllowance", value: "Guardians allowance" },
        {
          key: "IndustrialInjuriesDisablementBenefit",
          value: "Industrial injuries disablement benefit",
        },
        { key: "PensionCredit", value: "Pension credit" },
        { key: "ReducedEarningsAllowance", value: "Reduced earnings allowance" },
        { key: "WidowedParentAllowance", value: "Widowed parent allowance" },
        { key: "WidowsPension", value: "Widow's allowance" },
        { key: "WorkingTaxCredit", value: "Working tax credit" },
        { key: "Other", value: "Other" },
      ]}
    />
  )
}
