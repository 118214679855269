import React, { useEffect, useState } from "react"
import styled from "styled-components"
import axios from "axios"
import BackgroundWave from "templates/BackgroundWave"
import { TopNavTemplate } from "templates/TopNavTemplate"
import { Intercom } from "components/molecules/Intercom"
import { EmailInput } from "components/molecules/EmailInput"
import { Button } from "components/atoms"
import { ContentBox } from "../ContentBox"
import { MY_MOJO_API } from "../../../Config"
import { useTrackNavigatedToPage } from "hooks/useTrackPageView"
import { LogGaEvent } from "lib/GoogleAnalytics"
import useDidMount from "hooks/useDidMount"
import { EventAction, EventCategory } from "models/GoogleAnalytics"
import keyboardjs from "keyboardjs"

const Container = styled.div`
  display: flex;
`

export const ResetPassword = () => {
  const [email, setEmail] = useState<string>("")
  const [emailIsValid, setEmailIsValid] = useState<boolean>(false)
  const [isBusy, setIsBusy] = useState<boolean>(false)
  const [requestSent, setRequestSent] = useState<boolean>(false)
  const didMount = useDidMount()

  useTrackNavigatedToPage("/reset-password")

  useEffect(() => {
    if (!emailIsValid) return

    keyboardjs.bind("enter", async () => {
      if (emailIsValid && !isBusy) {
        await ResetPasswordEmail()
      }
    })

    return () => {
      keyboardjs.unbind("enter")
    }
  }, [email, emailIsValid])

  useEffect(() => {
    !didMount &&
      LogGaEvent({
        action: EventAction.componentDisplay,
        event_category: EventCategory.siteInteraction,
        event_label: "Forgotten Password",
      })
  }, [])

  async function ResetPasswordEmail() {
    LogGaEvent({
      action: EventAction.buttonClicked,
      event_category: EventCategory.functionalInteraction,
      event_label: "Request Password Reset",
    })
    LogGaEvent({
      action: EventAction.forgotPassword,
      event_category: EventCategory.accountInteraction,
      event_label: "Request",
    })
    setIsBusy(true)
    try {
      await axios.post(`${MY_MOJO_API}/auth/password-reset-email`, {
        email,
      })
      setRequestSent(true)
      LogGaEvent({
        action: EventAction.forgotPassword,
        event_category: EventCategory.accountInteraction,
        event_label: "Success",
      })
    } finally {
      setIsBusy(false)
    }
  }

  return (
    <TopNavTemplate
      miniNav={true}
      content={
        <>
          <BackgroundWave />
          <Container>
            <Intercom style={{ zIndex: "100" }} />

            <ContentBox>
              <h1>Forgotten password?</h1>

              {!requestSent && (
                <p>
                  Pop in your email and we'll send you instructions to reset your account password.
                </p>
              )}

              {requestSent && (
                <>
                  <h4>Request Sent!</h4>
                  <p>
                    If the email address you've entered is associated with a Mojo account, we've
                    sent you an email to help you reset your password.
                  </p>
                </>
              )}

              {!requestSent && (
                <>
                  <EmailInput
                    placeholder="Email address"
                    value={email}
                    onChange={(value) => setEmail(value)}
                    onIsValidChange={(isValid) => setEmailIsValid(isValid)}
                    onBlur={() => {
                      LogGaEvent({
                        action: EventAction.fieldBlur,
                        event_category: EventCategory.formInteraction,
                        event_label: "Email Address",
                      })
                      !emailIsValid &&
                        LogGaEvent({
                          action: EventAction.validationMessageShown,
                          event_category: EventCategory.siteInteraction,
                          event_label: "Email Address - Email address is invalid",
                        })
                    }}
                    onFocus={() =>
                      LogGaEvent({
                        action: EventAction.fieldFocus,
                        event_category: EventCategory.formInteraction,
                        event_label: "Email Address",
                      })
                    }
                    onLoaded={() =>
                      LogGaEvent({
                        action: EventAction.inputShown,
                        event_category: EventCategory.siteInteraction,
                        event_label: "Email Address",
                      })
                    }
                  />
                  <Button
                    disabled={!emailIsValid}
                    text="Continue"
                    onClick={ResetPasswordEmail}
                    showLoader={isBusy}
                  />
                </>
              )}
            </ContentBox>
          </Container>
        </>
      }
    />
  )
}
