import React from "react"
import styled from "styled-components"
import { MojoCircle } from "components/atoms"

const ListItemContainer = styled.li`
  display: grid;
  grid-template-columns: 40px auto;
  height: 100%;
`

type ListItemProps = {
  showTick?: boolean
  children: JSX.Element[] | JSX.Element | string
}

export function MojoListItem({ showTick = true, children }: ListItemProps) {
  return (
    <ListItemContainer>
      <MojoCircle enabled={true} type={showTick ? "tick" : "circle"} />
      <span>{children}</span>
    </ListItemContainer>
  )
}
