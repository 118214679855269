import React from "react"
import styled from "styled-components"
// @ts-ignore
import TickBlue from "url:assets/images/icons/TickBlue.svg"
import devices from "styles/devices"
import { Document, DocumentType } from "models/FullApplication"

const Root = styled.div<{ hasAddedDocs: boolean }>`
  font-weight: 550;
  font-size: 14px;
  background: ${(props) =>
    props.hasAddedDocs ? "rgba(0, 69, 255, 0.05)" : props.theme.primaryTwo};
  color: ${(props) => (props.hasAddedDocs ? props.theme.primary : "#000")};
  padding: 0.5em;
  border-radius: 4px;
  @media screen and ${devices.bp_xs} {
    font-size: 16px;
  }
`

const Tick = styled.span`
  margin-left: 0.2em;
  ::after {
    content: url(${TickBlue});
  }
`

type Props = {
  documents: Document[]
}

export const DocumentStatus = ({ documents }: Props) => {
  const hasAddedDocs = documents.length > 0
  return (
    <Root hasAddedDocs={hasAddedDocs}>
      {hasAddedDocs ? (
        <>
          {documents.length} added <Tick />
        </>
      ) : (
        "None added"
      )}
    </Root>
  )
}
