import React from "react"
import { CallRecommendations as CallRecommendationsWithDeal } from "./CallRecommendationsWithDeal"
import { CallRecommendations as CallRecommendationsControl } from "./CallRecommendationsControl"
import { useGetMapDealSelection } from "hooks/useAdminControls"
import { Loader, LoaderTheme } from "components/atoms/Loader"
import styled from "styled-components"

const SHOW_DEALS_VARIENT_QS = "allowDealSelection"

const LoaderWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const CallRecommendations = () => {
  const { isLoading, showDeals } = useGetMapDealSelection()

  if (isLoading)
    return (
      <LoaderWrapper>
        <Loader size={30} theme={LoaderTheme} />
      </LoaderWrapper>
    )

  const queryStrings = new URLSearchParams(window.location.search)
  const showSelectDeals = true // can delete this flag and CallRecommendationsControl after we are sure it's working with no issues
  return showSelectDeals ? <CallRecommendationsWithDeal /> : <CallRecommendationsControl />
}
