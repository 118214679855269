import React, { useState } from "react"
import { PaymentAmountGroup, Group } from "components/molecules/PaymentAmountGroup"

type Props = {
  value: Group[]
  onDone: (values: Group[], errors: string[]) => void
  id: string
}
export function AdditionalIncomes({ value, onDone, id }: Props) {
  const [input, setInput] = useState(value || [])

  function HandleOnChange(values, errors) {
    setInput(values)
    onDone(values, errors)
  }

  return (
    <PaymentAmountGroup
      id={id}
      onChange={(values, errors) => HandleOnChange(values, errors)}
      value={input}
      needsProving={true}
      categories={[
        { key: "SecondJob", value: "Second job" },
        { key: "CsaChildMaintenance", value: "CSA Child maintenance" },
        { key: "RentalIncomeSurplus", value: "Rental income surplus" },
        { key: "Other", value: "Other" },
      ]}
    />
  )
}
