import React, { useEffect, useState } from "react"
import { StatusContentKey } from "models/Eligibility"
import { GaObserver } from "lib/GaObserver"
import { EventAction, EventCategory, EventLabel } from "models/GoogleAnalytics"
import { Checklist } from "components/molecules/Checklist"
import styled from "styled-components"
import devices from "styles/devices"
import { GetContent } from "lib/GetContent"
import { Button, ButtonType, Trustpilot } from "components/atoms"
import { Card } from "components/atoms/Card"
import { LogGaEvent } from "lib/GoogleAnalytics"
import { Booking } from "pages/Application/Eligibility/Approved/Booking"
import { Loader, LoaderTheme } from "components/atoms/Loader"
import { useHistory } from "react-router-dom"
import { FullApplication, OpportunityStage } from "models/FullApplication"

import  { ProudLean, Paperwork, Paperwork2, Mirror, Guitar } from 'components/Illustrations'

import axios, { AxiosResponse } from "axios"

import { formatAppointmentDateTime } from "lib/FormatDate"
import { HasAppointmentBooked } from "lib/HasAppointmentBooked"

import { AVATAR_CLOUDFRONT_URL } from "../../../../Config"
import MojoMiniLogo from "url:assets/images/mojo-mini-logo.svg"

const Container = styled.div`
  max-width: 1500px;
  display: flex;
  flex-direction: column;
  width: 100%;

  @media screen and ${devices.bp_lg} {
    flex-direction: row;
  }
`

const ContentOuterContainer = styled.div<{ wider?: boolean }>`
  @media screen and ${devices.bp_lg} {
    margin: ${({ wider }) => (wider ? "8.5em 5em 0 6.25em" : "8.5em 12.5em 0 6.25em")};
  }
`

const ContentContainer = styled.div<{ wider?: boolean }>`
  margin: 2em 2em 2em 1em;
  strong {
    font-weight: 550;
  }
  @media screen and ${devices.bp_lg} {
    width: ${({ wider }) => (wider ? "30em" : "22.5em")};
    margin: 0;
  }
`

const Title = styled.h2<{ bottomGap?: boolean }>`
  font-size: 24px;
  margin-bottom: 24px;
  margin-top: 36px;
  word-break: break-word;
  line-height: 38px;
  font-weight: 550;

  @media screen and ${devices.bp_lg} {
    font-size: 28px;
    margin-bottom: 48px;
    margin-top: 0;
  }
`

const TitleWithoutBottomGap = styled(Title)`
  margin-bottom: 0;
`

const SmallTitle = styled.h4`
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
`

const Text = styled.p`
  color: #333a53;
  line-height: 180%;
  margin: 16px 0;
`

const TextSingleLine = styled(Text)`
  margin: 0;
`

const SubHeading = styled.h3`
  margin: 0;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
`

const BeforeYourCallInstructions = styled.div`
  line-height: 24px;
`

const Flex = styled.div`
  display: flex;
  flex-direction: column;
`

const SmallPrint = styled.div`
  margin-top: 2em;
  margin-bottom: 6em;
  p {
    margin: 0 0 1em 0;
    line-height: 18px;
    font-size: 12px;
    color: ${(props) => props.theme.shade20};
  }
  @media screen and ${devices.bp_lg} {
    margin-bottom: 0;
  }
`

const LoaderContainer = styled.div`
  margin-bottom: 5em;
  text-align: center;
`

const MirrorImage = styled.img`
  width: 100%;
  position: relative;
  top: 200px;
  left: -250px;
  right: 0;
  display: none;

  > img {
    width: 100%;
    height: auto;
  }

  @media screen and ${devices.bp_lg} {
    width: 600px;
    display: block;
  }
`

const WonGraphic = styled.img`
  width: 100%;
  height: auto;
  position: relative;
  top: 100px;
  height: auto;
  left: -150px;
  right: 0;
  display: none;

  > img {
    width: 100%;
    height: auto;
  }

  @media screen and ${devices.bp_lg} {
    width: auto;
    height: 587px;
    display: block;
  }
`

const SideGraphic = styled.img<{ flip: boolean }>`
  display: none;

  @media screen and ${devices.bp_lg} {
    display: block;
    height: 484px;
    right: ${({ flip }) => (flip ? "-258px" : "336px")};
    transform: ${({ flip }) => (flip ? "rotateY(180deg)" : "rotateY(0deg)")};
  }
`

const SideWrapper = styled.div`
  position: relative;
`

const ChecklistContainer = styled.div`
  background: white;
  border-radius: 16px;
  padding: 1.5em;
  margin: 1em;
  bottom: 5em;

  @media screen and ${devices.bp_lg} {
    margin: 7em 2em 0 0;
    padding: 4em 4em 1.5em;
    width: 22.5em;
    bottom: 0;
  }
`

const ChecklistHeader = styled.h2`
  font-size: 26px;
  margin: 0;
  font-weight: 550;
  line-height: 32px;
`

const UploadDocsButtonContainer = styled.div`
  display: block;
  @media screen and ${devices.bp_lg} {
    display: inline-block;
  }
`

const Graphic = styled.div`
  width: 100%;
  position: absolute;
  left: -75%;
  right: 0;
  bottom: -15%;
  z-index: -1;
  > img {
    margin-left: auto;
  }
`

const PaperworkGraphic = styled(Graphic)`
  left: -100%;
  bottom: 0;
`

const GuitarGraphic = styled(Graphic)`
  width: 344px;
  height: 264px;
  bottom: -60px;
  z-index: 2;
  left: -60%;

  > img {
    width: 100%;
    height: auto;
  }
`

const BoldText = styled(Text)`
  font-weight: bold;
`

const BlueTextLink = styled.a`
  color: rgb(7, 62, 217);
  margin: 10px 0;
  display: block;
  text-decoration: underline;
`

const SubTitle = styled.h6`
  font-size: 16px;
  margin-top: 36px;
  margin-bottom: 1em;
  line-height: 38px;
  font-weight: bold;
`

const CardTitle = styled(SubTitle)`
  margin: 0;
  margin-top: 16px;
`

interface Props {
  friendlyId: string
  application: FullApplication
  applicationStatus: OpportunityStage
  refreshApplication: () => void
}

interface Status {
  recommendationAccepted: boolean,
  numberOfDocumentsRequested: number
}

interface StatusResult {
  res: {
    data: Status
  }
}

interface StatusData {
  recommendationAccepted: boolean;
  documentsRequested: number;
  setCompletionDateForEmail: string;
  opportunityOwnerTextForEmail: string;
  ownerEmailAddress: string;
}

export const Status = ({
  friendlyId,
  application,
  refreshApplication,
  applicationStatus = null,
}: Props) => {
  const [appointmentDate, setAppointmentDate] = useState<Date>(null)
  const [bookedAppointment, setBookedAppointment] = useState<{ assignee: any }>({ assignee: null })
  const history = useHistory()
  const [bookingComplete, setBookingComplete] = useState(false)
  const [isBookingVisible, setIsBookingVisible] = useState<boolean>(false)
  const hasAppointment = HasAppointmentBooked(application)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [fetchedStatus, setFetchedStatus] = useState<StatusData | null>(null)

  useEffect(() => {
    const getStatus = async () => {
      try {
        setIsLoading(true)
        const res = await axios
          .get(
            `${process.env.MY_MOJO_API}/status/${application.applicationId}`,
            {
              withCredentials: true
            }
          ) as AxiosResponse<StatusResult>

        const data = res.data
        const manageStatusView = (status): StatusData => {
          const documentsRequested = status.numberOfDocumentsRequested > 0 && status.numberOfDocumentsRequested

          return {
            ...status,
            documentsRequested,
          }
        }
        const status = manageStatusView(data)
        setFetchedStatus(status)

        LogGaEvent({
          action: EventAction.caseStatus,
          event_category: EventCategory.customEvent,
          event_label:
            `Recommendation acctepted ${JSON.stringify(status.recommendationAccepted)}, documents requested: ${JSON.stringify(status.documentsRequested)}}`,
        })

      } catch (e) {
        console.log(e)
      } finally {
        setIsLoading(false)
      }
    }
    if (!application.applicationId) return
    if (applicationStatus === OpportunityStage.quoted || applicationStatus === OpportunityStage.closedlost || applicationStatus === OpportunityStage.completionSet) {
      getStatus();
    }
  }, [application.applicationId, applicationStatus]);

  const lender = application?.metadata?.opportunity?.lender ?? "your lender"

  useEffect(() => {
    LogGaEvent({
      action: EventAction.pageViewed,
      event_category: EventCategory.formInteraction,
      event_label: "MyMojo - Status",
    })
    LogGaEvent({
      action: EventAction.caseStatus,
      event_category: EventCategory.customEvent,
      event_label: applicationStatus,
    })
  }, [])

  useEffect(() => {
    if (!application) return

    if (Object.keys(application).length) {
      if (hasAppointment) {
        setBookedAppointment(application.appointments[0])
        // Ensure date is UTC
        const apptDate = new Date(application.appointments[0].appointmentDate)
        setAppointmentDate(apptDate)
      }
    }
  }, [application])

  const contentStage: StatusContentKey = {
    [OpportunityStage.mortgageready]: StatusContentKey.advise,        // Made it through fact find
    [OpportunityStage.online]: StatusContentKey.advise,               // Fact find and pre-appointment / appointment pending
    [OpportunityStage.coach]: StatusContentKey.advise,                // Mortgage coach flow
    [OpportunityStage.quoted]: StatusContentKey.recommendation,       // Had a recommentation from the ME
    [OpportunityStage.submitted]: StatusContentKey.application,       // After fact find and recommendation has been submittd. The application is with the lender
    [OpportunityStage.offer]: StatusContentKey.offer,                 // Lender has come back with an offer for the customer to review
    [OpportunityStage.exchanged]: StatusContentKey.offer,             // Exchanged contracts & documents through soliciors & the process can go through to completed
    [OpportunityStage.closedwon]: StatusContentKey.won,               // We have completed and they have finished their mortgage process
    [OpportunityStage.dip]: StatusContentKey.dip,                     // CMT are on the lender portal and manually keying in salesforce
    [OpportunityStage.completionSet]: StatusContentKey.completionSet, // Completion date has been set
    [OpportunityStage.presub]: StatusContentKey.wip,                  // Pre-submission (it is dipped and waiting feeback from lender)
    [OpportunityStage.awaiting]: StatusContentKey.wip,                // Awaiting to go over to the case management team
    [OpportunityStage.returned]: StatusContentKey.wip,                // Returned to advisor, its been DIPed but lender has questions or application is delcined from lender
    [OpportunityStage.packaged]: StatusContentKey.dip,                // Fully ME packaged, everything we need to DIP
    [OpportunityStage.closedlost]: StatusContentKey.lost,             // We have lost the client, they are not mortgage ready or chosen a different lender
  }[applicationStatus]


  let flip = false;
  let graphic
  switch (contentStage) {
    case StatusContentKey.wip:
      graphic = Paperwork
      flip = true
      break

    case StatusContentKey.application:
    case StatusContentKey.offer:
    case StatusContentKey.won:
      graphic = Paperwork2
      break

    case StatusContentKey.completionSet:
      graphic = Guitar
      break

    case StatusContentKey.lost:
      graphic = Mirror
      break

    default:
      graphic = ProudLean
      break
  }

  const pageContent =
    contentStage === StatusContentKey.advise && bookingComplete
      ? StatusContentKey.booked
      : contentStage

  const content = GetContent({
    [StatusContentKey.booked]: (
      <ContentContainer wider={pageContent === StatusContentKey.booked}>
        <GaObserver
          category={EventCategory.siteInteraction}
          action={EventAction.componentDisplay}
          label={EventLabel.appointmentBooked}
        >
          <SmallTitle>You’re all booked in.</SmallTitle>
          <TitleWithoutBottomGap>
            {formatAppointmentDateTime(appointmentDate)} with {bookedAppointment.assignee}
          </TitleWithoutBottomGap>
        </GaObserver>
        <Text>
          You’ll soon be getting free expert advice on the best mortgage option for your specific
          circumstances.
        </Text>
        <Text>
          <SubHeading>Before your call...</SubHeading>
        </Text>
        <BeforeYourCallInstructions>
          <Text>
            We’ll need some documents from you so your expert can get you the most accurate
            recommendation.
          </Text>
          <Text>For each applicant we need:</Text>
          <ul>
            <li>
              <strong>Proof of ID</strong> - A scan or high quality photo of a passport or driving
              licence
            </li>
            <li>
              <strong>Proof of income</strong> - PDF copies of payslips or SA302/annual tax returns
              preferred
            </li>
            <li>
              <strong>Bank statements</strong> - PDF copies from your online banking preferred
            </li>
          </ul>
        </BeforeYourCallInstructions>

        <UploadDocsButtonContainer>
          <Button
            onClick={() => {
              LogGaEvent({
                action: EventAction.buttonClicked,
                event_category: EventCategory.functionalInteraction,
                event_label: EventLabel.uploadDocs,
              })
              history.push(`/application/${friendlyId}/documents`)
            }}
            text={"Upload documents"}
          />
        </UploadDocsButtonContainer>

        <SmallPrint>
          <p>
            If for any reason you need to amend or cancel your appointment, contact your expert
            direct via email.
          </p>
          <p>
            You don’t have to provide these documents but it may result in an inappropriate
            recommendation or slow down any future mortgage application if you do not upload these
            for review.
          </p>
        </SmallPrint>
      </ContentContainer>
    ),
    [StatusContentKey.advise]: (
      <ContentContainer>
        <GaObserver
          category={EventCategory.siteInteraction}
          action={EventAction.componentDisplay}
          label={EventLabel.bookAnAppointment}
        >
          <Title>Your personal Mojo expert is ready to make your mortgage recommendation</Title>
        </GaObserver>
        <Text>
          Mortgages can be complicated things, and here at Mojo we want to get it perfect. Your
          expert wants to review and approve everything, and it's far easier for you if we have a
          quick call.
        </Text>
        <Flex>
          <Button
            disabled={bookingComplete}
            type={ButtonType.Solid}
            onClick={() => {
              LogGaEvent({
                event_category: EventCategory.functionalInteraction,
                action: EventAction.buttonClicked,
                event_label: EventLabel.bookAnAppointment,
                appointment_type: "Mortgage Advisor - Appointment",
              })
              setIsBookingVisible(true)
            }}
            text="Book an appointment"
          />

          <div style={{ margin: "0 auto" }}>
            <Trustpilot />
          </div>

          <Booking
            isBookingVisible={isBookingVisible}
            setIsBookingVisible={setIsBookingVisible}
            bookingComplete={bookingComplete}
            setBookingComplete={setBookingComplete}
            fullApplication={application}
            refreshApplication={refreshApplication}
          />
        </Flex>
      </ContentContainer>
    ),
    [StatusContentKey.successfulApplicaton]: (
      <ContentContainer>
        <GaObserver
          category={EventCategory.siteInteraction}
          action={EventAction.componentDisplay}
          label="Successful Application"
        >
          <TitleWithoutBottomGap>
            You mortgage application has been successful!
          </TitleWithoutBottomGap>
        </GaObserver>
        <Text>Congratulations. {lender} is prepared to offer you a mortgage. 🎉</Text>
        <BoldText>What happens next?</BoldText>
        <Text>
          You’ll be sent the details of the offer directly from the lender and will need to accept
          the offer through your Case Manager.{" "}
        </Text>
        <Text>
          Your Case Manager will now see the offer through to completion and keep you updated.{" "}
        </Text>
      </ContentContainer>
    ),
    [StatusContentKey.recommendation]: (
      <ContentContainer>
        <RecommendationSteps
          friendlyId={friendlyId}
          fetchedStatus={fetchedStatus}
          deal={{ lender }}
        />
      </ContentContainer>
    ),
    [StatusContentKey.dip]: (
      <ContentContainer>
        <DIPView />
      </ContentContainer>
    ),
    [StatusContentKey.offer]: (
      <ContentContainer>
        <GaObserver
          category={EventCategory.siteInteraction}
          action={EventAction.componentDisplay}
          label="Offer received"
        >
          <Title>Application approved</Title>
        </GaObserver>

        <Text>
          Congratulations. {lender} is prepared to offer you a mortgage. 🎉
        </Text>
        <SubTitle>
          What happens next?
        </SubTitle>
        <Text>
          Your Case Manager will now work with you until your mortgage completes and they will keep you updated throughout the process.
        </Text>
      </ContentContainer>
    ),
    [StatusContentKey.application]: (
      <ContentContainer>
        <GaObserver
          category={EventCategory.siteInteraction}
          action={EventAction.componentDisplay}
          label="Submitted Application"
        >
          <Title>We’ve submitted your application.</Title>
        </GaObserver>

        <Text>
          All paperwork for your mortgage has been completed, packaged and sent to {lender}.
        </Text>
        <Text>
          It can take a week or two for {lender} to process your application but we'll be in touch the
          moment we hear anything.
        </Text>
        <Text>
          Sometimes lenders ask for extra paperwork too, but you will hear from your case manager if that happens.
        </Text>
      </ContentContainer>
    ),
    [StatusContentKey.won]: (
      <ContentContainer>
        <GaObserver
          category={EventCategory.siteInteraction}
          action={EventAction.componentDisplay}
          label="Complete"
        >
          <Title>Congratulations on the new mortgage!</Title>
        </GaObserver>
        <Text>We hope its all smooth sailing, but we are always here to help if you need us.</Text>
        <Text>We'll be back in touch in 6 months before your current deal ends so we can help you find your new one.</Text>

      </ContentContainer>
    ),
    [StatusContentKey.lost]: (
      <ContentContainer>
        <GaObserver
          category={EventCategory.siteInteraction}
          action={EventAction.componentDisplay}
          label="Closed case"
        >
          <Title>Had a change of heart?</Title>
        </GaObserver>

        <Text>If you'd like to pick up where you left off, drop us a call, your Mortgage Expert will be more than happy to help.</Text>

        <Card content={
          <>
            <MortgageExpertAvatar advisorEmail={fetchedStatus?.ownerEmailAddress} />
            <CardTitle>Contact your Mortgage Expert</CardTitle>
            <TextSingleLine>{fetchedStatus?.opportunityOwnerTextForEmail || "Mojo Mortgages"}</TextSingleLine>
            <BlueTextLink href="tel:0333 123 0012">0333 123 0012</BlueTextLink>
            <BlueTextLink href={`mailto:${fetchedStatus?.ownerEmailAddress}`}>{fetchedStatus?.ownerEmailAddress}</BlueTextLink>
          </>
        } />
      </ContentContainer>
    ),
    [StatusContentKey.completionSet]: (
      <ContentContainer>
        <GaObserver
          category={EventCategory.siteInteraction}
          action={EventAction.componentDisplay}
          label="Post Rec"
        >
          <Title>A completion date has been set</Title>
        </GaObserver>
        <Text>Great news! Your completion date has been set for {fetchedStatus?.setCompletionDateForEmail}.</Text>
        <SubTitle>What happens next?</SubTitle>
        <Text>There shouldn't be much left to do, but depending on your situation there might be a couple of things to keep an eye on.</Text>
        <Text>Staying with your existing lender, your lender will continue to keep you updated.</Text>
        <Text>Moving to a new lender, it may be worth a quick call to your solicitors to make sure they have all they need.</Text>
        <Text>If moving to a new home, it's worth checking in with your estate agent to find out when you can collect your keys.</Text>
      </ContentContainer>
    ),
    [StatusContentKey.postRec]: (
      <ContentContainer>
        <GaObserver
          category={EventCategory.siteInteraction}
          action={EventAction.componentDisplay}
          label="Post Rec"
        >
          <TitleWithoutBottomGap>Your mortgage application is almost ready</TitleWithoutBottomGap>
        </GaObserver>
        <Text>You’ve chosen your next deal and one step closer to mortgage joy.</Text>
        <BoldText>We need some documents</BoldText>
        <Text>
          To complete your application to {lender}, we need you to upload some additional documents.
        </Text>
        <Button
          onClick={() => {
            LogGaEvent({
              action: EventAction.buttonClicked,
              event_category: EventCategory.functionalInteraction,
              event_label: EventLabel.uploadDocs,
            })
            history.push(`/application/${friendlyId}/documents`)
          }}
          text={"Upload documents"}
        />
      </ContentContainer>
    ),
    [StatusContentKey.wip]: (
      <ContentContainer>
      <GaObserver
        category={EventCategory.siteInteraction}
        action={EventAction.componentDisplay}
        label="Work in progress"
      >
        <Title>We're working on things... sit tight..</Title>
      </GaObserver>

      <Text>
        We're in the process of completing some work on your application, please contact us for
        further details or drop back in later and things should be updated
      </Text>
    </ContentContainer>
    ),
    default: (
      <ContentContainer>
        <LoadingStateView />
      </ContentContainer>
    ),
  })(pageContent)

  return (
    <Container>
      <ContentOuterContainer wider={pageContent === StatusContentKey.booked}>
        {!isLoading && content || <LoadingStateView />}
      </ContentOuterContainer>

      {[StatusContentKey.won].includes(contentStage) ? (
          <WonGraphic src={Paperwork2} alt="mirror" />
      ) : [StatusContentKey.wip, StatusContentKey.lost].includes(contentStage) ? (
        <div>
          <MirrorImage src={Mirror} alt="mirror" />
        </div>
      ) : (
        <GaObserver
          category={EventCategory.siteInteraction}
          action={EventAction.componentDisplay}
          label="Case Stage"
        >
          <SideWrapper>
            <ChecklistContainer>
              <ChecklistHeader>Here’s where you’re at right now...</ChecklistHeader>
              <Checklist stage={contentStage} />
            </ChecklistContainer>
          </SideWrapper>
        </GaObserver>
      )}
    </Container>
  )
}

const RecommendationSteps = ({ fetchedStatus, deal, friendlyId }) => {

  const history = useHistory()
  if (fetchedStatus?.recommendationAccepted && !fetchedStatus.documentsRequested) { // Complete: Accepted and no documents required
    return (
      <DIPView />
    )
  }

  if (fetchedStatus?.recommendationAccepted && fetchedStatus.documentsRequested) { // Ready: Accepted and documents are required
    return (
      <>
        <GaObserver
          category={EventCategory.siteInteraction}
          action={EventAction.componentDisplay}
          label="Recommendation accepted, docs required"
        >
          <Title>Great news, you've accepted our mortgage recommendation!</Title>
        </GaObserver>
        <SubTitle>What’s next</SubTitle>
        <Text>To submit your application to {deal.lender}, we just need you to upload some additional documents</Text>
        <Button
          width="auto"
          onClick={() => {
            LogGaEvent({
              action: EventAction.buttonClicked,
              event_category: EventCategory.functionalInteraction,
              event_label: EventLabel.uploadDocs,
            })
            history.push(`/application/${friendlyId}/documents`)
          }}
          text={"Upload documents"}
        />
      </>
    )
  }

  if (fetchedStatus?.recommendationAccepted === false) { // Recommendation has been made to the client yet
    return (
      <>
        <GaObserver
          category={EventCategory.siteInteraction}
          action={EventAction.componentDisplay}
          label="Recommendation ready"
        >
          <Title>You mortgage recommendation is ready</Title>
        </GaObserver>
        <SubTitle>What’s next</SubTitle>
        <Text>Your Mortgage Expert has reviewed your circumstances and preferences and has sourced their recommended deal for you to view.</Text>
        <Button onClick={() => {
          LogGaEvent({
            action: EventAction.buttonClicked,
            event_category: EventCategory.functionalInteraction,
            event_label: EventLabel.digiRec,
          })
          history.push(`/application/${friendlyId}/recommendation`)
        }} width="auto" text={"Review recommendation"} />
      </>
    )
  }

  if (fetchedStatus?.recommendationAccepted === null) { // Recommendation has not been made to the client yet
    return (
      <>
        <GaObserver
          category={EventCategory.siteInteraction}
          action={EventAction.componentDisplay}
          label="Recommendation unsent"
        >
          <Title>Your recommendation is on it’s way</Title>
        </GaObserver>
        <Text>
          Your Mortgage Expert will be in contact with you within the next working day to provide the official recommendation and begin the application process.
        </Text>
        {/* <Button width="auto" text="View selected deal" /> TODO: Wider discussion needed */}
      </>
    )
  }

  return ( // Error state
    <>
      <GaObserver
        category={EventCategory.siteInteraction}
        action={EventAction.componentDisplay}
        label="Recommendation failure"
      >
        <Title>...</Title>
      </GaObserver>
      <LoadingStateView />
    </>
  )
}

const DIPView = () => {
  return (
    <>
      <GaObserver
        category={EventCategory.siteInteraction}
        action={EventAction.componentDisplay}
        label="Recommendation is ready"
      >
        <Title>Recommendation accepted</Title>
      </GaObserver>
      <Text>You’ve chosen your next mortgage deal and you’re now one step closer.</Text>
      <SubTitle>What happens next?</SubTitle>
      <Text>As mentioned on your call, there's no need to provide any additional documents.</Text>
      <Text>We have everything we need from you right now, we'll be in touch as soon as your application has been submitted</Text>
    </>
  )
}

const LoadingStateView = () => (
  <ContentContainer>
    <LoaderContainer>
      <Loader size={100} theme={LoaderTheme.Outline} />
    </LoaderContainer>
  </ContentContainer>
)

const MiniLogo = styled.div`
  width: 50px;
  height: 50px;
  margin-right: 20px;
  background-image: url(${({
    backgroundImage,
  }: {
    backgroundImage: string
    borderRadius: boolean
  }) => backgroundImage});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: ${({ borderRadius }) => (borderRadius ? "50%" : "0%")};
`

const checkImageURLisValid = (url: string) => {
  return new Promise<boolean>((resolve) => {
    const image = document.createElement("img")
    image.src = url
    image.onload = () => resolve(true)
    image.onerror = () => resolve(false)
  })
}

const MortgageExpertAvatar = ({ advisorEmail }: { advisorEmail: string }) => {
  const [adviserAvatar, setAdviserAvatar] = React.useState<string>(MojoMiniLogo)
  useEffect(() => {
    const req = `${AVATAR_CLOUDFRONT_URL}/${advisorEmail}.jpg`
    checkImageURLisValid(req).then((valid) => (valid ? setAdviserAvatar(req) : null))
  }, [setAdviserAvatar, advisorEmail])

  return (
    <MiniLogo backgroundImage={adviserAvatar} borderRadius={adviserAvatar !== MojoMiniLogo} />
  )
}
