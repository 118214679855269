import { useEffect } from "react"
import useDidMount from "./useDidMount"

// Equivalent of a useEffect that skips the initial render
export default function usePostRenderEffect(fn, inputs) {
  const didMount = useDidMount()

  useEffect(() => {
    if (didMount) {
      fn()
    }
  }, inputs)
}
