export default function toQueryString(obj): string {
  const keyValues = []

  for (const key in obj) {
    if (obj[key] !== null) {
      keyValues.push(`${key}=${obj[key]}`)
    }
  }
  return `?${keyValues.join("&")}`
}
