import React from "react";
import styled from "styled-components";

type Props = {
  children: React.ReactNode;
  leftIcon?: React.ReactNode | string;
}
export const Banner = ({
  children,
  leftIcon
}: Props) => {
  return (
    <BannerContainer>
      {leftIcon && (
        <div className="banner__leftIcon">
          {leftIcon}
        </div>
      )}
      <div className="banner__content">
        {children}
      </div>
    </BannerContainer>
  )
}

const BannerContainer = styled.div({
  background: `#FFFFFF`,
  padding: "1.5rem 1.5rem 1.5rem 1rem",
  borderRadius: "1rem",
  border: `3px solid #FB7123`,
  borderLeft: `9px solid #FB7123`,
  display: "flex",
  gap: "12px",
  maxWidth: 1200,
  width: "90%",
  ".banner__content": {
    "> *": {
      width: "100%",
      margin: "0 0 8px"
    }
  },
})
