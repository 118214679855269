import React, { useEffect, useState } from "react"
import { useApplicationFriendlyId } from "hooks/useApplication"
import { useParams } from "react-router-dom"
import { Flag } from "models/FullApplication"
import { LoadingSpinner } from "pages/Application/Eligibility/EligibilityLoader"
import { GenerateTableUrl } from "lib/MojoUrlObject"
import { PushNewApplicationFlag } from "lib/Applications"
import { ApprovedSection } from "pages/Application/Eligibility/Approved/Approved"
import { ExperienceRes } from "models/ExperienceTLS"
import { useTrackNavigatedToPage } from "hooks/useTrackPageView"

type Props = {
  isPreOffer: boolean
  hasAdBlocker: boolean
  trafficLightResult: ExperienceRes
  refreshApplication: () => void
}

export function ComparisonTableApproved({
  isPreOffer,
  hasAdBlocker,
  trafficLightResult,
  refreshApplication,
}: Props) {
  const { friendlyId } = useParams<{ friendlyId: string }>()
  const { application, hasLoaded } = useApplicationFriendlyId(friendlyId)
  const [showBooking, setShowBooking] = useState(false)

  useEffect(() => {
    const getUrlObject = async () => {
      if (hasLoaded) {
          await PushNewApplicationFlag(application, Flag.HasSeenComparisonTable)
          const tableUrl = await GenerateTableUrl(application)

          const comparisonTableURL = `${tableUrl}&${new URLSearchParams({
            utm_source: "internal",
            utm_medium: "TLS green D",
            utm_campaign: null,
          }).toString()}`
          window.location.assign(comparisonTableURL)
        } else {
          setShowBooking(true)
        }
    }

    getUrlObject()
  }, [hasLoaded])

  useTrackNavigatedToPage("/application/eligibility/mortgage-rates")

  if (!hasLoaded || !showBooking) {
    return <LoadingSpinner />
  }

  return (
    <ApprovedSection
      fullApplication={application}
      isPreOffer={isPreOffer}
      hasAdBlocker={hasAdBlocker}
      trafficLightResult={trafficLightResult}
      refreshApplication={refreshApplication}
    />
  )
}
