import axios from "axios"
import * as Sentry from "@sentry/browser"

export const getSession = async () => {
  try {
    return await axios.get<{
      Id: number
      Email: string
      UserRef: string
      CreatedOn: string
      CreatedTimestamp: number
      Provider: "email"
      UpdatedOn: string
      LastLogOn: string
      LockedExpires: string | null
      InvalidAttempts: number
    }>(`${process.env.PUBLIC_AUTH_API}/session`, {
      withCredentials: true,
    })
  } catch (err) {
    Sentry.captureMessage("Session expired.", "warning")
    throw err
  }
}

export type GetSessionQROptions = { redirect?: string }

/**
 * Returns a base64 image of a QR code that, when scanned, shares the user's session to the device that scanned it
 */
export const getSessionQR = ({ redirect }: GetSessionQROptions = {}) => {
  return axios.get<string>(
    `${process.env.PUBLIC_AUTH_API}/session/share?redirect=${encodeURIComponent(redirect)}`,
    {
      withCredentials: true,
    }
  )
}
