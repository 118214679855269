import ConveyancingCard from "components/molecules/ConveyancingCard"
import Tooltip from "components/_blueprint/Tooltip"
import { Accordion } from "pages/Application/Eligibility/Coach/Accordion"
import React, { useEffect } from "react"
import styled from "styled-components"
import devices from "styles/devices"
import { HeaderStyle, MyMojoTemplate, PageTheme } from "templates/MyMojoTemplate"
import ProudLean from "assets/images/illustrations/Proud lean.png"
import { Route, Switch, useParams, useRouteMatch } from "react-router-dom"
import Schedule from "./Schedule/Schedule"
import { useConveyancingQuotes } from "hooks/useConveyancing"
import { Loader, LoaderTheme } from "components/atoms/Loader"
import { useApplicationFriendlyId } from "hooks/useApplication"
import { useTrackNavigatedToPage } from "hooks/useTrackPageView"
import { GetGaMortgageType, LogGaEvent, SetGaDimensions } from "lib/GoogleAnalytics"
import { EventAction, EventCategory } from "models/GoogleAnalytics"
import { useExperienceTLS } from "hooks/useExperienceTLS"
import Typography from "components/_blueprint/Typography"
import { HasAppointmentBooked } from "lib/HasAppointmentBooked"
import { LogError } from "logging"

/**
 * Used to make the word "Conveyancers" clickable
 */
const Conveyancers = styled.button`
  display: inline;
  font-weight: 500;
  background-color: transparent;
  border: 0px;
  border-bottom: 2px dashed ${({ theme }) => theme.primary};
  cursor: pointer;
`

const Image = styled.img`
  display: none;

  @media ${devices.bp_lg} {
    z-index: 1000;
    display: block;
    width: 277px;
    margin-left: -255px;
    margin-top: 200px;
    position: absolute;
  }
`

const conveyancingAccordion = [
  {
    title: "What even is conveyancing, and is it necessary?",
    content: (
      <>
        <Typography.Body>
          Conveyancing is the legal side of buying or selling a house (or remortgaging to another
          lender).
        </Typography.Body>
        <Typography.Body>
          The main role of a conveyancer is to legally transfer the ownership of a property from one
          person to another. The exact nature of the work they do changes from case to case, but
          generally they look at:
        </Typography.Body>
        <ul>
          <li>
            <Typography.Text>
              Checking the Land Registry to find any boundary issues and what land and buildings are
              included in the sale
            </Typography.Text>
          </li>
          <li>
            <Typography.Text>
              Creating contracts for the transfer of ownership and checking clauses included by
              other parties in the transaction
            </Typography.Text>
          </li>
          <li>
            <Typography.Text>
              Guarding against potential fraud issues if the property is used as part of an illegal
              transaction
            </Typography.Text>
          </li>
          <li>
            <Typography.Text>
              Checking the property has all the right certificates and approvals and they have been
              properly registered
            </Typography.Text>
          </li>
          <li>
            <Typography.Text>Transferring money at exchange and completion</Typography.Text>
          </li>
        </ul>
      </>
    ),
  },
  {
    title: "What does it cost and when do you pay conveyancing fees?",
    content: (
      <>
        <Typography.Body>
          There are 2 types of costs you have to pay with conveyancing:
        </Typography.Body>
        <ol>
          <li>
            <Typography.Text>
              The legal fees – paying the conveyancer for the work they do
            </Typography.Text>
          </li>
          <li>
            <Typography.Text>
              The disbursement – these are extra costs that the conveyancer has to charge to do
              their job, namely 3rd parties charges for certain services like searches and
              registering your property. Stamp duty can also be listed as a disbursement
            </Typography.Text>
          </li>
        </ol>
        <Typography.Body>
          Some conveyancing fees only apply to sellers and some only apply to buyers. It can be
          tough to understand what is good value, which is why Mojo has sourced a selection of
          quotes based on your specific circumstances and conveyancing needs.
        </Typography.Body>
        <Typography.Body>Quotes are based on a number of factors including:</Typography.Body>
        <ul>
          <li>
            <Typography.Text>Whether your property is leasehold or freehold</Typography.Text>
          </li>
          <li>
            <Typography.Text>The price of your property</Typography.Text>
          </li>
          <li>
            <Typography.Text>
              If you’re buying a Shared Ownership, Help to Buy or Right to Buy property
            </Typography.Text>
          </li>
          <li>
            <Typography.Text>The number of additional searches needed</Typography.Text>
          </li>
        </ul>
        <Typography.Body>
          Normally, conveyancing fees are paid at the completion of the purchase or remortgaging
          process.
        </Typography.Body>
      </>
    ),
  },
  {
    title: "How long does it take?",
    content: (
      <>
        <Typography.Body>
          Conveyancing ends once the new ownership of a property is registered at the Land Registry.
          It starts as soon as you have an official offer accepted.
        </Typography.Body>
        <Typography.Body>
          There are two major parts of conveyancing: exchange and completion – and these can occur
          on the same day. However, typically you'll find there are 2 weeks between the exchange and
          completion.{" "}
        </Typography.Body>
        <Typography.Body>
          In reality, lots of work is done prior to these stages and the whole process usually takes
          2 to 3 months depending on the size of your chain.
        </Typography.Body>
        <Typography.Body>
          Always make your conveyancer aware of any completion date you have in mind at the start of
          the process.
        </Typography.Body>
      </>
    ),
  },
  {
    title: "Why do you need a conveyancer if you're remortgaging?",
    content: (
      <>
        <Typography.Body>You may not. </Typography.Body>
        <Typography.Body>
          If you’re staying with your existing lender and simply moving to a new rate or borrowing
          more, there are no legal charges involved in the transaction.
        </Typography.Body>
        <Typography.Body>
          If you remortgage to a new lender there will be legal work involved as the paperwork will
          need to be updated with the new lender's details.
        </Typography.Body>
      </>
    ),
  },
  {
    title: "Does Mojo get a commission for recommending a conveyancer?",
    content: (
      <>
        <Typography.Body>Mojo doesn't recommend any conveyancer.</Typography.Body>
        <Typography.Body>
          We work with a company called Smoove to bring you a selection of conveyancing quotes. It
          is your choice to choose any conveyancer you wish. If you do opt for a conveyance via a
          quote provided to you by Mojo, Smoove will pay Mojo a referral fee.
        </Typography.Body>
        <Typography.Body>
          Mojo doesn't charge any broker fees and we are bringing you the conveyancing quotes in an
          effort to make the mortgage process easier and more transparent for you.
        </Typography.Body>
      </>
    ),
  },
  {
    title: "Free legals v Mojo/Smoove?",
    content: (
      <>
        <Typography.Body>
          Many mortgage deals offer free legal fees – particularly if you are remortgaging.
        </Typography.Body>
        <Typography.Body>
          Obviously, this may help you save money and can be a good option.
        </Typography.Body>
        <Typography.Body>
          However, many people like to believe that their conveyancer is working for them and not
          their lender.
        </Typography.Body>
      </>
    ),
  },
  {
    title: "Why does Mojo offer these conveyancing quotes?",
    content: (
      <>
        <Typography.Body>
          Mojo wants to make mortgages easier and has a mission to make you feel more confident
          about your options.
        </Typography.Body>
        <Typography.Body>
          Conveyancing is a really tricky part of completing a remortgage or purchasing a property –
          we're not conveyancers, but we want to do what we can to help.
        </Typography.Body>
        <Typography.Body>
          Part of that is offering you early insight into typical conveyancing costs as well as some
          transparency about how other customers rated their service.{" "}
        </Typography.Body>
      </>
    ),
  },
  {
    title: "Who are Smoove? Are they part of Mojo?",
    content: (
      <>
        <Typography.Body>
          Smoove is a service separate from Mojo that makes it easy for people to find the
          conveyancing services they need.
        </Typography.Body>
        <Typography.Body>
          The great thing about Smoove is that they only source conveyancers with a high customer
          satisfaction rating.
        </Typography.Body>
        <Typography.Body>
          Mojo is simply bringing this service to its customers. Ultimately, the decision is yours
          and you can choose any conveyancer you want either from Smoove's selection or a
          conveyancer you find on your own. However, if you choose to go ahead with any of the
          options provided by Smoove via Mojo, Mojo has more opportunity to help keep track of the
          case and help you chase the solicitors to avoid any unnecessary delays.
        </Typography.Body>
      </>
    ),
  },
  {
    title:
      "Is Mojo responsible for chasing conveyancers for updates and ensuring completion dates are met?",
    content: (
      <>
        <Typography.Body>No.</Typography.Body>
        <Typography.Body>
          Whatever conveyancer you choose, it is your responsibility to communicate and work with
          them.
        </Typography.Body>
        <Typography.Body>
          However, Mojo recognises this can be a stressful and frustrating process – and we want to
          help where we can.
        </Typography.Body>
        <Typography.Body>
          That is why we work with Smoove. Our agreement with them allows us to help you by
          occasionally calling and asking for updates on your behalf.{" "}
        </Typography.Body>
        <Typography.Body>
          If you choose your own conveyancer it is likely they will not offer extra updates to us.
        </Typography.Body>
      </>
    ),
  },
]

/**
 * Renders conveyancing routes tree.
 *
 * `/` - A page listing conveyancing quotes that allows users to express interest
 * `/schedule` - Where users get sent after expressing interest about a conveyancing quote.
 */
const Conveyancing = () => {
  const match = useRouteMatch()
  const { friendlyId } = useParams<{ friendlyId: string }>()
  const { application, hasLoaded } = useApplicationFriendlyId(friendlyId)
  const { data, isLoading, isError } = useConveyancingQuotes(application.applicationId)
  const { experienceTLS, hasFetchedExperienceTLS } = useExperienceTLS(friendlyId, true)
  const hasAppointment = HasAppointmentBooked(application)

  useTrackNavigatedToPage("/application/conveyancing")

  /**
   * When the user loads the page
   */
  useEffect(() => {
    LogGaEvent({
      action: EventAction.page,
      event_category: EventCategory.siteInteraction,
      event_label: "Conveyancing",
    })
  }, [])

  /**
   * Sending events depending on which button is shown to the user
   */
  useEffect(() => {
    if (hasLoaded) {
      LogGaEvent({
        action: EventAction.componentDisplay,
        event_category: EventCategory.siteInteraction,
        event_label: hasAppointment ? "I'm interested CTA" : "Book a call CTA",
      })
    }
  }, [hasAppointment, hasLoaded])

  /**
   * Sending events depending on whether the user has an appointment booked or not
   */
  useEffect(() => {
    if (hasLoaded) {
      LogGaEvent({
        action: EventAction.opportunityStage,
        event_category: EventCategory.siteInteraction,
        event_label: hasAppointment ? "Appointment booked" : "Appointment not booked",
      })
    }
  }, [hasLoaded, hasAppointment])

  /**
   * Log the experience's TLS
   */
  useEffect(() => {
    if (hasFetchedExperienceTLS) {
      SetGaDimensions({
        traffic_light_definition: `${experienceTLS.rule.color} ${experienceTLS.rule.tier}`,
      })
      LogGaEvent({
        action: EventAction.experienceTLS,
        event_category: EventCategory.siteInteraction,
        event_label: `${experienceTLS.rule.color}-${experienceTLS.rule.tier}`,
      })
    }
  }, [hasFetchedExperienceTLS])

  /**
   * Recording how many quotes are being shown to the user
   */
  useEffect(() => {
    if (!isLoading) {
      const numberOfQuotes = data?.quotes?.length ?? 0

      LogGaEvent({
        action: EventAction.numberOfProductsShown,
        event_category: EventCategory.siteInteraction,
        event_label: `${numberOfQuotes} conveyancing quotes shown`,
      })

      if (numberOfQuotes < 1) {
        console.log(`No conveyancing quotes returned for ${friendlyId}`)
        LogError(`No conveyancing quotes returned.`)
      }
    }
  }, [isLoading])

  /**
   * This will log one of:
   * - Resi Remo
   * - Resi Purchase
   * - BTL Remo
   * - BTL Purchase
   *
   * Could be flakey but I think it should be accurate
   */
  useEffect(() => {
    if (hasLoaded) {
      SetGaDimensions({ mortgage_type: GetGaMortgageType(application) })
      LogGaEvent({
        action: EventAction.mortgageType,
        event_category: EventCategory.siteInteraction,
        event_label: GetGaMortgageType(application),
      })
    }
  }, [hasLoaded])

  return (
    <Switch>
      <Route path={`${match.path}/schedule`}>
        <Schedule />
      </Route>
      <Route path="/">
        <MyMojoTemplate
          backgroundWave
          stickyHeader={false}
          colourTheme={PageTheme.Alternate}
          headerStyle={HeaderStyle.Transparent}
          content={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",

                marginTop: "4rem",
                padding: "1.5rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "2rem",

                  maxWidth: 1080,
                }}
              >
                <Image src={ProudLean} alt="person leaning" />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography.H1>
                    Buying a new house? Remortgaging? You’re gonna need a conveyancer...
                  </Typography.H1>
                  <Typography.LargeBody>
                    We’ve sourced quotes from different
                    <Tooltip
                      eventLabel="Conveyancers explanation"
                      content={
                        <>
                          <Typography.H6 color="white">What's conveyancing?</Typography.H6>
                          <Typography.SmallBody color="white">
                            Conveyancing is the legal process of transferring ownership of a
                            property. Conveyancers are solicitors that specialise in the legal side
                            of moving home. A conveyancer will go through the legal process to
                            transfer the property ownership from one person to another.
                          </Typography.SmallBody>
                        </>
                      }
                    >
                      <Conveyancers>conveyancers</Conveyancers>
                    </Tooltip>
                    based on your personal circumstances. It’s now easy for you to check price AND
                    customer rating - both so important when it comes to conveyancing.
                  </Typography.LargeBody>
                  <Typography.LargeBody>
                    Choose one that interests you. After that, your Mortgage Expert will provide
                    more details (like how these conveyancers commit to updating Mojo customers) and
                    talk about any next steps.
                  </Typography.LargeBody>
                </div>
                {isLoading ? (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Loader theme={LoaderTheme.Outline} size={28} />
                  </div>
                ) : (
                  <>
                    {!isError ? (
                      data.quotes.map((q) => (
                        <ConveyancingCard key={q.QuoteRef} quote={q} friendlyId={friendlyId} />
                      ))
                    ) : (
                      <div style={{ display: "flex", justifyContent: "center" }}>
                        Sorry, we don't have any quotes to show you at the moment.
                      </div>
                    )}
                  </>
                )}
                <div
                  style={{
                    textAlign: "center",
                  }}
                >
                  <Typography.SmallBody>
                    These quotes are provided by Smoove’s eConveyancer platform to help our
                    customers to compare conveyancing providers.
                  </Typography.SmallBody>
                  <Typography.SmallBody>
                    Please be aware, that prices are based on the information you have provided to
                    Mojo and are subject to change
                  </Typography.SmallBody>
                </div>
                <Typography.H2>Conveyancing FAQs</Typography.H2>
                <div>
                  <Accordion
                    data={conveyancingAccordion.map((d, i) => ({
                      id: i.toString(),
                      name: d.title,
                      page: d.content,
                    }))}
                  />
                </div>
              </div>
            </div>
          }
        />
      </Route>
    </Switch>
  )
}

export default Conveyancing
