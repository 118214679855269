import React, { useEffect, useLayoutEffect } from "react"
import styled from "styled-components"
import { MortgageCoachHero } from "pages/Application/Eligibility/Coach/MortgageCoachHero"
import { Accordion } from "pages/Application/Eligibility/Coach/Accordion"
import devices from "styles/devices"
import { YourCreditHistory } from "pages/Application/Eligibility/Coach/YourCreditHistory"
import { YourEmployment } from "pages/Application/Eligibility/Coach/YourEmployment"
import { YourIncome } from "pages/Application/Eligibility/Coach/YourIncome"
import { YourDebt } from "pages/Application/Eligibility/Coach/YourDebt"
import { YourDisposableIncome } from "pages/Application/Eligibility/Coach/YourDisposableIncome"
import { YourDepositLoanAmount } from "pages/Application/Eligibility/Coach/YourDepositLoanAmount"
import { General } from "pages/Application/Eligibility/Coach/General"
import { UpdateInfo } from "components/organisms/UpdateInfo"
import { GaObserver } from "lib/GaObserver"
import { LogGaEvent } from "lib/GoogleAnalytics"
import { GetContent } from "lib/GetContent"
import { EventAction, EventCategory } from "models/GoogleAnalytics"
import { useTrackNavigatedToPage } from "hooks/useTrackPageView"

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`

const WhiteBox = styled.div`
  background-color: white;
  border-radius: 24px;
  padding: 30px 20px;

  @media screen and ${devices.bp_xl} {
    padding: 40px 60px;
  }

  h5 {
    margin-top: 0px;
    margin-bottom: 20px;
    line-height: 130%;
  }
`

const getAccordionData = (friendlyId, isPreOffer) => {
  const IS_PRE_OFFER = [
    {
      name: "Credit History",
      status: "Good" as const,
      id: "credit-history",
      page: (
        <>
          <UpdateInfo
            title="Start by checking & updating your info"
            text="Credit record checks are based on your name, date of birth and address history, if there's a problem in those details it could harm your chances"
            friendlyId={friendlyId}
            taggingLabel="Credit history"
          />
          <YourCreditHistory
            cardTitle="Give your credit profile a good work-out to get it mortgage ready"
            cardText="Mortgage lenders need to be confident that their customers will be able to pay their mortgage every month. Having poor credit, now or in the recent past, makes them nervous that you won’t be able to do that."
            cardBulletPoints={[
              "Pay any debts you have on time",
              "Get your bills paid when they’re due",
              "Close any old, unused accounts",
              "Review and update credit report",
              "Don’t apply for more credit (no matter how tempting the offer)",
              "Stay out of your overdraft",
              "Avoid payday loans",
            ]}
          />
        </>
      ),
    },
    {
      name: "Employment",
      status: "Good" as const,
      id: "employment",
      page: (
        <>
          <UpdateInfo
            title="Start by checking & updating your info"
            text="Stable employment is a big tick for lenders. Are your start dates right?"
            friendlyId={friendlyId}
            taggingLabel="Employment"
          />
          <YourEmployment
            cardTitle="Time and evidence are key to getting a mortgage"
            cardText="If you put income aside, mortgage lenders' main consideration with employment is not the type of job you do, but how long you have been doing it. Lenders love it if you've had a stable source of income, whether this be in one role over the last 10 years, or a number of roles - as long as it's consistent."
            cardBulletPoints={[
              "Not working? It'll be difficult, but not impossible",
              "Employed? The longer the better, but 3-6 months minimum",
              "Retired? Possible, but will come down to the individual lender",
              "Contracting? 12 months evidence needed as a minimum",
              "Self-employed? 3 years trading and 2 years of accounts, please",
              "Part-time? Again, consistency is key",
            ]}
          />
        </>
      ),
    },
    {
      name: "Income",
      status: "Good" as const,
      id: "income",
      page: (
        <>
          <UpdateInfo
            title="Start by checking & updating your info"
            text="Check you've included all your income including bonus, commission and other sources. Income's so important to your chances!"
            friendlyId={friendlyId}
            taggingLabel="Income"
          />
          <YourIncome
            cardTitle="Mo' money, less problems"
            cardText="Mortgage lenders will look at the maximum they are willing to lend based on multiples of your income. For example, if your annual income was £50,000, you might be able to borrow three to five times this amount, giving you a mortgage of anywhere between £150,000 and £250,000."
            cardBulletPoints={[
              "Borrow anywhere up to 3 & 5 times your annual income (combined for all applicants)",
              "You might be able to borrow more if your income is above £60k",
              "Any expected changes in income in the near future should be considered - it might help (or hinder) what you can afford to borrow",
              "Having a stable, set amount paid in regularly makes lenders happy, it shows consistency",
              "You'll need bank statements and payslips to prove your income to a lender",
              "Non-regular income and benefits will be considered by some lenders",
            ]}
          />
        </>
      ),
    },
    {
      name: "Debt",
      status: "Good" as const,
      id: "debt",
      page: (
        <>
          <UpdateInfo
            title="Start by checking & updating your info"
            text="Debt affects your ability to afford your new mortgage repayments. Any commitments that you can remove soon?"
            friendlyId={friendlyId}
            taggingLabel="Debt"
          />
          <YourDebt
            cardTitle="Reduce your debt and you're set"
            cardText="A key factor that mortgage lenders will assess as part of your application is the level of debt that you currently have as a percentage of your income For example: say your debts each month are - £900 on your mortgage - £100 on your car loan - £200 payment on your credit card Your monthly debts will come to £1,200. If your gross income is £3,600 per month, your debt to income (DTI) ratio will be 33%"
            cardBulletPoints={[
              "Consider consolidating debt where possible or changing provider to reduce your repayments (be careful though, extending a repayment term will increase the overall amount you'll repay",
              "Pay off any debts before starting an application if you can",
              "You'll need to provide details of your commitments as part of your application",
              "Lenders will complete a credit check to identify and verify what you've told them, so be as honest as possible as they will find out",
            ]}
          />
        </>
      ),
    },
    {
      name: "Disposable Income",
      status: "Good" as const,
      id: "disposable-income",
      page: (
        <>
          <UpdateInfo
            title="Start by checking & updating your info"
            text="This is one of the quicker things you can change to improve your chances. Any spending you can cut?"
            friendlyId={friendlyId}
            taggingLabel="Disposable income"
          />
          <YourDisposableIncome
            cardTitle="Show some restraint to show you're a responsible borrower"
            cardText="Knowing what you spend each month is really important. You'll have your essential expenses and costs to live at a certain level, but anything over and above that you should think, 'do I really need to spend money on that?'"
            cardBulletPoints={[
              "Recommendations will be made based on what you can realistically afford each month",
              "Consider that your disposable income will likely be less than it was/is prior to homeownership (consider insurances, ground rent, service charges, utilities, etc)",
              "Bank statements and payslips will be needed to prove your income",
              "Reduce unnecessary spending each month",
            ]}
          />
        </>
      ),
    },
    {
      name: "Deposit & Loan Amount",
      status: "Good" as const,
      id: "deposit-loan-amount",
      page: (
        <>
          <UpdateInfo
            title="Start by checking & updating your info"
            text="Looking for a cheaper property is perhaps the single biggest way to boost your mortgage chances. Update it so your deposit covers at least 95% of the total price."
            friendlyId={friendlyId}
            taggingLabel="Deposit"
          />
          <YourDepositLoanAmount
            cardTitle="Build your deposit to open doors to the best deals"
            cardText="For the most part, a 5% deposit should be enough to put down to get you a mortgage (25% for a buy to let). If you're moving home or remortgaging, you'll use the equity in your home as your 'down payment' for your next mortgage."
            cardBulletPoints={[
              "Start getting your deposit together as soon as possible",
              "Making overpayments on an existing mortgage will reduce your balance and give you more options for your next mortgage",
              "First-time buyers can consider purchase schemes to help make a property more affordable",
              "Get as big a deposit as possible, this will open the door to better mortgage rates (or more expensive properties)",
              "If you're receiving help with your deposit, you'll need to be able to explain and prove where it came from",
            ]}
          />
        </>
      ),
    },
    {
      name: "General",
      status: "Good" as const,
      id: "general",
      page: (
        <>
          <UpdateInfo
            title="Start by checking & updating your info"
            text="Looking for a cheaper property is perhaps the single biggest way to boost your mortgage chances. Update it so your deposit covers at least 95% of the total price."
            friendlyId={friendlyId}
            taggingLabel="General"
          />
          <General
            cardTitle="Get yourself ready to get on the property ladder / get your next mortgage"
            cardText="Some general things about getting a mortgage."
            cardBulletPoints={[
              "Get important documents together (ID, proof of income, etc) for your application",
              "Review and understand your spending habits",
              "Don't forget extra costs like stamp duty, estate agents fees, etc",
              "You'll need a solicitor to handle your mortgage completion - this is called conveyancing and we can help you with that too",
              "Think about what you'd do if you couldn't afford to pay your mortgage - we can arrange mortgage-related insurance to help if the worst was ever to happen",
              "Your home/investment property may be repossessed if you can't afford to make your monthly repayments",
            ]}
          />
        </>
      ),
    },
  ]

  const POST_OFFER = [
    {
      name: "Credit History",
      status: "Good" as const,
      id: "credit-history",
      page: (
        <>
          <UpdateInfo
            title="Start by checking & updating your info"
            text="Credit record checks are based on your name, date of birth and address history, if there's a problem in those details it could harm your chances"
            friendlyId={friendlyId}
            taggingLabel="Credit history"
          />
          <YourCreditHistory
            cardTitle="Give your credit profile a good work-out to get it mortgage ready"
            cardText="Mortgage lenders need to be confident that their customers will be able to pay their mortgage every month. Having poor credit, now or in the recent past, makes them nervous that you won’t be able to do that."
            cardBulletPoints={[
              "Pay any debts you have on time",
              "Get your bills paid when they’re due",
              "Close any old, unused accounts",
              "Review and update credit report",
              "Don’t apply for more credit (no matter how tempting the offer)",
              "Stay out of your overdraft",
              "Avoid payday loans",
            ]}
          />
        </>
      ),
    },
    {
      name: "Employment",
      status: "Good" as const,
      id: "employment",
      page: (
        <>
          <UpdateInfo
            title="Start by checking & updating your info"
            text="Stable employment is a big tick for lenders. Are your start dates right?"
            friendlyId={friendlyId}
            taggingLabel="Employment"
          />
          <YourEmployment
            cardTitle="Time and evidence are key to getting a mortgage"
            cardText="If you put income aside, mortgage lenders' main consideration with employment is not the type of job you do, but how long you have been doing it. Lenders love it if you've had a stable source of income, whether this be in one role over the last 10 years, or a number of roles - as long as it's consistent."
            cardBulletPoints={[
              "Not working? It'll be difficult, but not impossible",
              "Employed? The longer the better, but 3-6 months minimum",
              "Retired? Possible, but will come down to the individual lender",
              "Contracting? 12 months evidence needed as a minimum",
              "Self-employed? 3 years trading and 2 years of accounts, please",
              "Part-time? Again, consistency is key",
            ]}
          />
        </>
      ),
    },
    {
      name: "Income",
      status: "Good" as const,
      id: "income",
      page: (
        <>
          <UpdateInfo
            title="Start by checking & updating your info"
            text="Check you've included all your income including bonus, commission and other sources. Income's so important to your chances!"
            friendlyId={friendlyId}
            taggingLabel="Income"
          />
          <YourIncome
            cardTitle="Mo' money, less problems"
            cardText="Mortgage lenders will look at the maximum they are willing to lend based on multiples of your income. For example, if your annual income was £50,000, you might be able to borrow three to five times this amount, giving you a mortgage of anywhere between £150,000 and £250,000."
            cardBulletPoints={[
              "Borrow anywhere up to 3 & 5 times your annual income (combined for all applicants)",
              "You might be able to borrow more if your income is above £60k",
              "Any expected changes in income in the near future should be considered - it might help (or hinder) what you can afford to borrow",
              "Having a stable, set amount paid in regularly makes lenders happy, it shows consistency",
              "You'll need bank statements and payslips to prove your income to a lender",
              "Non-regular income and benefits will be considered by some lenders",
            ]}
          />
        </>
      ),
    },
    {
      name: "Debt",
      status: "Good" as const,
      id: "debt",
      page: (
        <>
          <UpdateInfo
            title="Start by checking & updating your info"
            text="Debt affects your ability to afford your new mortgage repayments. Any commitments that you can remove soon?"
            friendlyId={friendlyId}
            taggingLabel="Debt"
          />
          <YourDebt
            cardTitle="Reduce your debt and you're set"
            cardText="A key factor that mortgage lenders will assess as part of your application is the level of debt that you currently have as a percentage of your income For example: say your debts each month are - £900 on your mortgage - £100 on your car loan - £200 payment on your credit card Your monthly debts will come to £1,200. If your gross income is £3,600 per month, your debt to income (DTI) ratio will be 33%"
            cardBulletPoints={[
              "Consider consolidating debt where possible or changing provider to reduce your repayments (be careful though, extending a repayment term will increase the overall amount you'll repay",
              "Pay off any debts before starting an application if you can",
              "You'll need to provide details of your commitments as part of your application",
              "Lenders will complete a credit check to identify and verify what you've told them, so be as honest as possible as they will find out",
            ]}
          />
        </>
      ),
    },
    {
      name: "Disposable Income",
      status: "Good" as const,
      id: "disposable-income",
      page: (
        <>
          <UpdateInfo
            title="Start by checking & updating your info"
            text="This is one of the quicker things you can change to improve your chances. Any spending you can cut?"
            friendlyId={friendlyId}
            taggingLabel="Disposable income"
          />
          <YourDisposableIncome
            cardTitle="Show some restraint to show you're a responsible borrower"
            cardText="Knowing what you spend each month is really important. You'll have your essential expenses and costs to live at a certain level, but anything over and above that you should think, 'do I really need to spend money on that?'"
            cardBulletPoints={[
              "Recommendations will be made based on what you can realistically afford each month",
              "Consider that your disposable income will likely be less than it was/is prior to homeownership (consider insurances, ground rent, service charges, utilities, etc)",
              "Bank statements and payslips will be needed to prove your income",
              "Reduce unnecessary spending each month",
            ]}
          />
        </>
      ),
    },
    {
      name: "Deposit & Loan Amount",
      status: "Good" as const,
      id: "deposit-loan-amount",
      page: (
        <>
          <UpdateInfo
            title="Start by checking & updating your info"
            text="Looking for a cheaper property is perhaps the single biggest way to boost your mortgage chances. Update it so your deposit covers at least 95% of the total price."
            friendlyId={friendlyId}
            taggingLabel="Deposit"
          />
          <YourDepositLoanAmount
            cardTitle="Build your deposit to open doors to the best deals"
            cardText="For the most part, a 5% deposit should be enough to put down to get you a mortgage (25% for a buy to let). If you're moving home or remortgaging, you'll use the equity in your home as your 'down payment' for your next mortgage."
            cardBulletPoints={[
              "Start getting your deposit together as soon as possible",
              "Making overpayments on an existing mortgage will reduce your balance and give you more options for your next mortgage",
              "First-time buyers can consider purchase schemes to help make a property more affordable",
              "Get as big a deposit as possible, this will open the door to better mortgage rates (or more expensive properties)",
              "If you're receiving help with your deposit, you'll need to be able to explain and prove where it came from",
            ]}
          />
        </>
      ),
    },
    {
      name: "General",
      status: "Good" as const,
      id: "general",
      page: (
        <>
          <UpdateInfo
            title="Start by checking & updating your info"
            text="Looking for a cheaper property is perhaps the single biggest way to boost your mortgage chances. Update it so your deposit covers at least 95% of the total price."
            friendlyId={friendlyId}
            taggingLabel="General"
          />
          <General
            cardTitle="Get yourself ready to get on the property ladder / get your next mortgage"
            cardText="Some general things about getting a mortgage."
            cardBulletPoints={[
              "Get important documents together (ID, proof of income, etc) for your application",
              "Review and understand your spending habits",
              "Don't forget extra costs like stamp duty, estate agents fees, etc",
              "You'll need a solicitor to handle your mortgage completion - this is called conveyancing and we can help you with that too",
              "Think about what you'd do if you couldn't afford to pay your mortgage - we can arrange mortgage-related insurance to help if the worst was ever to happen",
              "Your home/investment property may be repossessed if you can't afford to make your monthly repayments",
            ]}
          />
        </>
      ),
    },
  ]

  return isPreOffer ? IS_PRE_OFFER : POST_OFFER
}

export const CoachSection = ({ friendlyId, isPreOffer = false }) => {
  const preOffer = isPreOffer ? "preOffer" : "postOffer"
  const data = getAccordionData(friendlyId, isPreOffer)

  const postOfferHeroContent = {
    title:
      "Right now, it looks like the best thing for you to do is boost your chances with our MortgageCoach",
    text: `This is because, given some of the things you've told us, there may be a bit of work to do before we can get you accepted for a mortgage.
    Don't worry. MortgageCoach can be a really useful tool to get a clearer picture of what you need to do going forward.`,
  }
  const heroContent = GetContent({
    preOffer: {
      title:
        "Right now, it looks like the best thing for you to do is boost your chances with our MortgageCoach",
      text: `This is because, given some of the things you've told us, there may be a bit of work to do before we can get you accepted for a mortgage.
      Don't worry. MortgageCoach can be a really useful tool as you're still looking for a property. It will give you a clearer picture of what you need to do going forward.`,
    },
    postOffer: postOfferHeroContent,
    default: postOfferHeroContent,
  })(preOffer)

  useEffect(() => {
    LogGaEvent({
      action: EventAction.page,
      event_category: EventCategory.siteInteraction,
      event_label: "Mortgage Coach",
    })
  }, [])

  useTrackNavigatedToPage("/application/eligibility/mortgage-coach")

  useLayoutEffect(() => {
    LogGaEvent({
      action: EventAction.componentDisplay,
      event_category: EventCategory.siteInteraction,
      event_label: "CoachSection",
    })
    LogGaEvent({
      action: EventAction.componentDisplay,
      event_category: EventCategory.siteInteraction,
      event_label: "Nurture hub",
    })
  }, [])

  return (
    <Container>
      <MortgageCoachHero {...heroContent} />
      <WhiteBox>
        <h5>Your very own MortgageCoach™</h5>
        <GaObserver label="Accordion in View">
          <Accordion data={data} />
        </GaObserver>
      </WhiteBox>
    </Container>
  )
}
