import React from "react"
import styled from "styled-components"

import { Button, ButtonType, Trustpilot } from "../../../../components/atoms"

// @ts-ignore
import MojoLogo from "url:assets/images/mojo-logo.png"
import { useHistory } from "react-router-dom"
import { SidebarNav } from "./SidebarNav"
import devices from "styles/devices"
import { LogGaEvent } from "lib/GoogleAnalytics"
import { EventAction, EventCategory } from "models/GoogleAnalytics"
import { StatusPageNavSelected } from "pages/Application/Eligibility/Status/index"
import { useLogout } from "lib/auth/react"

const SideBar = styled.div<{ isOpen: boolean }>`
  max-height: ${({ isOpen }) => (isOpen ? "100vh" : "0")};
  position: ${({ isOpen }) => (isOpen ? "fixed" : "static")};
  width: 100vw;
  overflow: hidden;
  transition: max-height ease-in 250ms;
  height: 100vh;
  z-index: 999;
  top: 56px;
  display: flex;
  @media screen and ${devices.bp_xl} {
    position: static;
    overflow: unset;
    top: 0;
    width: 18em;
    max-height: unset;
    height: unset;
  }
`

const MojoIconWrapper = styled.div`
  display: none;

  @media screen and ${devices.bp_xl} {
    display: block;
    margin-bottom: 32px;
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  padding: 0 32px;
  min-height: 100vh;
  width: 100%;
  @media screen and ${devices.bp_xl} {
    padding: 32px;
    width: 18em;
    margin-right: 16px;
    height: unset;
    flex: 1;
  }
`

const ButtonMaxWidth = styled.div`
  max-width: 350px;
`

const TrustpilotWrapper = styled.div`
  margin-top: 16px;
  display: flex;
  margin-bottom: 56px;

  @media screen and ${devices.bp_xl} {
    justify-content: center;
    margin-bottom: 0;
  }
`

interface Props {
  friendlyId: string
  address: string
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  selected: StatusPageNavSelected
}

export const Sidebar = ({
  friendlyId = "",
  address = "",
  isOpen,
  setIsOpen,
  selected = StatusPageNavSelected.Status,
}: Props) => {
  const history = useHistory()
  const { logout } = useLogout({
    onSuccess: () => {
      window.location.reload()
    },
  })
  return (
    <SideBar isOpen={isOpen}>
      <Container>
        <MojoIconWrapper>
          <img src={MojoLogo} alt="mojo logo" style={{ height: "30px" }} />
        </MojoIconWrapper>
        <ButtonMaxWidth>
          <Button
            type={ButtonType.Outline}
            onClick={() => {
              LogGaEvent({
                action: EventAction.buttonClicked,
                event_category: EventCategory.functionalInteraction,
                event_label: "mymojo",
              })
              history.push("/dashboard")
            }}
            text="mymojo"
          />
        </ButtonMaxWidth>

        <div style={{ marginTop: "16px" }}>
          <p>
            <strong>{friendlyId}</strong>
          </p>
          <p>
            <strong>{address}</strong>
          </p>
        </div>

        <SidebarNav friendlyId={friendlyId} selected={selected} setIsOpen={setIsOpen} />

        <div style={{ marginTop: "auto" }}>
          <ButtonMaxWidth>
            <Button
              type={ButtonType.Outline}
              onClick={() => {
                LogGaEvent({
                  action: EventAction.buttonClicked,
                  event_category: EventCategory.functionalInteraction,
                  event_label: "Logout",
                })
                logout()
              }}
              text="Logout"
            />
          </ButtonMaxWidth>
          <TrustpilotWrapper>
            <Trustpilot height="100px" />
          </TrustpilotWrapper>
        </div>
      </Container>
    </SideBar>
  )
}
