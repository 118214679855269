import React, { useEffect, useRef } from "react"
import PropTypes from "prop-types"
import styled, { keyframes } from "styled-components"

const Label = styled.label`
  position: absolute;
  width: 100%;
  display: block;
  transition: 0.4s;
  padding-left: 16px;
  padding-right: 16px;
  top: 0;
  bottom: 0;
  margin: auto;
  line-height: 44px;
  pointer-events: none;
  user-select: none;
  color: ${(props) => props.theme.shade[20]};
  z-index: 1;
  white-space: nowrap;
  text-align: right;
  p {
    display: inline-block;
  }
`
const Container = styled.div`
  border: 1px solid ${(props) => props.theme.shade[60]};
  background: ${(props) => (props.disabled ? props.theme.shade[80] : props.theme.shade[100])};
  position: relative;
  border-radius: ${(props) => props.theme.radius.default};
  &:focus-within {
    border: 1px solid ${(props) => props.theme.cta};
    box-shadow: 0 0 0 1px ${(props) => props.theme.cta};
  }
`
const InnerInput = styled.input`
  display: block;
  border: none;
  background: transparent;
  width: 100%;
  height: 100%;
  padding: 12px ${(props) => props.labelOffset * 0.9 + 32 + "px"} 12px 16px;
  font-size: 16px;
  color: ${(props) => (props.disabled ? props.theme.shade[40] : props.theme.shade[0])};
  box-sizing: border-box;
  outline: none;
  &:empty {
    + ${Label} {
      width: 0;
    }
  }
  &:focus,
  &:not(:placeholder-shown) {
    + ${Label} {
      width: 100%;
      p {
        transform-origin: right;
        transform: scale(0.8);
      }
      /* The 0.5 is to fix a bug with overflow */
    }
  }
`
const animation = keyframes`
  0% {
    opacity: 0;
    transform: scale(0)
  }

  100 {
    opacity: 1;
    transform: scale(1)
  }
`
const Loader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${(props) => props.theme.shade[60]};
  width: 32px;
  height: 32px;
  margin: auto;
  border-radius: 50%;
  transform-origin: center;
  animation: ${animation} 1s infinite alternate;
  user-select: none;
  pointer-events: none;
  display: ${(props) => (props.isLoading ? "block" : "none")};
`

export function Input(props: React.HTMLProps<HTMLInputElement>) {
  const [dimensions, setDimensions] = React.useState({
    labelWidth: 0,
  })
  const labelRef = useRef(null)

  useEffect(() => {
    setDimensions({
      labelWidth: labelRef.current.clientWidth,
    })
  }, [])

  return (
    <Container disabled={props.disabled}>
      <Loader isLoading={props.isLoading} />
      <InnerInput
        type="text"
        id={props.id}
        disabled={props.disabled}
        value={props.value}
        onChange={props.onChange}
        onClick={props.onClick}
        placeholder=" "
        autoComplete="off"
        labelOffset={dimensions.labelWidth}
        {...props}
      />
      <Label htmlFor={props.id} disabled={props.disabled}>
        <p ref={labelRef}>{props.label}</p>
      </Label>
    </Container>
  )
}

Input.propTypes = {
  placeholder: PropTypes.string,
}
