import { ThemeConfig } from "./DefaultTheme"

export const themesData: Record<string, ThemeConfig> = {
  uswitch: {
    name: "uswitch",
    primary: "#141424",
    secondary: "#A3BCFF",
    tertiary: "#141424",
    cta: "#141424",
    ctaButton: "#141424",
    buttonShadow: "#141424",
    error: "#D64226",
    backgroundColour: "#F5F7FB",
    infoColor: "#dbe1eb",
    shade: {
      "0": "#3F4148",
      "10": "#3c4044",
      "20": "#61676e",
      "30": "#828a93",
      "40": "#9ea7b2",
      "50": "#b6becb",
      "60": "#cad1dd",
      "70": "#dbe1eb",
      "80": "#F2F2F7",
      "90": "#F2F2F7",
      "100": "#ffffff",
    },
    fontFamily:
      "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, sans-serif",
    pageTitle: "Uswitch | Mortgages",
    faviconURL: "https://static.mojomortgages.com/partner-favicons/uswitch-favicon.png",
    mainLogoURL: "https://static.mojomortgages.com/partner-logos/uswitch-logo.svg",
    illustrations: {
      lander: "https://static.mojomortgages.com/partner-illustrations/bankrate/bbq.svg",
      routing: null,
    },
    privacyPolicy: "https://www.uswitch.com/about-us/privacy-policy/",
    showPoweredBy: true,
    footerCopy: [
      "Uswitch makes introductions to Mojo Mortgages to provide mortgage solutions.",
      "Uswitch and Mojo Mortgages are part of the same group of companies. Uswitch Limited is authorised and regulated by the Financial Conduct Authority (FCA) under firm reference number 312850. You can check this on the Financial Services Register by visiting the FCA website.",
      "Uswitch Limited is registered in England and Wales (Company No 03612689) The Cooperage, 5 Copper Row, London SE1 2LH.",
      "Mojo Mortgages is a trading style of Life’s Great Limited which is registered in England and Wales (06246376). Mojo are authorised and regulated by the Financial Conduct Authority and are on the Financial Services Register (478215).",
      "Mojo’s registered office is The Cooperage, 5 Copper Row, London, SE1 2LH. To contact Mojo by phone, please call 0333 123 0012.",
    ],
  },
  monzo: {
    name: "monzo",
    pageTitle: "Monzo | Mortgages",
    showPoweredBy: true,
    poweredByVariant: "partnership",
    faviconURL: "https://static.mojomortgages.com/partner-favicons/monzo.svg",
    mainLogoURL: "https://static.mojomortgages.com/partner-logos/monzo-logo-on-light.svg",
    primary: "#016B83",
    primaryOne: "#FF4B44",
    backgroundColour: "#FFFFFF",
    infoColor: "#F2F8F3",
    secondary: "limegreen",
    tertiary: "#112231",
    cta: "#016B83",
    ctaButton: "#016B83",
    buttonShadow: "#016B83",
    borderRadius: "6.75px",
    navItemColour: "#EEF0F8",
    activeNavItemBackground: "#F5F7FB",
    illustrations: {
      lander: "https://static.mojomortgages.com/partner-illustrations/monzo/lander.png",
    },
  },
  "uswitch-test": {
    name: "uswitch-test",
    primary: "#141424",
    secondary: "#A3BCFF",
    tertiary: "#141424",
    cta: "#141424",
    ctaButton: "#141424",
    buttonShadow: "#141424",
    error: "#D64226",
    backgroundColour: "#F5F7FB",
    infoColor: "#dbe1eb",
    shade: {
      "0": "#3F4148",
      "10": "#3c4044",
      "20": "#61676e",
      "30": "#828a93",
      "40": "#9ea7b2",
      "50": "#b6becb",
      "60": "#cad1dd",
      "70": "#dbe1eb",
      "80": "#F2F2F7",
      "90": "#F2F2F7",
      "100": "#ffffff",
    },
    pageTitle: "Uswitch | Mortgages",
    faviconURL: "https://static.mojomortgages.com/partner-favicons/uswitch-favicon.png",
    mainLogoURL: "https://static.mojomortgages.com/partner-logos/uswitch-logo.svg",

    illustrations: {
      lander: "https://static.mojomortgages.com/partner-illustrations/bankrate/bbq.svg",
      routing: null,
    },
    privacyPolicy: "https://www.uswitch.com/about-us/privacy-policy/",
  },
  "gocompare-test": {
    name: "gocompare-test",
    primary: "#0045FF",
    secondary: "#BEE3FF",
    tertiary: "#00C893",
    cta: "#00A0FF",
    ctaButton: "#00A0FF",
    buttonShadow: "#00A0FF",
    infoColor: "#dbe1eb",
    error: "#ED5A5A",
    pageTitle: "Mojo Mortgages | Your free online mortgage broker",
    faviconURL: "https://static.mojomortgages.com/mojofavicon.ico",
    mainLogoURL: "https://static.mojomortgages.com/mojologo.svg",
    illustrations: {
      lander: "https://static.mojomortgages.com/illustrations/coffee.svg",
      routing: null,
    },
  },
  "money-test": {
    name: "money-test",
    primary: "#FAA740",
    secondary: "#924A8B",
    tertiary: "#34454E",
    cta: "linear-gradient(45deg, rgb(219, 77, 117) 0%, rgb(146, 74, 139) 100%);",
    ctaButton: "linear-gradient(45deg, rgb(219, 77, 117) 0%, rgb(146, 74, 139) 100%);",
    buttonShadow: "#924A8B",
    infoColor: "#dbe1eb",
    error: "#ED5A5A",
    pageTitle: "Money | Mortgages",
    faviconURL: "https://static.mojomortgages.com/partner-favicons/moneyfavicon.ico",
    mainLogoURL: "https://static.mojomortgages.com/partner-logos/money-logo.svg",
    illustrations: {
      lander: "https://static.mojomortgages.com/illustrations/coffee.svg",
      routing: null,
    },
    privacyPolicy: "https://www.money.co.uk/privacy-policy.htm",
  },
  money: {
    name: "money",
    primary: "#FAA740",
    secondary: "#924A8B",
    tertiary: "#34454E",
    cta: "linear-gradient(45deg, rgb(219, 77, 117) 0%, rgb(146, 74, 139) 100%);",
    ctaButton: "linear-gradient(45deg, rgb(219, 77, 117) 0%, rgb(146, 74, 139) 100%);",
    buttonShadow: "#924A8B",
    infoColor: "#dbe1eb",
    error: "#ED5A5A",
    pageTitle: "Money | Mortgages",
    faviconURL: "https://static.mojomortgages.com/partner-favicons/moneyfavicon.ico",
    mainLogoURL: "https://static.mojomortgages.com/partner-logos/money-logo.svg",
    illustrations: {
      lander: "https://static.mojomortgages.com/illustrations/coffee.svg",
      routing: null,
    },
    privacyPolicy: "https://www.money.co.uk/privacy-policy.htm",
    showPoweredBy: true,
    footerCopy: [
      "money.co.uk makes introductions to Mojo Mortgages to provide mortgage solutions.",
      "money.co.uk and Mojo Mortgages are part of the same group of companies. money.co.uk is a trading name of Dot Zinc Limited and is authorised and regulated by the Financial Conduct Authority (FCA) under firm reference number 415689. You can check this on the Financial Services Register by visiting the FCA website.",
      "money.co.uk is registered in England (Company No 4093922) The Cooperage, 5 Copper Row, London SE1 2LH.",
      "Mojo Mortgages is a trading style of Life’s Great Limited which is registered in England and Wales (06246376). Mojo are authorised and regulated by the Financial Conduct Authority and are on the Financial Services Register (478215).",
      "Mojo’s registered office is The Cooperage, 5 Copper Row, London, SE1 2LH. To contact Mojo by phone, please call 0333 123 0012.",
    ],
    fontFamily: `"Open Sans", -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", "Segoe WP", Ubuntu, sans-serif`,
  },
  snoop: {
    name: "snoop",
    showPoweredBy: true,
    poweredByVariant: "partnership",
    primary: "#231A4F",
    secondary: "#E4F1F5",
    tertiary: "#00dbeb",
    cta: "#ff4f00",
    ctaButton: "#ff4f00",
    buttonShadow: "#ff4f00",
    error: "#fb5050",
    backgroundColour: "#E4F1F5",
    infoColor: "#dbe1eb",
    shade: {
      "100": "#ffffff",
      "90": "#F2F2F7",
      "80": "#F2F2F7",
      "70": "#dbe1eb",
      "60": "#cad1dd",
      "50": "#b6becb",
      "40": "#9ea7b2",
      "30": "#828a93",
      "20": "#61676e",
      "10": "#3c4044",
      "0": "#3F4148",
    },
    pageTitle: "Snoop | Mortgages",
    faviconURL: "https://static.mojomortgages.com/partner-favicons/snoopfavicon.ico",
    mainLogoURL: "https://static.mojomortgages.com/partner-logos/snooplogo.svg",
    landerHeader: "Okay, let’s get things set up for you.",
    landerBodyText:
      "I just need to ask you a few questions and pass your info to Mojo. It’ll only take about 8 mins. Mojo will do a soft credit check (don’t worry, it won’t affect your credit score) and show you your mortgage results",
    landerCTA: "Let’s do this",
    illustrations: {
      lander: "https://static.mojomortgages.com/partner-illustrations/snoop/Bike.svg",
      routing: null,
    },
  },
  creditladder: {
    name: "creditladder",
    primary: "#FDEE01",
    secondary: "#22DFBC",
    tertiary: "#FDEE01",
    cta: "#FDEE01",
    ctaButton: "#FDEE01",
    buttonShadow: "#FDEE01",
    infoColor: "#dbe1eb",
    error: "#ED5A5A",
    pageTitle: "CreditLadder | Mortgages",
    faviconURL: "https://static.mojomortgages.com/partner-favicons/creditladderfavicon.png",
    mainLogoURL: "https://static.mojomortgages.com/partner-logos/credit-ladder-logo.svg",
    illustrations: {
      lander: "https://static.mojomortgages.com/illustrations/coffee.svg",
      routing: null,
    },
  },
  together: {
    name: "together",
    primary: "#402B55",
    secondary: "#00837F",
    tertiary: "#00837F",
    cta: "#F7B12C",
    ctaButton: "#F7B12C",
    buttonShadow: "#F7B12C",
    infoColor: "#dbe1eb",
    error: "#ED5A5A",
    backgroundColour: "#ffffff",
    pageTitle: "Together | Your free online mortgage broker",
    faviconURL: "https://static.mojomortgages.com/partner-favicons/together-favicon.png",
    mainLogoURL: "https://static.mojomortgages.com/partner-logos/together-logo.png",
    illustrations: {
      lander: "https://static.mojomortgages.com/illustrations/coffee.svg",
      routing: null,
    },
  },
  rmq: {
    name: "rmq",
    primary: "#2457DD",
    secondary: "#E5388F",
    tertiary: "#2457DD",
    cta: "#2457DD",
    ctaButton: "#2457DD",
    buttonShadow: "#2457DD",
    infoColor: "#dbe1eb",
    error: "#ED5A5A",
    pageTitle: "Remortgage Quotes Online | Mortgages",
    faviconURL: "https://static.mojomortgages.com/partner-favicons/rmqfavicon.png",
    mainLogoURL: "https://static.mojomortgages.com/partner-logos/rmq-logo.svg",
    illustrations: {
      lander: "https://static.mojomortgages.com/illustrations/coffee.svg",
      routing: null,
    },
  },
  nerdwallet: {
    name: "nerdwallet",
    primary: "#008255",
    secondary: "#008255",
    tertiary: "#008255",
    cta: "#008255",
    ctaButton: "#008255",
    buttonShadow: "#008255",
    infoColor: "#dbe1eb",
    error: "#ED5A5A",
    pageTitle: "NerdWallet | Mortgages",
    faviconURL: "https://static.mojomortgages.com/partner-favicons/nerdwallet-favicon.ico",
    mainLogoURL: "https://static.mojomortgages.com/partner-logos/nerdwallet-logo.jpg",
    illustrations: {
      lander: "https://static.mojomortgages.com/illustrations/coffee.svg",
      routing: null,
    },
  },
  thinkmoney: {
    name: "thinkmoney",
    primary: "#00577A",
    secondary: "#24ABE0",
    tertiary: "#7060EB",
    cta: "linear-gradient(45deg, rgb(36, 171, 224) 0%, rgb(112, 96, 235) 100%);",
    ctaButton: "linear-gradient(45deg, rgb(36, 171, 224) 0%, rgb(112, 96, 235) 100%);",
    buttonShadow: "rgb(36, 171, 224)",
    infoColor: "#dbe1eb",
    error: "#ED5A5A",
    pageTitle: "thinkmoney | Mortgages",
    faviconURL: "https://static.mojomortgages.com/partner-favicons/thinkmoneyfavicon.png",
    mainLogoURL: "https://static.mojomortgages.com/partner-logos/thinkmoney-logo.svg",
    illustrations: {
      lander: "https://static.mojomortgages.com/partner-illustrations/thinkmoney-1.png",
      routing: null,
    },
  },
  citibank: {
    name: "citibank",
    primary: "#164883",
    secondary: "#164883",
    tertiary: "#164883",
    cta: "#164883",
    ctaButton: "#164883",
    buttonShadow: "#164883",
    infoColor: "#dbe1eb",
    error: "#ED5A5A",
    pageTitle: "Citibank | Mortgages",
    faviconURL: "https://static.mojomortgages.com/partner-favicons/citibankfavicon.png",
    mainLogoURL: "https://static.mojomortgages.com/partner-logos/citibank-logo.png",
    illustrations: {
      lander: "https://static.mojomortgages.com/illustrations/coffee.svg",
      routing: null,
    },
  },
  zoopla: {
    name: "zoopla",
    primary: "#7F46F1",
    primaryOne: "#7F46F1",
    secondary: "#FF6009",
    tertiary: "#322744",
    cta: "#7F46F1",
    ctaButton: "#7F46F1",
    buttonShadow: "#7F46F1",
    infoColor: "#DBE1EB",
    error: "#E91640",
    backgroundColour: "#fff",
    shade: {
      "0": "#3F4148",
      "10": "#3c4044",
      "20": "#61676e",
      "30": "#828a93",
      "40": "#9ea7b2",
      "50": "#b6becb",
      "60": "#cad1dd",
      "70": "#dbe1eb",
      "80": "#F2F2F7",
      "90": "#F2F2F7",
      "100": "#ffffff",
    },
    pageTitle: "Zoopla | Mortgages",
    landerHeader: "Get mortgage experts to find a great deal for you …and your home’s value",
    landerLeadText:
      "Mojo’s mortgage experts answer your questions about LTV bands, your current equity, additional borrowing, fixed vs variable and anything else.",
    landerBullet1: "Tell us what you want",
    landerBullet2: "There’s a soft credit check — it won’t impact your score",
    landerBullet3: "Your expert compares 1,000s of deals",
    landerBullet4: "You get a time and money-saving recommendation from them",
    landerSecondaryText:
      "Swap confusion and comparison for a clear and certain expert mortgage recommendation.",
    landerCTA: "Get your expert recommendation",
    landerComplianceText:
      "Zoopla may receive a fee of up to £300 should you complete a mortgage through Mojo Mortgages.",
    faviconURL: "https://static.mojomortgages.com/partner-favicons/zooplafavicon_v2.png",
    mainLogoURL: "https://static.mojomortgages.com/partner-logos/zoopla_v2_logo.png",
    illustrations: {
      lander: "https://static.mojomortgages.com/partner-illustrations/zoopla/keys.png",
      routing: "https://static.mojomortgages.com/partner-illustrations/zoopla/bike.svg",
    },
    showPoweredBy: true,
    poweredByVariant: "partnership",
    mojoBlurb: true,
    privacyPolicy: "https://mojomortgages.com/privacy-policy",
    fontFamily: "'Zoopla Sans', Arial, sans",
    footerCopy: [
      "Zoopla makes introductions to Mojo Mortgages to provide mortgage solutions.",
      "Zoopla Limited and Mojo Mortgages are part of the same group of companies. Zoopla Limited is an appointed representative of Uswitch Limited with is authorised and regulated by the Financial Conduct Authority (FCA) under firm reference number 312850. You can check this on the Financial Services Register by visiting the FCA website.",
      "Zoopla Limited is registered in England and Wales (Company No 06074771) The Cooperage, 5 Copper Row, London SE1 2LH.",
      "Zoopla may receive an introduction fee from Mojo Mortgages on completion of a successful mortgage application. The fee is based on a percentage of your loan amount.",
      "Mojo Mortgages is a trading style of Life’s Great Limited which is registered in England and Wales (06246376). Mojo are authorised and regulated by the Financial Conduct Authority and are on the Financial Services Register (478215).",
      "Mojo’s registered office is The Cooperage, 5 Copper Row, London, SE1 2LH. To contact Mojo by phone, please call 0333 123 0012.",
    ],
  },
  metro: {
    name: "metro",
    primary: "#000000",
    secondary: "#262626",
    tertiary: "#FF0044",
    cta: "#EA6300",
    ctaButton: "#EA6300",
    buttonShadow: "#EA6300",
    infoColor: "#dbe1eb",
    error: "#ED5A5A",
    pageTitle: "Metro | Mortgages",
    faviconURL: "https://static.mojomortgages.com/partner-favicons/metrofavicon.png",
    mainLogoURL: "https://static.mojomortgages.com/partner-logos/metrologo.jpg",
    illustrations: {
      lander: "https://static.mojomortgages.com/illustrations/coffee.svg",
      routing: null,
    },
  },
  barratthomes: {
    name: "barratthomes",
    primary: "#A3CA36",
    secondary: "#313539",
    tertiary: "#EFF0F2",
    cta: "#CE3116",
    ctaButton: "#CE3116",
    buttonShadow: "#CE3116",
    infoColor: "#dbe1eb",
    error: "#ED5A5A",
    pageTitle: "Barratt Homes | Mortgages",
    faviconURL: "https://static.mojomortgages.com/partner-favicons/barretthomesfavicon.png",
    mainLogoURL: "https://static.mojomortgages.com/partner-logos/barrathomeslogo.png",
    illustrations: {
      lander: "https://static.mojomortgages.com/illustrations/coffee.svg",
      routing: null,
    },
  },
  yolt: {
    name: "yolt",
    primary: "#09C081",
    secondary: "#043173",
    tertiary: "#762AA6",
    cta: "#EE286E",
    ctaButton: "#EE286E",
    buttonShadow: "#EE286E",
    infoColor: "#dbe1eb",
    error: "#ED5A5A",
    pageTitle: "Yolt | Mortgages",
    faviconURL: "https://static.mojomortgages.com/partner-favicons/yoltfavicon.png",
    mainLogoURL: "https://static.mojomortgages.com/partner-logos/yoltlogo.svg",
    illustrations: {
      lander: "https://static.mojomortgages.com/illustrations/coffee.svg",
      routing: null,
    },
  },
  restless: {
    name: "restless",
    primary: "#F07860",
    secondary: "#00445D",
    tertiary: "#222B38",
    cta: "#41AB8B",
    ctaButton: "#41AB8B",
    buttonShadow: "#41AB8B",
    infoColor: "#dbe1eb",
    error: "#ED5A5A",
    pageTitle: "Rest Less | Mortgages",
    faviconURL: "https://static.mojomortgages.com/partner-favicons/restlessfavicon.webp",
    mainLogoURL: "https://static.mojomortgages.com/partner-logos/restless-logo.svg",
    illustrations: {
      lander: "https://static.mojomortgages.com/illustrations/coffee.svg",
      routing: null,
    },
  },
  finder: {
    name: "finder",
    primary: "#0271e1",
    secondary: "#0271e1",
    tertiary: "#00ba9c",
    cta: "#fa8643",
    ctaButton: "#fa8643",
    buttonShadow: "#fa8643",
    infoColor: "#dbe1eb",
    error: "#ED5A5A",
    pageTitle: "Finder | Mortgages",
    faviconURL: "https://static.mojomortgages.com/partner-favicons/finder.ico",
    mainLogoURL: "https://static.mojomortgages.com/partner-logos/finder-logo.png",
    illustrations: {
      lander: "https://static.mojomortgages.com/illustrations/coffee.svg",
      routing: null,
    },
  },
  oma: {
    name: "oma",
    primary: "#420859",
    secondary: "#722FA2",
    tertiary: "#FD9C27",
    cta: "#26AD5F",
    ctaButton: "#26AD5F",
    buttonShadow: "#26AD5F",
    infoColor: "#dbe1eb",
    error: "#ED5A5A",
    pageTitle: "Online Mortgage Advisor | Mortgages",
    faviconURL: "https://static.mojomortgages.com/partner-favicons/omafavicon.ico",
    mainLogoURL: "https://static.mojomortgages.com/partner-logos/oma-logo.svg",
    illustrations: {
      lander: "https://static.mojomortgages.com/illustrations/coffee.svg",
      routing: null,
    },
  },
  multiply: {
    name: "multiply",
    primary: "#1D1D1E",
    secondary: "#F144C2",
    tertiary: "#1D1D1E",
    cta: "#F8654E",
    ctaButton: "#F8654E",
    buttonShadow: "#F8654E",
    infoColor: "#dbe1eb",
    error: "#ED5A5A",
    pageTitle: "Multiply | Mortgages",
    faviconURL: "https://static.mojomortgages.com/partner-favicons/multiplyfavicon.png",
    mainLogoURL: "https://static.mojomortgages.com/partner-logos/multiplylogo.png",
    illustrations: {
      lander: "https://static.mojomortgages.com/illustrations/coffee.svg",
      routing: null,
    },
  },
  strike: {
    name: "strike",
    primary: "#000000",
    secondary: "#F5F5F5",
    tertiary: "#2AFD80",
    cta: "#2AFD80",
    ctaButton: "#2AFD80",
    buttonShadow: "#2AFD80",
    infoColor: "#dbe1eb",
    error: "#ED5A5A",
    pageTitle: "Strike | Mortgages",
    faviconURL: "https://static.mojomortgages.com/partner-favicons/strikefavicon.png",
    mainLogoURL: "https://static.mojomortgages.com/partner-logos/strikelogo.png",
    illustrations: {
      lander: "https://static.mojomortgages.com/illustrations/coffee.svg",
      routing: null,
    },
  },
  thorntonrobson: {
    name: "thorntonrobson",
    primary: "#0045FF",
    secondary: "#BEE3FF",
    tertiary: "#00C893",
    cta: "#00A0FF",
    ctaButton: "#00A0FF",
    buttonShadow: "#00A0FF",
    infoColor: "#dbe1eb",
    error: "#ED5A5A",
    pageTitle: "Mojo Mortgages | Your free online mortgage broker",
    faviconURL: "https://static.mojomortgages.com/mojofavicon.ico",
    mainLogoURL: "https://static.mojomortgages.com/mojologo.svg",
    illustrations: {
      lander: "https://static.mojomortgages.com/illustrations/coffee.svg",
      routing: null,
    },
  },
  gocompare: {
    name: "gocompare",
    primary: "#0045FF",
    secondary: "#BEE3FF",
    tertiary: "#00C893",
    cta: "#00A0FF",
    ctaButton: "#00A0FF",
    buttonShadow: "#00A0FF",
    infoColor: "#dbe1eb",
    error: "#ED5A5A",
    pageTitle: "Mojo Mortgages | Your free online mortgage broker",
    faviconURL: "https://static.mojomortgages.com/mojofavicon.ico",
    mainLogoURL: "https://static.mojomortgages.com/mojologo.svg",
    illustrations: {
      lander: "https://static.mojomortgages.com/illustrations/coffee.svg",
      routing: null,
    },
  },
  bankrate: {
    name: "bankrate",
    primary: "#0157FF",
    secondary: "#0157FF",
    tertiary: "#0157FF",
    cta: "#0157FF",
    ctaButton: "#0157FF",
    buttonShadow: "#0157FF",
    infoColor: "#dbe1eb",
    error: "#F62745",
    backgroundColour: "#ffffff",
    shade: {
      "0": "#3F4148",
      "10": "#3c4044",
      "20": "#61676e",
      "30": "#828a93",
      "40": "#9ea7b2",
      "50": "#b6becb",
      "60": "#cad1dd",
      "70": "#dbe1eb",
      "80": "#F2F2F7",
      "90": "#F2F2F7",
      "100": "#ffffff",
    },
    pageTitle: "Bankrate | Mortgages",
    faviconURL: "https://static.mojomortgages.com/partner-favicons/bankrate.png",
    mainLogoURL: "https://static.mojomortgages.com/partner-logos/bankrate-logo.svg",
    illustrations: {
      lander: "https://static.mojomortgages.com/partner-illustrations/bankrate/bbq.svg",
      routing: null,
    },
    privacyPolicy: "https://www.bankrate.com/uk/privacy-policy/",
  },
  thisismoney: {
    name: "thisismoney",
    primary: "#451344",
    secondary: "#004db3",
    tertiary: "#9a3334",
    cta: "#05A9D2",
    ctaButton: "#05A9D2",
    buttonShadow: "#05A9D2",
    infoColor: "#dbe1eb",
    error: "#ED5A5A",
    pageTitle: "This is Money | Mortgages",
    faviconURL: "https://static.mojomortgages.com/partner-favicons/thisismoneyfavicon.png",
    mainLogoURL: "https://static.mojomortgages.com/partner-logos/thisismoneylogo.png",
    illustrations: {
      lander: "https://static.mojomortgages.com/illustrations/coffee.svg",
      routing: null,
    },
  },
  confused: {
    name: "confused",
    pageTitle: "Confused | Mortgages",
    showPoweredBy: true,
    poweredByVariant: "partnership",
    faviconURL: "https://static.mojomortgages.com/partner-favicons/confused.png",
    mainLogoURL: "https://static.mojomortgages.com/partner-logos/confused-logo.svg",
    fontFamily: "Poppins, Arial, sans", // https://zeroheight.com/784b13be8/p/793051-design-language/b/345143
    // https://zeroheight.com/784b13be8/p/328c9e-colours
    primary: "#EA9665",
    primaryOne: "#EA9665",
    secondary: "#F0B693",
    tertiary: "#3C3C3C",
    infoColor: "#EFEFEF",
    cta: "#EA9665",
    ctaButton: "#1F1F1F",
    borderRadius: 0,
    buttonShadow: "#1F1F1F",
    error: "#E61414",
    illustrations: {
      lander: "https://static.mojomortgages.com/illustrations/confused.svg",
      routing: "https://static.mojomortgages.com/illustrations/meditate.svg",
    },
    footerCopy: [
      "Confused.com makes introductions to Mojo Mortgages to provide mortgage solutions.",
      "Confused.com and Mojo Mortgages are part of the same group of companies. Confused.com is a trading name of Inspop.com Limited and is authorised and regulated by the Financial Conduct Authority (FCA) under firm reference number 310635. You can check this on the Financial Services Register by visiting the FCA website.",
      "Confused.com is registered in England and Wales (Company No 03881924) 2nd Floor, Greyfriars House, Greyfriars Road, Cardiff, South Wales, CF10 3AL.",
      "Mojo Mortgages is a trading style of Life’s Great Limited which is registered in England and Wales (06246376). Mojo are authorised and regulated by the Financial Conduct Authority and are on the Financial Services Register (478215).",
      "Mojo’s registered office is The Cooperage, 5 Copper Row, London, SE1 2LH. To contact Mojo by phone, please call 0333 123 0012.",
    ],
  },
}
