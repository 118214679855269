import React, { useEffect, useState } from "react"

import styled from "styled-components"

// @ts-ignore
import WaveSvg from "url:assets/images/background-wave-2.svg"
import devices from "styles/devices"
import { Trustpilot } from "components/atoms"

// @ts-ignore
import CoffeeSatDown from "url:assets/images/illustrations/Coffee sat down.png"
import { useApplicationFriendlyId } from "hooks/useApplication"
import { useTrackNavigatedToPage } from "hooks/useTrackPageView"
import { EventAction, EventCategory, EventLabel } from "models/GoogleAnalytics"
import { GaObserver } from "lib/GaObserver"
import { LogGaEvent } from "lib/GoogleAnalytics"
import { ExperienceRes } from "models/ExperienceTLS"

const Page = styled.div`
  background: linear-gradient(137.52deg, #fff1e8 19.57%, #ffe2cd 79.66%);
  @media screen and ${devices.bp_lg} {
    background-image: url(${WaveSvg}), linear-gradient(137.52deg, #fff1e8 19.57%, #ffe2cd 79.66%);
    background-repeat: no-repeat, no-repeat;
    background-position: bottom, bottom;
    background-size: 200rem, cover;
  }
`

const ImageContainer = styled.div`
  @media screen and ${devices.bp_sm} {
    display: none;
  }
  display: flex;
  justify-content: flex-end;
  margin-right: 24px;
`

const Image = styled.img`
  width: 245px;
`

const HeadingBlock = styled.div`
  h1 {
    font-weight: 600;
    font-size: 28px;
    line-height: 32px;
  }
  margin: 0 24px 16px;
`

const DealEndsInfo = styled.div`
  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
  }
  margin: 0 24px 32px;
`

const Content = styled.div`
  max-width: 700px;
  margin: auto;

  h2:first-of-type {
    margin: 0 24px 16px;
    padding-top: 64px;
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
  }

  > h1:first-of-type {
    margin: 0 24px 16px;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
  }
  > p:first-of-type,
  > p:nth-of-type(2) {
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    margin: 0 24px 16px;
  }
  > p:nth-of-type(2) {
    margin: 0 24px 38px;
  }
  > p:nth-of-type(3) {
    margin: 0 24px 16px;
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    @media screen and ${devices.bp_sm} {
      margin-bottom: 38px;
    }
    span {
      background: ${({ theme }) => theme.colors.secondary.three};
      padding: 6px 12px;
      border-radius: 18px;
    }
  }
`

const TrustPilotWrapper = styled.div`
  height: 64px;
  overflow-y: clip;
  margin: 0 24px 44px;
`

const Block = styled.div`
  background: white;
  margin: -6px 24px 32px;
  border-radius: 8px;
  position: relative;

  h3 {
    margin: 0 32px 16px;
    padding-top: 32px;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
  }

  ul {
    margin: 0 32px 32px 14px;
    padding-bottom: 32px;
    li {
      font-weight: 500;
      font-size: 16px;
      line-height: 28px;
      margin-bottom: 16px;
    }
  }

  ${Image} {
    display: none;
    position: absolute;
    right: 0;
    bottom: -6px;
    @media screen and ${devices.bp_sm} {
      display: inline;
    }
  }
`

const SpeakSoonerSection = styled.div`
  h1 {
    margin: 0 24px 16px;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
  }
  p {
    margin: 0 24px 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    a {
      color: ${({ theme }) => theme.colors.primary.one};
    }
  }
  margin-bottom: 32px;
`

const Footer = styled.div`
  font-size: 14px;
  line-height: 24px;
  margin: 0 24px 16px;
  padding-bottom: 64px;
`

type Props = {
  friendlyId: string
  trafficLightResult: ExperienceRes
}

export const RemoERC = ({ friendlyId = "", trafficLightResult }: Props) => {
  const [firstName, setFirstName] = useState<string>("")
  const [remoDateMonth, setRemoDateMonth] = useState<string>("")
  const [remoDateYear, setRemoDateYear] = useState<string>("")
  const [validAppointmentMonth, setValidAppointmentMonth] = useState<string>("")
  const [validAppointmentYear, setValidAppointmentYear] = useState<string>("")
  const { application } = useApplicationFriendlyId(friendlyId)

  const appointmentId = trafficLightResult?.rule?.appointmentId ?? "26973384"

  useEffect(() => {
    if (!application) return
    const applicants = application?.applicants
    if (applicants.length) {
      const firstApplicant = applicants.find((applicant) => applicant.firstApplicant)
      if (firstApplicant) {
        setFirstName(firstApplicant.forename)
      }
    }
    if (application.remortgageDetails) {
      const remoDate = new Date(application?.remortgageDetails?.initialPeriodEndDate)
      remoDate.toLocaleString("default", { month: "long" })
      setRemoDateMonth(remoDate.toLocaleString("default", { month: "long" }))
      setRemoDateYear(remoDate.getFullYear().toString())
      const sixMonthsBeforeRemoDate = new Date(remoDate)
      sixMonthsBeforeRemoDate.setMonth(remoDate.getMonth() - 6)
      setValidAppointmentMonth(sixMonthsBeforeRemoDate.toLocaleString("default", { month: "long" }))
      setValidAppointmentYear(sixMonthsBeforeRemoDate.getFullYear().toString())
    }
  }, [application])

  useTrackNavigatedToPage("/application/eligibility/early-repayment-charge")

  return (
    <Page>
      <Content>
        <h2>Thanks, {firstName}!</h2>
        <HeadingBlock>
          <h1>We'll get you expert help and regular rate updates</h1>
        </HeadingBlock>

        <DealEndsInfo>
          <p>
            Your current deal ends in {remoDateMonth} {remoDateYear}. If you have Early Repayment
            Charges you can remortgage around {validAppointmentMonth} {validAppointmentYear}.
          </p>
        </DealEndsInfo>
        <GaObserver
          category={EventCategory.siteInteraction}
          action={EventAction.componentDisplay}
          label="Speak sooner link"
        >
          <SpeakSoonerSection>
            <h1>Want to speak to someone sooner?</h1>
            <p>
              If your current mortgage product doesn't have an Early Repayment Charge, or you'd like
              to discuss remortgaging now and paying the charge anyway,{" "}
              <a
                href="#"
                onClick={() => {
                  LogGaEvent({
                    event_category: EventCategory.functionalInteraction,
                    action: EventAction.buttonClicked,
                    event_label: EventLabel.bookAnAppointment,
                  })
                  window.location.href = `${window.location.origin}/results/${application.applicationId}/book?appointmentTypeId=${appointmentId}&utm_source=Online&utm_medium=Results%20Page&utm_campaign=EarlyRemoERC`
                }}
              >
                book an appointment here
              </a>
            </p>
          </SpeakSoonerSection>
        </GaObserver>
        <TrustPilotWrapper>
          <Trustpilot />
        </TrustPilotWrapper>
        <h1>Help is already on its way</h1>
        <p>We'll contact you to discuss your options closer to the time.</p>
        <p>
          Come to your appointment with any existing interest rates you’ve seen on the market and
          we'll find which is best for you.
        </p>
        <p>
          It's totally <span>free</span> and could save you £1000s.
        </p>
        <ImageContainer>
          <Image src={CoffeeSatDown} alt="person holding a coffee" />
        </ImageContainer>

        <Block>
          <h3>In the meantime you'll receive</h3>
          <ul>
            <li>Monthly personalised mortgage rates</li>
            <li>Reminders when it’s time to remortgage</li>
          </ul>
          <Image src={CoffeeSatDown} alt="person holding a coffee" />
        </Block>

        <Footer>
          <p>
            All mortgage deals are subject to eligibility and affordability criteria. Rates are not
            guaranteed and can be subject to change.
          </p>
          <p>
            *Our customers saved an average of £301 per month in April 2023 against reverting to
            their lender's standard variable rate.
          </p>
        </Footer>
      </Content>
    </Page>
  )
}
