import React from "react"

import styled from "styled-components"
import devices from "styles/devices"

import { MojoCircle } from "components/atoms"

// @ts-ignore
import Boxes from "url:assets/images/illustrations/Boxes.png"

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  padding: 64px 24px 0 24px;
  background-color: white;
  box-shadow: 0px 25px 240px rgba(0, 23, 143, 0.08);
  border-radius: 24px;
  margin: 0 20px;

  @media screen and ${devices.bp_lg} {
    margin: 0 auto;
    padding: 0 64px;
  }
`

const List = styled.ul`
  padding: 0;
  margin: 0 0 2em 0;

  @media screen and ${devices.bp_lg} {
    margin: 0;
  }
`

const ListItem = styled.li`
  display: flex;
  margin-bottom: 26px;
  line-height: 1.5;

  @media screen and ${devices.bp_lg} {
    margin-bottom: 36px;
  }
`

const ListText = styled.p`
  margin: 0;
  padding: 0 16px;
`

const BoldSpan = styled.span`
  font-weight: 550;
`

const IconWrapper = styled.div`
  width: 22px;
`

const StyledH2 = styled.h2`
  font-size: 23px;
  line-height: 31px;
  font-weight: 550;
  margin-bottom: 1em;
  margin-right: 0;
  @media screen and ${devices.bp_lg} {
    max-width: 500px;
    font-size: 28px;
    line-height: 33px;
  }
`

const Image = styled.div`
  background-image: url(${Boxes});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 100%;
  width: 100%;
`

const Grid = styled.div`
  display: grid;
  place-items: center;

  @media screen and ${devices.bp_lg} {
    padding: 4em 0;
    grid-template-columns: repeat(2, 1fr);
  }
`

const ListContent = [
  <ListText>
    See your current lender’s deal compared to over 10,000 mortgages from 90+ other lenders.
  </ListText>,
  <ListText>
    Chat about whether 2, 3, 5 or even 10-year deals are the most suitable for you in this current
    mortgage rate environment.
  </ListText>,
  <ListText>We’re rated Excellent on Trustpilot with over 1,800 reviews.</ListText>,
]

export function BenefitsBoxWithImage() {
  return (
    <Container>
      <Grid>
        <Image />

        <List>
          <StyledH2>Just a few more reasons to use Mojo...</StyledH2>
          {ListContent.map((content, idx) => (
            <ListItem key={idx}>
              <IconWrapper>
                <MojoCircle type="tick" enabled={true} />
              </IconWrapper>
              {content}
            </ListItem>
          ))}
        </List>
      </Grid>
    </Container>
  )
}
