import smartlookClient from "smartlook-client"

let isInitialised = false
const logs: ((smartlook: any) => void)[] = []

export function Init() {
  if (isInitialised) return

  smartlookClient.init("9dd660b0387cb118e4187684c2b743401e0ec8f0")

  setTimeout(() => {
    isInitialised = true

    for (const log of logs) {
      log(smartlookClient)
    }
  }, 1000)
}

export default function (event: (smartLook: any) => void) {
  if (!isInitialised) {
    logs.push((slc) => event(slc))
    return
  }

  event(smartlookClient)
}
