import React, { useEffect, useState } from "react"

// @ts-ignore
import Advisors from "url:assets/images/advisors2.png"

import styled from "styled-components"
import { Button } from "components/atoms"
import { HeaderStyle, MyMojoTemplate, PageTheme } from "templates/MyMojoTemplate"
import { ContentArea } from "templates/ContentArea"
import { Loader, LoaderTheme } from "components/atoms/Loader"
import { Address, CurrencyInput } from "components/molecules"
import { useHistory, useParams } from "react-router-dom"
import { useApplicationFriendlyId } from "hooks/useApplication"
import { Card } from "components/atoms/Card"
import { LogGaEvent } from "lib/GoogleAnalytics"
import { EventAction, EventCategory, EventLabel } from "models/GoogleAnalytics"
import { GaObserver } from "lib/GaObserver"
import { Clone } from "lib/Clone"
import { UpdateApplication } from "lib/Applications"
import { FullApplication, PropertyAddress } from "models/FullApplication"
import devices from "styles/devices"
import { useExperienceTLS } from "hooks/useExperienceTLS"
import { useTrackNavigatedToPage } from "hooks/useTrackPageView"
import { normalisePostcode } from "lib/postcodes"

const LoaderWrapper = styled.div<{ show: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`

const ButtonContainer = styled.div`
  width: 100%;
`

const ImageContainer = styled.div`
  align-self: start;
`

const ItemContainer = styled.div`
  width: 100%;
`

const Title = styled.div`
  font-size: 16px;
  font-weight: 550;
  margin-top: 35px;
  margin-bottom: 10px;
`

const MainTitle = styled.p`
  height: 105px;
  font-size: 26px;
  font-weight: 550;
  margin-top: 16px;
  padding-bottom: 24px;
  border-bottom: 2px solid #dadee8;
  display: block;
`

const Wrapper = styled.div`
  min-width: 380px;
  min-height: 500px;
  padding: 32px;

  @media ${devices.bp_md} {
    padding: 64px;
  }
`

const MaxValue = styled.div`
  font-size: 10px;
  font-weight: 600;
  font-style: italic;
  margin-bottom: 10px;
`

function IsAddressSet(address: PropertyAddress): boolean {
  if (!address) {
    return false
  }

  const { postcode, nameNumber, street, city } = address
  return !!postcode && !!nameNumber && !!street && !!city
}

export const NurturePropertyDetails = (value) => {
  useTrackNavigatedToPage("/application/found-a-property")

  const { friendlyId } = useParams<{ friendlyId: string }>()
  const { application, hasLoaded } = useApplicationFriendlyId(friendlyId)
  const [amount, setAmount] = useState(value?.amount || "")
  const [address, setAddress] = useState(null)
  const [isUpdating, setIsUpdating] = useState(false)
  const { experienceTLS, hasFetchedExperienceTLS } = useExperienceTLS(friendlyId, true)
  const canContinue = IsAddressSet(address) && amount

  // The user gets here by going through the Pre-Offer journey, but at the results
  // page, selecting that they've actually Found a Property. Their TLS rule will
  // still be for their pre-offer state, and won't let them book an appointment,
  // so we manually override it here. Long term, we'll want to run them through TLS
  // again, but this will do for now.
  const appointmentTypeId = 10101634 // Initial Mortgage Appointment - Core

  useEffect(() => {
    if (!amount) return

    LogGaEvent({
      action: EventAction.inputComplete,
      event_category: EventCategory.formInteraction,
      event_label: EventLabel.foundPropertyValue,
    })
  }, [amount])

  useEffect(() => {
    if (!IsAddressSet(address)) return

    LogGaEvent({
      action: EventAction.inputComplete,
      event_category: EventCategory.formInteraction,
      event_label: EventLabel.propertyAddress,
    })
  }, [address])

  const ChangeApplication = async () => {
    const copy: FullApplication = Clone(application)

    if (amount) {
      copy.basicPropertyDetails.propertyValue = amount
    }

    if (address) {
      Object.assign(copy, {
        completePropertyDetails: {
          propertyAddress: { ...address, postcode: normalisePostcode(address.postcode) },
        },
      })
    }

    await UpdateApplication(copy)
  }

  const history = useHistory()

  const edeMax = experienceTLS?.affordability?.maxPropertyValue ?? 10000000

  return (
    <MyMojoTemplate
      stickyHeader={false}
      headerStyle={HeaderStyle.Transparent}
      backgroundWave={true}
      colourTheme={PageTheme.Alternate}
      content={
        <ContentArea maxWidth={800}>
          {!hasLoaded || !hasFetchedExperienceTLS ? (
            <LoaderWrapper show={true}>
              <Loader size={50} theme={LoaderTheme.Outline} />
            </LoaderWrapper>
          ) : (
            <>
              <Card
                content={
                  <GaObserver
                    category={EventCategory.siteInteraction}
                    action={EventAction.componentDisplay}
                    label="Offer Accepted Property Details"
                  >
                    <Wrapper>
                      <ImageContainer>
                        <img src={Advisors} alt="The advisors" />
                      </ImageContainer>
                      <MainTitle>
                        Nice, let's get you booked in to make the mortgage happen!
                      </MainTitle>
                      <ItemContainer>
                        <Title>Accepted offer amount</Title>
                        {edeMax && edeMax < 10000000 ? (
                          <MaxValue>
                            Based on your information the maximum value is £
                            {edeMax.toLocaleString()}.
                          </MaxValue>
                        ) : (
                          ""
                        )}
                        <CurrencyInput
                          id="currency-input"
                          value={amount}
                          min={0}
                          max={edeMax}
                          placeholder={"£0"}
                          onDone={(value) => setAmount(value)}
                        />
                        <Title>What's the address?</Title>
                        <Address
                          id="property-address"
                          value={address}
                          onDone={(address, isValid, errors) => {
                            setAddress(address)
                          }}
                        />
                      </ItemContainer>
                      <ButtonContainer>
                        <Button
                          disabled={!canContinue}
                          text="Continue"
                          showLoader={isUpdating}
                          onClick={async () => {
                            setIsUpdating(true)
                            await ChangeApplication()
                            LogGaEvent({
                              action: EventAction.buttonClicked,
                              event_category: EventCategory.functionalInteraction,
                              event_label: EventLabel.continue,
                            })
                            history.push(
                              `/book/${application.applicationId}?appointmentTypeId=${appointmentTypeId}&phoneNumber=${application?.applicants?.[0]?.telephone}`
                            )
                          }}
                        />
                      </ButtonContainer>
                    </Wrapper>
                  </GaObserver>
                }
              />
            </>
          )}
        </ContentArea>
      }
    />
  )
}
