import React, { useEffect, useMemo, useState } from "react"
import styled from "styled-components"
import { DateDiff, Subtract } from "lib/Date"
import { Address, DateInput } from "components/molecules/index"
import { Button } from "components/atoms"
import { ButtonType } from "components/atoms/Button"
import { DateFlag, DateFormat } from "./DateInput"
import { ScrollTo } from "lib/ScrollTo"
import { ScrollAnchor } from "components/atoms/ScrollAnchor"
import { defaultTheme } from "styles/DefaultTheme"

const Layout = styled.div`
  display: grid;
  grid-gap: 45px;
`

const PreviousAddressesHeader = styled.div`
  font-size: 1.2em;
  font-weight: bold;
  margin: 45px 0 -15px 0;
`
const AddressErrorLabel = styled.div`
  color: ${defaultTheme.error};
  font-weight: 500;
`

type Props = {
  id: string
  value: AddressValue[]
  onDone: (value: AddressValue[], hasThreeYears: boolean) => void
  onPostcodeLookup?: (addresses: []) => void
  onAddressSelected?: () => void
}

export type AddressValue = {
  address?: {
    postcode?: string
    nameNumber?: string
    street?: string
    city?: string
  }
  moveInDate?: Date
  index?: number
}

export default function AddressHistory({
  value,
  onDone,
  id = "",
  onPostcodeLookup,
  onAddressSelected,
}: Props) {
  const [addressCount, setAddressCount] = useState<number>(value?.length || 0)
  const [addresses, setAddresses] = useState<AddressValue[]>(value || [])
  const [newAddressInput, setNewAddressInput] = useState<AddressValue>()
  const [previousAddressError, setPreviousAddressError] = useState("")

  const addressErrorTxt = "Same address entered twice, please enter correct address"
  const moveInDateErrorTxt = "Same move in date entered twice, please re-enter correct details"

  useEffect(() => {
    if (addresses.length !== addressCount) {
      ScrollTo("address_scrollAnchor")
    }

    setAddressCount(addresses.length)
  }, [addresses])

  const hasThreeYears = useMemo(() => {
    const totalAddressHistory = addresses
      .map((x) => x.moveInDate)
      .reduce((acc, curr) => DateDiff(curr, new Date(), "month"), 0)

    return totalAddressHistory >= 36
  }, [addresses])

  const hasPreviousValue = useMemo(() => {
    if (addresses.length < 1) return false

    let address: AddressValue

    if (addresses.length === 1) {
      address = addresses[0]
    } else {
      address = addresses[addresses.length - 2]
    }

    if (!address.moveInDate) {
      return false
    }

    return true
  }, [addresses, hasThreeYears])

  useEffect(() => {
    const mappedAddresses = addresses.map((addr) => {
      return {
        ...addr,
      }
    })

    onDone(mappedAddresses, hasThreeYears)
  }, [hasThreeYears])

  function setAddressItem(addressHistoryItem: AddressValue) {
    const copy = [...addresses]
    const index = addresses.findIndex((x) => x.index === addressHistoryItem.index)

    copy[index].address = addressHistoryItem.address
    copy[index].moveInDate = addressHistoryItem.moveInDate

    setAddresses(copy)
  }

  function addNewAddressItem(addressHistoryItem) {
    if (addresses.length >= 1) {
      const lastAddressObject = addresses[addresses.length - 1]
      if (
        Object.entries(lastAddressObject.address).toString() ===
        Object.entries(addressHistoryItem.address).toString()
      ) {
        setAddresses([])
        setPreviousAddressError(addressErrorTxt)
        return
      } else if (
        lastAddressObject.moveInDate.toString() === addressHistoryItem.moveInDate.toString()
      ) {
        setAddresses([])
        setPreviousAddressError(moveInDateErrorTxt)
        return
      }
    }
    addresses.push({ ...addressHistoryItem, index: addresses.length })
    setAddresses([...addresses])
    setNewAddressInput({})
  }

  return (
    <Layout id="addressGrid">
      {previousAddressError.length > 0 && (
        <AddressErrorLabel>{previousAddressError}</AddressErrorLabel>
      )}
      {addresses.map((address, i) => (
        <div key={`${id}-${i + 1}`}>
          <AddressHistoryItem
            id={`${id}-${i + 1}`}
            key={`${id}-${i + 1}`}
            index={i}
            value={address}
            maxMoveInDate={address[i - 1]?.moveInDate ?? Subtract(new Date(), 1, "month")}
            onSave={(historyItem) => setAddressItem(historyItem)}
            onAddressSelected={() => onAddressSelected()}
            onPostcodeLookup={(addresses) => onPostcodeLookup(addresses)}
          />
          {!hasThreeYears && (addresses.length < 1 || hasPreviousValue) && i === 0 && (
            <PreviousAddressesHeader>Previous addresses</PreviousAddressesHeader>
          )}
        </div>
      ))}

      {!hasThreeYears && (addresses.length < 1 || hasPreviousValue) && (
        <AddressHistoryItem
          id={`${id}-${addresses.length + 1}`}
          key={`${addresses.length}-add-new`}
          index={"add-new"}
          value={newAddressInput}
          onSave={(historyItem) => addNewAddressItem(historyItem)}
          maxMoveInDate={
            addresses[addresses.length - 1]?.moveInDate ?? Subtract(new Date(), 1, "month")
          }
          onAddressSelected={() => onAddressSelected()}
          onPostcodeLookup={(addresses) => onPostcodeLookup(addresses)}
        />
      )}
    </Layout>
  )
}

const HistoryLayout = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
  display: grid;
  grid-gap: 8px;
`

function AddressHistoryItem({
  index,
  onSave,
  value,
  maxMoveInDate,
  id = "",
  onPostcodeLookup,
  onAddressSelected,
}) {
  const [address, setAddress] = useState(value?.address || {})
  const [addressValid, setAddressValid] = useState(false)
  const [moveInDate, setMoveInDate] = useState(value?.moveInDate || "")
  const [canSave, setCanSave] = useState(address && moveInDate)
  const [addressErrors, setAddressErrors] = useState([])
  const [dateErrors, setDateErrors] = useState(null)

  useEffect(() => {
    if (maxMoveInDate && DateDiff(moveInDate, maxMoveInDate, "month") < 0) {
      setCanSave(false)
      return
    }

    if (addressErrors?.length > 0 || dateErrors) {
      setCanSave(false)
      return
    }

    if (addressValid && moveInDate) {
      setCanSave(true)
      return
    }

    setCanSave(false)
  }, [address, moveInDate, addressValid])

  function handleUpdate(address, isValid, errors) {
    setAddressErrors(errors)
    setAddress(address)
    setAddressValid(isValid)
  }

  return (
    <div>
      <ScrollAnchor offset={160} className="address_scrollAnchor" />
      <Address
        id={id}
        value={address}
        onDone={handleUpdate}
        isOpen={!!address?.postcode ?? false}
        onAddressSelected={() => onAddressSelected()}
        onPostcodeLookup={(addresses) => onPostcodeLookup(addresses)}
      />
      <HistoryLayout>
        <label>When did you move in?</label>
        <DateInput
          id={"date-move-in-" + id}
          format={DateFormat.Short}
          dateFlags={[DateFlag.PastDate]}
          value={moveInDate}
          onDone={(date, errors) => {
            setDateErrors(errors)
            setMoveInDate(date)
          }}
        />
        <>
          {dateErrors?.length > 0 && <p>{dateErrors}</p>}
          {addressErrors?.length > 0 && <p>{[...addressErrors]}</p>}
        </>
        <Button
          id={`button-save-address-${id}`}
          disabled={!canSave}
          type={ButtonType.Solid}
          onClick={() => {
            ScrollTo()
            onSave({
              address,
              moveInDate,
              moveOutDate: maxMoveInDate,
              index,
            })
          }}
          text="Save"
        />
      </HistoryLayout>
    </div>
  )
}
