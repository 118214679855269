import React from "react"
import styled from "styled-components"
import devices from "styles/devices"
// @ts-ignore
import WaveSvg from "url:assets/images/background-wave-2.svg"
// @ts-ignore
import CoffeeSatDown from "url:assets/images/illustrations/Coffee sat down.png"

import { useConfig } from "hooks/useConfig"
import { useHistory } from "react-router-dom"

const Root = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 30px;
`

const Logo = styled.img`
  margin: -15px 0 20px 5px;
  width: 110px;
`

const Container = styled.div`
  margin: 0 -1.875em -1.875em;
  background: linear-gradient(137.52deg, #fff1e8 19.57%, #ffe2cd 79.66%);
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 24px;
  min-height: calc(100vh - 73px);
  align-items: center;
  @media screen and ${devices.bp_lg} {
    background-image: url(${WaveSvg}), linear-gradient(137.52deg, #fff1e8 19.57%, #ffe2cd 79.66%);
    background-repeat: no-repeat, no-repeat;
    background-position: bottom, bottom;
    background-size: 500em, cover;
  }
`

const ContentBox = styled.div`
  max-width: 670px;
  margin: 0 auto 200px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  padding: 64px 24px 0 24px;
  background-color: white;
  border-radius: 24px;
  @media screen and ${devices.bp_lg} {
    padding: 64px 64px 0px 64px;
  }
`

const Image = styled.img`
  max-width: 210px;

  position: relative;
  top: 10px;
  margin-top: 100px;

  @media screen and ${devices.bp_lg} {
    padding: 64px 64px 0px 64px;
    max-width: 470px;
    margin-top: 0;
  }
`

interface Props {
  children: React.ReactNode
}

export const InfoPage = ({ children }: Props) => {
  const history = useHistory()
  const { theme } = useConfig()

  return (
    <Root>
      <a onClick={() => history.push("/dashboard")}>
        <Logo src={theme.mainLogoURL} alt="logo" />
      </a>

      <Container>
        <Image src={CoffeeSatDown} alt="person holding a coffee" />
        <ContentBox>{children}</ContentBox>
      </Container>
    </Root>
  )
}
