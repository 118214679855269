import React, { useEffect } from "react"
import { useEligibility } from "hooks/useEligibility/useEligibility"
import { ConfidenceFooter } from "../shared/ConfidenceFooter"
import { TopNav } from "components/molecules/TopNav"
import BassGuy from "../../../../assets/images/illustrations/Guitar.png"
import { GreatWave } from "../shared/WaveBackground"
import {
  ContentGrid,
  FooterBackground,
  LayoutGrid,
  desktop,
  mobile,
  tablet,
} from "components/_blueprint/Layout"
import { useTrackNavigatedToPage } from "hooks/useTrackPageView"
import { NoAvailabilityCopy } from "./NoAvailabilityCopy"
import { ExperienceRes } from "models/ExperienceTLS"
import styled from "styled-components"

type Props = {
  trafficLightResult: ExperienceRes
}

export const NoAvailability = ({ trafficLightResult }: Props) => {
  const {
    logoState: [_, setShowLogo],
  } = useEligibility()

  useEffect(() => {
    setShowLogo(false)
  }, [])

  useTrackNavigatedToPage("/application/eligibility/no-availability")

  const mip = trafficLightResult?.mip

  return (
    <GreatWave>
      <TopNav />
      <LayoutGrid>
        <ContentGrid>
          <NoAvailabilityCopy mip={mip} />
          <Illustration alt="" src={BassGuy} bottomOffset={!mip} />
        </ContentGrid>
        <FooterBackground />
        <ConfidenceFooter mortgageType="mortgage" />
      </LayoutGrid>
    </GreatWave>
  )
}

const Illustration = styled.img<{ bottomOffset: boolean }>(({ bottomOffset }) => ({
  alignSelf: "center",
  marginLeft: -110,
  pointerEvents: "none",
  width: 500,
  gridColumnStart: 9,
  gridColumnEnd: "span 4",
  marginBottom: 0,
  [mobile]: {
    display: "none",
  },
  [tablet]: {
    display: "none",
  },
  [desktop]: {
    gridColumnEnd: "span 4",
    marginBottom: bottomOffset ? -179 : 0,
  },
}))
