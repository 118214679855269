import { useEffect } from "react"
import { ExperienceRes } from "models/ExperienceTLS"
import { useApplicationFriendlyId } from "hooks/useApplication"
import { axiosInstance } from "lib/Api"
import useSWR from "swr"
import { EventAction, EventCategory } from "models/GoogleAnalytics"
import { LogGaEvent, SetGaDimensions } from "lib/GoogleAnalytics"

/**
 * Fetches and returns TLS data
 */
export const getExperienceTLS = (applicationId: string) => {
  return fetcher(`${process.env.MY_MOJO_API}/experience/${applicationId}`)
}

/**
 * Fetch the TLS result for an application
 */
export const useExperienceTLS = (
  /**
   * The id for the application, usually from the URL
   */
  friendlyId: string,
  /**
   * Whether the cached result should be invalidated and refetched
   * @default false
   */
  invalidateRedis = false,
  /**
   * Callback when TLS data has been fetched
   */
  onSuccess?: () => void
) => {
  const { application, hasLoaded: applicationHasLoaded } = useApplicationFriendlyId(friendlyId)

  const { data, error, isLoading, mutate } = useSWR<ExperienceRes, Error>(
    () =>
      applicationHasLoaded && application.applicationId
        ? `${process.env.MY_MOJO_API}/experience/${application.applicationId}?isUpdate=${invalidateRedis}&withSwr=true`
        : null,
    fetcher,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  )

  /**
   * Invalidate cache and refetch experience
   */
  const refresh = () => {
    mutate()
  }

  useEffect(() => {
    const experienceTLS = data
    if (experienceTLS) {
      SetGaDimensions({
        eligibility_tier: `${experienceTLS.eligibility?.tier}`,
        traffic_light_definition: `${experienceTLS.rule.color} ${experienceTLS.rule.tier}`,
      })
      LogGaEvent({
        action: EventAction.experienceTLS,
        event_category: EventCategory.siteInteraction,
        event_label: `${experienceTLS.rule.color}-${experienceTLS.rule.tier}`,
      })

      onSuccess?.()
    }
  }, [data])

  return {
    experienceTLS: data,
    hasFetchedExperienceTLS: !isLoading && applicationHasLoaded,
    // only false if both queries aren't loading
    isLoading: isLoading || !applicationHasLoaded,
    isError: error,
    refresh,
  }
}

const fetcher = (url): Promise<ExperienceRes> =>
  axiosInstance
    .get(url, {
      withCredentials: true,
    })
    .then((res) => {
      const experienceTLS = res.data

      return experienceTLS
    })
