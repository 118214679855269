import {
  Flag,
  FullApplication,
  Occupancy,
  Persona,
  PurchaseType,
  RemortgageReason,
} from "models/FullApplication"
import { ApplicationReason, ApplicationSummary } from "models/ApplicationSummary"
import { Cohort } from "models/Cohort"
import { DateDiff } from "lib/Date"
import { MY_MOJO_API } from "../Config"
import { AnalyticsData } from "lib/Analytics"
import { axiosInstance } from "lib/Api"

export async function GetAllApplications(invalidateCache = false): Promise<ApplicationSummary[]> {
  const response = await axiosInstance.get(`${MY_MOJO_API}/applications`, {
    headers: {
      "Cache-Control": invalidateCache ? "no-store, max-age=0" : null,
    },
    withCredentials: true,
  })

  return response.data
}

export async function GetApplication(
  applicationId: string,
  invalidateCache = false
): Promise<FullApplication> {
  const response = await axiosInstance.get(`${MY_MOJO_API}/applications/${applicationId}`, {
    headers: {
      "Cache-Control": invalidateCache ? "no-store, max-age=0" : null,
    },
    withCredentials: true,
  })

  return response.data
}

export async function CreateApplication(
  application: FullApplication,
  analyticsData: AnalyticsData,
  gaId: string = null
): Promise<FullApplication> {
  const response = await axiosInstance.post(
    `${MY_MOJO_API}/applications`,
    {
      application: application,
      analytics: analyticsData,
      gaId,
    },
    {
      headers: {
      },
      withCredentials: true,
    }
  )

  return response.data
}

export async function UpdateApplication(application: FullApplication): Promise<FullApplication> {
  const response = await axiosInstance.put(
    `${MY_MOJO_API}/applications/${application.applicationId}`,
    {
      application: application,
    },
    {
      headers: {
      },
      withCredentials: true,
    }
  )

  return response.data
}

export async function SetApplicationFlags(
  application: FullApplication,
  flags: Flag[]
): Promise<FullApplication> {
  const result = await axiosInstance.put(
    `${MY_MOJO_API}/applications/${application.applicationId}/flags?flags=${flags.join(",")}`,
    null,
    {
      headers: {
      },
      withCredentials: true,
    }
  )

  return result.data as FullApplication
}

export async function PushNewApplicationFlag(
  application: FullApplication,
  flag: Flag
): Promise<FullApplication> {
  const flagsToSet = []
  const existingFlags = application?.metadata?.flags ?? []

  if (existingFlags.includes(flag)) {
    return application
  }

  if (existingFlags?.length > 0) {
    flagsToSet.push(existingFlags)
  }

  flagsToSet.push(flag)

  const result = await axiosInstance.put(
    `${MY_MOJO_API}/applications/${application.applicationId}/flags?flags=${flagsToSet.join(",")}`,
    null,
    {
      headers: {
      },
      withCredentials: true,
    }
  )

  return result.data as FullApplication
}

export function HasApplicationExpired(application: ApplicationSummary): boolean {
  return DateDiff(application.createdOn, new Date(), "month") > 6
}

export function GetApplicationCohorts(application: FullApplication): Cohort[] {
  if (!application) {
    return []
  }

  const cohorts: Cohort[] = []

  if (
    application.borrowingDetails?.firstTimeBuyer ||
    application?.purchaseType === PurchaseType.FirstTimeBuyer
  ) {
    addUnique(Cohort.FirstTimeBuyer)
  }

  if (application.applicationReason === ApplicationReason.LookingForProperty) {
    addUnique(Cohort.NotFoundAProperty)
    addUnique(Cohort.Purchase)
    addUnique(Cohort.PreOffer)
  }

  if (application.applicationReason === ApplicationReason.FoundProperty) {
    addUnique(Cohort.FoundAProperty)
    addUnique(Cohort.Purchase)
  }

  if (application.persona === Persona.OfferAccepted) {
    addUnique(Cohort.PostOffer)
  }

  if (application.persona === Persona.FoundProperty) {
    addUnique(Cohort.FoundAProperty)
    addUnique(Cohort.PreOffer)
  }

  if ([Persona.StartedLooking, Persona.ActivelyLooking].includes(application.persona)) {
    addUnique(Cohort.NotFoundAProperty)
    addUnique(Cohort.PreOffer)
  }

  if (application?.purchaseType === PurchaseType.HomeMove) {
    addUnique(Cohort.MovingHome)
    addUnique(Cohort.Purchase)
  }

  if (application?.purchaseType === PurchaseType.SecondHome) {
    addUnique(Cohort.SecondHome)
    addUnique(Cohort.Purchase)
  }

  if (application.applicationReason === ApplicationReason.Remortgage) {
    addUnique(Cohort.Remortgage)

    if (DaysRemainingInErc(application) > 1) {
      addUnique(Cohort.StuckInERC)
    }

    switch (application.remortgageReason) {
      case RemortgageReason.Additional_borrowing:
        addUnique(Cohort.AdditionalBorrowing)
        break
      case RemortgageReason.Release_money:
        addUnique(Cohort.ReleaseMoney)
        break
      default:
        addUnique(Cohort.LikeForLike)
        break
    }
  }

  if (application?.basicPropertyDetails?.occupancy === Occupancy.RentItOut) {
    addUnique(Cohort.BuyToLet)
  }

  return cohorts

  function addUnique(cohort: Cohort) {
    if (cohorts.indexOf(cohort) < 0) {
      cohorts.push(cohort)
    }
  }
}

export async function ExpireApplication(application: ApplicationSummary): Promise<void> {
  await UpdateApplication({
    ...application,
    isDeleted: true,
  })
}

export function IsRemo(application: FullApplication): boolean {
  const cohorts = GetApplicationCohorts(application)
  return cohorts.includes(Cohort.Remortgage)
}

export function IsPreOffer(application: FullApplication): boolean {
  const cohorts: Cohort[] = GetApplicationCohorts(application)
  return !!cohorts.includes(Cohort.PreOffer)
}

export function IsERC(application: FullApplication) {
  const ercDate = application?.remortgageDetails?.initialPeriodEndDate
  const months = DateDiff(new Date(), ercDate, "month")

  return months > 6
}

export function DaysRemainingInErc(application: FullApplication): number {
  if (!application?.remortgageDetails?.initialPeriodEndDate) {
    return null
  }

  return DateDiff(new Date(), application.remortgageDetails?.initialPeriodEndDate, "day")
}

export function NeedsMip(application: FullApplication): boolean {
  if (application.applicationReason === ApplicationReason.Remortgage) {
    return false
  }

  const cohorts: Cohort[] = GetApplicationCohorts(application)

  if (cohorts.includes(Cohort.PreOffer)) {
    return true
  }

  return false
}

export function HasCompletedFactFind(application: FullApplication): boolean {
  return application?.metadata?.flags?.includes(Flag.CompletedFactFindPreferences) ?? false
}

export function HasEligibilityResultApproved(application: FullApplication): boolean {
  const resultFlags = [Flag.EligibilityResultApproved]
  return application?.metadata?.flags?.some((x) => resultFlags.includes(x))
}

export function GetApplicationTypeString(application: FullApplication): string {
  const cohorts = GetApplicationCohorts(application)

  if (cohorts.includes(Cohort.FirstTimeBuyer)) return "First time buyer application"

  if (cohorts.includes(Cohort.Purchase)) return "Purchase application"
  if (cohorts.includes(Cohort.Remortgage)) return "Remortgage application"
}

export const GetLeadApplicant = (application: FullApplication) => {
  return application.applicants.find((applicant) => applicant.firstApplicant)
}
