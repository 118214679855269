import React from "react"
import styled from "styled-components"
import { Heading, Paragraph } from "../../../../components/_blueprint/Typography"
import { Icon } from "../../../../components/atoms"
import { IconType } from "../../../../components/atoms/Icon"
import { ContentContainer, Gap, space } from "components/_blueprint/Layout"
import { useHistory, useParams, useRouteMatch } from "react-router-dom"
import { resultPageToPath } from "../Eligibility"
import { useExperienceTLS } from "hooks/useExperienceTLS"
import { useApplicationFriendlyId } from "hooks/useApplication"
import { GenerateTableUrl } from "lib/MojoUrlObject"

export const PhoneCheckCopy = () => {
  const match = useRouteMatch()
  const { friendlyId } = useParams<{ friendlyId: string }>()
  const { experienceTLS } = useExperienceTLS(friendlyId)
  const { application } = useApplicationFriendlyId(friendlyId)
  const nextHref = match.url.replace("/review-number", resultPageToPath[experienceTLS.resultPage])
  const nextPromise: Promise<string> = new Promise((res) => res(nextHref))
  const comparisonTableURL = GenerateTableUrl(application)

  return (
    <ContentContainer>
      <Paragraph weight={600} size="large">
        {text.intro}
      </Paragraph>
      <Heading size="h1">{text.headline}</Heading>
      <Paragraph weight={600}>{text.text1}</Paragraph>
      <Paragraph>{text.text2}</Paragraph>
      <Paragraph>{text.text3}</Paragraph>
      <CTA href={nextPromise} recommended title={text.cta1Title} text={text.cta1Text} />
      <Gap x={0.5} />
      <CTA href={comparisonTableURL} title={text.cta2Title} text={text.cta2Text} />
    </ContentContainer>
  )
}

type CTAProps = {
  href: Promise<string>
  title: string
  text: string
  recommended?: boolean
}

const CTA: React.FC<CTAProps> = ({ href, title, text, recommended = false }) => {
  const history = useHistory()
  const redirect = (href: string) =>
    href.startsWith("/") ? history.push(href) : window.location.assign(href)
  return (
    <Pill onClick={() => href.then(redirect)}>
      {recommended && <Badge>Recommended</Badge>}
      <Row>
        <Heading as="h2" size="h6" margin={0}>
          {title}
        </Heading>
        <Icon type={IconType.ArrowRight} />
      </Row>
      <Paragraph margin={0}>{text}</Paragraph>
    </Pill>
  )
}

const ochre = "#FBBB5B"

const Pill = styled.button((props) => ({
  backgroundColor: props.theme.colors.white,
  borderRadius: space,
  border: "none",
  cursor: "pointer",
  padding: 32,
  textAlign: "left",
  ["&:focus"]: {
    boxShadow: `0 0 0 2px ${ochre}`,
  },
}))

const Badge = styled.span({
  backgroundColor: ochre,
  borderRadius: 1000,
  display: "inline-block",
  fontWeight: 600,
  marginBottom: space,
  padding: `${0.5 * space}px ${space}px`,
})

const Row = styled.div({
  alignItems: "center",
  display: "flex",
  justifyContent: "space-between",
  marginBottom: space,
})

const text = {
  intro: "Hello... is it Mojo you’re looking for?",
  headline: "We don’t recognise your phone number",
  text1: "Why are we asking for your number?",
  text2:
    "Well, we believe that real people, giving real advice is the best, quickest way to finding the right mortgage for you.",
  text3:
    "Now, we could give you a generic rates table, but we like to get to know you so we can personalise your options and recommend a deal you can have confidence in getting.",
  cta1Title: "Get a free mortgage recommendation from an expert",
  cta1Text:
    "Update your number and get advice you can trust, in making a confident choice in an increasingly confusing market. We could help you save £1,000s",
  cta2Title: "Compare live mortgage deals",
  cta2Text:
    "If you’re not ready to speak to a Mortgage Expert, and just want some rough figures, you can view live mortgage deals online",
}
