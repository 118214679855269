import React, { useEffect } from "react"
import { Loader, LoaderTheme, LoaderWrapper } from "components/atoms/Loader"
import { redirectToDiscovery } from "lib/discovery"
import { useTrackNavigatedToPage } from "hooks/useTrackPageView"

// For now (2024-05-15) in case there are any extant links to /new-application
// keep this component, but just redirect to discovery.
export default () => {
  useTrackNavigatedToPage("/new-application")

  useEffect(() => {
    redirectToDiscovery()
  }, [])

  return (
    <LoaderWrapper>
      <Loader theme={LoaderTheme.Outline} size={80} />
    </LoaderWrapper>
  )
}
