import React from "react"
import { Heading, Paragraph } from "../../../../components/_blueprint/Typography"
import { Button, ButtonType } from "../../../../components/atoms"
import { ContentContainer, Gap, desktop, space, tablet } from "components/_blueprint/Layout"
import { useParams } from "react-router-dom"
import { useApplicationFriendlyId } from "hooks/useApplication"
import { GenerateTableUrl } from "lib/MojoUrlObject"
import { LogGaEvent } from "lib/GoogleAnalytics"
import { EventAction, EventCategory, EventLabel } from "models/GoogleAnalytics"
import Squiggle from "../../../../assets/images/illustrations/Squiggle.png"
import styled from "styled-components"
import devices from "styles/devices"

type Props = {
  mip: string
}

export const NoAvailabilityCopy = ({ mip }: Props) => {
  const { friendlyId } = useParams<{ friendlyId: string }>()
  const { application } = useApplicationFriendlyId(friendlyId)

  const handleClick = async () => {
    LogGaEvent({
      action: EventAction.buttonClicked,
      event_category: EventCategory.functionalInteraction,
      event_label: EventLabel.viewDeals,
    })
    const comparisonTableURL = await GenerateTableUrl(application)
    window.location.assign(comparisonTableURL)
  }

  return (
    <ContentContainer>
      <Heading size="h1">{text.headline}</Heading>
      <Heading size="h2">{mip ? text.text1Purchase : text.text1Remo}</Heading>
      <Paragraph>{text.text2}</Paragraph>
      <Paragraph>{text.text3}</Paragraph>
      <Paragraph>{text.text4}</Paragraph>
      <ButtonContainer>
        <Button onClick={handleClick} text="View mortgage deals" />
      </ButtonContainer>
      {mip && (
        <>
          <Gap x={4} />
          <MipContainer>
            <SquiggleImage src={Squiggle} alt="Squiggle" aria-hidden="true" />
            <Heading size="h2">{mipText.headline}</Heading>
            <Paragraph>{mipText.text1}</Paragraph>
            <Paragraph>{mipText.text2}</Paragraph>
            <ButtonContainer>
              <Button
                type={ButtonType.Outline}
                onClick={() => {
                  LogGaEvent({
                    action: EventAction.buttonClicked,
                    event_category: EventCategory.functionalInteraction,
                    event_label: EventLabel.downloadMip,
                  })
                  window.open(mip, "_blank")
                }}
                text={"Download MIP"}
              />
            </ButtonContainer>
          </MipContainer>
        </>
      )}
    </ContentContainer>
  )
}

const text = {
  headline: "Our experts are all booked up",
  text1Purchase: "For now, view our top deals and get your Mortgage in Principle",
  text1Remo: "View our top deals for now",
  text2: "Looks like our appointment slots are full at the moment.",
  text3: "In today’s market, we know how important it is to get the expert guidance you need.",
  text4:
    "So we’re working hard to get everyone an appointment. We’ll email you as soon as one becomes available. ",
}

const mipText = {
  headline: "Download your Mortgage in Principle",
  text1:
    "A Mortgage in Principle (MIP) is a document that gives you an indication of how much a lender might be willing to lend you.",
  text2:
    "It can help you set a realistic house budget, and estate agents will see a MIP as a sign that you’re a serious buyer.",
}

const ButtonContainer = styled.div({
  width: "100%",
  [`@media ${devices.bp_sm}`]: {
    width: "fit-content",
  },
})

const MipContainer = styled.div((props) => ({
  backgroundColor: props.theme.colors.white,
  borderRadius: space,
  border: "none",
  padding: 32,
  textAlign: "left",
  width: "100%",
  position: "relative",
  overflow: "hidden",
}))

const SquiggleImage = styled.img({
  position: "absolute",
  top: -30,
  right: 20,
  width: "100%",
  [tablet]: {
    top: -60,
    right: 50,
  },
  [desktop]: {
    top: -70,
    left: -50,
  },
})
