import React, { useEffect, useState } from "react"
import { Input } from "./Input"
import styled from "styled-components"
import { darken } from "polished"
import useDidMount from "hooks/useDidMount"

const Container = styled.div`
  font-family: Arial, sans-serif;
  position: relative;

  div {
    border-radius: 8px;
    border: 1px solid black !important;
    padding: 5px 5px !important;
  }
`

const PasswordMaskToggle = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  background-color: #f6f6f6;
  color: #97989e;
  height: 100%;
  width: 22%;
  text-align: center;
  padding-top: 18px;
  border: 1px solid black;
  border-left: none;
  border-radius: 0 8px 8px 0;
  cursor: pointer;
  user-select: none;

  &:hover {
    background-color: ${darken(0.03, "#f6f6f6")};
  }
`

type Props = {
  value?: string
  placeholder?: string
  onBlur?: () => void
  onFocus?: () => void
  onChange?: (value: string) => void
  onLoaded?: () => void
  id?: string
}

export function PasswordInput({
  value,
  placeholder,
  onBlur = () => {},
  onFocus = () => {},
  onChange = (value) => {},
  onLoaded = () => {},
  id = "",
}: Props) {
  const [isMasked, setIsMasked] = useState(true)
  const didMount = useDidMount()

  useEffect(() => {
    !didMount && onLoaded()
  }, [])

  return (
    <Container>
      <Input
        id={id}
        value={value}
        type={isMasked ? "password" : "text"}
        onBlur={onBlur}
        onFocus={onFocus}
        placeholder={placeholder}
        onChange={(e) => onChange(e.target.value)}
      />
      <PasswordMaskToggle onClick={() => setIsMasked(!isMasked)}>
        {isMasked ? "Show" : "Hide"}
      </PasswordMaskToggle>
    </Container>
  )
}
