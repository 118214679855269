import { INTERCOM_APP_ID } from "../Config"

export const initIntercom = () => {
  ;(function (...xs) {
    const w = window
    const ic = w.Intercom
    if (typeof ic === "function") {
      // @ts-ignore
      ic("reattach_activator")
      ic("update", w.intercomSettings)
    } else {
      const d = document
      const i = function () {
        i.c(xs)
      }
      i.q = []
      i.c = function (args) {
        i.q.push(args)
      }
      w.Intercom = i
      const l = function () {
        const s = d.createElement("script")
        s.type = "text/javascript"
        s.async = true
        s.src = "https://widget.intercom.io/widget/" + INTERCOM_APP_ID
        const x = d.getElementsByTagName("script")[0]
        x.parentNode.insertBefore(s, x)
      }
      if (document.readyState === "complete") {
        l()
      } else if (w.attachEvent) {
        w.attachEvent("onload", l)
      } else {
        w.addEventListener("load", l, false)
      }
    }
  })()
  window.Intercom && window.Intercom("update", { hide_default_launcher: true })
}
