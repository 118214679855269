import React, { ReactElement } from "react"
import styled from "styled-components"
import { TopNav } from "components/molecules/TopNav"
import { useConfig } from "hooks/useConfig"
import { darken, rgba } from "polished"
import { NavBarHeight } from "styles/StyleVariabes"
import WaveSvg from "assets/images/background-wave.svg"
import { PageTheme } from "components/_blueprint/Template"

const Root = styled.div<Props>`
  z-index: -1;
  display: grid;
  grid-template-rows: ${({ headerStyle }) =>
    `${headerStyle === HeaderStyle.None ? "" : NavBarHeight} 1fr auto`};
  min-height: 100vh;

  &.primary {
    background: ${(props) => props.theme.backgroundColour};
  }

  &.alternate {
    background: ${(props) =>
      `linear-gradient(137.52deg, ${props.theme.secondaryOne} 19.57%, ${darken(
        0.05,
        props.theme.secondaryOne
      )} 79.66%)`};
  }

  &.peach {
    background: #ffebdc;
  }

  &::before {
    display: none;

    @media (min-width: 500px) {
      content: "&nbsp;";
      display: ${({ backgroundWave }) => (backgroundWave ? "block" : "none")};
      position: fixed;
      background: ${({ theme, colourTheme }) => {
        switch (colourTheme) {
          case PageTheme.Primary:
            return darken(0.05, theme.backgroundColour)
          case PageTheme.Alternate:
            return darken(0.08, theme.secondaryOne)
          case PageTheme.Peach:
            return "#ffdcc4"
        }
      }};
      mask-image: url(${WaveSvg});
      mask-size: cover;
      background-size: 140vw;
      z-index: 0;
      bottom: 0;
      left: -80vw;
      right: 0vw;
      top: 25%;
    }
  }
`

const TopNavContainer = styled.div<Props>`
  display: ${({ headerStyle }) => (headerStyle === HeaderStyle.None && "none") || "block"};
  visibility: ${({ headerStyle }) => (headerStyle === HeaderStyle.Hidden && "hidden") || "initial"};
  background: ${({ headerStyle, theme }) =>
    headerStyle === HeaderStyle.Default ? theme.navColour : rgba(0, 0, 0, 0)};
  position: ${({ stickyHeader }) => (stickyHeader ? "sticky" : "relative")};
  width: 100%;
  height: fit-content;
  top: 0;
  z-index: 999;
  border-bottom: ${({ headerStyle, theme }) =>
    headerStyle === HeaderStyle.Default ? `1px solid ${theme.borderColor}` : 0};
`

const ContentContainer = styled.div<Props>`
  z-index: 100;
  height: 100%;
  display: flex;
  position: relative;
`

const FooterContainer = styled.div`
  z-index: 100;
  height: fit-content;
`

export enum HeaderStyle {
  Default,
  Transparent,
  Hidden,
  None,
  NoUser,
}

export enum PageTheme {
  Primary = "primary",
  Alternate = "alternate",
  Peach = "peach",
}

type Props = {
  content?: ReactElement
  footer?: ReactElement
  headerStyle?: HeaderStyle
  backgroundWave?: boolean
  colourTheme?: PageTheme
  stickyHeader?: boolean
}

export function MyMojoTemplate({
  content,
  footer = null,
  headerStyle = HeaderStyle.Default,
  backgroundWave = false,
  colourTheme = PageTheme.Primary,
  stickyHeader = true,
}: Props) {
  const { theme } = useConfig()

  return (
    <Root
      className={colourTheme}
      backgroundWave={backgroundWave}
      colourTheme={colourTheme}
      headerStyle={headerStyle}
    >
      <TopNavContainer stickyHeader={stickyHeader} headerStyle={headerStyle}>
        <TopNav showPoweredBy={theme.showPoweredBy} showUser={headerStyle !== HeaderStyle.NoUser} />
      </TopNavContainer>

      <ContentContainer headerStyle={headerStyle}>{content}</ContentContainer>

      {footer && <FooterContainer>{footer}</FooterContainer>}
    </Root>
  )
}
