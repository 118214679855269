import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import Theme from "styles/Theme"
import { RecoilRoot } from "recoil"
import initSentry from "./sentry"

initSentry()

ReactDOM.render(
  <Theme>
    <RecoilRoot>
      <App />
    </RecoilRoot>
  </Theme>,
  document.getElementById("root")
)
