import * as React from "react"
import styled, { keyframes } from "styled-components"

const radius = 50
const diameter = 2 * radius * Math.PI
export const fillDuration = 2
const fadeDuration = 0.5
const drawDuration = 0.5

const Container = styled.div({
  display: "grid",
  gridTemplateColumns: "1fr",
  gridTemplateRows: "1fr",
  height: 24,
  width: 24,
})

const fill = keyframes`
  to {
    stroke-dashoffset: 0;
  }
`

const fadeOut = (color: string) => keyframes`
  to {
    fill: ${color};
    stroke: ${color};
  }
`

// I can't get the keyframe interpolation to work with the object notation. If
// you can get it to work, these can be combined.
const FillAnimationName = styled.svg`
  animation-name: ${fill}, ${({ theme }) => fadeOut(theme.colors.success[0])};
`

const Fill = styled(FillAnimationName)<{ initialDelay: number }>(({ initialDelay, theme }) => ({
  animationDelay: `${initialDelay}s, ${initialDelay + (fillDuration - fadeDuration)}s`,
  animationDuration: `${fillDuration}s, ${fadeDuration}s`,
  animationFillMode: "forwards",
  animationTimingFunction: "ease, linear",
  fill: "transparent",
  gridColumnStart: 1,
  gridRowStart: 1,
  height: "100%",
  stroke:
    theme.name === "mojo"
      ? theme.colors.secondary.three
      : theme.name === "uswitch"
      ? "#84A6FF"
      : theme.primary,
  strokeDasharray: diameter,
  strokeDashoffset: diameter,
  transform: "rotate(-90deg)",
  width: "100%",
}))

const Track = styled.svg(({ theme }) => ({
  fill: "none",
  gridColumnStart: 1,
  gridRowStart: 1,
  height: "100%",
  stroke: theme.name === "uswitch" ? "#E3E3E3" : theme.colors.neutral[200],
  width: "100%",
}))

const draw = keyframes`
  to {
    stroke-dashoffset: 0;
  }
`

const DrawAnimation = styled.svg`
  animation-name: ${draw};
`

const Check = styled(DrawAnimation)<{ initialDelay: number }>(({ initialDelay, theme }) => ({
  animationDelay: `${initialDelay + (fillDuration - drawDuration)}s`,
  animationDuration: `${drawDuration}s`,
  animationFillMode: "forwards",
  animationTimingFunction: "linear",
  strokeDasharray: 125,
  strokeDashoffset: 125,
  gridColumnStart: 1,
  gridRowStart: 1,
  height: "100%",
  width: "100%",
  stroke: theme.colors.success[500],
  zIndex: 1, // hack, not sure why it's hidden by the fill
}))

type Props = {
  initialDelay: number
}

export const AnimatedCheck = ({ initialDelay }: Props) => (
  <Container>
    <Track viewBox="0 0 120 120">
      <circle cx="60" cy="60" r={radius} strokeWidth="14"></circle>
    </Track>
    <Fill initialDelay={initialDelay} viewBox="0 0 120 120">
      <circle cx="60" cy="60" r={radius} strokeWidth="16"></circle>
    </Fill>
    <Check
      initialDelay={initialDelay}
      viewBox="0 0 120 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
    >
      <path d="M 27.983657,58.181695 50.552212,79.138212 90.853205,35.61314" strokeWidth="16" />
    </Check>
  </Container>
)
