import React from "react"
import ctxt, { EligibilityContext } from "./context"

export const useEligibility = () => {
  const context = React.useContext(ctxt)

  return context
}

/**
 * Context containing shared utilities and data for `Eligibility` component tree
 */
export const EligibilityContextProvider = ({
  children,
  logoState,
}: {
  children: React.ReactNode
  logoState: EligibilityContext["logoState"]
}): JSX.Element => {
  return <ctxt.Provider value={{ logoState }}>{children}</ctxt.Provider>
}
