import React, { ReactElement, useState } from "react"
import styled from "styled-components"
import { lighten } from "polished"
import { Loader, LoaderTheme } from "components/atoms/Loader"

const ButtonShadowOffset = 6
const height = 56

const Container = styled.div<{ width: string }>`
  padding: 10px 0;
  height: ${height + 20}px;
  width: ${(props) => props.width};
`

const ButtonSpan = styled.button<{ width: string; isLoading: boolean }>`
  display: flex;
  border-radius: ${(props) => props.theme.borderRadius ?? "10px"};
  padding: 10px 30px;
  width: ${(props) => props.width};
  box-sizing: border-box;
  text-align: center;
  height: 56px;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: width 0.7s;
  pointer-events: ${(props) => (props.isLoading ? "none" : "all")};
  cursor: ${(props) => (props.isLoading ? "default" : "pointer")};
  user-select: none;
  border: 0;
  outline: 0;

  &.solid {
    background: ${(props) => props.theme.ctaButton};
    color: white;
    font-weight: 550;
    box-shadow: inset 0 -${ButtonShadowOffset}px 0px ${(props) => props.theme.buttonShadow};

    &:hover {
      &.isPressed {
        box-shadow: unset;
        height: ${height - 6}px;
        top: 6px;
      }
    }
    &.disabled {
      background: ${(props) => props.theme.shade80} !important;
      color: ${(props) => props.theme.shade50};
      pointer-events: none;
      box-shadow: none !important;
    }

    &:hover {
      background: ${(props) => {
        if (props.theme.ctaButton[0] === "#") {
          return lighten(0.1, props.theme.ctaButton)
        } else {
          return props.theme.ctaButton
        }
      }};
      box-shadow: inset 0 -${ButtonShadowOffset}px 0px ${(props) => {
          if (props.theme.ctaButton[0] === "#") {
            return lighten(0.1, props.theme.buttonShadow)
          } else {
            return props.theme.buttonShadow
          }
        }};
    }
  }

  &.dark {
    background: ${(props) => props.theme.primaryThree};
    color: white;
    font-weight: 550;

    &:hover {
      background: ${(props) => lighten(0.08, props.theme.primaryThree)};
    }
  }

  &.danger {
    background: ${(props) => props.theme.secondaryThree};
    color: white;
    font-weight: 550;

    &:hover {
      background: ${(props) => lighten(0.08, props.theme.secondaryThree)};
    }
  }

  &.outline {
    background: transparent;
    color: black;
    border: 2px solid black;
    font-weight: 550;

    &:hover {
      background: #f5f5f5;

      &.disabled {
        background: inherit;
        cursor: default;
      }
    }

    &.disabled {
      color: #969696;
      border: 2px solid #969696;
    }
  }
`

const LoaderWrapper = styled.div<{ show: boolean }>`
  transition: 0.7s;
  text-align: center;
  width: ${(props) => (props.show ? "30px" : "0")};
`

const TextWrapper = styled.span``

export enum ButtonType {
  Solid = 0,
  Outline = 1,
  Dark = 2,
  Danger = 3,
}

interface ButtonProps extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "type"> {
  /**
   * What style of button should this be displayed as. Import `ButtonType` and pass it as a prop.
   */
  type?: ButtonType
  /**
   * Remapped from `type`, defines the HTML button type
   */
  buttonType?: React.ButtonHTMLAttributes<HTMLButtonElement>["type"]
  tabIndex?: number
  text?: string | ReactElement
  onClick?: () => void
  showLoader?: boolean
  width?: string
  testId?: string
}

export function Button({
  tabIndex = 0,
  text = "",
  type = ButtonType.Solid,
  disabled = false,
  onClick = () => {},
  showLoader = false,
  id = "",
  width = "100%",
  testId = "",
  buttonType = "button",
  ...props
}: ButtonProps) {
  const [isPressed, setIsPressed] = useState<boolean>(false)

  function handleOnClick() {
    if (!onClick || showLoader) {
      return
    }

    onClick()
  }

  const classNames = []

  if (disabled) {
    classNames.push("disabled")
  }

  switch (type) {
    case ButtonType.Solid:
      classNames.push("solid")
      break
    case ButtonType.Outline:
      classNames.push("outline")
      break
    case ButtonType.Dark:
      classNames.push("dark")
      break
    case ButtonType.Danger:
      classNames.push("danger")
      break
  }

  let loaderTheme

  switch (type) {
    case ButtonType.Solid:
    case ButtonType.Dark:
    case ButtonType.Danger:
      loaderTheme = LoaderTheme.Solid
      break
    case ButtonType.Outline:
    default:
      loaderTheme = LoaderTheme.Outline
      break
  }

  return (
    <Container data-text={text} width={width}>
      <ButtonSpan
        type={buttonType}
        tabIndex={tabIndex}
        id={id}
        data-testid={testId}
        className={`${classNames.join(" ")} ${isPressed && "isPressed"}`}
        onClick={handleOnClick}
        width={width}
        isLoading={showLoader}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleOnClick()
          }
        }}
        onMouseDownCapture={() => setIsPressed(true)}
        onMouseUp={() => setIsPressed(false)}
        onMouseLeave={() => setIsPressed(false)}
        disabled={disabled}
        {...props}
      >
        <TextWrapper>{text}</TextWrapper>
        <LoaderWrapper show={showLoader}>
          {showLoader && <Loader size={30} theme={loaderTheme} />}
        </LoaderWrapper>
      </ButtonSpan>
    </Container>
  )
}
