import React from "react"
import styled from "styled-components"

import { Star, StarStatus } from "../atoms/Star"

const Container = styled.div`
  display: flex;
  font-size: 35px;
  justify-content: space-evenly;
`

type Props = {
  currentStars: number
}

const test = `${(props) => props.theme.primaryOne}`

export const StarsRating = ({ currentStars }: Props) => {
  const getStars = () => {
    const stars = []
    for (let i = 0; i < 5; i++) {
      if (i <= 5) {
        stars.push(
          <Star
            color="#0045FF"
            status={i < currentStars ? StarStatus.Filled : StarStatus.Outline}
          />
        )
      }
    }
    return stars
  }

  return <Container>{getStars()}</Container>
}
