import { axiosInstance } from "lib/Api"

export const ZapierWebhook = async (data, webhookId) => {
  try {
    await axiosInstance.post(
      `${process.env.MY_MOJO_API}/request/zapier-webhook-mop-tracking`,
      {
        data,
        webhookId,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
  } catch (error) {
    console.log("error", error)
  }
}
