import { ErrorDisplay } from "components/molecules"
import { Button } from "components/atoms"
import { BaseTemplate } from "templates/BaseTemplate"
import React from "react"
import styled from "styled-components"
import devices from "styles/devices"

const ErrorPageContainer = styled.div`
  max-width: 90%;
  margin: 0 auto;
  @media screen and ${devices.bp_sm} {
    max-width: 30em;
  }
`

export const GenericError = () => {
  return (
    <BaseTemplate
      content={
        <ErrorPageContainer>
          <ErrorDisplay>
            <h2>Something went wrong</h2>
            <p>Sorry, the page you were looking for could not be found. Please try again later.</p>
            <Button
              onClick={() => {
                window.location.href = "/"
              }}
              text="Go to homepage"
            />
          </ErrorDisplay>
        </ErrorPageContainer>
      }
    />
  )
}
