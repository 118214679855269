import React, { Fragment, useEffect, useReducer, useState } from "react"
import styled from "styled-components"
import { FormOptionsQuestion, FormQuestion, IFormState, Question } from "models/FormDataCollection"
import devices from "styles/devices"
import { Button } from "components/atoms"
import reducer from "components/molecules/QuestionRenderer/questionReducer"
import { QuestionSpacing } from "styles/StyleVariabes"
import AddressHistory from "components/molecules/AddressHistory"
import { EmailInput } from "components/molecules/EmailInput"
import { ValidateEmailAddress } from "lib/EmailAddress"
import { DateInput } from "../DateInput"
import { TwoYearInput } from "components/molecules/TwoYearInput"
import { PercentageInput } from "components/molecules/PercentageInput"
import { IncomeBenefits } from "components/molecules/IncomeBenefits"
import { AdditionalIncomes } from "components/molecules/AdditionalIncomes"
import { IncomeDeductions } from "components/molecules/IncomeDeductions"
import { MultiSelect } from "components/molecules/MultiSelect"
import { PhoneNumberInput } from "components/molecules/PhoneNumberInput"
import { IncomeSupplements } from "components/molecules/IncomeSupplements"
import {
  Address,
  CurrencyInput,
  InitialPeriodEndDate,
  LenderLookup,
  NameInput,
  NumberInput,
  SingleSelect,
} from "components/molecules"
import { AdditionalIncomeTypes, EmploymentStatus, Income } from "components/molecules/Income"
import { LogGaEvent } from "lib/GoogleAnalytics"
import { EventAction, EventCategory } from "models/GoogleAnalytics"
import { useRecoilState } from "recoil"
import { TooltipState } from "models/Tooltips"
import { tooltipState } from "state"
import { ScrollTo } from "lib/ScrollTo"

type ContainerProps = {
  bottomPadding: boolean
}

const Container = styled.div<ContainerProps>`
  position: relative;
  padding-bottom: ${(props) => (props.bottomPadding ? "1000px" : `${QuestionSpacing}`)};
  width: 100%;

  @media ${devices.bp_sm} {
    width: 100%;
  }
`

const ButtonWrapper = styled.div`
  margin-top: 1rem;
  display: grid;
  justify-content: start;
`

const QuestionWrapperStyles = styled.div`
  animation: fadeIn 0.25s;
  min-height: 50px;

  &.scrollAnchor {
    padding-top: 11rem;

    &:first-of-type {
      margin-top: -150px;
      padding-top: 150px;

      @media ${devices.bp_sm} {
        margin-top: calc(${QuestionSpacing} * -1);
        padding-top: 10rem;
      }
    }

    &:not(:first-of-type) {
      padding-top: ${QuestionSpacing};
    }
  }

  &:not(:last-child) {
    margin-bottom: 5rem;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 100;
    }
  }

  @media ${devices.bp_sm} {
    margin-bottom: ${QuestionSpacing};
    display: grid;
    grid-template-columns: 340px 340px;
    justify-content: center;
    grid-column-gap: 1rem;
    grid-template-areas: "title info" "explanation info" "question info";
  }
  @media (min-width: 800px) {
    // Custom one time breakpoint for FFF
    grid-template-columns: 380px 340px;
  }
  @media ${devices.bp_md} {
    grid-template-columns: 340px 26vw;
  }
  @media ${devices.bp_lg} {
    grid-template-columns: 340px 23vw;
  }
  @media ${devices.bp_xl} {
    grid-column-gap: 2.25rem;
    grid-template-columns: 25rem 20rem;
  }
`

const QuestionTitle = styled.h4`
  grid-area: title;
  font-weight: bold;
  margin: 0 0 20px 0;
  align-self: flex-end;
`

const QuestionExplanation = styled.p`
  grid-area: explanation;
  margin: 0 0 20px 0;
`

const ErrorViewStyles = styled.p`
  color: ${({ theme }) => theme.error};
  font-weight: 500;
`

const QuestionInfo = styled.div`
  div {
    background-color: ${({ theme }) => theme.infoColor};
    border-radius: ${({ theme }) => theme.radius.large};
    font-weight: 300;
    padding: 1.625rem 1.3rem;
    margin-bottom: 1rem;

    @media ${devices.bp_sm} {
      max-width: 22rem;
      margin-top: calc(${QuestionSpacing} - 1rem);
      grid-area: info;
    }
  }
  @media ${devices.bp_sm} {
    grid-area: info;
  }
`

const QuestionContainer = styled.div`
  @media ${devices.bp_sm} {
    max-width: 22rem;
    grid-area: question;
  }
`

const SubQuestion = styled.div`
  margin-top: 0.5rem;
  margin-bottom: 0.25rem;
  font-size: 0.9rem;
`

type QuestionRendererProps = {
  config: IFormState
  onStateChange?: (state: IFormState) => void
}

type QuestionWrapperProps = {
  title: string
  question: any
  info?: string | React.ReactElement
  explanation?: string
  children?: React.ReactNode
}

function QuestionWrapper({
  title,
  question,
  explanation,
  info = null,
  children,
}: QuestionWrapperProps) {
  return (
    <>
      <QuestionWrapperStyles
        onFocus={() => HandleFocus(question)}
        onBlur={() => HandleBlur(question)}
        className="scrollAnchor"
      >
        <QuestionTitle>{title}</QuestionTitle>
        {explanation && <QuestionExplanation>{explanation}</QuestionExplanation>}
        {info && (
          <QuestionInfo>
            <div>{info}</div>
          </QuestionInfo>
        )}
        <QuestionContainer data-sl={question.isPII ? "mask" : ""}>{children}</QuestionContainer>
      </QuestionWrapperStyles>
    </>
  )
}

export default function QuestionRenderer({
  config,
  onStateChange = () => {},
}: QuestionRendererProps) {
  const [state, dispatch] = useReducer(reducer, config)
  const [errors, setErrors] = useState<string[]>([])
  const [tooltips] = useRecoilState<TooltipState[]>(tooltipState)

  useEffect(() => {
    onStateChange(state)
  }, [state])

  useEffect(() => {
    tooltips.forEach(({ closeFn }) => closeFn())
  }, [state])

  useEffect(() => {
    dispatch({ type: "init" })
  }, [])

  const mapped: JSX.Element[] = state.questions.map((question) => {
    if (!question.visible) {
      return null
    }

    const explanationValue = question?.explanation
    let infoValue = question?.info
    let titleValue = question?.title
    let optionsValue = (question as FormOptionsQuestion)?.options

    if (typeof infoValue === "function") {
      infoValue = infoValue(state)
    }

    if (typeof titleValue === "function") {
      titleValue = titleValue(state)
    }

    if (typeof optionsValue === "function") {
      optionsValue = optionsValue(state)
    }

    if (question.type === "SingleSelect") {
      const mappedQuestion = question
      return (
        <>
          <QuestionWrapper
            title={titleValue}
            question={mappedQuestion}
            key={mappedQuestion.id}
            info={infoValue}
          >
            <SingleSelect
              id={mappedQuestion.id}
              options={optionsValue}
              value={mappedQuestion.value}
              onClick={(value) => {
                setErrors([])

                dispatch({
                  type: "updateValue",
                  payload: {
                    id: mappedQuestion.id,
                    value,
                  },
                })
              }}
            />
          </QuestionWrapper>
        </>
      )
    }

    if (question.type === "PhoneNumberInput") {
      const mappedQuestion = question

      return (
        <QuestionWrapper
          title={titleValue}
          question={mappedQuestion}
          key={mappedQuestion.id}
          info={infoValue}
        >
          <PhoneNumberInput
            id={mappedQuestion.id}
            value={mappedQuestion.value}
            onDone={(value, errors) => {
              if (errors?.length > 0) {
                setErrors(errors)

                dispatch({
                  type: "silentUpdateValue",
                  payload: {
                    id: mappedQuestion.id,
                    value: null,
                  },
                })

                return
              }

              setErrors([])

              dispatch({
                type: "silentUpdateValue",
                payload: {
                  id: mappedQuestion.id,
                  value,
                },
              })
            }}
          />
          <ErrorView errorViewQuestion={mappedQuestion} reducerErrors={errors} />
          <ButtonWrapper>
            <Button
              id={"button-next-" + mappedQuestion.id}
              disabled={!mappedQuestion.value}
              onClick={() => {
                ScrollTo()
                dispatch({
                  type: "showOnly",
                  payload: { id: mappedQuestion.id, value: mappedQuestion.value },
                })
              }}
              text="Next"
            />
          </ButtonWrapper>
        </QuestionWrapper>
      )
    }

    if (question.type === "TwoYearInput") {
      const mappedQuestion = question

      return (
        <QuestionWrapper
          title={titleValue}
          question={mappedQuestion}
          key={mappedQuestion.id}
          info={infoValue}
        >
          <TwoYearInput
            id={mappedQuestion.id}
            value={mappedQuestion.value}
            onDone={(value, errors) => {
              setErrors(errors)

              dispatch({
                type: "silentUpdateValue",
                payload: {
                  id: mappedQuestion.id,
                  value,
                },
              })
            }}
          />
          <ErrorView errorViewQuestion={mappedQuestion} reducerErrors={errors} />
          <ButtonWrapper>
            <Button
              id={"button-next-" + mappedQuestion.id}
              disabled={!mappedQuestion.value || errors?.length > 0}
              onClick={() => {
                ScrollTo()
                dispatch({
                  type: "showOnly",
                  payload: { id: mappedQuestion.id, value: mappedQuestion.value },
                })
              }}
              text="Next"
            />
          </ButtonWrapper>
        </QuestionWrapper>
      )
    }

    if (question.type === "MultiSelect") {
      const mappedQuestion = question

      return (
        <QuestionWrapper
          title={titleValue}
          question={mappedQuestion}
          key={mappedQuestion.id}
          info={infoValue}
        >
          <MultiSelect
            id={mappedQuestion.id}
            options={optionsValue}
            value={mappedQuestion.value}
            onClick={(value) =>
              dispatch({
                type: "silentUpdateValue",
                payload: {
                  id: mappedQuestion.id,
                  value,
                },
              })
            }
          />
          <ButtonWrapper>
            <Button
              id={"button-next-" + mappedQuestion.id}
              disabled={
                mappedQuestion.requiresAnswer &&
                (!mappedQuestion.value || mappedQuestion?.value?.length < 1)
              }
              onClick={() => {
                ScrollTo()
                dispatch({
                  type: "showOnly",
                  payload: { id: mappedQuestion.id, value: mappedQuestion.value },
                })
              }}
              text="Next"
            />
          </ButtonWrapper>
        </QuestionWrapper>
      )
    }

    if (question.type === "MultiNumberInput") {
      const mappedQuestion = question

      return (
        <QuestionWrapper
          title={titleValue}
          question={mappedQuestion}
          key={mappedQuestion.id}
          info={infoValue}
        >
          {question.labels.map((questionInput, index) => (
            <Fragment key={index.toString()}>
              <SubQuestion>{questionInput}</SubQuestion>
              <NumberInput
                id={questionInput}
                value={mappedQuestion.value[index]}
                onDone={(value) => {
                  const clone = [...mappedQuestion.value]
                  if (value) {
                    clone[index] = value
                  } else {
                    clone[index] = ""
                  }

                  dispatch({
                    type: "silentUpdateValue",
                    payload: {
                      id: mappedQuestion.id,
                      value: clone,
                    },
                  })
                }}
              />
            </Fragment>
          ))}
          <ButtonWrapper>
            <Button
              id={"button-next-" + mappedQuestion.id}
              onClick={() => {
                ScrollTo()
                dispatch({
                  type: "showOnly",
                  payload: { id: mappedQuestion.id, value: mappedQuestion.value },
                })
              }}
              disabled={mappedQuestion?.value?.toString?.()?.length === 0}
              text="Next"
            />
          </ButtonWrapper>
        </QuestionWrapper>
      )
    }

    if (question.type === "NameInput") {
      const mappedQuestion = question

      return (
        <QuestionWrapper
          title={titleValue}
          question={mappedQuestion}
          key={mappedQuestion.id}
          info={infoValue}
        >
          <NameInput
            id={mappedQuestion.id}
            value={mappedQuestion.value}
            onDone={(value) => {
              dispatch({
                type: "silentUpdateValue",
                payload: {
                  id: mappedQuestion.id,
                  value: value,
                },
              })
            }}
          />
          <ButtonWrapper>
            <Button
              id={"button-next-" + mappedQuestion.id}
              onClick={() => {
                ScrollTo()
                dispatch({
                  type: "showOnly",
                  payload: { id: mappedQuestion.id, value: mappedQuestion.value },
                })
              }}
              disabled={
                !mappedQuestion.value?.title ||
                !mappedQuestion?.value?.firstName ||
                !mappedQuestion?.value?.lastName
              }
              text="Next"
            />
          </ButtonWrapper>
        </QuestionWrapper>
      )
    }

    if (question.type === "EmploymentStatus") {
      const mappedQuestion = question
      const answered =
        mappedQuestion.value?.firstApplicant &&
        (mappedQuestion.secondApplicantName ? mappedQuestion.value?.secondApplicant : true)
      return (
        <QuestionWrapper
          title={titleValue}
          question={mappedQuestion}
          key={mappedQuestion.id}
          explanation={explanationValue}
          info={infoValue}
        >
          <EmploymentStatus
            id={mappedQuestion.id}
            secondApplicantName={mappedQuestion.secondApplicantName}
            value={mappedQuestion.value}
            onDone={(value) => {
              dispatch({
                type: "silentUpdateValue",
                payload: {
                  id: mappedQuestion.id,
                  value: value,
                },
              })
            }}
          />
          <ButtonWrapper>
            <Button
              id={"button-next-" + mappedQuestion.id}
              onClick={() => {
                ScrollTo()
                dispatch({
                  type: "showOnly",
                  payload: { id: mappedQuestion.id, value: mappedQuestion.value },
                })
              }}
              disabled={!answered}
              text="Next"
            />
          </ButtonWrapper>
        </QuestionWrapper>
      )
    }

    if (question.type === "AnnualIncome") {
      const mappedQuestion = question
      const answered =
        mappedQuestion.value?.firstApplicant !== undefined &&
        (mappedQuestion.secondApplicantName
          ? mappedQuestion.value?.secondApplicant !== undefined
          : true)
      return (
        <QuestionWrapper
          title={titleValue}
          question={mappedQuestion}
          key={mappedQuestion.id}
          info={infoValue}
        >
          <Income
            id={mappedQuestion.id}
            incomeType="income"
            secondApplicantName={mappedQuestion.secondApplicantName}
            value={mappedQuestion.value}
            onDone={(value) => {
              dispatch({
                type: "silentUpdateValue",
                payload: {
                  id: mappedQuestion.id,
                  value: value,
                },
              })
            }}
          />
          <ButtonWrapper>
            <Button
              id={"button-next-" + mappedQuestion.id}
              onClick={() => {
                ScrollTo()
                dispatch({
                  type: "showOnly",
                  payload: { id: mappedQuestion.id, value: mappedQuestion.value },
                })
              }}
              disabled={!answered}
              text="Next"
            />
          </ButtonWrapper>
        </QuestionWrapper>
      )
    }

    if (question.type === "AdditionalIncome") {
      const mappedQuestion = question
      const answered =
        mappedQuestion.value?.firstApplicant !== undefined &&
        (mappedQuestion.secondApplicantName
          ? mappedQuestion.value?.secondApplicant !== undefined
          : true)
      const initialValue = { firstApplicant: 0, secondApplicant: 0 }
      return (
        <QuestionWrapper
          title={titleValue}
          question={mappedQuestion}
          key={mappedQuestion.id}
          info={infoValue}
          explanation={explanationValue}
        >
          <Income
            id={mappedQuestion.id}
            incomeType="additional income"
            secondApplicantName={mappedQuestion.secondApplicantName}
            value={mappedQuestion.value || initialValue}
            onDone={(value) => {
              dispatch({
                type: "silentUpdateValue",
                payload: {
                  id: mappedQuestion.id,
                  value: value,
                },
              })
            }}
          />
          <ButtonWrapper>
            <Button
              id={"button-next-" + mappedQuestion.id}
              onClick={() => {
                ScrollTo()
                dispatch({
                  type: "showOnly",
                  payload: { id: mappedQuestion.id, value: mappedQuestion.value },
                })
              }}
              disabled={!answered}
              text="Next"
            />
          </ButtonWrapper>
        </QuestionWrapper>
      )
    }

    if (question.type === "BenefitIncome") {
      const mappedQuestion = question
      const answered =
        mappedQuestion.value?.firstApplicant !== undefined &&
        (mappedQuestion.secondApplicantName
          ? mappedQuestion.value?.secondApplicant !== undefined
          : true)
      const initialValue = { firstApplicant: 0, secondApplicant: 0 }
      return (
        <QuestionWrapper
          title={titleValue}
          question={mappedQuestion}
          key={mappedQuestion.id}
          info={infoValue}
          explanation={explanationValue}
        >
          <Income
            id={mappedQuestion.id}
            incomeType="benefit income"
            secondApplicantName={mappedQuestion.secondApplicantName}
            value={mappedQuestion.value || initialValue}
            onDone={(value) => {
              dispatch({
                type: "silentUpdateValue",
                payload: {
                  id: mappedQuestion.id,
                  value: value,
                },
              })
            }}
          />
          <ButtonWrapper>
            <Button
              id={"button-next-" + mappedQuestion.id}
              onClick={() => {
                ScrollTo()
                dispatch({
                  type: "showOnly",
                  payload: { id: mappedQuestion.id, value: mappedQuestion.value },
                })
              }}
              disabled={!answered}
              text="Next"
            />
          </ButtonWrapper>
        </QuestionWrapper>
      )
    }

    if (question.type === "OtherIncome") {
      const mappedQuestion = question
      const answered =
        mappedQuestion.value?.firstApplicant !== undefined &&
        (mappedQuestion.secondApplicantName
          ? mappedQuestion.value?.secondApplicant !== undefined
          : true)
      const initialValue = { firstApplicant: 0, secondApplicant: 0 }
      return (
        <QuestionWrapper
          title={titleValue}
          question={mappedQuestion}
          key={mappedQuestion.id}
          info={infoValue}
          explanation={explanationValue}
        >
          <Income
            id={mappedQuestion.id}
            incomeType="other income"
            secondApplicantName={mappedQuestion.secondApplicantName}
            value={mappedQuestion.value || initialValue}
            onDone={(value) => {
              dispatch({
                type: "silentUpdateValue",
                payload: {
                  id: mappedQuestion.id,
                  value: value,
                },
              })
            }}
          />
          <ButtonWrapper>
            <Button
              id={"button-next-" + mappedQuestion.id}
              onClick={() => {
                ScrollTo()
                dispatch({
                  type: "showOnly",
                  payload: { id: mappedQuestion.id, value: mappedQuestion.value },
                })
              }}
              disabled={!answered}
              text="Next"
            />
          </ButtonWrapper>
        </QuestionWrapper>
      )
    }

    if (question.type === "AdditionalIncomeTypes") {
      const mappedQuestion = question
      const answered =
        mappedQuestion.value?.bonusCommissionOvertime !== undefined &&
        mappedQuestion.value?.benefits !== undefined &&
        mappedQuestion.value?.other !== undefined
      return (
        <QuestionWrapper
          title={titleValue}
          question={mappedQuestion}
          key={mappedQuestion.id}
          explanation={explanationValue}
          info={infoValue}
        >
          <AdditionalIncomeTypes
            id={mappedQuestion.id}
            value={mappedQuestion.value}
            onDone={(value) => {
              dispatch({
                type: "silentUpdateValue",
                payload: {
                  id: mappedQuestion.id,
                  value: value,
                },
              })
            }}
          />
          <ButtonWrapper>
            <Button
              id={"button-next-" + mappedQuestion.id}
              onClick={() => {
                ScrollTo()
                dispatch({
                  type: "showOnly",
                  payload: { id: mappedQuestion.id, value: mappedQuestion.value },
                })
              }}
              disabled={!answered}
              text="Next"
            />
          </ButtonWrapper>
        </QuestionWrapper>
      )
    }

    if (question.type === "EmailAddress") {
      const mappedQuestion = question

      const canSkip = mappedQuestion.config?.canSkip && !mappedQuestion.value

      return (
        <QuestionWrapper
          title={titleValue}
          question={mappedQuestion}
          key={mappedQuestion.id}
          info={infoValue}
        >
          <EmailInput
            id={mappedQuestion.id + "-input"}
            value={mappedQuestion.value}
            allowBlank={true}
            onChange={(value) => {
              dispatch({
                type: "silentUpdateValue",
                payload: {
                  id: mappedQuestion.id,
                  value: value,
                },
              })
            }}
          />
          <ButtonWrapper>
            <Button
              id={"button-next-" + mappedQuestion.id}
              onClick={() => {
                ScrollTo()
                dispatch({
                  type: "showOnly",
                  payload: { id: mappedQuestion.id, value: mappedQuestion.value },
                })
              }}
              disabled={
                canSkip && !mappedQuestion.value
                  ? false
                  : !mappedQuestion?.value || !ValidateEmailAddress(mappedQuestion.value)
              }
              text={canSkip ? "Skip" : "Next"}
            />
          </ButtonWrapper>
        </QuestionWrapper>
      )
    }

    if (question.type === "AddressHistory") {
      const mappedQuestion = question

      return (
        <QuestionWrapper
          title={titleValue}
          question={mappedQuestion}
          key={mappedQuestion.id}
          info={infoValue}
        >
          <AddressHistory
            id={mappedQuestion.id}
            value={mappedQuestion.value}
            onDone={(value, hasThreeYearsHistory) => {
              if (hasThreeYearsHistory) {
                dispatch({
                  type: "updateValue",
                  payload: {
                    id: mappedQuestion.id,
                    value: value,
                  },
                })
              } else {
                dispatch({
                  type: "silentUpdateValue",
                  payload: {
                    id: mappedQuestion.id,
                    value: null,
                  },
                })
              }
            }}
            onPostcodeLookup={(addresses) => {
              if (addresses.length > 0) {
                LogGaEvent({
                  action: EventAction.addressLookupSuccessful,
                  event_category: EventCategory.formInteraction,
                  event_label: question.id,
                })
              } else {
                LogGaEvent({
                  action: EventAction.addressLookupUnsuccessful,
                  event_category: EventCategory.formInteraction,
                  event_label: question.id,
                })
              }
            }}
            onAddressSelected={() =>
              LogGaEvent({
                action: EventAction.addressSelected,
                event_category: EventCategory.formInteraction,
                event_label: question.id,
              })
            }
          />
        </QuestionWrapper>
      )
    }

    if (question.type === "CurrencyInput") {
      const mappedQuestion = question
      return (
        <QuestionWrapper
          title={titleValue}
          question={mappedQuestion}
          key={mappedQuestion.id}
          info={infoValue}
        >
          <CurrencyInput
            id={mappedQuestion.id}
            value={mappedQuestion.value}
            label=""
            onKeyDown={(e) => {
              if (
                e.keyCode === 13 &&
                !!mappedQuestion?.value &&
                mappedQuestion?.value?.toString?.()?.length !== 0
              ) {
                dispatch({
                  type: "showOnly",
                  payload: { id: mappedQuestion.id, value: mappedQuestion.value },
                })
              }
            }}
            onDone={(value, errors) => {
              setErrors(errors)

              if (value) {
                dispatch({
                  type: "silentUpdateValue",
                  payload: {
                    id: mappedQuestion.id,
                    value: value.toString(),
                  },
                })
              } else {
                dispatch({
                  type: "silentUpdateValue",
                  payload: {
                    id: mappedQuestion.id,
                    value: "",
                  },
                })
              }
            }}
          />
          <ErrorView errorViewQuestion={mappedQuestion} reducerErrors={errors} />
          <ButtonWrapper>
            <Button
              id={"button-next-" + mappedQuestion.id}
              onClick={() => {
                ScrollTo()
                dispatch({
                  type: "showOnly",
                  payload: { id: mappedQuestion.id, value: mappedQuestion.value },
                })
              }}
              disabled={!mappedQuestion?.value || mappedQuestion?.value?.toString?.()?.length === 0}
              text="Next"
            />
          </ButtonWrapper>
        </QuestionWrapper>
      )
    }

    if (question.type === "DateInput") {
      const mappedQuestion = question
      return (
        <QuestionWrapper
          title={titleValue}
          question={mappedQuestion}
          key={mappedQuestion.id}
          info={infoValue}
        >
          <DateInput
            id={mappedQuestion.id}
            value={mappedQuestion.value}
            dateFlags={mappedQuestion?.config?.dateFlags}
            format={mappedQuestion?.config?.dateFormat}
            onKeyDown={(e) => {
              if (e.keyCode === 13 && !!mappedQuestion?.value) {
                dispatch({
                  type: "showOnly",
                  payload: { id: mappedQuestion.id, value: mappedQuestion.value },
                })
              }
            }}
            onDone={(value, errors) => {
              setErrors(errors)

              if (value) {
                dispatch({
                  type: "silentUpdateValue",
                  payload: {
                    id: mappedQuestion.id,
                    value: value,
                  },
                })
              } else {
                dispatch({
                  type: "silentUpdateValue",
                  payload: {
                    id: mappedQuestion.id,
                    value: "",
                  },
                })
              }
            }}
          />
          <ErrorView errorViewQuestion={mappedQuestion} reducerErrors={errors} />
          <ButtonWrapper>
            <Button
              id={"button-next-" + mappedQuestion.id}
              onClick={() => {
                ScrollTo()
                dispatch({
                  type: "showOnly",
                  payload: { id: mappedQuestion.id, value: mappedQuestion.value },
                })
              }}
              disabled={
                mappedQuestion?.value === undefined ||
                mappedQuestion?.value?.toString().length === 0
              }
              text="Next"
            />
          </ButtonWrapper>
        </QuestionWrapper>
      )
    }

    if (question.type === "NumberInput") {
      const mappedQuestion = question
      return (
        <QuestionWrapper
          title={titleValue}
          question={mappedQuestion}
          key={mappedQuestion.id}
          info={infoValue}
        >
          <NumberInput
            id={mappedQuestion.id}
            value={mappedQuestion.value}
            placeholder={mappedQuestion.placeholder}
            onKeyDown={(e) => {
              if (e.keyCode === 13 && !!mappedQuestion?.value) {
                dispatch({
                  type: "showOnly",
                  payload: { id: mappedQuestion.id, value: mappedQuestion.value },
                })
              }
            }}
            onDone={(value, errors) => {
              setErrors(errors)
              dispatch({
                type: "silentUpdateValue",
                payload: {
                  id: mappedQuestion.id,
                  value: value?.toString(),
                },
              })
            }}
          />
          <ErrorView errorViewQuestion={mappedQuestion} reducerErrors={errors} />
          <ButtonWrapper>
            <Button
              id={"button-next-" + mappedQuestion.id}
              onClick={() => {
                ScrollTo()
                dispatch({
                  type: "showOnly",
                  payload: { id: mappedQuestion.id, value: mappedQuestion.value },
                })
              }}
              disabled={!mappedQuestion.value || errors?.length > 0}
              text="Next"
            />
          </ButtonWrapper>
        </QuestionWrapper>
      )
    }

    if (question.type === "PercentageInput") {
      const mappedQuestion = question

      return (
        <QuestionWrapper
          title={titleValue}
          question={mappedQuestion}
          key={mappedQuestion.id}
          info={infoValue}
        >
          <PercentageInput
            id={mappedQuestion.id}
            value={mappedQuestion.value}
            onDone={(value, errors) => {
              setErrors(errors)

              dispatch({
                type: "silentUpdateValue",
                payload: {
                  id: mappedQuestion.id,
                  value: value?.toString(),
                },
              })
            }}
            optional={mappedQuestion.config?.canSkip}
            optionalText={mappedQuestion.config?.canSkipText}
          />
          <ErrorView errorViewQuestion={mappedQuestion} reducerErrors={errors} />
          <ButtonWrapper>
            <Button
              id={"button-next-" + mappedQuestion.id}
              onClick={() => {
                ScrollTo()
                dispatch({
                  type: "showOnly",
                  payload: { id: mappedQuestion.id, value: mappedQuestion.value },
                })
              }}
              disabled={
                errors?.length > 0 ||
                parseFloat(mappedQuestion.value) === null ||
                isNaN(parseFloat(mappedQuestion.value))
              }
              text="Next"
            />
          </ButtonWrapper>
        </QuestionWrapper>
      )
    }

    if (question.type === "InitialPeriodEndDate") {
      const mappedQuestion = question
      return (
        <QuestionWrapper
          title={titleValue}
          question={mappedQuestion}
          key={mappedQuestion.id}
          info={infoValue}
        >
          <InitialPeriodEndDate
            id={mappedQuestion.id}
            question={{ titleValue }}
            value={mappedQuestion.value}
            onKeyDown={(e) => {
              if (e.keyCode === 13 && !!mappedQuestion?.value) {
                dispatch({
                  type: "showOnly",
                  payload: { id: mappedQuestion.id, value: mappedQuestion.value },
                })
              }
            }}
            onDone={(value, errors) => {
              setErrors(errors)

              if (value === "NONE") {
                dispatch({
                  type: "silentUpdateValue",
                  payload: {
                    id: mappedQuestion.id,
                    value: "NONE",
                  },
                })

                return
              }

              if (value) {
                dispatch({
                  type: "silentUpdateValue",
                  payload: {
                    id: mappedQuestion.id,
                    value: value,
                  },
                })
              } else {
                dispatch({
                  type: "silentUpdateValue",
                  payload: {
                    id: mappedQuestion.id,
                    value: "",
                  },
                })
              }
            }}
          />
          <ErrorView errorViewQuestion={mappedQuestion} reducerErrors={errors} />
          <ButtonWrapper>
            <Button
              id={"button-next-" + mappedQuestion.id}
              onClick={() => {
                ScrollTo()
                dispatch({
                  type: "showOnly",
                  payload: { id: mappedQuestion.id, value: mappedQuestion.value },
                })
              }}
              disabled={
                mappedQuestion?.value === "Invalid Date" ||
                (mappedQuestion?.value?.toString?.()?.length === 0 &&
                  mappedQuestion?.value !== "NONE") // NONE if they select I'm not sure
              }
              text="Next"
            />
          </ButtonWrapper>
        </QuestionWrapper>
      )
    }

    if (question.type === "LenderLookup") {
      const mappedQuestion = question
      return (
        <QuestionWrapper
          title={titleValue}
          question={mappedQuestion}
          key={mappedQuestion.id}
          info={infoValue}
        >
          <LenderLookup
            question={titleValue}
            value={mappedQuestion.value}
            onDone={(val) => {
              dispatch({
                type: "silentUpdateValue",
                payload: {
                  id: mappedQuestion.id,
                  value: val,
                },
              })
            }}
          />
          <ButtonWrapper>
            <Button
              id={"button-next-" + mappedQuestion.id}
              onClick={() => {
                ScrollTo()
                dispatch({
                  type: "showOnly",
                  payload: { id: mappedQuestion.id, value: mappedQuestion.value },
                })
              }}
              disabled={!mappedQuestion.value && mappedQuestion.value !== "NONE"}
              text="Next"
            />
          </ButtonWrapper>
        </QuestionWrapper>
      )
    }

    if (question.type === "IncomeBenefits") {
      const mappedQuestion = question

      return (
        <QuestionWrapper
          title={titleValue}
          question={mappedQuestion}
          key={mappedQuestion.id}
          info={infoValue}
        >
          <IncomeBenefits
            id={mappedQuestion.id}
            value={mappedQuestion.value}
            onDone={(val, errors) => {
              setErrors(errors)

              dispatch({
                type: "silentUpdateValue",
                payload: {
                  id: mappedQuestion.id,
                  value: val ? val : [],
                },
              })
            }}
          />
          <ErrorView errorViewQuestion={mappedQuestion} reducerErrors={errors} />
          <ButtonWrapper>
            <Button
              id={"button-next-" + mappedQuestion.id}
              disabled={errors?.length > 0}
              onClick={() => {
                ScrollTo()
                dispatch({
                  type: "showOnly",
                  payload: { id: mappedQuestion.id, value: mappedQuestion.value },
                })
              }}
              text="Next"
            />
          </ButtonWrapper>
        </QuestionWrapper>
      )
    }

    if (question.type === "AdditionalIncomes") {
      const mappedQuestion = question

      return (
        <QuestionWrapper
          title={titleValue}
          question={mappedQuestion}
          key={mappedQuestion.id}
          info={infoValue}
        >
          <AdditionalIncomes
            id={mappedQuestion.id}
            value={mappedQuestion.value}
            onDone={(val, errors) => {
              setErrors(errors)

              dispatch({
                type: "silentUpdateValue",
                payload: {
                  id: mappedQuestion.id,
                  value: val ? val : [],
                },
              })
            }}
          />
          <ErrorView errorViewQuestion={mappedQuestion} reducerErrors={errors} />
          <ButtonWrapper>
            <Button
              id={"button-next-" + mappedQuestion.id}
              disabled={errors?.length > 0}
              onClick={() => {
                ScrollTo()
                dispatch({
                  type: "showOnly",
                  payload: { id: mappedQuestion.id, value: mappedQuestion.value },
                })
              }}
              text="Next"
            />
          </ButtonWrapper>
        </QuestionWrapper>
      )
    }

    if (question.type === "IncomeDeductions") {
      const mappedQuestion = question

      return (
        <QuestionWrapper
          title={titleValue}
          question={mappedQuestion}
          key={mappedQuestion.id}
          info={infoValue}
        >
          <IncomeDeductions
            id={mappedQuestion.id}
            value={mappedQuestion.value}
            onDone={(val, errors) => {
              setErrors(errors)

              dispatch({
                type: "silentUpdateValue",
                payload: {
                  id: mappedQuestion.id,
                  value: val ? val : [],
                },
              })
            }}
          />
          <ErrorView errorViewQuestion={mappedQuestion} reducerErrors={errors} />
          <ButtonWrapper>
            <Button
              id={"button-next-" + mappedQuestion.id}
              disabled={errors?.length > 0}
              onClick={() => {
                ScrollTo()
                dispatch({
                  type: "showOnly",
                  payload: { id: mappedQuestion.id, value: mappedQuestion.value },
                })
              }}
              text="Next"
            />
          </ButtonWrapper>
        </QuestionWrapper>
      )
    }

    if (question.type === "IncomeSupplements") {
      const mappedQuestion = question

      return (
        <QuestionWrapper
          title={titleValue}
          question={mappedQuestion}
          key={mappedQuestion.id}
          info={infoValue}
        >
          <IncomeSupplements
            id={mappedQuestion.id}
            value={mappedQuestion.value}
            onDone={(val, errors) => {
              setErrors(errors)

              dispatch({
                type: "silentUpdateValue",
                payload: {
                  id: mappedQuestion.id,
                  value: val ? val : [],
                },
              })
            }}
          />
          <ErrorView errorViewQuestion={mappedQuestion} reducerErrors={errors} />
          <ButtonWrapper>
            <Button
              id={"button-next-" + mappedQuestion.id}
              disabled={errors?.length > 0}
              onClick={() => {
                ScrollTo()
                dispatch({
                  type: "showOnly",
                  payload: { id: mappedQuestion.id, value: mappedQuestion.value },
                })
              }}
              text="Next"
            />
          </ButtonWrapper>
        </QuestionWrapper>
      )
    }

    if (question.type === "Address") {
      const mappedQuestion = question

      return (
        <QuestionWrapper
          title={titleValue}
          question={mappedQuestion}
          key={mappedQuestion.id}
          info={infoValue}
        >
          <Address
            id={question.id}
            value={mappedQuestion.value}
            onDone={(address, isValid, errors) => {
              setErrors(errors)

              dispatch({
                type: "silentUpdateValue",
                payload: {
                  id: mappedQuestion.id,
                  value: { ...address, isValid },
                },
              })
            }}
            onPostcodeLookup={(addresses) => {
              if (addresses.length > 0) {
                LogGaEvent({
                  action: EventAction.addressLookupSuccessful,
                  event_category: EventCategory.formInteraction,
                  event_label: question.id,
                })
              } else {
                LogGaEvent({
                  action: EventAction.addressLookupUnsuccessful,
                  event_category: EventCategory.formInteraction,
                  event_label: question.id,
                })
              }
            }}
            onAddressSelected={() =>
              LogGaEvent({
                action: EventAction.addressSelected,
                event_category: EventCategory.formInteraction,
                event_label: question.id,
              })
            }
          />
          <ErrorView errorViewQuestion={mappedQuestion} reducerErrors={errors} />
          <ButtonWrapper>
            <Button
              id={"button-next-" + mappedQuestion.id}
              disabled={!mappedQuestion?.value?.isValid || errors?.length > 0}
              onClick={() => {
                ScrollTo()
                dispatch({
                  type: "showOnly",
                  payload: { id: mappedQuestion.id, value: mappedQuestion.value },
                })
              }}
              text="Next"
            />
          </ButtonWrapper>
        </QuestionWrapper>
      )
    }
  })

  return <Container bottomPadding={state?.completed === false}>{mapped}</Container>
}

function HandleFocus(question: FormQuestion) {
  LogGaEvent({
    action: EventAction.fieldFocus,
    event_category: EventCategory.formInteraction,
    event_label: question.id,
    field_value: question.isPII ? null : question.value,
  })
}

function HandleBlur(question: FormQuestion) {
  LogGaEvent({
    action: EventAction.fieldBlur,
    event_category: EventCategory.formInteraction,
    event_label: question.id,
    field_value: question.isPII ? null : question.value,
  })
}

function ErrorView({
  errorViewQuestion,
  reducerErrors,
}: {
  errorViewQuestion: Question
  reducerErrors: any[]
}) {
  const [errorsToShow, setErrorsToShow] = useState([])

  useEffect(() => {
    const allErrors = [...(reducerErrors ?? []), errorViewQuestion.error]?.filter((x) => !!x)
    setErrorsToShow(allErrors)

    if (allErrors?.length > 0) {
      LogGaEvent({
        action: EventAction.validationMessageShown,
        event_category: EventCategory.siteInteraction,
        event_label: `${errorViewQuestion.id} - ${allErrors.join("; ")}`,
      })
    }
  }, [errorViewQuestion])

  return <ErrorViewStyles>{errorsToShow}</ErrorViewStyles>
}
