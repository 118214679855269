import React, { useEffect } from "react"
import { Applicant, FullApplication, Occupancy } from "models/FullApplication"
import { ApplicationReason } from "models/ApplicationSummary"
import { BooleanEnum, ExperienceRes } from "models/ExperienceTLS"

interface Props {
  application?: FullApplication
  tls?: ExperienceRes
  journeyCohort?: string
  style?: any
}

export function Intercom({ application, tls, journeyCohort }: Props) {
  const subdomain = location.hostname.includes("local") ? null : window.location.host.split(".")[0]

  useEffect(() => {
    // @ts-ignore
    if (!window.Intercom) return
    // @ts-ignore
    window.Intercom("boot", {
      app_id: process.env.INTERCOM_APP_ID,
      liveChatAppointment: false,
    })

    window.Intercom && window.Intercom("update", { hide_default_launcher: false })

    if (application) {
      const applicant = getFirstApplicant(application)
      const isRemo = application.applicationReason === ApplicationReason.Remortgage
      const options = {
        app_id: process.env.INTERCOM_APP_ID,
        liveChatAppointment: false,
        email: applicant?.email ?? null,
        name: applicant?.forename ? `${applicant.forename} ${applicant.surname}` : null,
        user_id: application.friendlyId ?? null,
        ede: null,
        hide_default_lecauncher: true, // not my spelling mistake
        cohort: application.applicationReason ?? null,
        eligibility_tier: null,
        isBuyToLet: application.basicPropertyDetails?.occupancy === Occupancy.RentItOut,
        journey_intention: application.applicationReason ?? null,
        remoType: application.remortgageReason ?? null,
        partnerName: subdomain ?? null,
        journey_cohort: journeyCohort ?? null,
        loanAmount: getLoanAmount(application, isRemo) ?? null,
        purchaseType: application.purchaseType ?? null,
      }

      if (tls) {
        options.ede = tls.rule.offerAppointment === BooleanEnum.true ?? null
        options.liveChatAppointment = true
        options.eligibility_tier = tls.eligibility.tier ?? null
      }

      // @ts-ignore
      window.Intercom("update", options)
    }
  })

  function getFirstApplicant(app: FullApplication): Applicant {
    let first
    if (app.applicants?.length) {
      for (const applicant of app.applicants) {
        if (applicant.firstApplicant) {
          first = applicant
        }
      }
    }
    return first
  }

  function getLoanAmount(app: FullApplication, isRemo: boolean): number {
    let loanAmount
    if (app) {
      if (isRemo === true) {
        loanAmount =
          (app.currentMortgage?.mortgageBalance ?? 0) + app.borrowingDetails?.additionalBorrowing
      }
      if (isRemo === false) {
        loanAmount = app.basicPropertyDetails?.propertyValue - app.borrowingDetails?.deposit
      }
    }
    return loanAmount
  }

  return <></>
}
