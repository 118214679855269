export enum Cohort {
  NotFoundAProperty = "NotFoundAProperty",
  FoundAProperty = "FoundAProperty",
  Purchase = "Purchase",
  Remortgage = "Remortgage",
  StuckInERC = "StuckInERC",
  FirstTimeBuyer = "FirstTimeBuyer",
  MovingHome = "MovingHome",
  SecondHome = "SecondHome",
  BuyToLet = "BuyToLet",
  LikeForLike = "LikeForLike",
  AdditionalBorrowing = "AdditionalBorrowing",
  ReleaseMoney = "ReleaseMoney",
  PostOffer = "PostOffer",
  PreOffer = "PreOffer",
}
