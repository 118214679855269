import styled from "styled-components"
import WaveSvg from "assets/images/wave-shape.svg"

/**
 * The background image for the result pages, with the wave svg.
 */
export const GreatWave = styled.div({
  backgroundImage: `url(${WaveSvg}), linear-gradient(137.52deg, #FFF7EB 19.57%, #FFEDD1 79.66%)`,
  backgroundBlendMode: "darken",
  backgroundPosition: "top 375px right 0px, top 0px right 0px",
  backgroundRepeat: "no-repeat",
  backgroundSize: "100%",
})
