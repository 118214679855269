import React, { useEffect, useState } from "react"
import styled from "styled-components"
import devices from "styles/devices"
import axios from "axios"
import { IS_DEV } from "../../../Config"
import { FullApplication } from "models/FullApplication"
import { DefaultContent } from "components/organisms/NurtureCalendar/DefaultContent"
import {
  BookingCalendarContent,
  BookingCalendarContentProps,
} from "components/organisms/NurtureCalendar/BookingCalendarContent"
import { LogGaEvent } from "lib/GoogleAnalytics"
import { EventAction, EventCategory } from "models/GoogleAnalytics"
import dayjs from "dayjs"
import { useExperienceTLS } from "hooks/useExperienceTLS"
import { Loader, LoaderTheme } from "components/atoms/Loader"
import AvailableTimesExperiment from "../../../experiments/FreeAvailableTimeslots/availableTimesExperimentContext"

function bookingFromDate() {
  const date = new Date()
  const month = date.getMonth()
  date.setMonth(month)
  date.setDate(1)
  return date
}

const getMonth = async (calendarId: number, appointmentTypeId: string, month: string) => {
  const url = new URL(
    `${
      process.env.MY_MOJO_API
    }/appointments/availability/dates?appointmentTypeId=${appointmentTypeId}&month=${month}&source=${encodeURIComponent(
      window.location.href
    )}`
  )

  return await axios.get(url.toString()).catch((e) => {
    return console.error("Unable to fetch dates: " + e.message)
  })
}

const getTimes = async (calendarId: number, appointmentTypeId: string, date: string) => {
  const url = new URL(
    `${process.env.MY_MOJO_API}/appointments/availability/times?appointmentTypeId=${appointmentTypeId}&date=${date}`
  )
  return await axios.get(url.toString()).catch((e) => {
    return console.error("Unable to fetch times for this date: " + e.message)
  })
}

export const Container = styled.div<{ isModal: boolean }>`
  background: white;
  border-radius: 24px;
  padding: 18px;
  @media screen and ${devices.bp_lg} {
    padding: ${(props) => (props.isModal ? "2em" : "4em")};
  }
`

interface BookingCalendarProps {
  /**
   * An in-progress application. Can be fetched with `const { application } = useApplicationFriendlyId(friendlyId);`
   */
  application: FullApplication
  /**
   * Skips initial landing interface that requires you to click a button to view the calendar.
   */
  skipToCalendar?: boolean
  crm?: boolean
  /**
   * Specify only if you want appointment to go to a specific adviser
   */
  calendarId: BookingCalendarContentProps["calendarId"]
  /**
   * The `friendlyId` associated with this application. This will define the appointment type and the calendars it is sent to.
   * In development it is automatically set to a test adviser.
   */
  friendlyId: string
  /**
   * This will define the appointment type and the calendars it is sent to. Do not override this unless you absolutely need to.
   */
  appTypeId?: string
  /**
   * Callback when booking has succeeded
   */
  bookingComplete?: BookingCalendarContentProps["bookingComplete"]
  /**
   * Callback when booking has succeeded
   */
  refreshApplication: BookingCalendarContentProps["refreshApplication"]
  /**
   * Shows calendar in a modal
   * @default false
   */
  isModal?: BookingCalendarContentProps["isModal"]
  /**
   * Text to show above the calendar.
   * @default "Book a call"
   */
  customHeader?: BookingCalendarContentProps["customHeader"]

  /**
   * Utm data to track against any booked appointments
   */
  utm?: {
    source?: string
    medium?: string
    campaign?: string
  }
}

/**
 * Wraps the component containing the booking logic. Sets up logging and states, then renders the UI.
 * In `DEV` all appointments will be sent to a test adviser.
 */
export const BookingCalendar = ({
  application,
  skipToCalendar = false,
  crm = false,
  calendarId,
  friendlyId,
  appTypeId,
  bookingComplete,
  refreshApplication,
  isModal,
  customHeader,
  utm = null,
}: BookingCalendarProps) => {
  const CALENDAR_ID = calendarId

  const { experienceTLS, hasFetchedExperienceTLS } = useExperienceTLS(friendlyId)

  const APPOINTMENT_TYPE_ID = React.useMemo(() => {
    if (IS_DEV) {
      console.log(
        "You're running in dev mode, all appointments will be booked with 34559389: Alan Smithy."
      )
      console.log(`CalendarId is: ${CALENDAR_ID}.`)
      return "34559389"
    } else {
      return appTypeId !== undefined
        ? appTypeId
        : hasFetchedExperienceTLS
        ? experienceTLS.rule.appointmentId
        : undefined
    }
  }, [hasFetchedExperienceTLS, IS_DEV, appTypeId])

  const [isBookingVisible, setIsBookingVisible] = useState<boolean>(skipToCalendar)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [dates, setDates] = useState<string[]>([])
  const [times, setTimes] = useState<Date[]>([])
  const [selectedDate, setSelectedDate] = useState<Date | null>(null)
  const [appointmentTime, setAppointmentTime] = useState<Date>()
  const [firstAvailableMonth, setFirstAvailableMonth] = useState<Date | null>(null)
  const [remoDate, setRemoDate] = useState<Date | null>(null)
  const [currentDisplayedMonth, setCurrentDisplayedMonth] = useState<Date | null>(null)

  // We can only set remoDate once we have it available in the application.
  useEffect(() => {
    if (Object.keys(application).length === 0) return
    setRemoDate(
      application?.remortgageDetails?.initialPeriodEndDate
        ? new Date(application.remortgageDetails.initialPeriodEndDate)
        : new Date()
    )
  }, [application])

  // We can only set currentDisplayedMonth once we have a remoDate.
  useEffect(() => {
    if (currentDisplayedMonth || !remoDate) return
    setCurrentDisplayedMonth(bookingFromDate())
  }, [remoDate])

  // if currentDisplayedMonth changes - make a new API call to get availability
  useEffect(() => {
    if (APPOINTMENT_TYPE_ID !== undefined) {
      if (!currentDisplayedMonth) return
      fetchMonth().then()
    }
  }, [currentDisplayedMonth, APPOINTMENT_TYPE_ID])

  useEffect(() => {
    if (appointmentTime)
      LogGaEvent({
        action: EventAction.apptTimeSelected,
        event_category: EventCategory.functionalInteraction,
        event_label: appointmentTime.toString(),
      })
  }, [setAppointmentTime])

  const fetchMonth = async () => {
    setIsLoading(true)
    const res: any = await getMonth(
      CALENDAR_ID,
      APPOINTMENT_TYPE_ID,
      `${currentDisplayedMonth.getFullYear()}-${currentDisplayedMonth.toLocaleString("default", {
        month: "2-digit",
      })}`
    )
    if (res.data.filteredResults.length === 0) {
      LogGaEvent({
        action: EventAction.apptDatesNoneAvailable,
        event_category: EventCategory.formInteraction,
        event_label: res.data.metaData.appointmentTypeId,
      })
    }

    setIsLoading(false)
    const datesArray = res?.data?.filteredResults?.map(({ date }) => date)

    LogGaEvent({
      action: EventAction.apptDatesShown,
      event_category: EventCategory.siteInteraction,
      event_label: JSON.stringify(datesArray),
    })

    LogGaEvent({
      action: EventAction.apptDatesShownAmount,
      event_category: EventCategory.siteInteraction,
      event_label: datesArray?.length.toString(),
    })

    if (!firstAvailableMonth) {
      setFirstAvailableMonth(new Date(res.data?.metaData?.availabilityMonthStart))
    }

    setTimes([])
    setDates(datesArray)
  }

  const fetchTimes = async (date: Date) => {
    setAppointmentTime(null)
    setSelectedDate(date)

    const date1 = dayjs(date)
    const date2 = dayjs()
    LogGaEvent({
      action: EventAction.calendarDateSelected,
      event_category: EventCategory.functionalInteraction,
      event_label: `${date1.diff(date2, "day")} days`,
    })

    setIsLoading(true)
    const res: any = await getTimes(
      CALENDAR_ID,
      APPOINTMENT_TYPE_ID,
      `${date.getFullYear()}-${date.toLocaleString("default", {
        month: "2-digit",
      })}-${date.toLocaleString("default", { day: "2-digit" })}`
    )
    setIsLoading(false)
    const timesArray = res?.data?.map(({ time, slotsAvailable }) => {
      if (slotsAvailable >= 1) {
        return new Date(time)
      }
    })

    LogGaEvent({
      action: EventAction.apptTimeslotsShown,
      event_category: EventCategory.siteInteraction,
      event_label: JSON.stringify(
        timesArray.map((timeslot) => dayjs(timeslot.time).format("HH:mm"))
      ),
    })
    LogGaEvent({
      action: EventAction.apptTimeslotsShownAmount,
      event_category: EventCategory.siteInteraction,
      event_label: timesArray?.length.toString(),
    })

    setTimes(timesArray)
  }

  return (
    <AvailableTimesExperiment
      applicationId={application.applicationId}
      times={times}
      dates={dates}
      appointmentTypeId={APPOINTMENT_TYPE_ID}
      selectedDate={selectedDate}
      setSelectedDate={setSelectedDate}
    >
      <Container isModal={isModal}>
        {!isBookingVisible && <DefaultContent setIsBookingVisible={setIsBookingVisible} />}
        {isBookingVisible && !hasFetchedExperienceTLS && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Loader size={100} theme={LoaderTheme.Outline} />
          </div>
        )}
        {isBookingVisible && currentDisplayedMonth && hasFetchedExperienceTLS && (
          <BookingCalendarContent
            setIsLoading={setIsLoading}
            isLoading={isLoading}
            application={application}
            dates={dates}
            onSelectNextMonth={async () => {
              const nextMonth = new Date(
                currentDisplayedMonth.setMonth(currentDisplayedMonth.getMonth() + 1)
              )
              nextMonth.setDate(1)
              setCurrentDisplayedMonth(nextMonth)
            }}
            onSelectPreviousMonth={() => {
              const previousMonth = new Date(
                currentDisplayedMonth.setMonth(currentDisplayedMonth.getMonth() - 1)
              )
              previousMonth.setDate(1)
              setCurrentDisplayedMonth(previousMonth)
            }}
            currentDisplayedMonth={currentDisplayedMonth}
            onSelectDate={fetchTimes}
            selectedDate={selectedDate}
            times={times}
            setAppointmentTime={setAppointmentTime}
            appointmentTime={appointmentTime}
            crm={crm}
            calendarId={CALENDAR_ID}
            appointmentTypeId={APPOINTMENT_TYPE_ID}
            bookingComplete={bookingComplete}
            refreshApplication={refreshApplication}
            isModal={isModal}
            customHeader={customHeader}
            utm={utm}
          />
        )}
      </Container>
    </AvailableTimesExperiment>
  )
}
