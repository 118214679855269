import { AccordionDataSection } from "pages/Application/Eligibility/Coach/Accordion"
import React from "react"
import styled from "styled-components"

const FaqSection = styled.div`
  --bold: 550;
  line-height: 1.5em;
  h4 {
    font-size: 18px;
    margin: 0 0 1.5em;
    font-weight: var(--bold);
  }
  p {
    margin: 0 0 1.5em;
    a {
      font-weight: var(--bold);
      color: ${(props) => props.theme.primaryOne};
    }
  }
  strong {
    font-weight: var(--bold);
  }
`

export const Faqs: AccordionDataSection[] = [
  {
    name: "How do I upload documents?",
    id: "faq-1",
    trackingLabelPrefix: "Doc Upload-FAQs",
    page: (
      <FaqSection>
        <p>
          You will find the option to upload documents in your mymojo account. Just{" "}
          <a
            href="https://mymojo.mojomortgages.com/login"
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            login
          </a>{" "}
          to your account and check for the Documents option in the navigation.
        </p>
        <p>
          And of course, your can be certain that your documents will be{" "}
          <strong>securely stored</strong>.
        </p>
      </FaqSection>
    ),
  },
  {
    name: "What documents do I need for a mortgage recommendation?",
    id: "faq-2",
    trackingLabelPrefix: "Doc Upload-FAQs",
    page: (
      <FaqSection>
        <p>
          We’d like to see <strong>proof of ID</strong>, <strong>proof of income</strong> and{" "}
          <strong>bank statements</strong> for all applicants so your expert can validate these
          against both the information you have already provided and the mortgage lender’s borrowing
          criteria. It means you’re more likely to get a
          <strong>recommendation that’s right first time</strong> as it’s the best possible fit to
          your exact circumstances.
        </p>
        <p>
          Where we can, <strong>we’ll use these documents for your application too</strong>, saving
          you time and effort down the line.
        </p>
        <p>
          <strong>Note:</strong> You'll find full details of what we need and what’s acceptable on
          the Document upload page in your mymojo account.
        </p>
      </FaqSection>
    ),
  },
  {
    name: "What documents do I need to apply for a mortgage?",
    id: "faq-3",
    trackingLabelPrefix: "Doc Upload-FAQs",
    page: (
      <FaqSection>
        <p>
          We need to <strong>satisfy the requirements</strong> of the mortgage provider your
          application is going with, as well as meeting our own regulatory requirements with the
          FCA, so <strong>we absolutely must collect documents</strong>
          from you for all applicants when you apply for a mortgage. Basically, you will not be able
          to proceed with a mortgage application without providing documents.
        </p>
        <p>
          It’s generally a <strong>form of ID, proof of income</strong> and{" "}
          <strong>
            bank statements as a minimum so we can verify your identity and income/outgoings
          </strong>
          . We may need the original copies if the lender asks for them but don’t worry, if
          electronic copies are acceptable, you’ll be able to add them on here.
        </p>
        <p>
          <strong>Note:</strong> Your <strong>application may be cancelled</strong> if you do not
          provide these and any delays in providing these may result in you{" "}
          <strong>losing access to the product</strong> originally recommended by your expert (so
          we’ll have to go through your recommendation again).
        </p>
      </FaqSection>
    ),
  },
  {
    name: "What documents are acceptable?",
    id: "faq-4",
    trackingLabelPrefix: "Doc Upload-FAQs",
    page: (
      <FaqSection>
        <h4>Form of ID</h4>
        <p>
          A <strong>Passport</strong> or <strong>Driving Licence</strong> which meet the following
          criteria:
        </p>
        <ul>
          <li>
            Must be a current, <strong>valid</strong> document
          </li>
          <li>
            Must show your <strong>name</strong> and <strong>date of birth</strong>
          </li>
          <li>
            A <strong>clear, high quality colour scan or photo*</strong>
          </li>
          <li>All four corners visible</li>
        </ul>
        <p>
          * In some circumstances we may need a physical copy, but a clear, high quality photo or
          scan will usually do.
        </p>
        <h4>Proof of income</h4>
        <ul>
          <li>
            Provide the <strong>last 3 months’ payslips</strong> showing your name, current address
            and income <strong>OR</strong> If self-employed, provide instead{" "}
            <strong>2 years’ SA302s</strong> and either the{" "}
            <strong>corresponding self-employed tax year overviews</strong>
            or <strong>company accounts</strong>.
          </li>
          <li>
            <strong>PDF document preferred</strong> from your employer or HMRC , or a{" "}
            <strong>clear photo/scan of a paper copy</strong> with all four corners visible*
          </li>
        </ul>
        <p>
          *If you’re getting an initial recommendation from us, a clear, high quality photo or scan
          will do for now
        </p>
        <h4>Other documents</h4>
        <p>
          Once we start putting together your mortgage application, we may need more from you.
          Additional documents could include:
        </p>
        <ul>
          <li>Proof of deposit for your purchase</li>
          <li>
            Evidence of other sources of income or supplements to your basic income like bonuses or
            regular overtime
          </li>
          <li>Proof of right to reside in the UK</li>
          <li>Additional proof of address such as a council tax statement or a utility bill</li>
        </ul>
        <p>Your mortgage expert or case manager will keep you informed of what else is needed.</p>
      </FaqSection>
    ),
  },
  {
    name: "Where can I get documents from if I don’t have them?",
    id: "faq-5",
    trackingLabelPrefix: "Doc Upload-FAQs",
    page: (
      <FaqSection>
        <h4>Driving Licence or Passport</h4>
        <p>
          You can obtain a{" "}
          <a href="https://www.gov.uk/renew-driving-licence" target="_blank">
            driving licence
          </a>{" "}
          or{" "}
          <a href="https://www.gov.uk/apply-renew-passport" target="_blank">
            passport
          </a>{" "}
          through the relevant government application portals for each. Speak to your Mortgage
          Expert if you do not have either of these forms of ID to discuss your alternative options.
        </p>
        <h4>Payslips</h4>
        <p>
          Please obtain these directly from your employer - a PDF copy is preferred. Alternatively,
          a scan or photo of an original paper copy, with all four corners visible and content
          legible is acceptable as an alternative.
        </p>
        <h4>Self Employed SA302 document</h4>
        <p>
          These can be requested directly from{" "}
          <a href="https://www.gov.uk/sa302-tax-calculation" target="_blank">
            HMRC
          </a>{" "}
          - a PDF copy is preferred. Alternatively, a scan or photo of an original paper copy, with
          all four corners visible and content legible is acceptable as an alternative.
        </p>
        <h4>Bank Statements</h4>
        <p>
          If you use online banking with your account provider, you can download copies from your
          bank or building society’s online portal in PDF format. Alternatively, a scan or photo of
          an original paper copy, with all four corners visible and content legible is acceptable as
          an alternative.
        </p>
        <h4>Other</h4>
        <p>
          Any other documents should be obtained from the relevant issuing provider and provided in
          the most accessible format available to you and ideally, a scan or photo of an original
          paper copy, with all four corners visible and content legible is required.
        </p>
      </FaqSection>
    ),
  },
  {
    name: "I don’t have a scanner, how can I scan my documents?",
    id: "faq-6",
    trackingLabelPrefix: "Doc Upload-FAQs",
    page: (
      <FaqSection>
        <p>
          Don’t worry if you don’t have a scanner. You can use a scanning app on your mobile device
          instead. We recommend the Adobe Scan mobile app available on both{" "}
          <a
            href="https://apps.apple.com/gb/app/adobe-scan-pdf-card-scanner/id1199564834"
            target="_blank"
          >
            iPhone
          </a>{" "}
          and{" "}
          <a
            href="https://play.google.com/store/apps/details?id=com.adobe.scan.android&hl=en_GB"
            target="_blank"
          >
            Android
          </a>
          .
        </p>
      </FaqSection>
    ),
  },
]
