// see MDN docs for further options: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat

export const formatDate = (
  date: Date,
  options: any = { dateStyle: "medium", timeStyle: "short" }
) => {
  return new Intl.DateTimeFormat("en-GB", options).format(date)
}

export const formatAppointmentDateTime = (date: Date) => {
  if (date === null || typeof date !== "object") return ""
  const dayOfMonth = date.getDate()
  const day = dayOfMonth + nth(dayOfMonth)
  const month = date.toLocaleString("default", { month: "long" })
  const time = date.toLocaleString("default", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  })
  return `${day} ${month}, ${time.replace("00:", "12:").replace(" ", "")}`
}

export const formatAppointmentDate = (date: Date) => {
  if (date === null) return ""
  const dayOfMonth = date.getDate()
  const day = dayOfMonth + nth(dayOfMonth)
  const month = date.toLocaleString("default", { month: "long" })
  const year = date.getFullYear()

  return `${day} ${month} ${year}`
}

export const formatAppointmentTime = (date: Date) => {
  if (date === null) return ""
  const time = date.toLocaleString("default", {
    hour: "2-digit",
    minute: "2-digit",
    hourCycle: "h12",
  })
  return `${time.replace(" ", "")}`
}

// ordinal number suffix
export const nth = (d: number) => {
  if (d > 3 && d < 21) return "th"
  switch (d % 10) {
    case 1:
      return "st"
    case 2:
      return "nd"
    case 3:
      return "rd"
    default:
      return "th"
  }
}

// function to return month in this format: YYYY-MM
export const ISO8601FormattedMonth = (date: Date) => {
  const month = date.getMonth() + 1
  return `${date.getFullYear()}-${doubleDigit(month)}`
}

// function to return date in this format: YYYY-MM-DD
export const ISO8601FormattedDate = (date: Date) => {
  const month = date.getMonth() + 1
  const day = date.getDate()
  return `${date.getFullYear()}-${doubleDigit(month)}-${doubleDigit(day)}`
}

const doubleDigit = (num: number) => {
  return num < 10 ? "0" + num : num
}
