import React, { useState, useEffect, createContext } from "react"
import { ThemeProvider } from "styled-components"
import { Normalize } from "styled-normalize"
import GlobalStyles from "./GlobalStyles"
import { Helmet } from "react-helmet"
import { useConfig } from "hooks/useConfig"
import { RootThemeContext } from "../contexts/root-theme-context"

export default function Theme({ children }) {
  const { theme } = useConfig()
  const [blockScroll, setBlockScroll] = useState<boolean>(false)

  return (
    <RootThemeContext.Provider value={{ setBlockScroll }}>
      <ThemeProvider theme={theme}>
        <Helmet>
          <title>{theme.pageTitle}</title>
          <link rel="icon" type="image/png" href={theme.faviconURL} sizes="16x16" />
        </Helmet>
        <Normalize />
        <GlobalStyles blockScroll={blockScroll} />
        {children}
      </ThemeProvider>
    </RootThemeContext.Provider>
  )
}
