import React from "react"
import ReactDOM from "react-dom"
import { Card } from "components/atoms/Card"
import styled, { createGlobalStyle, ThemeContext } from "styled-components"
import { tint } from "polished"
import { Button, ButtonType } from "components/atoms"
import Tooltip from "components/_blueprint/Tooltip"
import { IconStyleable, IconType } from "components/atoms/Icon"
import { defaultTheme } from "styles/DefaultTheme"
import { animated, useTransition } from "@react-spring/web"
import devices from "styles/devices"
import { SmooveQuote, useInterestedInQuote } from "hooks/useConveyancing"
import { useHistory } from "react-router-dom"
import { useApplicationFriendlyId } from "hooks/useApplication"
import { LogGaEvent } from "lib/GoogleAnalytics"
import { EventAction, EventCategory } from "models/GoogleAnalytics"
import { HasAppointmentBooked } from "lib/HasAppointmentBooked"
import Typography from "components/_blueprint/Typography"
import dayjs from "dayjs"

/**
 * Card styles
 */

const InternalContainer = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;

  @media ${devices.bp_lg} {
    flex-direction: row;
  }
`

const RatesContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media ${devices.bp_md} {
    flex-direction: row;
  }

  flex: 1 1 0;
  justify-content: space-between;

  background-color: ${(props) => tint(0.95, props.theme.colors.primary.one)};
  border-radius: ${(props) => props.theme.radius.default};
  padding: 1rem;
`

const TwoRateContainer = styled.div`
  display: flex;
  flex-direction: row;

  flex: 1 1 0;
  justify-content: space-between;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media ${devices.bp_sm} {
    flex-direction: row;
    gap: 1rem;
    justify-content: end;
  }

  @media ${devices.bp_md} {
    flex-direction: column;
  }

  & button {
    min-width: 210px;
  }
`

const Rates = styled.div<{
  $hasBackground?: boolean
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;

  background-color: ${(props) =>
    props.$hasBackground ? tint(0.9, props.theme.colors.primary.one) : "transparent"};
  border-radius: ${(props) => props.theme.radius.default};

  padding: 0.5rem;
  flex: 1 1 0;

  @media ${devices.bp_md} {
    padding: 1rem 1.5rem;
    text-align: left;
  }

  ${(props) =>
    props.$hasBackground &&
    `
		& > * {
			text-align: center;
		}

		@media ${devices.bp_md} {
			max-width: 185px;
		}
	`};
`

const TitleContainer = styled.div`
  position: relative;

  display: flex;
  flex-direction: row;
  gap: 0.5rem;

  & > button {
    position: relative;
  }
`

/**
 * Panel Styles
 */

const PanelOverlay = styled(animated.div)`
  background-color: ${(props) => props.theme.primaryThree};
  opacity: 0.4;
  position: fixed;
  inset: 0px;
  z-index: 1001;
`

const Panel = styled(animated.div)`
  background-color: ${(props) => props.theme.primaryFour};
  border-radius: ${(props) => props.theme.radius.large} 0 0 ${(props) => props.theme.radius.large};
  padding: 1rem;
  max-width: 590px;
  width: 100%;

  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1002;

  overflow-y: auto;
`

const HideBodyOverflow = createGlobalStyle`
	body {
		overflow-y: hidden !important;;
	}
`

const PanelContainers = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  gap: 1rem;

  background-color: ${(props) => tint(0.95, props.theme.colors.primary.one)};
  border-radius: ${(props) => props.theme.radius.default};
  padding: 1rem;
`

const SpacedContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const QuoteFee = styled.span`
  font-size: 23px;
  line-height: 135%;
  margin-top: 0;
  margin-bottom: 0px;
  font-weight: 600;
`

// this portal is required to render the modal outside of the current DOM flow
// or it will clash with z-index of the main body content
const Portal = ({ children, className = "root-portal", el = "div" }) => {
  const [container] = React.useState(() => {
    // This will be executed only on the initial render
    // https://reactjs.org/docs/hooks-reference.html#lazy-initial-state
    return document.createElement(el)
  })

  React.useEffect(() => {
    container.classList.add(className)
    document.body.appendChild(container)
    return () => {
      document.body.removeChild(container)
    }
  }, [])

  return ReactDOM.createPortal(children, container)
}

export interface ConveyancingCard {
  quote: SmooveQuote
  friendlyId: string
  // callback when user successfully registers interest for a quote
  onRegisterInterest?: () => void
}

/**
 * Renders a card that displays a conveyancing quote.
 * Allows user to express interest for a specific quote as well as opening a modal to view more info.
 */
const ConveyancingCard = ({ quote, friendlyId }: ConveyancingCard) => {
  const [error, setError] = React.useState<string | undefined>(undefined)
  // for button state
  const [isLoading, setIsLoading] = React.useState(false)
  // shows an extra info modal
  const [detailsOpen, setDetailsOpen] = React.useState(false)
  const history = useHistory()

  const { application } = useApplicationFriendlyId(friendlyId)
  const hasAppointment = HasAppointmentBooked(application)
  const { mutate } = useInterestedInQuote(application)

  const transitions = useTransition(detailsOpen, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })

  const theme = React.useContext(ThemeContext)

  const CoreConveyancing = quote.AdjustedConveyancingCost
  const OtherFees = quote.AdjustedOtherFees
  const VAT = quote.VATTotal
  const Total = quote.AdjustedTotalCost

  const prettyPrintCurrency = (val: number) => {
    return new Intl.NumberFormat("en-EN", { style: "currency", currency: "GBP" }).format(val)
  }

  return (
    <>
      <div>
        <Card
          style={{
            padding: 24,
          }}
          content={
            <InternalContainer>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flex: "1 0 0",
                }}
              >
                <Typography.H5>{quote.ServiceProviderName}</Typography.H5>
                <RatesContainer>
                  <TwoRateContainer>
                    <Rates>
                      <TitleContainer>
                        <Typography.Text
                          size="small"
                          style={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          Rating
                        </Typography.Text>
                        <Tooltip
                          questionMarkColor={theme.primaryOne}
                          eventLabel="Rating tooltip"
                          content={
                            <>
                              <Typography.H6 color="white">Customer rating</Typography.H6>
                              <Typography.SmallBody color="white">
                                These are satisfaction ratings compiled using feedback from other
                                customers. If no rating is shown for a service providerit simply
                                means that we do not yet have sufficient feedback to provide a fair
                                rating. If a service provider has had less than 20 ratings, we show
                                the number of ratings.
                              </Typography.SmallBody>
                            </>
                          }
                        />
                      </TitleContainer>
                      <QuoteFee
                        style={{
                          whiteSpace: "nowrap",
                        }}
                      >
                        {quote.ServiceProviderRatingString}
                      </QuoteFee>
                    </Rates>
                    <Rates>
                      <Typography.Text
                        size="small"
                        style={{
                          whiteSpace: "nowrap",
                        }}
                      >
                        Conveyancing
                      </Typography.Text>
                      <QuoteFee>{prettyPrintCurrency(CoreConveyancing)}</QuoteFee>
                    </Rates>
                  </TwoRateContainer>
                  <TwoRateContainer>
                    <Rates>
                      <TitleContainer>
                        <Typography.Text
                          size="small"
                          style={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          Other fees
                        </Typography.Text>
                        <Tooltip
                          questionMarkColor={theme.primaryOne}
                          eventLabel="Other fees tooltip"
                          content={
                            <>
                              <Typography.H6 color="white">Other fees</Typography.H6>
                              <Typography.SmallBody color="white">
                                These are a mixture of admin and search fees charged by the
                                conveyancer to complete certain checks. You can find a breakdown of
                                these fees when you click ‘View details’
                              </Typography.SmallBody>
                            </>
                          }
                        />
                      </TitleContainer>
                      <QuoteFee>{prettyPrintCurrency(OtherFees)}</QuoteFee>
                    </Rates>
                    <Rates>
                      <Typography.Text
                        size="small"
                        style={{
                          whiteSpace: "nowrap",
                        }}
                      >
                        VAT
                      </Typography.Text>
                      <QuoteFee>{prettyPrintCurrency(VAT)}</QuoteFee>
                    </Rates>
                  </TwoRateContainer>
                  <Rates $hasBackground>
                    <Typography.Text
                      size="small"
                      style={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      Total
                    </Typography.Text>
                    <QuoteFee>{prettyPrintCurrency(Total)}</QuoteFee>
                  </Rates>
                </RatesContainer>
              </div>
              <ButtonContainer>
                <Button
                  data-testid="primaryButton"
                  text={hasAppointment ? "I'm interested" : "Book a call"}
                  showLoader={isLoading}
                  disabled={isLoading}
                  onClick={async () => {
                    // if a user has already expressed interest we display a button that sends them to the schedule page
                    if (!quote.IsInterested) {
                      LogGaEvent({
                        action: EventAction.buttonClicked,
                        event_category: EventCategory.siteInteraction,
                        event_label: hasAppointment ? "Conveyancing interest" : "Book a call",
                      })

                      setIsLoading(true)
                      try {
                        // sends interest to salesforce
                        await mutate(quote.QuoteRef)
                        // once interest is recorded we redirect users to schedule a meeting
                        history.push(`/application/${friendlyId}/conveyancing/schedule`)
                      } catch (e) {
                        console.error(e)
                        setError("Something went wrong, please try again.")
                      }
                      setIsLoading(false)
                    } else {
                      history.push(`/application/${friendlyId}/conveyancing/schedule`)
                    }
                  }}
                />
                <Button
                  type={ButtonType.Outline}
                  text="View details"
                  onClick={() => {
                    LogGaEvent({
                      action: EventAction.buttonClicked,
                      event_category: EventCategory.siteInteraction,
                      event_label: "Conveyancing details",
                    })

                    setDetailsOpen(true)
                  }}
                />
              </ButtonContainer>
            </InternalContainer>
          }
        />
        {error !== undefined && (
          <div
            style={{
              paddingTop: ".5rem",
              textAlign: "center",
            }}
          >
            {error}
          </div>
        )}
      </div>
      {/**
       * This is a kind-of accessible modal. Please don't use this anywhere else.
       * TODO: Switch this out for a real modal component.
       */}
      {transitions((style, active) => (
        <>
          {active && (
            <Portal>
              <PanelOverlay
                data-state="open"
                style={{
                  pointerEvents: "auto",
                  opacity: style.opacity.to([0, 1], [0, 0.4]),
                }}
                data-aria-hidden="true"
                aria-hidden="true"
              />
              <Panel
                role="dialog"
                id="radix-:r0:"
                aria-describedby="mojo-:r2:"
                aria-labelledby="mojo-:r1:"
                data-state="open"
                tabIndex={-1}
                style={{
                  pointerEvents: "auto",
                  opacity: style.opacity.to([0, 1], [0, 1]),
                  transform: style.opacity
                    .to([0, 1], [100, 0])
                    .to((value) => `translateX(${value}px)`),
                }}
              >
                <button
                  style={{
                    display: "flex",
                    alignItems: "center",
                    background: "none",
                    border: "none",
                    outline: "none",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setDetailsOpen(false)
                  }}
                  aria-label="Close"
                >
                  <IconStyleable
                    fillDefault={defaultTheme.colors.primary.one}
                    width={24}
                    height={24}
                    type={IconType.Cross}
                  />
                  Close
                </button>
                <Typography.H5
                  id="mojo-:r1:"
                  style={{
                    marginTop: "2rem",
                  }}
                >
                  {quote.ServiceProviderName}
                </Typography.H5>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                  }}
                >
                  <PanelContainers>
                    <Typography.Text weight={600}>Fee breakdown</Typography.Text>
                    <div>
                      <Typography.Text size="small" weight={700}>
                        Legal work
                      </Typography.Text>
                      <SpacedContainer>
                        <Typography.Text size="small">Core conveyancing</Typography.Text>
                        <Typography.Text size="small">
                          {prettyPrintCurrency(CoreConveyancing)}
                        </Typography.Text>
                      </SpacedContainer>
                    </div>
                    <div>
                      <Typography.Text size="small" weight={700}>
                        Other fees
                      </Typography.Text>
                      {quote.QuoteItems.map((item, i) => {
                        if (item.Description === null) {
                          return null
                        }
                        if (item.Description.includes("Stamp Duty")) {
                          return null
                        }
                        return (
                          <div
                            key={i}
                            style={{
                              borderBottom:
                                quote.QuoteItems.length !== i + 1
                                  ? `1px solid ${theme.shade70}`
                                  : "none",
                              paddingBottom: "0.5rem",
                              marginBottom: "0.5rem",
                            }}
                          >
                            <SpacedContainer>
                              <Typography.Text size="small">
                                {item.Description || "Other"}
                              </Typography.Text>
                              <Typography.Text size="small">
                                {prettyPrintCurrency(item.Amount)}
                              </Typography.Text>
                            </SpacedContainer>
                          </div>
                        )
                      })}
                    </div>
                    <div>
                      <SpacedContainer>
                        <Typography.Text size="small">VAT</Typography.Text>
                        <Typography.Text size="small">{prettyPrintCurrency(VAT)}</Typography.Text>
                      </SpacedContainer>
                    </div>
                    <div>
                      <SpacedContainer>
                        <Typography.Text size="small" weight={700}>
                          Total
                        </Typography.Text>
                        <Typography.Text size="small" weight={700}>
                          {prettyPrintCurrency(Total)}
                        </Typography.Text>
                      </SpacedContainer>
                    </div>
                  </PanelContainers>
                  <PanelContainers
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography.Text weight={600}>Customer rating</Typography.Text>
                    <Typography.Text
                      size="small"
                      style={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      {quote.ServiceProviderRatingString}
                    </Typography.Text>
                  </PanelContainers>
                  <Typography.Text>
                    This quote is valid until{" "}
                    {dayjs(quote.CreatedDate).add(2, "weeks").format("MMMM D, YYYY")}
                  </Typography.Text>
                  <Typography.Text weight={600}>Notes</Typography.Text>
                  <ol>
                    {quote.Notes.map((n, i) => (
                      <li key={i}>
                        <Typography.Text>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: n.replace(/^\d+\s*[-\\.)]?\s+/i, ""),
                            }}
                          />
                        </Typography.Text>
                      </li>
                    ))}
                  </ol>
                </div>
              </Panel>
            </Portal>
          )}
          {/* We want hiding body overflow to be instant */}
          {detailsOpen && <HideBodyOverflow />}
        </>
      ))}
    </>
  )
}

export default ConveyancingCard
