import styled from "styled-components"
import { desktop, desktopContentPadding, mobile, tablet } from "components/_blueprint/Layout"

export const Illustration = styled.img<{ leftOffset: number }>(({ leftOffset }) => ({
  alignSelf: "end",
  gridColumnStart: 9,
  gridColumnEnd: "span 4",
  gridRowStart: 1,
  marginBottom: -desktopContentPadding, // offseting container padding
  marginLeft: leftOffset,
  pointerEvents: "none",
  width: 500,
  [mobile]: {
    display: "none",
  },
  [tablet]: {
    display: "none",
  },
  [desktop]: {
    gridColumnEnd: "span 4",
  },
}))
