import React, { Dispatch, SetStateAction, useEffect, useLayoutEffect, useState } from "react"
import { PaperworkNoShadow } from "components/Illustrations"
import { LogGaEvent } from "lib/GoogleAnalytics"
import Carousel from "../../../../components/organisms/Carousel"
import { Booking, Props as BookingProps } from "../Approved/Booking"
import { BenefitsBoxWithImage } from "./BenefitsWithImage"
import { FullApplication } from "models/FullApplication"
import { EventAction, EventCategory } from "models/GoogleAnalytics"
import { slides } from "./slides"
import { LoadingSpinner } from "pages/Application/Eligibility/EligibilityLoader"
import {
  CarouselTitle,
  Container,
  HeroImage,
  HeroImageContainer,
  InnerContainer,
  InnerWrapper,
  SmallPrint,
  Wave,
  Wrapper,
} from "./Shared"
import { RatesBanner } from "components/molecules/RatesBanner"

export interface Props {
  fullApplication: FullApplication
  isBookingVisible: boolean
  setIsBookingVisible: Dispatch<SetStateAction<boolean>>
  bookingComplete: boolean
  setBookingComplete: Dispatch<SetStateAction<boolean>>
  children: React.ReactNode
  refreshApplication: () => void
}

export const IncreaseBookingsPage = ({
  setIsBookingVisible,
  isBookingVisible,
  fullApplication,
  bookingComplete,
  setBookingComplete,
  children,
  refreshApplication,
}: Props) => {
  useLayoutEffect(() => {
    LogGaEvent({
      action: EventAction.componentDisplay,
      event_category: EventCategory.siteInteraction,
      event_label: "Increased Bookings",
    })
  }, [])

  const [isLoading, setIsLoading] = useState<boolean>(true)

  const bookingProps: BookingProps = {
    isBookingVisible: isBookingVisible,
    setIsBookingVisible: setIsBookingVisible,
    bookingComplete: bookingComplete,
    setBookingComplete: setBookingComplete,
    fullApplication: fullApplication,
    refreshApplication: refreshApplication,
  }

  useEffect(() => {
    if (Object.keys(fullApplication).length > 0) {
      setIsLoading(false)
    }
    if (!fullApplication.appointments.length) return
  }, [fullApplication])

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <div>
      <Booking {...bookingProps} />
      <Wave>
        <RatesBanner />
        <Container>
          <InnerContainer>
            {children}
            <HeroImageContainer>
              <HeroImage src={PaperworkNoShadow} alt="Paperwork" />
            </HeroImageContainer>
          </InnerContainer>
        </Container>
      </Wave>
      <Wrapper>
        <InnerWrapper hasBooked={bookingComplete}>
          <BenefitsBoxWithImage />

          <CarouselTitle>Check out what our customers have said about us.</CarouselTitle>

          <Carousel background={"#fafbfd"} slides={slides} />
        </InnerWrapper>
      </Wrapper>
      <SmallPrint>
        <p>
          Your property may be repossessed if you do not keep up repayments on your mortgage. You
          may have to pay a charge to exit an existing mortgage deal early. Think carefully before
          securing other debts against your property.
        </p>
      </SmallPrint>
    </div>
  )
}
