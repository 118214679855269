import React from "react"
import styled from "styled-components"
import Tick from "assets/images/Tick.svg"

const CircleRadius = "22px"

const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${CircleRadius};
  height: ${CircleRadius};
  border-radius: 50%;
  background: #d8dce7;

  &.enabled {
    background: ${(props) => props.theme.primary};
  }

  &.disabled {
    background: ${(props) => props.theme.shade[60]};
  }

  &.circle {
    ::after {
      content: "";
      background: white;
      display: block;
      width: calc(${CircleRadius} / 2 - 1px);
      height: calc(${CircleRadius} / 2 - 1px);
      border-radius: 50%;
    }
  }

  &.tick {
    ::after {
      content: url(${Tick});
      color: white;
      transform: scale(1);
    }
  }
`

type Props = {
  enabled?: boolean
  type?: "circle" | "tick"
  disabled?: boolean
}

export function MojoCircle({ enabled = false, type = "circle", disabled = false }: Props) {
  const classNames = []
  classNames.push(type)

  if (enabled) {
    classNames.push("enabled")
  }

  if (disabled) {
    classNames.push("disabled")
  }

  return <Circle className={classNames.join(" ")} />
}
