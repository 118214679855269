import { IFormState, Question } from "models/FormDataCollection"
import * as EmailValidator from "email-validator"
import { LogGaEvent } from "lib/GoogleAnalytics"
import { isMobile } from "react-device-detect"
import { EventAction, EventCategory } from "models/GoogleAnalytics"

type ActionType = "silentUpdateValue" | "showOnly" | "updateValue" | "reset" | "init"

type Action = {
  type: ActionType
  payload?: {
    id: string
    value: any
  }
  resetState?: any
}

// This function sets the value of the fields in the state.
function resetForwardValues(state: IFormState, questionIndex: number) {
  return {
    completed: false,
    started: true,
    questions: state.questions.map((question, index) => {
      if (index > questionIndex) {
        return {
          ...question,
          value: question.autoValue || undefined,
          error: null,
          visible: false,
        }
      }
      return question
    }),
  }
}

function showNextQuestion(state: IFormState, questionIndex: number) {
  let testing = true
  let completed = false
  let count = 1

  const question = state?.questions[questionIndex]

  question?.value && LogQuestionValue(question)
  question?.config?.onDoneHandler && question.config.onDoneHandler(state)

  while (testing) {
    if (state.questions[questionIndex + count]) {
      const conditionals = state.questions[questionIndex + count]?.conditional ?? []
      if (!conditionals || conditionals.length === 0) {
        const testing = setVisible()

        if (!testing) {
          break
        }
      }

      let shouldShow = true

      for (const condition of conditionals) {
        if (condition === null || condition === undefined) {
          shouldShow = true
          break
        }

        let passed = true

        const queryQuestion = state.questions.find((question) => question.id === condition.id)
        const queryQuestionValue = condition.key
          ? queryQuestion?.value?.[condition.key]
          : queryQuestion?.value

        switch (condition.type) {
          case "eval":
            if (typeof condition.value === "function") {
              passed = condition.value(state)
              break
            }
            passed = condition.value
            break
          case "equals":
            passed = condition.values.find((value) => value === queryQuestionValue) !== undefined
            break
          case "isNot":
            passed = condition.values.find((value) => value === queryQuestionValue) === undefined
            break
          case "contains":
            passed = queryQuestionValue?.includes(condition.value) ?? false
            break
          case "excludes":
            passed = !queryQuestionValue.includes(condition.value) ?? false
            break
        }

        if (!passed) {
          shouldShow = false
          break
        }
      }

      if (shouldShow) {
        const testing = setVisible()

        if (!testing) {
          break
        }
      }

      count++
      continue
    } else {
      completed = true
    }

    if (completed) {
      return {
        ...state,
        completed: true,
      }
    }
  }

  return state

  function setVisible(): boolean {
    const question = state.questions[questionIndex + count]
    question.visible = true

    LogGaEvent({
      action: EventAction.questionShown,
      event_category: EventCategory.siteInteraction,
      event_label: question.id,
    })

    testing = false

    if (question.autoValue) {
      question.value = question.autoValue

      if (question.autoValueForceProgress) {
        testing = true
        LogQuestionValue(question)
      }
    }

    return testing
  }
}

function LogQuestionValue(question: Question) {
  if (!question) {
    return
  }

  let valueToLog = question.value

  if (question?.config?.analyticsValueOverrider) {
    valueToLog = question.config.analyticsValueOverrider(question.value)
  }

  LogGaEvent({
    action: EventAction.questionAnswered,
    event_category: EventCategory.formInteraction,
    event_label: question.id,
    field_value: question.isPII ? null : valueToLog,
  })
}

function checkForErrors(state: IFormState, questionIndex: number) {
  let error = null

  const questions = state.questions.map((question, index) => {
    if (!!question.validation && questionIndex === index) {
      let internalError = ""
      question.validation.map((check) => {
        if (!error) {
          switch (check.type) {
            case "between": {
              const questionValue = state.questions[questionIndex].value
              if (questionValue < check.range[0] || questionValue > check.range[1]) {
                error = check.error
                internalError = check.error
              }
              break
            }
            case "regexMatch": {
              const questionValueString1 = `${state.questions[questionIndex].value}`
              if (questionValueString1.match(new RegExp(check.regex)) !== null) {
                error = check.error
                internalError = check.error
              }
              break
            }
            case "regexNoMatch": {
              const questionValueString2 = `${state.questions[questionIndex].value}`
              if (questionValueString2.match(new RegExp(check.regex)) === null) {
                error = check.error
                internalError = check.error
              }
              break
            }
            case "email":
              if (
                EmailValidator.validate(`${state.questions[questionIndex].value}`) === false &&
                question.config?.canSkip === false &&
                state.questions[questionIndex].value !== ""
              ) {
                error = check.error
                internalError = check.error
              }
              break
            case "eval":
              if (check.function(state) === false) {
                error = check.error
                internalError = check.error
              }
          }
        }
      })

      if (internalError) {
        return {
          ...question,
          error: internalError,
        }
      } else {
        return {
          ...question,
          error: null,
        }
      }
    } else {
      return question
    }
  })

  return {
    error,
    state: {
      ...state,
      questions,
    },
  }
}

export default function reducer(state: IFormState, action: Action): IFormState {
  const questionIndex =
    state.questions.findIndex((question) => question.id === action?.payload?.id) ?? -1
  const stateClone = { ...state }

  if (questionIndex === -1) {
    const stateForwardReset = resetForwardValues(state, questionIndex)
    const stateShowNextQuestion = showNextQuestion(stateForwardReset, questionIndex)
    return stateShowNextQuestion
  }

  stateClone.questions[questionIndex] = {
    ...stateClone.questions[questionIndex],
    value: action.payload.value,
  }

  const errorsValidated = checkForErrors(stateClone, questionIndex)

  switch (action.type) {
    case "silentUpdateValue":
      return resetForwardValues(stateClone, questionIndex)
    case "showOnly":
      if (errorsValidated.error) {
        return errorsValidated.state
      } else {
        if (
          isMobile &&
          document.activeElement &&
          document.activeElement instanceof HTMLInputElement
        ) {
          document.activeElement.blur()
        }

        return showNextQuestion(errorsValidated.state, questionIndex)
      }
    case "updateValue":
      if (errorsValidated.error) {
        return errorsValidated.state
      } else {
        const stateForwardReset = resetForwardValues(errorsValidated.state, questionIndex)
        const stateShowNextQuestion = showNextQuestion(stateForwardReset, questionIndex)
        return stateShowNextQuestion
      }
    case "reset":
      return action.resetState
    case "init": {
      const stateShowNextQuestion = showNextQuestion(state, questionIndex)
      return stateShowNextQuestion
    }
    default:
      return state
  }
}
