import scrollIntoView from "smooth-scroll-into-view-if-needed"

export function ScrollToElement(element: HTMLElement) {
  scrollIntoView(element, {
    scrollMode: "always",
    behavior: "auto",
    block: "start",
  })
}

/**
 * Scrolls the page to the last element with matching `className`
 * @param className	- className to scroll to
 */
export function ScrollTo(className = "scrollAnchor") {
  const targetElement = GetScrollTarget(className)

  if (!targetElement) return

  // this doesn't actually need an external dep, could be done with targetElement.scrollIntoView
  scrollIntoView(targetElement, {
    scrollMode: "always",
    behavior: "smooth",
    block: "start",
    duration: 0,
  })
}

/**
 * Will return the last element with the specified `className` on the page
 * @param className - Classname to search for
 */
export function GetScrollTarget(className = "scrollAnchor"): HTMLElement | null {
  const scrollAnchor = document.getElementsByClassName(className)

  if (scrollAnchor.length < 1) return null

  return Array.from(scrollAnchor).pop() as HTMLElement
}
