export function GetUrlParameter(name: string) {
  const params = new URLSearchParams(window.location.search)
  return params.get(name)
}

export function SearchParams() {
  let qs = window.location.search

  if (!qs) return ""

  if (qs.startsWith("?")) {
    qs = qs.substr(1)
  }

  return qs
}
