import React from "react"
import * as PopoverPrimitive from "@radix-ui/react-popover"
import { LogGaEvent } from "lib/GoogleAnalytics"
import { EventAction, EventCategory, EventLabel } from "models/GoogleAnalytics"
import { Icon, IconStyleable, IconType } from "components/atoms/Icon"
import styled from "styled-components"
import devices from "styles/devices"

export const questionMarkColors = {
  WHITE: "white",
  NAVY: "#666B7E",
}
export interface TooltipProps extends PopoverPrimitive.PopoverContentProps {
  /**
   * Content to be displayed in the popover. If null is passed, popover won't display.
   */
  content: React.ReactNode | null
  /**
   * A string to identify the popover open event on Google Analytics
   * @default null
   */
  eventLabel?: EventLabel | string | null
  /**
   * The component that the user will hover over to display the popover.
   * @default "A button containing a question mark icon."
   */
  children?: React.ReactNode
  /**
   * The color of the default icon rendered if `children` is not provided will be.
   */
  questionMarkColor?: string
}

const TooltipContent = styled(PopoverPrimitive.Content)`
  z-index: 1000;

  background-color: #000928;
  color: white;

  font-family: ${(props) => props.theme.fontFamily};
  font-style: normal;
  line-height: 150%;
  font-feature-settings: "pnum" on, "lnum" on;

  max-width: 100vw;

  h2 {
    font-weight: bold;
    font-size: 1.1em;
    margin: 0;
    margin-top: 8px;
    margin-bottom: 16px;
  }

  p {
    font-weight: 500;
    font-size: 0.9rem;
    margin: 0;
  }

  box-shadow: 0px 16.25px 97.5px rgba(0, 0, 0, 0.12);
  padding: 1.5rem;

  border-top-left-radius: ${(props) => props.theme.radius.large};
  border-top-right-radius: ${(props) => props.theme.radius.large};

  @media ${devices.bp_sm} {
    margin: initial;
    border-radius: ${(props) => props.theme.radius.large};
    max-width: 440px;
  }
`

const TooltipClose = styled(PopoverPrimitive.Close)`
  position: absolute;
  right: 0;
  top: 0;

  cursor: pointer;

  color: #fff;
  background-color: transparent;
  border: 0px;
  padding: 0.5rem;
`

const TooltipContainer = styled.div<{
  $displayAsMobile: boolean
}>`
  ${(props) =>
    props.$displayAsMobile
      ? `& div[data-radix-popper-content-wrapper] {
            transform: translate3d(0px, 0, 0px)!important;
            width: 100%;
            bottom: 0;
            top: auto !important;
            max-width: 768px;
        }`
      : ""}
`

/**
 * Button used in case popover children aren't provided
 */
const DefaultButton = styled.button`
  cursor: pointer;
  display: flex;
  justify-items: center;
  align-items: center;
  background: transparent;
  border: 0;
`

/**
 * Wrapper for the default question mark icon
 */
const QuestionMarkWrapper = styled.div`
  width: 24px;
  height: 24px;
`

/**
 * A reusable, accessible tooltip component built with Radix UI.
 * For documentation, read Radix docs https://www.radix-ui.com/docs/primitives/components/popover.
 *
 * Technically more of a popover, but internally referred as Tooltip.
 */
const Tooltip = (
  { content, questionMarkColor, children, eventLabel, ...props }: TooltipProps = {
    eventLabel: null,
    content: null,
    side: "top",
  }
): JSX.Element => {
  const { width } = useWindowSize()
  /**
   * We display a fixed tooltip only if the window width is between 0 and 768px.
   */
  const displayAsMobile = width > 0 && width < 768

  return (
    <PopoverPrimitive.Root
      // this will lock scrolling if we're displaying the tooltip as a modal on mobile, it lookes better
      modal={displayAsMobile}
      onOpenChange={() => {
        LogGaEvent({
          action: EventAction.tooltipClicked,
          event_category: EventCategory.siteInteraction,
          event_label: eventLabel,
        })
      }}
    >
      <PopoverPrimitive.Trigger asChild>
        {children || (
          <DefaultButton data-testid="default-icon-button" style={{ padding: 0 }}>
            <QuestionMarkWrapper>
              <IconStyleable
                active={false}
                fillDefault={questionMarkColor || "#666B7E"}
                fillHover={questionMarkColor === questionMarkColors.WHITE ? "#ffffff" : "#073ED9"}
                width={20}
                height={20}
                type={IconType.QuestionMark}
              />
            </QuestionMarkWrapper>
          </DefaultButton>
        )}
      </PopoverPrimitive.Trigger>
      {content !== null && (
        <PopoverPrimitive.Portal>
          <TooltipContainer $displayAsMobile={displayAsMobile}>
            <TooltipContent {...props}>
              {content}
              {!displayAsMobile && <PopoverPrimitive.Arrow offset={20} />}
              <TooltipClose>
                <IconStyleable
                  fillDefault={"#ffffff"}
                  width={24}
                  height={24}
                  type={IconType.Cross}
                />
              </TooltipClose>
            </TooltipContent>
          </TooltipContainer>
        </PopoverPrimitive.Portal>
      )}
    </PopoverPrimitive.Root>
  )
}

/**
 * Calculates browser's window size to decide whether to display a mobile version of the tooltip.
 *
 * Code from https://usehooks.com/useWindowSize/
 */
const useWindowSize = () => {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = React.useState({
    width: undefined,
    height: undefined,
  })
  React.useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    // Add event listener
    window.addEventListener("resize", handleResize)
    // Call handler right away so state gets updated with initial window size
    handleResize()
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize)
  }, []) // Empty array ensures that effect is only run on mount
  return windowSize
}

export default Tooltip
