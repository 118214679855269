import React, { useEffect, useState } from "react"
import { Select, InlineCheck } from "mojo-storybook-v2-library"
import styled from "styled-components"
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { CurrencyInput } from "components/molecules/index"
import { Button, ButtonType } from "components/atoms/Button"
import { ReplaceAll } from "lib/ReplaceAll"
import { ScrollTo } from "lib/ScrollTo"
import { ScrollAnchor } from "components/atoms/ScrollAnchor"

const GridGap = "8px"

const Wrapper = styled.div`
  display: grid;
  margin-bottom: 35px;

  &.end {
    margin-bottom: ${GridGap};
  }
`

const ItemContainer = styled.div`
  display: grid;
  grid-auto-rows: auto;
  gap: ${GridGap};
`

const ButtonGrid = styled.div`
  display: grid;
`

const ButtonIconWrapper = styled.span`
  padding-right: 7px;
`

type Category = {
  key?: string
  value?: string
}

type PayFrequency = "weekly" | "monthly" | "annually"

export type Group = {
  type?: string
  payPerPeriod: string
  payFrequency: PayFrequency
  isProven: boolean
}

type Props = {
  onChange: (values: Group[], errors: string[]) => void
  categories?: Category[]
  value: Group[]
  needsProving: boolean
  id: string
}

export function PaymentAmountGroup({ onChange, categories = [], value, needsProving, id }: Props) {
  const [inputLength, setInputLength] = useState(value?.length ?? 1)

  const [inputs, setInputs] = useState<Group[]>(
    value?.length > 0
      ? value
      : [{ type: null, payPerPeriod: null, payFrequency: "monthly", isProven: false }]
  )

  const [selectableCategories] = useState([...categories])

  useEffect(() => {
    onChange(inputs, validate(inputs))
    if (inputs.length !== inputLength) {
      ScrollTo("pagScrollAnchor")
    }
    setInputLength(inputs.length)
  }, [inputs])

  function validate(inputs: Group[]): string[] {
    const errors: string[] = []

    if (inputs?.length < 1) {
      errors.push("You need to provide at least one item")
    }

    // validate is proven
    if (needsProving && inputs?.some((x) => x.isProven === false)) {
      errors.push("You need to be able to prove all these items")
    }

    // validate category
    else if (inputs?.some((input) => input?.type === null)) {
      errors.push("You need to provide a category")
    }

    // validate value amount
    else if (inputs?.some((input) => +input?.payPerPeriod < 0 || input?.payPerPeriod === null)) {
      errors.push("You need to provide a valid value")
    }

    return errors
  }

  function setItemCategory(index: number, categoryKey: string): void {
    const copy = [...inputs]
    copy[index].type = selectableCategories.find((x) => x.key === categoryKey).key

    setInputs([...copy])
  }

  function setItemFrequency(index: number, payFrequency: PayFrequency): void {
    const copy = [...inputs]
    copy[index].payFrequency = payFrequency

    setInputs([...copy])
  }

  function setItemAmount(index: number, payPerPeriod: string): void {
    const copy = [...inputs]
    copy[index].payPerPeriod = payPerPeriod

    setInputs([...copy])
  }

  function setItemProven(index: number, isProven: boolean): void {
    const copy = [...inputs]
    copy[index].isProven = isProven

    setInputs([...copy])
  }

  function removeItem(index: number): void {
    const copy = [...inputs]
    copy.splice(index, 1)

    setInputs([...copy])
  }

  function addNewAmount(): void {
    setInputs((current) => [
      ...current,
      {
        type: null,
        payPerPeriod: "",
        payFrequency: "monthly",
        isProven: !needsProving,
      },
    ])
  }

  return (
    <>
      {inputs.map((input, index) => {
        return (
          <Wrapper
            className={index === inputs.length - 1 ? "end" : ""}
            key={`${input?.type}-${index}`}
          >
            <ItemContainer>
              <CategorySelector
                id={id + "-category-select-" + index}
                value={input?.type || ""}
                index={index}
                onChange={(category) => setItemCategory(index, category)}
              />
              <CurrencyInput
                id={id + "-currency-input-" + index}
                value={input?.payPerPeriod}
                min={0}
                max={10000000}
                placeholder={"£0"}
                onDone={(payPerPeriod) => setItemAmount(index, payPerPeriod)}
              />
              <FrequencySelector
                id={id + "-frequency-select-" + index}
                value={input?.payFrequency}
                index={index}
                onChange={(payFrequency) => setItemFrequency(index, payFrequency)}
              />
            </ItemContainer>

            {needsProving && (
              <InlineCheck
                id={`pag-${id}-${index}-isProven`}
                checked={input.isProven}
                onChange={(e) => setItemProven(index, e.target.checked)}
                value={input.isProven}
              >
                I can prove this income with my payslip or tax return from HMRC
              </InlineCheck>
            )}

            <div style={needsProving ? null : { marginTop: `${GridGap}` }}>
              <Button
                id={"button-remove-" + id + "-" + index}
                onClick={() => removeItem(index)}
                type={ButtonType.Danger}
                text={
                  <>
                    <ButtonIconWrapper>
                      <FontAwesomeIcon icon={faTrash} />
                    </ButtonIconWrapper>
                    Remove
                  </>
                }
              />
            </div>
          </Wrapper>
        )
      })}
      <ButtonGrid>
        <Button
          id={"button-add-another-" + id}
          onClick={addNewAmount}
          type={ButtonType.Outline}
          text={
            <>
              <ButtonIconWrapper>
                <FontAwesomeIcon icon={faPlus} />
              </ButtonIconWrapper>
              Add another
            </>
          }
        />
      </ButtonGrid>
    </>
  )

  type CategorySelectorProps = {
    onChange: (value: string) => void
    value: string
    index: number
    id: string
  }
  function CategorySelector({ onChange, value, index, id }: CategorySelectorProps) {
    return (
      <>
        <ScrollAnchor offset={160} className="pagScrollAnchor" />
        <Select id={id} onChange={(e) => onChange(e.target.value)} value={value}>
          <option id={value} value="" disabled>
            Select a category...
          </option>
          {selectableCategories.map((category) => (
            <option
              id={ReplaceAll(category.value, "/ /", "-")}
              value={category.key}
              selected={category.key === value}
              key={category.key}
            >
              {category.value}
            </option>
          ))}
        </Select>
      </>
    )
  }

  type FrequencySelectorProps = {
    onChange: (value: PayFrequency) => void
    value: PayFrequency
    index?: number
    id: string
  }
  function FrequencySelector({ onChange, value, index, id }: FrequencySelectorProps) {
    return (
      <Select id={id} onChange={(e) => onChange(e.target.value)}>
        <option selected={value === "weekly"} value="weekly">
          Weekly
        </option>
        <option selected={value === "monthly"} value="monthly">
          Monthly
        </option>
        <option selected={value === "annually"} value="annually">
          Annually
        </option>
      </Select>
    )
  }
}
