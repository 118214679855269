import React, { useState, useEffect } from "react"
import { Input } from "../atoms/Input"
import styled from "styled-components"
import HandleEnter from "lib/HandleEnter"

const OptionalBox = styled.div`
  display: flex;
  align-items: center;
  input {
    width: 22px;
    height: 22px;
    margin: 15px;
  }
`

const validPercentageRegex = new RegExp(/^-?[0-9]+(e[0-9]+)?(\.[0-9]+)?$/)

const Container = styled.div`
  input + label > p {
    margin-top: 2px;
  }
`

type Props = {
  id?: string
  value?: string
  onDone: (value: number, errors: string[]) => void
  optional?: boolean
  optionalText?: string
}

export function PercentageInput({ id, value, onDone, optional, optionalText }: Props) {
  const [input, setInput] = useState(value || "")
  const [isOptional, setIsOptional] = useState<boolean>(false)

  useEffect(() => {
    HandleSubmit(input)
  }, [input])

  function HandleSubmit(value = input) {
    if (value === "") {
      onDone(null, null)
      return
    }

    const parsed = parseFloat(value)

    if (!validPercentageRegex.test(value) || parsed > 100) {
      onDone(null, ["Enter a valid percentage"])
      return
    }

    onDone(parseFloat(value), null)
  }

  function toggleOptional(isToggled: boolean) {
    setIsOptional(isToggled)
    setInput(isToggled ? "0" : "")
  }

  return (
    <Container>
      <Input
        value={input}
        inputMode="decimal"
        onKeyDown={(e) => HandleEnter(e, HandleSubmit)}
        onChange={(e) => setInput(e.target.value.replace(/[^0-9.]/g, ""))}
        id={`${id}-percentage`}
        label={"%"}
        autoComplete="off"
        disabled={isOptional}
      />
      {optional && (
        <OptionalBox>
          <input
            type="checkbox"
            checked={isOptional}
            onChange={() => toggleOptional(!isOptional)}
          />
          <div>{optionalText}</div>
        </OptionalBox>
      )}
    </Container>
  )
}
