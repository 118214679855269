import React, { useEffect, useState } from "react"
import { TopNavTemplate } from "templates/TopNavTemplate"
import styled from "styled-components"
import { WelcomeBackCard } from "pages/Dashboard/WelcomeBackCard"
import { ApplicationSummaryCard } from "pages/Dashboard/ApplicationSummaryCard"
import { useRequireAuthentication } from "hooks/useRequireAuthentication"
import { useApplicationSummaries } from "hooks/useApplicationSummaries"
import devices from "styles/devices"
import { Paperwork2 } from "components/Illustrations"
import { MojoListItem } from "components/molecules/MojoListItem"
import { useTrackNavigatedToPage } from "hooks/useTrackPageView"
import { LogGaEvent } from "lib/GoogleAnalytics"
import { EventAction, EventCategory } from "models/GoogleAnalytics"

const Layout = styled.div`
  display: grid;
  height: 100%;
  grid-gap: 1.6rem;
  margin-top: -110px;

  @media ${devices.bp_md} {
    grid-template-columns: 340px 1fr;
    grid-column-gap: 1.75rem;
    margin-top: 0;
  }

  @media ${devices.bp_xl} {
    grid-template-columns: 340px minmax(auto, 650px);
    grid-column-gap: 3.1875rem;
    margin-top: 0;
  }
`

const WelcomeBackStickyArea = styled.div`
  position: relative;
  display: block;
  margin-top: 8rem;
  @media ${devices.bp_md} {
    position: sticky;
    top: 190px;
    margin-top: 0;
    height: 280px;
  }
`

const ApplicationsList = styled.div``

const AppCardWrapper = styled.div`
  margin-bottom: 20px;
`

const MobileTitle = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  margin-top: 2rem;
  color: #434344;
  @media ${devices.bp_md} {
    display: none;
  }
`

const Footer = styled.div`
  background: ${(props) => props.theme.shade80};
  width: auto;
  height: 350px;
  display: grid;
  grid-template-columns: 3fr 2fr;
  padding-left: 3em;

  @media (min-width: 9000px) {
    padding-left: 0;
  }
`

const PaperworkImage = styled.img`
  max-width: 100%;
  max-height: 320px;
  align-self: center;
  justify-self: left;
`

const MojoListContainer = styled.div`
  justify-self: flex-end;
  align-self: center;
  padding-right: 0;

  @media (min-width: 1400px) {
    padding-right: 4em;
  }

  h2 {
    margin: 0;
    padding: 0;
    align-self: flex-start;
  }
`

const ListGrid = styled.ul`
  display: grid;
  max-width: 600px;
  grid-row-gap: 1em;
  grid-column-gap: 1em;
  font-size: 0.9em;
  grid-template-columns: 1fr;
  padding-left: 0;

  @media (min-width: 1100px) {
    padding: 10px 20px 0 0;
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: 1400px) {
    padding: 10px 20px 0 0;
    grid-template-columns: 1fr 1fr 1fr;
  }
`

export default function () {
  useRequireAuthentication(false)
  useTrackNavigatedToPage("/dashboard")

  const { applicationSummaries, hasFetchedSummaries } = useApplicationSummaries()
  const [loggedOpps, setLoggedOpps] = useState<boolean>(false)

  useEffect(() => {
    LogGaEvent({
      action: EventAction.pageViewed,
      event_category: EventCategory.formInteraction,
      event_label: "MyMojo - Dashboard",
    })
  }, [])

  useEffect(() => {
    if (hasFetchedSummaries && !loggedOpps) {
      LogGaEvent({
        action: EventAction.dashboardOpps,
        event_category: EventCategory.customEvent,
        event_label: `${applicationSummaries.length}`,
      })
      setLoggedOpps(true)
    }
  }, [hasFetchedSummaries])

  return (
    <TopNavTemplate
      content={
        <Layout>
          <WelcomeBackStickyArea>
            <WelcomeBackCard />
          </WelcomeBackStickyArea>

          <ApplicationsList>
            {applicationSummaries?.length > 0 && <MobileTitle>Your applications</MobileTitle>}
            {applicationSummaries?.map((summary) => {
              return (
                <AppCardWrapper key={summary.friendlyId}>
                  <ApplicationSummaryCard friendlyId={summary.friendlyId} />
                </AppCardWrapper>
              )
            })}
          </ApplicationsList>
        </Layout>
      }
    />
  )
}
