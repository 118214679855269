import React from "react"
import { Loader, LoaderTheme, LoaderWrapper } from "components/atoms/Loader"
import { useConfig } from "hooks/useConfig"

import { useHistory } from "react-router-dom"
import { useTrackNavigatedToPage } from "hooks/useTrackPageView"

import { GetSeedData } from "lib/SeedData"
import { LanderContent } from "./LanderContent"
import { redirectToDiscovery } from "lib/discovery"

export const PartnerLander = () => {
  const { theme, isLoaded: themeLoaded } = useConfig()
  const history = useHistory()
  useTrackNavigatedToPage("/get-started")

  const seedData = GetSeedData()
  const isRemo = seedData?.mortgageType === "remo"

  if (!themeLoaded) {
    return (
      <LoaderWrapper>
        <Loader theme={LoaderTheme.Outline} size={80} />
      </LoaderWrapper>
    )
  }

  const getStarted = () => {
    redirectToDiscovery()
  }

  return <LanderContent getStarted={getStarted} isRemo={isRemo} theme={theme} />
}
