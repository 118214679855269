import React from "react"

export const slides = [
  {
    quote: () => (
      <React.Fragment>
        First time getting a mortgage, everything explained in plain English, kept fully updated.
        Would highly recommend to anyone.
      </React.Fragment>
    ),
    reference: "Adele, Stafford",
  },
  {
    quote: () => (
      <React.Fragment>
        A companies reputation is only as good as its workforce performance. A brilliant service
        from this company and especially it's representative. I would highly recommend and have no
        hesitation in using their services again.
      </React.Fragment>
    ),
    reference: "Martyn, Northampton",
  },
  {
    quote: () => (
      <React.Fragment>
        Mojo have been very customer focused and did go above and beyond to support us in the
        process. No doubt would love to recommend Mojo to friends and colleagues. Thank you for
        covering us on this journey to our first home.
      </React.Fragment>
    ),
    reference: "Vineet, London",
  },
  {
    quote: () => (
      <React.Fragment>
        Mojo were fuss free from the start. I ended up having an issue with the lawyers representing
        me, however mojo had my back all the way. Thank you all so much. I will definitely recommend
        you.
      </React.Fragment>
    ),
    reference: "Angela, Milton Keynes",
  },
]
