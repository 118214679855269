import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { InlineRadios } from "./InlineRadios"

const Layout = styled.div({
  display: "grid",
  gap: 8,
})

type AdditionalIncome = {
  bonusCommissionOvertime: boolean
  benefits: boolean
  other: boolean
}

type Props = {
  id: string
  onDone: (annualIncome: AdditionalIncome) => void
  value?: AdditionalIncome
}

const left = { text: "Yes", value: true }
const right = { text: "No", value: false }

/**
 * Radios only return strings, so we tell it how to parse our options.
 */
const parseBoolStr = (s: string) => s === "true"

export const AdditionalIncomeTypes = ({ id, onDone, value }: Props) => {
  const [bonusCommissionOvertime, setBonusCommissionOvertime] = useState<boolean | null>(
    value?.bonusCommissionOvertime ?? null
  )
  const [benefits, setBenefits] = useState<boolean | null>(value?.benefits ?? null)
  const [other, setOther] = useState<boolean | null>(value?.other ?? null)

  const answered = bonusCommissionOvertime !== null && benefits !== null && other !== null
  const handleComplete = () => {
    if (answered) {
      onDone({
        bonusCommissionOvertime,
        benefits,
        other,
      })
    } else {
      onDone(null)
    }
  }
  useEffect(() => {
    handleComplete()
  }, [bonusCommissionOvertime, benefits, other])

  return (
    <Layout>
      <InlineRadios<boolean>
        id={`${id}-bonus`}
        label="Bonus, commission or overtime?"
        left={left}
        right={right}
        value={bonusCommissionOvertime}
        parser={parseBoolStr}
        onClick={setBonusCommissionOvertime}
      />
      <InlineRadios<boolean>
        id={`${id}-benefits`}
        label="State benefits, e.g. PIP, working tax, DLA, child benefits or Universal Credit?"
        left={left}
        right={right}
        value={benefits}
        parser={parseBoolStr}
        onClick={setBenefits}
      />
      <InlineRadios<boolean>
        id={`${id}-other`}
        label="Other income sources, e.g. pension, a second job or rental income?"
        left={left}
        right={right}
        value={other}
        parser={parseBoolStr}
        onClick={setOther}
      />
    </Layout>
  )
}
