import React, { useLayoutEffect } from "react"
import styled from "styled-components"
import { Button, ButtonType } from "components/atoms"
import devices from "styles/devices"
import { useHistory } from "react-router-dom"
import { LogGaEvent } from "lib/GoogleAnalytics"
import { EventAction, EventCategory, EventLabel } from "models/GoogleAnalytics"

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px 0px;
  padding-bottom: 32px;
  @media screen and ${devices.bp_lg} {
    flex-direction: row;
  }
`

const ButtonMarginRight = styled.div`
  @media screen and ${devices.bp_lg} {
    margin-right: 32px;
  }
`

const Title = styled.h1`
  font-weight: 550;
  font-size: 20px;
  @media screen and ${devices.bp_lg} {
    font-size: 32px;
  }
`

const Text = styled.p`
  font-size: 16px;
  line-height: 24px;
  @media screen and ${devices.bp_lg} {
    font-size: 16px;
  }
`

export const FeatureTest = () => {
  useLayoutEffect(
    () =>
      LogGaEvent({
        event_category: EventCategory.siteInteraction,
        action: EventAction.page,
        event_label: EventLabel.featureTest,
      }),
    []
  )
  const history = useHistory()
  return (
    <>
      <Title>This feature is being tested</Title>
      <Text>
        We're currently working hard to make mortgages easier for everyone. Thanks for letting us
        know you're interested in using this feature. Hopefully, it'll be available to help you in
        the future.
      </Text>

      <ButtonWrapper>
        <ButtonMarginRight>
          <Button
            type={ButtonType.Solid}
            onClick={async () => {
              await LogGaEvent({
                action: EventAction.buttonClicked,
                event_category: EventCategory.functionalInteraction,
                event_label: EventLabel.backToMortgageCoach,
              })
              history.goBack()
            }}
            text="Back to MortgageCoach"
          />
        </ButtonMarginRight>
      </ButtonWrapper>
    </>
  )
}
