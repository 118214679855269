import React from "react"

type Props = {
  offset: number
  className?: string
}

export function ScrollAnchor({ offset, className = "scrollAnchor" }: Props) {
  return (
    <div
      className={className}
      style={{
        marginTop: -offset,
        paddingTop: offset,
      }}
    />
  )
}
