import React from "react"
import { Button, Trustpilot } from "components/atoms"
import { ButtonContainer, Content, GhostWarning, Header, Pill, PriceEm, Text } from "./Shared"

interface Props {
  bookingComplete: boolean
  isGhostAppt: boolean
  onBookAppointment: () => void // Callback when user clicks on the "Book appointment" CTA
}

export const IncreaseBookingsRemoCopy = ({
  bookingComplete,
  isGhostAppt,
  onBookAppointment,
}: Props) => (
  <Content>
    <Header>Your remortgage expert is ready to make your recommendation</Header>
    <Text>
      Right now, remortgages are complicated things so your expert wants to review and approve your
      options to make sure we save you the biggest amount of money.
    </Text>
    <Text>Because we’re almost done, the call won’t take long at all.</Text>
    <Text bold={true}>
      It’s totally <PriceEm>free</PriceEm> and could save you £1000s.
    </Text>
    <ButtonContainer>
      <Button disabled={bookingComplete} onClick={onBookAppointment} text="Book your free review" />
    </ButtonContainer>
    {isGhostAppt && (
      <GhostWarning>
        This is a high demand callback slot. We aim to call within the 30 minute slot. If we cannot,
        we will email you to re-arrange.
      </GhostWarning>
    )}
    <Trustpilot />
  </Content>
)
