import React, { useLayoutEffect, useState } from "react"
import styled from "styled-components"
import devices from "styles/devices"
import { LogGaEvent } from "lib/GoogleAnalytics"

// @ts-ignore
import Lucy from "url:assets/images/lucyMortgageCoach.png"
import { EventAction, EventCategory } from "models/GoogleAnalytics"

import { Button, ButtonType } from "components/atoms"
import { GetApplicationCohorts, SetApplicationFlags, UpdateApplication } from "lib/Applications"
import { useApplicationFriendlyId } from "hooks/useApplication"
import { Flag } from "models/FullApplication"
import { Clone } from "lib/Clone"
import { LogError } from "../../../../Logging"
import { Cohort } from "models/Cohort"
import { GaObserver } from "lib/GaObserver"

export enum Affordability {
  Affordable = "affordable",
  Unaffordable = "unaffordable",
}

const Header = styled.div`
  margin: 0 auto 32px;
  display: flex;
  flex-direction: column;

  h2 {
    font-size: 28px;
  }
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
  }

  @media screen and ${devices.bp_xl} {
    flex-direction: row;
    justify-content: space-between;

    h2 {
      font-size: 32px;
    }

    & div.mortgage-coach-hero {
      margin-right: 3em;
    }
  }
`

const Title = styled.h2`
  margin: 2em 1em 1em;
  text-align: center;
  @media screen and ${devices.bp_lg} {
    text-align: left;
    margin: 2em 0em 1em;
  }
`

const TextBox = styled.div`
  padding: 24px;
  background: #ffebdc;
  border-radius: 24px 24px 24px 0;
  margin-top: 16px;
  p {
    margin: 0;
  }
  @media screen and ${devices.bp_xl} {
    width: 428px;
    margin-top: 0;
  }
`

const ChatText = styled.div`
  p {
    margin: 0;
    &:nth-child(2) {
      font-size: 12px;
    }
  }
`

const CoachWrapper = styled.div`
  display: flex;
  margin-top: 1em;

  align-items: center;
  img {
    margin-right: 16px;
  }
`

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1em;

  @media screen and ${devices.bp_xl} {
    flex-direction: row;
    margin: 0;
    > * {
      &:first-child {
        margin-right: 1.25em;
      }
    }
  }
`

const HomeBuyingExpertQuote = styled.div`
  margin: 2em 1em 0;
  @media screen and ${devices.bp_xl} {
    margin: 2em 0 0;
  }
`

interface Props {
  title: string
  text: string
  affordability?: Affordability
  friendlyId?: string
  eligibleBorrowAmount?: number
  updateDetailsCallback?: () => void
  executeScroll?: () => void
}

export const MortgageCoachHero = ({
  title,
  text,
  friendlyId,
  affordability,
  eligibleBorrowAmount,
  updateDetailsCallback,
  executeScroll,
}: Props) => {
  useLayoutEffect(
    () =>
      LogGaEvent({
        action: EventAction.componentDisplay,
        event_category: EventCategory.siteInteraction,
        event_label: title,
      }),
    []
  )

  const { application, refreshApplication } = useApplicationFriendlyId(friendlyId)

  const [isAdjustingBorrowing, setIsAdjustingBorrowing] = useState(false)
  const [isUpdatingDetails, setIsUpdatingDetails] = useState(false)

  async function HandleContinue() {
    LogGaEvent({
      action: EventAction.buttonClicked,
      event_category: EventCategory.functionalInteraction,
      event_label: "Borrow a Lower Amount",
    })

    setIsAdjustingBorrowing(true)

    const copy = Clone(application)

    if (GetApplicationCohorts(copy).includes(Cohort.Purchase)) {
      copy.basicPropertyDetails.propertyValue =
        eligibleBorrowAmount + application.borrowingDetails.deposit
    } else {
      LogError(`Unaffordable shown for remo (MortgageCoachHero): ${friendlyId}.`)
    }

    await UpdateApplication(copy)

    const resultFlags = [
      Flag.EligibilityResultApproved,
      Flag.EligibilityResultUnaffordable,
      Flag.EligibilityResultCoach,
      Flag.EligibilityResultReferred,
    ]
    const currentFlags = application?.metadata?.flags ?? []

    const flagsToSet = currentFlags.filter((flag) => resultFlags.includes(flag) === false)
    const updated = await SetApplicationFlags(application, flagsToSet)

    await refreshApplication(updated, true)
    setIsAdjustingBorrowing(false)
    setIsUpdatingDetails(true)
    updateDetailsCallback()
  }

  return (
    <Header>
      <div className="mortgage-coach-hero">
        <Title>{title}</Title>
        {affordability === Affordability.Unaffordable && (
          <ButtonsContainer>
            <GaObserver
              category={EventCategory.siteInteraction}
              action={EventAction.componentDisplay}
              label="Borrow lower amount"
            >
              <Button
                disabled={isUpdatingDetails}
                showLoader={isAdjustingBorrowing}
                text={"Borrow lower amount"}
                onClick={HandleContinue}
              />
            </GaObserver>
            <GaObserver
              category={EventCategory.siteInteraction}
              action={EventAction.componentDisplay}
              label="Improve my chances"
            >
              <Button
                disabled={isAdjustingBorrowing}
                showLoader={isUpdatingDetails}
                text="Improve my chances"
                type={ButtonType.Outline}
                onClick={() => {
                  LogGaEvent({
                    action: EventAction.buttonClicked,
                    event_category: EventCategory.functionalInteraction,
                    event_label: "Improve my chances",
                  })
                  executeScroll()
                }}
              />
            </GaObserver>
          </ButtonsContainer>
        )}
      </div>

      <HomeBuyingExpertQuote>
        <TextBox>
          <p>{text}</p>
        </TextBox>
        <CoachWrapper>
          <img src={Lucy} alt="The mortgage coach" />
          <ChatText>
            <p>
              <strong>Lucy</strong>
            </p>
            <p>Home Buying Expert – here to help you get a mortgage </p>
          </ChatText>
        </CoachWrapper>
      </HomeBuyingExpertQuote>
    </Header>
  )
}
