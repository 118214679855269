import React from "react"
import styled from "styled-components"
import { Button, ButtonType, MojoCircle, Trustpilot } from "components/atoms"
import Advisors from "assets/images/advisors.png"
import devices from "styles/devices"
import { LogGaEvent } from "lib/GoogleAnalytics"
import { GaObserver } from "lib/GaObserver"
import { EventAction, EventCategory, EventLabel } from "models/GoogleAnalytics"

const ContentRoot = styled.div``

const ContentSectionContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media ${devices.bp_lg} {
    flex-direction: row;
  }
`

const ContentSection = styled.div`
  flex: 1;

  @media ${devices.bp_lg} {
    &:last-child {
      margin-left: 5em;
    }
  }
`

const List = styled.ul`
  padding-left: 0;
`

const ListItem = styled.li`
  display: flex;
  margin-bottom: 1em;
`

const ListText = styled.span`
  margin-left: 0.5em;
`

const BoldText = styled.p`
  font-weight: bold;

  @media ${devices.bp_lg} {
    margin-top: 100px;
  }
`

const TickContainer = styled.div`
  width: 2rem;
  height: 2rem;
`

const TrustPilotContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-top: 8px;
  .trustpilot-widget {
    iframe {
      height: 4em;
    }
  }
  @media ${devices.bp_lg} {
    margin-top: 0;
  }
`

const Image = styled.img`
  height: 100px;

  @media ${devices.bp_lg} {
    height: auto;
  }
`
export const DefaultContent = ({ setIsBookingVisible }) => (
  <ContentRoot>
    <ContentSectionContainer>
      <ContentSection>
        <Image src={Advisors} alt="advisors" />
        <GaObserver
          category={EventCategory.siteInteraction}
          action={EventAction.componentDisplay}
          label="Save £100s by scheduling a quick call"
        >
          <h2>Save £100s by scheduling a quick call?</h2>
        </GaObserver>
        <p>
          You need to be certain when remortgaging, there's no better way to ease your household
          budget.
        </p>
      </ContentSection>
      <ContentSection>
        <BoldText>Get certain you're saving with your Mojo expert:</BoldText>
        <List>
          <ListItem>
            <TickContainer>
              <MojoCircle enabled={true} type={"tick"} />
            </TickContainer>
            <ListText>Compare rates from over 90 lenders – save up to £300/month</ListText>
          </ListItem>
          <ListItem>
            <TickContainer>
              <MojoCircle enabled={true} type={"tick"} />
            </TickContainer>
            <ListText>Fixed or not? 2, 3, 5 or 10-year deal? Find out with free advice</ListText>
          </ListItem>
          <ListItem>
            <TickContainer>
              <MojoCircle enabled={true} type={"tick"} />
            </TickContainer>
            <ListText>
              Turns out your lender is offering a good new deal? We'll sort that for you too
            </ListText>
          </ListItem>
        </List>
      </ContentSection>
    </ContentSectionContainer>
    <ContentSectionContainer>
      <ContentSection>
        <Button
          type={ButtonType.Solid}
          onClick={() => {
            LogGaEvent({
              action: EventAction.buttonClicked,
              event_category: EventCategory.functionalInteraction,
              event_label: EventLabel.scheduleCall,
            })
            setIsBookingVisible(true)
          }}
          text="Schedule a call"
        />
      </ContentSection>
      <ContentSection>
        <TrustPilotContainer>
          <Trustpilot height="4em" />
        </TrustPilotContainer>
      </ContentSection>
    </ContentSectionContainer>
  </ContentRoot>
)
