import React, { useEffect, useState } from "react"
import { FullApplication } from "models/FullApplication"
import { PricingCopyType } from "components/organisms/QualifyForHBE"
import { LogGaEvent } from "lib/GoogleAnalytics"
import { IsPreOffer, IsRemo } from "lib/Applications"
import {
  IncreaseBookingsPage,
  Props as IBProps,
} from "pages/Application/Eligibility/IncreaseBookings/IncreaseBookings"
import { HbePage } from "pages/Application/Eligibility/HBE/Hbe"
import { ExperienceRes } from "models/ExperienceTLS"
import { EventAction, EventCategory, EventLabel } from "models/GoogleAnalytics"
import { IncreaseBookingsCopy } from "../IncreaseBookings/IncreaseBookingsCopy"
import { IncreaseBookingsRemoCopy } from "../IncreaseBookings/IncreaseBookingsRemoCopy"

type Props = {
  fullApplication: FullApplication
  isPreOffer: boolean
  hasAdBlocker: boolean
  trafficLightResult: ExperienceRes
  refreshApplication: () => void
}

export enum Variant {
  Control,
  VariantA,
  VariantB,
  VariantC,
}

const ACUITY_GHOST_APPT_TYPE = "52507112"

export const ApprovedSection = ({
  fullApplication,
  refreshApplication,
  trafficLightResult,
}: Props) => {
  const [isBookingVisible, setIsBookingVisible] = useState<boolean>(false)
  const [bookingComplete, setBookingComplete] = useState<boolean>(false)
  const isRemo = IsRemo(fullApplication)
  const isPreOffer = IsPreOffer(fullApplication)
  const mip = trafficLightResult?.mip

  useEffect(() => {
    LogGaEvent({
      action: EventAction.componentDisplay,
      event_category: EventCategory.siteInteraction,
      event_label: "ApprovedSection",
    })
  }, [])

  const increaseBookingsPageProps: Omit<IBProps, "children"> = {
    setIsBookingVisible,
    isBookingVisible,
    fullApplication,
    bookingComplete,
    setBookingComplete,
    refreshApplication,
  }

  const logEvents = () => {
    LogGaEvent({
      action: EventAction.buttonClicked,
      event_category: EventCategory.functionalInteraction,
      event_label: EventLabel.bookFreeReview,
      appointment_type: "Mortgage Advisor - Appointment",
    })
    LogGaEvent({
      action: EventAction.buttonClicked,
      event_category: EventCategory.functionalInteraction,
      event_label: EventLabel.bookAnAppointment,
    })
  }

  const onBookAppointment = () => {
    logEvents()
    setIsBookingVisible(true)
  }

  if (isPreOffer) {
    return (
      <HbePage
        title="Next Step: Get your Mortgage in Principle completed by an expert"
        pricingCopyType={PricingCopyType.Free2}
        onBookAppointment={() => {
          logEvents()
          if (onBookAppointment !== undefined) {
            onBookAppointment()
          } else {
            setIsBookingVisible(true)
          }
        }}
        mip={mip}
        fullApplication={fullApplication}
        isBookingVisible={isBookingVisible}
        setIsBookingVisible={setIsBookingVisible}
        bookingComplete={bookingComplete}
        setBookingComplete={setBookingComplete}
        variant={Variant.VariantA}
        refreshApplication={refreshApplication}
        isGhostAppt={trafficLightResult.rule.appointmentId === ACUITY_GHOST_APPT_TYPE}
      />
    )
  } else if (isRemo) {
    return (
      <IncreaseBookingsPage {...increaseBookingsPageProps}>
        <IncreaseBookingsRemoCopy
          bookingComplete={bookingComplete}
          isGhostAppt={trafficLightResult.rule.appointmentId === ACUITY_GHOST_APPT_TYPE}
          onBookAppointment={onBookAppointment}
        />
      </IncreaseBookingsPage>
    )
  } else {
    return (
      <IncreaseBookingsPage {...increaseBookingsPageProps}>
        <IncreaseBookingsCopy
          bookingComplete={bookingComplete}
          isGhostAppt={trafficLightResult.rule.appointmentId === ACUITY_GHOST_APPT_TYPE}
          mip={mip}
          onBookAppointment={onBookAppointment}
        />
      </IncreaseBookingsPage>
    )
  }
}
