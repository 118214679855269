import React, { useEffect, useRef, useState } from "react"
import { LogGaEvent } from "lib/GoogleAnalytics"
import { EventAction, EventCategory, EventLabel } from "models/GoogleAnalytics"

type Props = {
  children: React.ReactNode
  category?: EventCategory
  action?: EventAction
  label: EventLabel | string
  value?: number
  threshold?: number | number[]
}

export const GaObserver = ({
  children,
  category = EventCategory.functionalInteraction,
  action = EventAction.componentViewed,
  label,
  value,
  threshold = 0,
}: Props) => {
  const [hasFired, setHasFired] = useState<boolean>(false)

  const containerRef = useRef()
  const triggerEvent = (entries) => {
    const [entry] = entries
    if (entry.isIntersecting && !hasFired) {
      LogGaEvent({
        action: action,
        event_category: category,
        event_label: label,
        value,
      })
      setHasFired(true)
    }
  }

  // Threshold is used to dictate how much of your element is inview before the event fires, 0 means as soon as it comes into view, and 1.0 means when the end of the element comes into view
  const options = {
    root: null,
    rootMargin: "0px",
    threshold,
  }

  useEffect(() => {
    const observer = new IntersectionObserver(triggerEvent, options)
    const el = containerRef.current
    if (el) {
      observer.observe(el)
    }
    return () => {
      if (el) {
        observer.unobserve(el)
      }
    }
  }, [containerRef, options])

  return <span ref={containerRef}>{children}</span>
}
