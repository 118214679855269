import Accord from "url:assets/images/lenders/Accord.png"
import Aldermore from "url:assets/images/lenders/Aldermore Mortgages.png"
import BM from "url:assets/images/lenders/BM Solutions.png"
import Bank from "url:assets/images/lenders/Bank of Ireland.png"
import Bath from "url:assets/images/lenders/Bath Building Society.png"
import Barclays from "url:assets/images/lenders/Barclays Bank.png"
import Bluestone from "url:assets/images/lenders/Bluestone Mortgages.png"
import Cambridge from "url:assets/images/lenders/Cambridge Building Society.png"
import Clydesdale from "url:assets/images/lenders/Clydesdale Bank.png"
import Coventry from "url:assets/images/lenders/Coventry Building Society.png"
import Digital from "url:assets/images/lenders/Digital Mortgages.png"
import GenH from "url:assets/images/lenders/Generation Home.png"
import HSBC from "url:assets/images/lenders/HSBC.png"
import Halifax from "url:assets/images/lenders/Halifax.png"
import Hinckley from "url:assets/images/lenders/Hinckley and Rugby Building Society.png"
import Kensington from "url:assets/images/lenders/Kensington Mortgages.png"
import Kent from "url:assets/images/lenders/Kent Reliance.png"
import Landbay from "url:assets/images/lenders/Landbay.png"
import Leeds from "url:assets/images/lenders/Leeds Building Society.png"
import Leek from "url:assets/images/lenders/Leek United Building Society.png"
import Mansfield from "url:assets/images/lenders/Mansfield Building Society.png"
import Metro from "url:assets/images/lenders/Metro Bank.png"
import MortgageWorks from "url:assets/images/lenders/Mortgage Works, The.png"
import Monmouthshire from "url:assets/images/lenders/Monmouthshire Building Society.png"
import Nationwide from "url:assets/images/lenders/Nationwide Building Society.png"
import Natwest from "url:assets/images/lenders/Natwest.png"
import Newcastle from "url:assets/images/lenders/Newcastle Building Society.png"
import Nottingham from "url:assets/images/lenders/Nottingham Building Society.png"
import Paragon from "url:assets/images/lenders/Paragon Banking Group PLC.png"
import Pepper from "url:assets/images/lenders/Pepper.png"
import Platform from "url:assets/images/lenders/Platform.png"
import Precise from "url:assets/images/lenders/Precise Mortgages.png"
import Principality from "url:assets/images/lenders/Principality BS.png"
import Progressive from "url:assets/images/lenders/Progressive Building Society.png"
import Santander from "url:assets/images/lenders/Santander UK PLC.png"
import ScottishWidows from "url:assets/images/lenders/Scottish Widows Bank.png"
import ScottishBS from "url:assets/images/lenders/ScottishBS.png"
import Skipton from "url:assets/images/lenders/Skipton Building Society.png"
import TSB from "url:assets/images/lenders/TSB.png"
import Together from "url:assets/images/lenders/Together.png"
import Vida from "url:assets/images/lenders/Vida Homeloans.png"
import Virgin from "url:assets/images/lenders/Virgin Money.png"

// "ACC" === Accord
// "ALD" === Aldermore Mortgages
// "BOI" === Bank Of Ireland
// "BWO" === Barclays Bank
// "BBB" === Bath BS
// "BPK" === Bespoke by BOI
// "BBV" === Beverley BS
// "BST" === Bluestone Mortgages
// "CHY" === BM Solutions
// "BBU" === Buckinghamshire BS
// "CAM" === Cambridge BS
// "CNT" === Central Trust Limited
// "BCO" === Chorley BS
// "CLY" === Clydesdale Bank
// "BCV" === Coventry BS
// "BDA" === Darlington BS
// "ATM" === Digital Mortgages
// "DUD" === Dudley BS
// "FAM" === Family Building Society (NCBS)
// "FLE" === Fleet Mortgages
// "FHL" === Foundation Home Loans
// "BFU" === Furness BS
// "GRH" === Generation Home
// "GOD" === Godiva Mortgages
// "BHX" === Halifax
// "HEC" === Hanley Economic BS
// "BHI" === Hinckley &amp; Rugby BS
// "HSB" === HSBC
// "KMC" === Kensington Mortgages
// "KBS" === Kent Reliance
// "LDB" === Landbay
// "BLH" === Leeds BS
// "LEE" === Leek Building Society
// "BLF" === Loughborough BS
// "BMN" === Mansfield BS
// "BMH" === Market Harborough BS
// "BMS" === Marsden BS
// "MBS" === MBS Lending Ltd
// "BME" === Melton Building Society
// "MET" === Metro Bank
// "BMO" === Monmouthshire BS
// "BNW" === Nationwide BS
// "CNW" === NatWest
// "BNE" === Newbury BS
// "NBS" === Newcastle BS
// "NTN" === Norton Home Loans
// "BNT" === Nottingham BS
// "PAR" === Paragon Banking Group PLC
// "PEP" === Pepper Money
// "CTS" === Platform
// "POS" === Post Office
// "PRE" === Precise Mortgages
// "BPR" === Principality BS
// "BPG" === Progressive BS
// "BSA" === Saffron BS
// "CAN" === Santander UK Plc
// "BSO" === Scottish BS
// "BAA" === Scottish Widows Bank
// "SHB" === Shawbrook Bank
// "SBS" === Skipton BS
// "SRB" === Stafford Railway BS
// "BIP" === Suffolk Building Society
// "TBS" === Teachers BS
// "TML" === The Mortgage Lender
// "CCN" === The Mortgage Works
// "TIP" === Tipton &amp; Coseley BS
// "BMF" === Together
// "TSB" === TSB
// "VBS" === Vernon BS
// "VDA" === Vida Homeloans
// "BNR" === Virgin Money
// "YBS" === Yorkshire Building Society

export default {
  ACC: Accord,
  ALD: Aldermore,
  BOI: Bank,
  BWO: Barclays,
  BBB: Bath,
  BPK: null,
  BBV: null,
  BST: Bluestone,
  CHY: BM,
  BBU: null,
  CAM: Cambridge,
  CNT: null,
  BCO: null,
  CLY: Clydesdale,
  BCV: Coventry,
  BDA: null,
  ATM: Digital,
  DUD: null,
  FAM: null,
  FLE: null,
  FHL: null,
  BFU: null,
  GOD: null,
  GRH: GenH,
  BHX: Halifax,
  HEC: null,
  BHI: Hinckley,
  HSB: HSBC,
  KMC: Kensington,
  KBS: Kent,
  LDB: Landbay,
  BLH: Leeds,
  LEE: Leek,
  BLF: null,
  BMN: Mansfield,
  BMH: null,
  BMS: null,
  MBS: null,
  BME: null,
  MET: Metro,
  BMO: Monmouthshire,
  BNW: Nationwide,
  CNW: Natwest,
  BNE: null,
  NBS: Newcastle,
  NTN: null,
  BNT: Nottingham,
  PAR: Paragon,
  PEP: Pepper,
  CTS: Platform,
  POS: null,
  PRE: Precise,
  BPR: Principality,
  BPG: Progressive,
  BSA: null,
  CAN: Santander,
  BSO: ScottishBS,
  BAA: ScottishWidows,
  SHB: null,
  SBS: Skipton,
  SRB: null,
  BIP: null,
  TBS: null,
  TML: null,
  CCN: MortgageWorks,
  TIP: null,
  BMF: Together,
  TSB: TSB,
  VBS: null,
  VDA: Vida,
  BNR: Virgin,
  YBS: null,
}
