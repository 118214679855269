import axios from "axios"
import { DeleteCookie, GetCookie, SetCookie } from "lib/Cookie"
import { DecodeJwt } from "lib/Jwt"
import { UserJwt } from "models/UserJwt"

export type PasswordValidation = {
  hasUppercase: boolean
  hasLowercase: boolean
  hasNumber: boolean
  hasMinimum: boolean
  hasSpecialChar: boolean
  hasBigAmount: boolean
  isValid: boolean
}

export type StrengthStatus = "Excellent" | "Strong" | "Good" | "Fair" | "Poor"

export const JwtCookieKey = "SessionJwt"

export function ClearToken() {
  DeleteCookie(JwtCookieKey)
}

export function SetToken(value: string) {
  if (!value) {
    DeleteCookie(JwtCookieKey)
    return
  }

  SetCookie(JwtCookieKey, value)
}

export function GetToken(): string | null {
  const jwt = GetCookie(JwtCookieKey)

  if (!jwt) {
    ClearToken()
    return null
  }

  const token = DecodeJwt<UserJwt>(jwt)

  if (token.exp < Math.floor(Date.now() / 1000)) {
    ClearToken()
    return null
  }

  return jwt
}

export function PasswordStrengthValidation(
  password: string,
  passwordConfirm: string,
  setStatusValues: (validationResponse: PasswordValidation, strengthStatus: StrengthStatus) => void
) {
  const hasUppercase = !!password.match(/[A-Z]/)
  const hasLowercase = !!password.match(/[a-z]/)
  const hasNumber = !!password.match(/[0-9]/)
  const hasMinimum = password.length >= 8
  const hasSpecialChar = !!password.match(/[^a-zA-Z0-9]/)
  const hasBigAmount = password.length >= 14
  const doPasswordsMatch = password === passwordConfirm
  const isValid = hasUppercase && hasLowercase && hasNumber && hasMinimum && doPasswordsMatch
  const validation = {
    hasUppercase,
    hasLowercase,
    hasNumber,
    hasMinimum,
    hasSpecialChar,
    hasBigAmount,
    isValid,
  }
  const validationValues = [
    hasUppercase,
    hasLowercase,
    hasNumber,
    hasMinimum,
    hasSpecialChar,
    hasBigAmount,
  ]

  // Score the strength out of 100
  let passwordScore = validationValues.reduce((total, val) => total + (val ? 20 : 0), -20)
  passwordScore = hasMinimum ? passwordScore : 0

  const strengthStatus: StrengthStatus = getStrengthStatus(passwordScore)
  setStatusValues(validation, strengthStatus)
}

const getStrengthStatus = (score: number): StrengthStatus => {
  switch (true) {
    case score > 80:
      return "Excellent"
    case score > 60:
      return "Strong"
    case score > 40:
      return "Good"
    case score > 20:
      return "Fair"
    default:
      return "Poor"
  }
}
