import React, { useRef, useState } from "react"
import styled from "styled-components"

import HamburgerIcon from "assets/images/icons/Menu.svg"
import PoweredByMojoImg from "assets/images/powered-by-mojo.svg"
import PartnershipWithMojoImg from "assets/images/in-partnership-with-mojo.svg"
import CloseHamburgerIcon from "assets/images/icons/Cross.svg"

import { useOutsideClick } from "hooks/useOutsideClick"
import devices from "styles/devices"
import { useConfig } from "hooks/useConfig"
import { NavBarHeight } from "styles/StyleVariabes"
import { useHistory } from "react-router-dom"
import { useLogout, useSession } from "lib/auth/react"

const HamburgerSize = "33px"

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 7px 15px;
  align-items: center;
  grid-gap: 5px;
  height: ${NavBarHeight};

  @media ${devices.bp_sm} {
    padding: 7px 35px;
  }
`

const Logo = styled.img<{ clickable: boolean }>`
  display: block;
  width: 90px;
  pointer-events: ${(props) => (props.clickable ? "all" : "none")};
  cursor: ${(props) => (props.clickable ? "pointer" : "default")};

  @media ${devices.bp_sm} {
    width: 110px;
  }
`

const LogoContainer = styled.div`
  justify-self: left;
  grid-column: 1;
`

const PoweredByMojo = styled.img<{ isLoggedIn: boolean }>`
  display: ${(props) => (props.isLoggedIn ? "none" : "initial")};
  grid-column: ${(props) => (props.isLoggedIn ? 2 : 3)};
  justify-self: ${(props) => (props.isLoggedIn ? "center" : "flex-end")};
  height: 100%;

  @media (min-width: 600px) {
    display: ${(props) => (props.isLoggedIn ? "initial" : "initial")};
  }
`

const User = styled.div`
  display: inline-flex;
  grid-column: 3;
  align-items: center;
  min-width: 240px;
  justify-self: flex-end;

  .hamburgerContainer {
    width: ${HamburgerSize};
    height: ${HamburgerSize};
    position: relative;
    margin-left: 15px;
  }
`

export function TopNav({
  showPoweredBy = false,
  clickableLogo = false,
  logoVariant = "partnership",
  showUser = true,
}) {
  const [showActions, setShowActions] = useState(false)

  const { logout } = useLogout()
  const { data, isLoading } = useSession()
  const loggedIn = isLoading ? true : Boolean(data)

  const { theme } = useConfig()
  const history = useHistory()

  const menuRef = useRef(null)
  useOutsideClick(menuRef, () => setShowActions(false))

  async function handleLogout() {
    await logout()
    window.location.reload()
  }

  const cornerLogo = {
    image: logoVariant == "partnership" ? PartnershipWithMojoImg : PoweredByMojoImg,
    alt: logoVariant == "partnership" ? "In Partnership With Mojo" : "Powered By Mojo",
  }
  return (
    <Container>
      <LogoContainer>
        <Logo
          clickable={clickableLogo}
          onClick={() => clickableLogo && history.push("/")}
          key="logo-img"
          src={theme.mainLogoURL}
          alt="logo"
        />
      </LogoContainer>

      {showPoweredBy && (
        <PoweredByMojo isLoggedIn={loggedIn} src={cornerLogo.image} alt={cornerLogo.alt} />
      )}

      {showUser && loggedIn && (
        <User>
          {isLoading ? "" : data.Email}
          <div ref={menuRef} className="hamburgerContainer">
            <Hamburger toggled={showActions} onToggle={(value) => setShowActions(value)} />
            {showActions && <UserActions logout={handleLogout} />}
          </div>
        </User>
      )}
    </Container>
  )
}

const HamburgerContainer = styled.div`
  width: ${HamburgerSize};
  height: ${HamburgerSize};
  display: block;
  cursor: pointer;

  img {
    width: ${HamburgerSize};
    height: ${HamburgerSize};
  }
`

function Hamburger({ toggled, onToggle }) {
  const iconComponent = toggled ? (
    <img src={CloseHamburgerIcon} alt="Close menu" />
  ) : (
    <img src={HamburgerIcon} alt="Open menu" />
  )

  return <HamburgerContainer onClick={() => onToggle(!toggled)}>{iconComponent}</HamburgerContainer>
}

const UserActionsContainer = styled.div`
  position: absolute;
  background: ${(props) => props.theme.navColour};
  width: 150px;
  right: 0;
  top: 60px;
  border-radius: 4px;
  border: 1px solid #ebedf3;
  box-shadow: 0 10px 10px -5px rgba(128, 128, 128, 0.2);

  .action {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;

    &:hover {
      cursor: pointer;
      background: #dfe1e6;
    }
  }
`

function UserActions({ logout }) {
  return (
    <UserActionsContainer>
      <div className="action" onClick={logout}>
        Logout
      </div>
    </UserActionsContainer>
  )
}
