// @ts-nocheck
import { createGlobalStyle } from "styled-components"
import devices from "styles/devices"

export default createGlobalStyle<{ blockScroll: boolean }>`
  html, body, #root {
    margin: 0;
    height: 100%;
  }

  html {
    scroll-behavior: smooth;
  }

  body {
    background: ${(props) => props.theme.backgroundColour};
    font-family: ${(props) => props.theme.fontFamily};
    overflow: ${(props) => (props.blockScroll ? "hidden" : "visible")};
    font-variant-numeric: lining-nums;
  }

  a {
    color: black;
    cursor: pointer;
  }

  *, *::before, *::after {
    box-sizing: border-box;
  }

  h1 {

    font-size: 1.6em;
    font-weight: bold;
    margin: 0;
    @media ${devices.bp_sm} {
      font-size: 2.27em;
    }
  }

  h2 {
    font-size: 1.5em;
    font-weight: bold;
    margin: 0;
    @media ${devices.bp_sm} {
      font-size: 1.802em;
    }
  }

  h3 {
    font-size: 1.45em;
    margin: 0;
    @media ${devices.bp_sm} {
      font-size: 1.602em;
    }
  }

  h4 {
    font-size: 1.2em;
    margin: 0;
    @media ${devices.bp_sm} {
      font-size: 1.424em;
    }
  }

  h5 {
    font-size: 1.66em;
  }

  p {
    margin: 25px 0;
  }

  @media ${devices.bp_md} {
    #root::-webkit-scrollbar {
      width: 15px;
    }

    #root::-webkit-scrollbar-track {
      background: white;
    }

    #root::-webkit-scrollbar-thumb {
      background-color: #c3c3c3;
      border-radius: 7.5px;
      box-shadow: inset 0px 0 0 3px white;
    }
  }
`
