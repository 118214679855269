import React, { useEffect, useState } from "react"
import styled from "styled-components"
import BackgroundWave from "templates/BackgroundWave"
import { TopNavTemplate } from "templates/TopNavTemplate"
import { Intercom } from "components/molecules/Intercom"
import { Button, Icon, MojoCircle } from "components/atoms"
import { PasswordInput } from "components/atoms/PasswordInput"
import { ContentBox } from "../ContentBox"
import axios from "axios"
import { MY_MOJO_API } from "../../../Config"
import { PasswordStrengthValidation, PasswordValidation, SetToken } from "lib/Authentication"
import { IconType } from "components/atoms/Icon"
import { useTrackNavigatedToPage } from "hooks/useTrackPageView"
import { LogGaEvent } from "lib/GoogleAnalytics"
import useDidMount from "hooks/useDidMount"
import { EventAction, EventCategory } from "models/GoogleAnalytics"

const Container = styled.div`
  display: flex;
`

const SubText = styled.p`
  padding-top: 0px;
  margin-bottom: 10px;
`

const ValidationList = styled.div`
  margin-top: 0px;
  margin-bottom: 20px;
`

const ValidationListItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;

  span {
    margin-left: 10px;
  }
`

const PasswordStrength = styled.p`
  padding-top: 5px;
  margin-bottom: 15px;
`

export const NewPassword = () => {
  const [password, setPassword] = useState<string>("")
  const [passwordConfirm, setPasswordConfirm] = useState<string>("")
  const [passwordValidation, setPasswordValidation] = useState<PasswordValidation>({
    hasUppercase: false,
    hasLowercase: false,
    hasNumber: false,
    hasMinimum: false,
    hasSpecialChar: false,
    hasBigAmount: false,
    isValid: false,
  })
  const [passwordStrength, setPasswordStrength] = useState<string>("Poor")
  const [isBusy, setIsBusy] = useState<boolean>(false)
  const [requestSent, setRequestSent] = useState<boolean>(false)
  const [errorText, setErrorText] = useState<string>("")
  const params = new URLSearchParams(window.location.search)
  const didMount = useDidMount()

  useTrackNavigatedToPage("/new-password")

  useEffect(() => {
    PasswordStrengthValidation(password, passwordConfirm, (validationResponse, strengthStatus) => {
      setPasswordValidation(validationResponse)
      setPasswordStrength(strengthStatus)
      LogGaEvent({
        action: EventAction.validationMessageShown,
        event_category: EventCategory.siteInteraction,
        event_label: `Password - ${strengthStatus}`,
      })
    })
    !didMount &&
      LogGaEvent({
        action: EventAction.componentDisplay,
        event_category: EventCategory.siteInteraction,
        event_label: "Set New Password",
      })
  }, [password, passwordConfirm])

  const ResetPasswordEmail = async () => {
    LogGaEvent({
      action: EventAction.buttonClicked,
      event_category: EventCategory.functionalInteraction,
      event_label: "Set New Password",
    })
    LogGaEvent({
      action: EventAction.newPassword,
      event_category: EventCategory.accountInteraction,
      event_label: "Request",
    })
    setIsBusy(true)
    const token = params.get("token")
    const email = params.get("email")

    if (email && token) {
      try {
        await PasswordReset(email, token, password)
        await AutoLogin(email)
        setRequestSent(true)
        LogGaEvent({
          action: EventAction.newPassword,
          event_category: EventCategory.accountInteraction,
          event_label: "Success",
        })
      } catch (error) {
        setErrorText(error.response.data.message)
      } finally {
        setIsBusy(false)
      }
    }
  }

  const PasswordReset = async (email: string, token: string, password: string) => {
    await axios.post(`${MY_MOJO_API}/auth/password-reset`, {
      email,
      token,
      password,
    })
    LogGaEvent({
      action: EventAction.resetPassword,
      event_category: EventCategory.functionalInteraction,
      event_label: "Set New Password Successful",
    })
  }

  const AutoLogin = async (email: string) => {
    const result = await axios.post(`${MY_MOJO_API}/auth`, { email, password })
    SetToken(result.data.token)
    LogGaEvent({
      action: EventAction.loginSuccess,
      event_category: EventCategory.functionalInteraction,
      event_label: "Auto Login",
    })
  }

  const ValidationIcon = (enabled = false) => {
    return enabled ? <MojoCircle type="tick" enabled={enabled} /> : <Icon type={IconType.Locked} />
  }

  return (
    <TopNavTemplate
      miniNav={true}
      content={
        <>
          <BackgroundWave />
          <Container>
            <Intercom style={{ zIndex: "100" }} />

            <ContentBox>
              <h1>Reset password</h1>

              {!requestSent && !errorText && (
                <>
                  <SubText>Your password must contain:</SubText>

                  <ValidationList>
                    <ValidationListItem>
                      {ValidationIcon(passwordValidation.hasMinimum)}
                      <span>At least 8 characters</span>
                    </ValidationListItem>
                    <ValidationListItem>
                      {ValidationIcon(passwordValidation.hasNumber)}
                      <span>At least 1 number</span>
                    </ValidationListItem>
                    <ValidationListItem>
                      {ValidationIcon(passwordValidation.hasUppercase)}
                      <span>At least 1 uppercase character</span>
                    </ValidationListItem>
                  </ValidationList>

                  <PasswordInput
                    value={password}
                    placeholder="Password"
                    onChange={(value) => setPassword(value)}
                    onBlur={() =>
                      LogGaEvent({
                        action: EventAction.fieldBlur,
                        event_category: EventCategory.formInteraction,
                        event_label: "New Password",
                      })
                    }
                    onFocus={() =>
                      LogGaEvent({
                        action: EventAction.fieldFocus,
                        event_category: EventCategory.formInteraction,
                        event_label: "New Password",
                      })
                    }
                    onLoaded={() =>
                      LogGaEvent({
                        action: EventAction.inputShown,
                        event_category: EventCategory.siteInteraction,
                        event_label: "New Password",
                      })
                    }
                  />

                  <PasswordStrength>
                    Password strength: <strong>{passwordStrength}</strong>
                  </PasswordStrength>

                  <PasswordInput
                    value={passwordConfirm}
                    placeholder="Confirm Password"
                    onChange={(value) => setPasswordConfirm(value)}
                    onBlur={() =>
                      LogGaEvent({
                        action: EventAction.fieldBlur,
                        event_category: EventCategory.formInteraction,
                        event_label: "Repeat Password",
                      })
                    }
                    onFocus={() =>
                      LogGaEvent({
                        action: EventAction.fieldFocus,
                        event_category: EventCategory.formInteraction,
                        event_label: "Repeat Password",
                      })
                    }
                    onLoaded={() =>
                      LogGaEvent({
                        action: EventAction.inputShown,
                        event_category: EventCategory.siteInteraction,
                        event_label: "Repeat Password",
                      })
                    }
                  />

                  <Button
                    disabled={!passwordValidation.isValid}
                    text="Continue"
                    onClick={ResetPasswordEmail}
                    showLoader={isBusy}
                  />
                </>
              )}

              {requestSent && !errorText && (
                <>
                  <h4>Reset complete!</h4>
                  <p>Your new password has been set.</p>
                  <Button
                    text="Go to my account"
                    onClick={() => (window.location.href = "/dashboard")}
                  />
                </>
              )}

              {errorText && <p>{errorText}</p>}
            </ContentBox>
          </Container>
        </>
      }
    />
  )
}
