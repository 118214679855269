import { LogGaEvent } from "lib/GoogleAnalytics"
import { EventAction, EventCategory } from "models/GoogleAnalytics"
import React from "react"

export function Bucket<T extends number>(
  experimentId: string,
  variants: T[],
  distribution: number[],
  active = true
): T {
  let currentExperiment = GetExperimentState<T>(experimentId)

  if (currentExperiment === null && active) {
    currentExperiment = BucketIntoExperiment<T>(variants, distribution)
    SetExperimentState(experimentId, currentExperiment)
    LogGaEvent({
      action: EventAction.bucketedIntoExperiment,
      event_category: EventCategory.functionalInteraction,
      event_label: `${experimentId} - ${currentExperiment}`,
    })
  }

  return currentExperiment
}

/**
 * React implementation of the bucketing function.
 */
export const useBucket = (...args: Parameters<typeof Bucket>) => {
  return React.useMemo(() => {
    // if `active` then bucket user
    if (args[3] || args[3] === undefined) {
      return { variant: Bucket<(typeof args)[1][0]>(...args), bucketed: true }
    } else {
      // if not we return the first (default) variant
      return { variant: args[1][0], bucketed: false }
    }
  }, args)
}

function BucketIntoExperiment<T extends number>(variants: T[], distribution: number[]) {
  const totalWeight = distribution.reduce((sum, weight) => sum + weight)
  const randomNum = Math.random() * totalWeight

  let cumulativeWeight = 0

  for (let i = 0; i < variants.length; i++) {
    cumulativeWeight += distribution[i]

    if (randomNum < cumulativeWeight) {
      return variants[i]
    }
  }

  // Default variant
  return variants[0]
}

function SetExperimentState(experimentId: string, variant: number) {
  localStorage.setItem(`experiment_${experimentId}`, String(variant))
}

function GetExperimentState<T extends number>(experimentId: string): T | null {
  const experimentCookie = localStorage.getItem(`experiment_${experimentId}`)
  if (!experimentCookie) {
    return null
  }

  return parseInt(experimentCookie) as T
}
