export enum EventCategory {
  siteInteraction = "Site Interaction",
  formInteraction = "Form Interaction",
  functionalInteraction = "Functional Interaction",
  accountInteraction = "Account Authentication",
  customEvent = "Custom Event",
}

export enum EventAction {
  componentDisplay = "Component Display",
  componentViewed = "Component Viewed",
  buttonClicked = "Button Clicked",
  tooltipClicked = "Tooltip Clicked",
  sectionLenderLookup = "Section Lender Lookup",
  sectionCurrentDealEndDate = "Section Current Deal End Date",
  addressLookupSuccessful = "Address Lookup - Successful",
  addressLookupUnsuccessful = "Address Lookup - Unsuccessful",
  addressSelected = "Address Selected",
  fieldFocus = "Field Focus",
  fieldBlur = "Field Blur",
  validationMessageShown = "Validation Message Shown",
  nextSectionCTAClick = "Next Section CTA Clicked",
  appointmentBooked = "Appointment Booked",
  page = "Page",
  sectionSummaryShown = "Section Summary Shown",
  experienceTLS = "ExperienceTLS",
  apptDatesShown = "Appt Dates Shown",
  apptDatesShownAmount = "Appt Dates Shown Amount",
  apptDatesNoneAvailable = "Appt Dates None Available",
  apptTimeslotsShown = "Appt Timeslots Shown",
  apptTimeslotsShownAmount = "Appt Timeslots Shown Amount",
  apptTimeConfirmed = "Appt Time Confirmed",
  apptEarlyContactPref = "Appt Early Contact Preference",
  apptTimeSelected = "Appt Time Selected",
  calendarDateSelected = "Calendar Date Selected",
  l4lNoQuotesFallback = "L4L No Quotes Fallback View",
  closeAccordion = "Close Accordion",
  openAccordion = "Open Accordion",
  status = "Status",
  pageViewed = "Page Viewed",
  dashboardOpps = "Dashboard Opps",
  questionShown = "Question Shown",
  questionAnswered = "Question Answered",
  inputShown = "Input Shown",
  inputComplete = "Input Complete",
  login = "Login",
  loginRedirect = "Login Redirect",
  loginSuccess = "Login Success",
  loginSurveyComplete = "Login Survey Complete",
  loginTlsStatus = "Login TLS Status",
  magicLink = "Magic Link",
  newPassword = "New Password",
  resetPassword = "Reset Password",
  forgotPassword = "Forgotten Password",
  journeyStarted = "Journey Started",
  sectionStarted = "Section Started",
  sectionCompleted = "Section Completed",
  ltvCalculated = "LTV Calculated",
  navigation = "Navigation",
  caseStatus = "Case Status",
  docsApplicantDDL = "Docs - Applicant DDL",
  docsAdded = "Docs added",
  numberOfProductsShown = "Number of Products Shown",
  accordionInView = "Accordion in View",
  documentUploaded = "Document Uploaded",
  arrivedFromComparison = "Arrived From Comparison",
  checkboxSelected = "Checkbox Selected",
  checkboxUnselected = "Checkbox Unselected",
  opportunityStage = "Opportunity Stage",
  mortgageType = "Mortgage Type",
  choiceTimeslotSeleced = "Choice Timeslot Selected",
  trueTimeslotSelected = "True Timeslot Selected",
  bucketedIntoExperiment = "Bucketed Into Experiment",
  documentDownload = "Document Download",
  recommendationShown = "Recommendation Shown",
  recommendationNotShown = "Recommendation Not Shown",
  rateCheckEsisDownloadClicked = "Rate Check ESIS Download Clicked",
  rateCheckEsisDownloadResult = "Rate Check ESIS Download Result"
}

// Some labels are dynamic/one off and are not contained within this list for obvious reasons.
export enum EventLabel {
  startApplication = "Start a new application",
  unknownCheckbox = "Unknown Checkbox",
  notShownCheckbox = "Not Shown Checkbox",
  thankYouForBooking = "Thank you for booking",
  callbackrequested = "Callback requested",
  appointmentBooked = "Appointment Booked",
  contactSooner = "Contact me sooner if possible",
  confirm = "Confirm",
  bookAnAppointment = "Book an Appointment",
  bookAnAppointmentPaidTest = "Book an Appointment - Paid Test",
  featureTest = "Feature is being tested",
  backToMortgageCoach = "Back to MortgageCoach",
  restartApplication = "Restart application",
  downloadMip = "Download my Mortgage in Principle",
  scheduleCall = "Schedule a Call",
  requestCallback = "Request a callback",
  callMeNow = "Call me now",
  close = "Close",
  closeError = "Close (Error)",
  noCtasDisplayed = "No CTAs Displayed",
  bookFreeReview = "Book your free review",
  eligibilityLoader = "EligibilityLoader",
  oops = "Oops",
  cancelBooking = "Cancel Booking",
  l4lNoQuotesFallback = "L4L No Quotes Fallback View",
  uploadDocs = "Upload Documents",
  endOfJourneyEmailAddress = "endOfJourneyEmailAddress",
  back = "Back",
  appType = "appType",
  mortgageScoreMaintenance = "MortgageScore is undergoing some maintenance",
  checkEligibility = "Check my eligibility",
  login = "Login",
  productComparisonStatus = "Product Comparison Status",
  appointmentCard = "Appointment Card",
  callbackConsent = "Callback consent",
  coachPropertyDetails = "Mortgage Coach - Property Details",
  coachDeposit = "Mortgage Coach - Deposit Details",
  coachSaved = "Save Info - Mortgage Coach",
  coachConfirm = "Confirm - Mortgage Coach",
  coachCancel = "Cancel - Mortgage Coach",
  tls = "TLS",
  personalisedMessagingPost = "Personalised Messaging - Post Offer",
  personalisedMessagingPre = "Personalised Messaging - Pre Offer",
  number = "number",
  hadOfferAccepted = "Had Offer Accepted",
  foundPropertyValue = "Found Property Value",
  continue = "Continue",
  propertyAddress = "Property Address",
  willCallYouSoon = "Will call you soon",
  willCallYouNow = "Will call you now",
  whyNoRatesTable = "Why no rates table",
  chooseTime = "Choose a time",
  startChat = "start a chat",
  startOnlineChat = "start an online chat",
  selectDeal = "Select this deal",
  getStarted = "Get started",
  goPro = "Go Pro",
  viewDeals = "View mortgage deals",
  digiRec = "Recommendation Shown",
  digiRecEsis = "Recommendation ESIS",
  digiRecNotReady = "Recommendation Not Ready Shown",
  digiRecAccepted = "Accept Recommendation",
  digiRecDocUpload = "DigiRec Doc Upload",
  autoRateAccept = "Identified Lower Rate Accepted",
  autoRateDecline = "Identified Lower Rate Declined",
}

export interface EventArgs {
  action: EventAction
  event_category: EventCategory
  event_label?: EventLabel | string
  value?: number // todo: confirm this is the correct attribute name

  dimension7?: string | number | boolean | null
  field_value?: string | number | boolean | null

  dimension8?: string
  mortgage_type?: string

  dimension9?: string
  user_stage?: string

  dimension10?: string
  traffic_light_definition?: string

  dimension12?: string
  full_page_url?: string

  dimension13?: string
  ltv_band?: string

  dimension14?: string
  internal_user?: string

  dimension15?: string
  appointment_type?: string

  dimension16?: string
  eligibility_tier?: string

  dimension17?: string
  total_documents_uploaded?: string

  dimension18?: string
  product_comparison_status?: string

  dimension19?: string
  mortgage_coach_status?: string

  dimension20?: string
  friendlyId?: string

  dimension21?: string
  lender_name?: string

  dimension22?: string
  product_name?: string

  dimension23?: string
  monthly_cost?: string

  dimension24?: string
  initial_rate?: string

  dimension25?: string
  mortgage_product_type?: string

  dimension26?: string
  initial_period_end_date?: string

  dimension27?: string
  initial_fixed_period?: string

  dimension28?: string
  arrangement_fee?: string

  dimension29?: string
  mortgage_payment_type?: string

  dimension30?: string
  offset?: string

  dimension31?: string
  portable?: string

  dimension32?: string
  overpayments_allowed?: string

  dimension33?: string
  free_legals?: string

  dimension34?: string
  free_valuation?: string

  dimension35?: string
  cashback?: string
}
