// @ts-nocheck
import React, { useEffect } from "react"

export function Trustpilot({
  height = "140px`",
  width = "130px",
  templateId = "5419b732fbfb950b10de65e5",
  theme = "light",
}) {
  const trustpilotRef = React.useRef(null)

  useEffect(() => {
    if (trustpilotRef && window.Trustpilot) {
      window.Trustpilot.loadFromElement(trustpilotRef.current, true)
      return
    }

    const interval = setInterval(() => {
      if (trustpilotRef && window.Trustpilot) {
        window.Trustpilot.loadFromElement(trustpilotRef.current, true)
        clearInterval(interval)
      }
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  return (
    <div
      ref={trustpilotRef}
      className="trustpilot-widget"
      data-locale="en-GB"
      data-template-id={templateId}
      data-businessunit-id="573992ca0000ff00058cec06"
      data-style-height={height}
      data-style-width={width}
      data-theme={theme}
    >
      <a href="https://uk.trustpilot.com/review/mojomortgages.com" target="_blank" rel="noreferrer">
        Trustpilot
      </a>
    </div>
  )
}
