import React from "react"
import Accord from "assets/images/lenders/Accord.png"
import AldermoreMortgages from "assets/images/lenders/Aldermore Mortgages.png"
import Atom from "assets/images/lenders/Atom Bank.png"
import BankOfIreland from "assets/images/lenders/Bank of Ireland.png"
import Barclays from "assets/images/lenders/Barclays Bank.png"
import BathBuildingSociety from "assets/images/lenders/Bath Building Society.png"
import BeverleyBuildingSociety from "assets/images/lenders/Beverley Building Society.png"
import BirminghamMidshires from "assets/images/lenders/Birmingham Midshires.png"
import BluestoneMortgages from "assets/images/lenders/Bluestone Mortgages.png"
import BMSolutions from "assets/images/lenders/BM Solutions.png"
import CambridgeBuildingSociety from "assets/images/lenders/Cambridge Building Society.png"
import ChorleyBuildingSociety from "assets/images/lenders/Chorley Building Society.png"
import ClydesdaleBank from "assets/images/lenders/Clydesdale Bank.png"
import CoventryBuildingSociety from "assets/images/lenders/Coventry Building Society.png"
import DigitalMortgages from "assets/images/lenders/Digital Mortgages.png"
import FoundationHomeLoans from "assets/images/lenders/Foundation Home Loans.png"
import FurnessBuildingSociety from "assets/images/lenders/Furness Building Society.png"
import GenerationHome from "assets/images/lenders/Generation Home.png"
import GodivaMortgages from "assets/images/lenders/Godiva Mortgages.png"
import Halifax from "assets/images/lenders/Halifax.png"
import HinckleyandRugbyBuildingSociety from "assets/images/lenders/Hinckley and Rugby Building Society.png"
import HSBC from "assets/images/lenders/HSBC.png"
import KensingtonMortgages from "assets/images/lenders/Kensington Mortgages.png"
import KentReliance from "assets/images/lenders/Kent Reliance.png"
import Landbay from "assets/images/lenders/Landbay.png"
import LeedsBuildingSociety from "assets/images/lenders/Leeds Building Society.png"
import LeekUnitedBuildingSociety from "assets/images/lenders/Leek United Building Society.png"
import LloydsBank from "assets/images/lenders/Lloyds Bank.png"
import MansfieldBuildingSociety from "assets/images/lenders/Mansfield Building Society.png"
import MetroBank from "assets/images/lenders/Metro Bank.png"
import MeltonBuildingSociety from "assets/images/lenders/Melton Building Society, The.png"
import MonmouthshireBuildingSociety from "assets/images/lenders/Monmouthshire Building Society.png"
import MortgageWorks from "assets/images/lenders/Mortgage Works, The.png"
import NationwideBuildingSociety from "assets/images/lenders/Nationwide Building Society.png"
import Natwest from "assets/images/lenders/Natwest.png"
import NewcastleBuildingSociety from "assets/images/lenders/Newcastle Building Society.png"
import NewburyBuildingSociety from "assets/images/lenders/Newbury Building Society.png"
import NottinghamBuildingSociety from "assets/images/lenders/Nottingham Building Society.png"
import ParagonBank from "assets/images/lenders/Paragon Banking Group PLC.png"
import PepperMoney from "assets/images/lenders/Pepper Money.png"
import Platform from "assets/images/lenders/Platform.png"
import PostOffice from "assets/images/lenders/Post Office.png"
import PreciseMortgages from "assets/images/lenders/Precise Mortgages.png"
import Principality from "assets/images/lenders/Principality BS.png"
import ProgressiveBuildingSociety from "assets/images/lenders/Progressive Building Society.png"
import RBS from "assets/images/lenders/Royal Bank of Scotland.png"
import Santander from "assets/images/lenders/Santander UK PLC.png"
import ScottishWidowsBank from "assets/images/lenders/Scottish Widows Bank.png"
import SkiptonBuildingSociety from "assets/images/lenders/Skipton Building Society.png"
import Together from "assets/images/lenders/Together.png"
import TSB from "assets/images/lenders/TSB.png"
import VidaHomeloans from "assets/images/lenders/Vida Homeloans.png"
import VirginMoney from "assets/images/lenders/Virgin Money.png"

import AccordWhite from "assets/images/lenders/white/Accord.png"
import AldermoreMortgagesWhite from "assets/images/lenders/white/Aldermore Mortgages.png"
import AtomWhite from "assets/images/lenders/white/Atom Bank.png"
import BankOfIrelandWhite from "assets/images/lenders/white/Bank of Ireland.png"
import BarclaysWhite from "assets/images/lenders/white/Barclays Bank.png"
import BathBuildingSocietyWhite from "assets/images/lenders/white/Bath Building Society.png"
import BeverleyBuildingSocietyWhite from "assets/images/lenders/white/Beverley Building Society.png"
import BirminghamMidshiresWhite from "assets/images/lenders/white/Birmingham Midshires.png"
import BluestoneMortgagesWhite from "assets/images/lenders/white/Bluestone Mortgages.png"
import BMSolutionsWhite from "assets/images/lenders/white/BM Solutions.png"
import CambridgeBuildingSocietyWhite from "assets/images/lenders/white/Cambridge Building Society.png"
import ChorleyBuildingSocietyWhite from "assets/images/lenders/white/Chorley Building Society.png"
import ClydesdaleBankWhite from "assets/images/lenders/white/Clydesdale Bank.png"
import CoventryBuildingSocietyWhite from "assets/images/lenders/white/Coventry Building Society.png"
import DigitalMortgagesWhite from "assets/images/lenders/white/Digital Mortgages.png"
import FoundationHomeLoansWhite from "assets/images/lenders/white/Foundation Home Loans.png"
import FurnessBuildingSocietyWhite from "assets/images/lenders/white/Furness Building Society.png"
import GenerationHomeWhite from "assets/images/lenders/white/Generation Home.png"
import GodivaMortgagesWhite from "assets/images/lenders/white/Godiva Mortgages.png"
import HalifaxWhite from "assets/images/lenders/white/Halifax.png"
import HinckleyandRugbyBuildingSocietyWhite from "assets/images/lenders/white/Hinckley and Rugby Building Society.png"
import HSBCWhite from "assets/images/lenders/white/HSBC.png"
import KensingtonMortgagesWhite from "assets/images/lenders/white/Kensington Mortgages.png"
import KentRelianceWhite from "assets/images/lenders/white/Kent Reliance.png"
import LandbayWhite from "assets/images/lenders/white/Landbay.png"
import LeedsBuildingSocietyWhite from "assets/images/lenders/white/Leeds Building Society.png"
import LeekUnitedBuildingSocietyWhite from "assets/images/lenders/white/Leek United Building Society.png"
import LloydsBankWhite from "assets/images/lenders/white/Lloyds Bank.png"
import MansfieldBuildingSocietyWhite from "assets/images/lenders/white/Mansfield Building Society.png"
import MetroBankWhite from "assets/images/lenders/white/Metro Bank.png"
import MeltonBuildingSocietyWhite from "assets/images/lenders/white/Melton Building Society, The.png"
import MonmouthshireBuildingSocietyWhite from "assets/images/lenders/white/Monmouthshire Building Society.png"
import MortgageWorksWhite from "assets/images/lenders/white/Mortgage Works, The.png"
import NationwideBuildingSocietyWhite from "assets/images/lenders/white/Nationwide Building Society.png"
import NatwestWhite from "assets/images/lenders/white/Natwest.png"
import NewcastleBuildingSocietyWhite from "assets/images/lenders/white/Newcastle Building Society.png"
import NewburyBuildingSocietyWhite from "assets/images/lenders/white/Newbury Building Society.png"
import NottinghamBuildingSocietyWhite from "assets/images/lenders/white/Nottingham Building Society.png"
import ParagonBankWhite from "assets/images/lenders/white/Paragon Banking Group PLC.png"
import PepperMoneyWhite from "assets/images/lenders/white/Pepper Money.png"
import PlatformWhite from "assets/images/lenders/white/Platform.png"
import PostOfficeWhite from "assets/images/lenders/white/Post Office.png"
import PreciseMortgagesWhite from "assets/images/lenders/white/Precise Mortgages.png"
import PrincipalityWhite from "assets/images/lenders/white/Principality BS.png"
import ProgressiveBuildingSocietyWhite from "assets/images/lenders/white/Progressive Building Society.png"
import RBSWhite from "assets/images/lenders/white/Royal Bank of Scotland.png"
import SantanderWhite from "assets/images/lenders/white/Santander UK PLC.png"
import ScottishWidowsBankWhite from "assets/images/lenders/white/Scottish Widows Bank.png"
import SkiptonBuildingSocietyWhite from "assets/images/lenders/white/Skipton Building Society.png"
import TogetherWhite from "assets/images/lenders/white/Together.png"
import TSBWhite from "assets/images/lenders/white/TSB.png"
import VidaHomeloansWhite from "assets/images/lenders/white/Vida Homeloans.png"
import VirginMoneyWhite from "assets/images/lenders/white/Virgin Money.png"

export const LenderIcon = ({ white, lenderName }: { white?: boolean; lenderName: string }) => {
  const lenderImagesRegular: Record<string, string> = {
    "Accord": Accord,
    "Aldermore Mortgages": AldermoreMortgages,
    "Atom Bank": Atom,
    "Bank of Ireland": BankOfIreland,
    "Barclays Bank": Barclays,
    "Bath Building Society": BathBuildingSociety,
    "Beverley Building Society": BeverleyBuildingSociety,
    "Birmingham Midshires": BirminghamMidshires,
    "Bluestone Mortgages": BluestoneMortgages,
    "BM Solutions": BMSolutions,
    "Cambridge Building Society": CambridgeBuildingSociety,
    "Chorley Building Society": ChorleyBuildingSociety,
    "Clydesdale Bank": ClydesdaleBank,
    "Coventry Building Society": CoventryBuildingSociety,
    "Digital Mortgages": DigitalMortgages,
    "Foundation Home Loans": FoundationHomeLoans,
    "Furness Building Society": FurnessBuildingSociety,
    "Generation Home": GenerationHome,
    "Godiva Mortgages": GodivaMortgages,
    "Halifax": Halifax,
    "Hinckley and Rugby Building Society": HinckleyandRugbyBuildingSociety,
    "HSBC": HSBC,
    "Kensington Mortgages": KensingtonMortgages,
    "Kent Reliance": KentReliance,
    "Landbay": Landbay,
    "Leeds Building Society": LeedsBuildingSociety,
    "Leek United Building Society": LeekUnitedBuildingSociety,
    "Lloyds Bank": LloydsBank,
    "Mansfield Building Society": MansfieldBuildingSociety,
    "Metro Bank": MetroBank,
    "Melton Building Society": MeltonBuildingSociety,
    "Monmouthshire Building Society": MonmouthshireBuildingSociety,
    "Mortgage Works": MortgageWorks,
    "Nationwide Building Society": NationwideBuildingSociety,
    "Natwest": Natwest,
    "Newcastle Building Society": NewcastleBuildingSociety,
    "Newbury Building Society": NewburyBuildingSociety,
    "Nottingham Building Society": NottinghamBuildingSociety,
    "Paragon Bank": ParagonBank,
    "Pepper Money": PepperMoney,
    "Platform": Platform,
    "Post Office": PostOffice,
    "Precise Mortgages": PreciseMortgages,
    "Principality": Principality,
    "Progressive Building Society": ProgressiveBuildingSociety,
    "Royal Bank of Scotland": RBS,
    "Santander UK PLC": Santander,
    "Scottish Widows Bank": ScottishWidowsBank,
    "Skipton Building Society": SkiptonBuildingSociety,
    "Together": Together,
    "TSB": TSB,
    "Vida Homeloans": VidaHomeloans,
    "Virgin Money": VirginMoney,
  }

  const lenderImagesWhite: Record<string, string> = {
    "Accord": AccordWhite,
    "Aldermore Mortgages": AldermoreMortgagesWhite,
    "Atom Bank": AtomWhite,
    "Bank of Ireland": BankOfIrelandWhite,
    "Barclays Bank": BarclaysWhite,
    "Bath Building Society": BathBuildingSocietyWhite,
    "Beverley Building Society": BeverleyBuildingSocietyWhite,
    "Birmingham Midshires": BirminghamMidshiresWhite,
    "Bluestone Mortgages": BluestoneMortgagesWhite,
    "BM Solutions": BMSolutionsWhite,
    "Cambridge Building Society": CambridgeBuildingSocietyWhite,
    "Chorley Building Society": ChorleyBuildingSocietyWhite,
    "Clydesdale Bank": ClydesdaleBankWhite,
    "Coventry Building Society": CoventryBuildingSocietyWhite,
    "Digital Mortgages": DigitalMortgagesWhite,
    "Foundation Home Loans": FoundationHomeLoansWhite,
    "Furness Building Society": FurnessBuildingSocietyWhite,
    "Generation Home": GenerationHomeWhite,
    "Godiva Mortgages": GodivaMortgagesWhite,
    "Halifax": HalifaxWhite,
    "Hinckley and Rugby Building Society": HinckleyandRugbyBuildingSocietyWhite,
    "HSBC": HSBCWhite,
    "Kensington Mortgages": KensingtonMortgagesWhite,
    "Kent Reliance": KentRelianceWhite,
    "Landbay": LandbayWhite,
    "Leeds Building Society": LeedsBuildingSocietyWhite,
    "Leek United Building Society": LeekUnitedBuildingSocietyWhite,
    "Lloyds Bank": LloydsBankWhite,
    "Mansfield Building Society": MansfieldBuildingSocietyWhite,
    "Metro Bank": MetroBankWhite,
    "Melton Building Society": MeltonBuildingSocietyWhite,
    "Monmouthshire Building Society": MonmouthshireBuildingSocietyWhite,
    "Mortgage Works": MortgageWorksWhite,
    "Nationwide Building Society": NationwideBuildingSocietyWhite,
    "Natwest": NatwestWhite,
    "Newcastle Building Society": NewcastleBuildingSocietyWhite,
    "Newbury Building Society": NewburyBuildingSocietyWhite,
    "Nottingham Building Society": NottinghamBuildingSocietyWhite,
    "Paragon Bank": ParagonBankWhite,
    "Pepper Money": PepperMoneyWhite,
    "Platform": PlatformWhite,
    "Post Office": PostOfficeWhite,
    "Precise Mortgages": PreciseMortgagesWhite,
    "Principality": PrincipalityWhite,
    "Progressive Building Society": ProgressiveBuildingSocietyWhite,
    "Royal Bank of Scotland": RBSWhite,
    "Santander UK PLC": SantanderWhite,
    "Scottish Widows Bank": ScottishWidowsBankWhite,
    "Skipton Building Society": SkiptonBuildingSocietyWhite,
    "Together": TogetherWhite,
    "TSB": TSBWhite,
    "Vida Homeloans": VidaHomeloansWhite,
    "Virgin Money": VirginMoneyWhite,
  }
  const lenderImages = white ? lenderImagesWhite : lenderImagesRegular

  const imageSrc = lenderImages[lenderName]

  if (imageSrc) {
    return <img src={imageSrc} alt={`Mortgage lender ${lenderName}`} />
  } else {
    return <></>
  }
}

export default LenderIcon
