import React from "react"
import { RadioButton } from "mojo-storybook-v2-library"
import styled from "styled-components"
import { ReplaceAll } from "lib/ReplaceAll"
import { Tooltip, questionMarkColors } from "./Tooltip"

const ButtonWrapper = styled.div`
  margin: 0.5rem 0;
  position: relative;
  ${({ hasTooltip }) =>
    hasTooltip &&
    `
		label {
			padding-right: 40px;
		}`}
`

type Props = {
  id?: string
  options?: {
    text?: string
    value?: string
    tooltip?: {
      title?: string
      content?: string
    }
  }[]
  value?: string
  onClick?: (value: string) => void
  className?: string
}

export default function SingleSelect({ id, options, value, onClick, className }: Props) {
  return (
    <>
      {options.map((option, index) => {
        return (
          <ButtonWrapper hasTooltip={!!option.tooltip} key={id + option.text} className={className}>
            {option.tooltip && (
              <Tooltip
                questionMarkColor={
                  value === option.value ? questionMarkColors.WHITE : questionMarkColors.NAVY
                }
                id={id + "tooltip"}
                option={option}
              />
            )}
            <RadioButton
              name={id}
              value={option.value}
              checked={option.value === value}
              onChange={(e) => onClick(e.target.value)}
              id={`radio-${id}-${ReplaceAll(option.value, "/ /", "-")}`}
            >
              {option.text}
            </RadioButton>
          </ButtonWrapper>
        )
      })}
    </>
  )
}
