import React, { useState } from "react"
import { IdentifiedLowerRate, ILRState, ProductDetails, ProductDetailsRequest } from "../types"
import { ToCurrency } from "lib/CurrencyFormatter"
import styled from "styled-components"
import { RateDetail, RateDetailEnum } from "./RateDetail"
import { Button, ButtonType } from "components/atoms"
import axios from "axios"
import { RateRepresentativeExample } from "./RepExample"
import IconTick from "assets/images/icons/Icon_Tick.svg"
import Refresh from "assets/images/icons/Refresh.svg"
import { EventAction, EventCategory, EventLabel } from "models/GoogleAnalytics"
import { LogGaEvent } from "lib/GoogleAnalytics"

type RateCardProps = {
  currentRate: boolean
  productDetails: ProductDetails
  productInput: ProductDetailsRequest
  identifiedLowerRate?: IdentifiedLowerRate
  identifiedLowerRateStatus: ILRState
  identifiedLowerRateId: string
  isError: boolean
  setIsError: React.Dispatch<React.SetStateAction<boolean>>
  actionClicked: null | "current" | "new"
  setActionClicked: React.Dispatch<React.SetStateAction<null | "current" | "new">>
  handleEsisDownload: () => void
}

const formatProductEndDate = (date: Date) => {
  return date.toLocaleString("default", { month: "short", year: "numeric" })
}

export const RateCard = ({
  productDetails,
  productInput,
  identifiedLowerRate,
  identifiedLowerRateStatus,
  identifiedLowerRateId,
  actionClicked,
  setActionClicked,
  isError,
  setIsError,
  currentRate,
  handleEsisDownload
}: RateCardProps) => {
  const [buttonClicked, setButtonClicked] = useState(false)

  const {
    AprLenders,
    ArrangementFee,
    BookingFee,
    FeesTotal,
    InitialMonthlyPayment,
    InitialPayRate,
    InitialRatePeriodPrecise,
    InitialRatePeriodMonths,
    LenderName,
    MonthlyPaymentAfterInitialPeriod,
    StandardVariableRate,
    TotalInterestPayable,
    TrueCost,
    TrueCostOverInitialPeriod,
  } = productDetails || {}

  const { loanAmount, termMonths } = productInput || {}

  const formattedMonthlyPayment = ToCurrency(InitialMonthlyPayment)
  const formattedInitialCosts = ToCurrency(TrueCostOverInitialPeriod)
  const formattedEndDate = formatProductEndDate(new Date(InitialRatePeriodPrecise))
  const formattedBorrowing = ToCurrency(loanAmount)
  const formattedTrueCosts = ToCurrency(TrueCost)
  const formattedArrangementFee = ToCurrency(ArrangementFee)
  const feesOther = FeesTotal - BookingFee
  const formattedFeesOther = ToCurrency(feesOther)
  const formattedInterest = ToCurrency(TotalInterestPayable)
  const formatMonthlyPaymentAfterInitialPeriod = ToCurrency(MonthlyPaymentAfterInitialPeriod)
  const declined = identifiedLowerRateStatus === ILRState.Declined
  const expired = identifiedLowerRateStatus === ILRState.Expired
  const completed = identifiedLowerRateStatus === ILRState.Completed
  const progressing = identifiedLowerRateStatus === ILRState.Progressing
  const cancelled = identifiedLowerRateStatus === ILRState.Cancelled

  const currentRateSelected = declined ? true : false
  const otherRateSelected = progressing || completed || expired || cancelled ? true : false
  const active = !expired && !completed && !cancelled

  const selected =
    (currentRate && currentRateSelected && active) || (!currentRate && otherRateSelected && active)
  const thisRateSelected = buttonClicked && !isError ? true : false

  const overallSelected = selected || thisRateSelected

  const disable =
    (currentRate && (actionClicked === "new" || otherRateSelected)) ||
    (!currentRate && (actionClicked === "current" || currentRateSelected)) ||
    expired
      ? true
      : false

  const cardTitle = currentRate
    ? "Your current rate"
    : expired
    ? "Expired rate"
    : declined
    ? "Declined rate"
    : "New rate"

  const showCard = currentRate || (identifiedLowerRate && !completed)

  const handleClick = () => {
    if (currentRate) {
      setActionClicked("current")
    } else {
      setActionClicked("new")
    }
    setButtonClicked(true)
    LogGaEvent({
      action: EventAction.buttonClicked,
      event_category: EventCategory.functionalInteraction,
      event_label: currentRate ? EventLabel.autoRateDecline : EventLabel.autoRateAccept,
    })
    axios
      .post(
        `${process.env.MY_MOJO_API}/rate-check/update`,
        {
          id: identifiedLowerRateId,
          status: currentRate ? ILRState.Declined : ILRState.Progressing,
        },
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        console.error(error)
        setIsError(true)
      })
  }

  return (
    <RateWrapper selected={overallSelected} disable={disable}>
      <RateCardTitle>{cardTitle}</RateCardTitle>
      {showCard ? (
        <>
          <RateContainer>
            <RateHighlight>
              <div>{InitialPayRate} %</div>
              <div>Initial interest rate</div>
            </RateHighlight>
            <RateDetails aria-label={`${cardTitle} details`}>
              <RateDetail type={RateDetailEnum.MONTHLY_PAYMENT} data={formattedMonthlyPayment} />
              <RateDetail type={RateDetailEnum.OVERALL_INITIAL_COST} data={formattedInitialCosts} />
              <RateDetail type={RateDetailEnum.APRC} data={`${AprLenders} %`} />
              <RateDetail type={RateDetailEnum.PRODUCT_FEE} data={`£${ArrangementFee}`} />
              <RateDetail type={RateDetailEnum.END_DATE} data={`${formattedEndDate}`} />
            </RateDetails>
          </RateContainer>
          {active && (
            <RateCTAs>
              {overallSelected ? (
                <RateActionContainer>
                  <div>{currentRate ? "Your current rate selected" : "New rate selected"}</div>
                </RateActionContainer>
              ) : (
                <Button
                  id={`button-select-${currentRate ? "current" : "this"}-rate`}
                  disabled={disable}
                  type={ButtonType.Solid}
                  onClick={handleClick}
                  text={currentRate ? "Stay with this rate" : "Request this rate"}
                />
              )}
            </RateCTAs>
          )}
          <RateRepresentativeExample
            formattedBorrowing={formattedBorrowing}
            formattedTrueCosts={formattedTrueCosts}
            AprLenders={AprLenders}
            formattedArrangementFee={formattedArrangementFee}
            feesOther={feesOther}
            formattedFeesOther={formattedFeesOther}
            InitialPayRate={InitialPayRate}
            InitialRatePeriodMonths={InitialRatePeriodMonths}
            formattedMonthlyPayment={formattedMonthlyPayment}
            formattedInterest={formattedInterest}
            formatMonthlyPaymentAfterInitialPeriod={formatMonthlyPaymentAfterInitialPeriod}
            StandardVariableRate={StandardVariableRate}
            termMonths={termMonths}
          />
          <DownloadEsis handleClick={handleEsisDownload} disabled={disable} />
        </>
      ) : (
        <RateMissingContainer>
          <RefreshIcon />
          <RateMissingTitle>
            When we find a lower rate with {LenderName}, it’ll appear here
          </RateMissingTitle>
          <div>Don’t worry, we’ll email to let you know</div>
        </RateMissingContainer>
      )}
    </RateWrapper>
  )
}

const DownloadEsis = ({ handleClick, disabled }: {
  handleClick: () => void
  disabled?: boolean
}) => {
  return (
    <DownloadEsisContainer>
      {disabled
        ? <DownloadEsisLinkDisabled>Download Mortgage Illustration</DownloadEsisLinkDisabled>
        : <DownloadEsisLink target="_blank" onClick={handleClick}>Download Mortgage Illustration</DownloadEsisLink>
      }
    </DownloadEsisContainer>
  )
}

const DownloadEsisContainer = styled.div({
  display: "flex",
  justifyContent: "center",
})

const DownloadEsisLink = styled.a({
  fontWeight: 600,
  fontSize: 20,
  margin: "16px 0",
  color: "#073ED9",
})

const DownloadEsisLinkDisabled = styled.p({
  fontWeight: 600,
  fontSize: 20,
  margin: "16px 0",
  color: "#6E717C",
})

const RateWrapper = styled.div<{ selected: boolean; disable: boolean }>`
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  background: #fff;
  padding: 24px;
  gap: 16px;
  flex: 1;
  border: ${({ selected }) => (selected ? "2px solid orange" : "none")};
  opacity: ${({ disable }) => (disable ? "0.7" : "1")};
`

const RateCardTitle = styled.div`
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 8px;
`

const RateMissingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 12px;
  background: #f3f5fc;
  padding: 24px;
  text-align: center;
`

const RateMissingTitle = styled.div`
  font-size: 16px;
`

const RateActionContainer = styled.div`
  width: 100%;
  margin: 10px 0;
  padding: 10px 30px;
  border-radius: 8px;
  background: #eef5f3;
  font-size: 18px;
  font-weight: 600;

  & > div {
    display: flex;
    align-items: center;
    color: #398156;

    justify-content: center;
    text-align: center;
    ::before {
      content: url(${IconTick});
    }
  }
`

const RateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  border-radius: 8px;
  padding: 8px;
  background: #f3f5fc;
`

const RateHighlight = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-items: center;
  align-items: center;
  padding: 16px;
  text-align: center;
  width: 100%;

  background: #dde3f7;
  border-radius: 4px;

  & > div:first-of-type {
    font-size: 20px;
    font-weight: 600;
  }
`

const RateDetails = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 16px;
`

const RateCTAs = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  gap: 16px;
`

const RefreshIcon = styled.div`
  ::before {
    content: url(${Refresh});
  }
`
