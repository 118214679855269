import React, { useEffect, useState } from "react"
import { FormatInput } from "../atoms/FormatInput"

type Props = {
  value?: string
  onDone: (value: string, errors: string[]) => void
  id: string
}

const phoneNumberRegex = /^(?:0|\+?44)(?:\d\s?){9,10}$/

export function validatePhoneNumber(phoneNumber) {
  if (phoneNumber?.replace(/ /g, "").length < 1) {
    return false
  } else if (!phoneNumberRegex.test(phoneNumber.replace(/ /g, ""))) {
    return false
  } else {
    return true
  }
}

export function PhoneNumberInput({ value, onDone, id }: Props) {
  const [input, setInput] = useState(value || "")

  useEffect(() => {
    if (!input) {
      return
    }

    const isValid = validatePhoneNumber(input)
    const errors = []

    if (!isValid) {
      errors.push("Please enter a valid phone number")
      onDone(input.trim(), errors)
      return
    }

    onDone(input.trim(), [])
  }, [input])

  return (
    <FormatInput
      format="###########"
      mask={["", "", "", "", "", "", "", "", "", "", ""]}
      autoComplete="off"
      onChange={(e) => setInput(e.target.value)}
      value={input}
      inputMode="decimal"
      id={`phone-${id}`}
    />
  )
}
