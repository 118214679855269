import * as React from "react"
import styled from "styled-components"
import Tooltip from "components/_blueprint/Tooltip"

export enum RateDetailEnum {
  MONTHLY_PAYMENT = "Monthly payment",
  OVERALL_INITIAL_COST = "Overall initial cost",
  APRC = "APRC",
  PRODUCT_FEE = "Product fee",
  END_DATE = "End date",
}

export const RateDetail = ({ type, data }: { type: RateDetailEnum; data: string }) => {
  const selectTooltipContent = (type: RateDetailEnum) => {
    switch (type) {
      case RateDetailEnum.OVERALL_INITIAL_COST:
        return "This is the total amount you’ll pay over your initial period including interest and any fees you add to the loan."
      case RateDetailEnum.APRC:
        return "APRC is a figure that shows the total yearly cost of a UK mortgage, including interest and fees, expressed as a percentage. It is intended to helps you compare different mortgage offers."
      case RateDetailEnum.PRODUCT_FEE:
        return "This is a fee you’ll pay to secure this interest rate. It is set by the lender, and might also be referred to as a mortgage booking fee, arrangement fee, or reservation fee."
      case RateDetailEnum.END_DATE:
        return "The is the date your next mortgage will end. The date may move later if you move onto a lower rate as you are moving onto it at a later date. This however, won’t impact your anticipated completion date."
      default:
        return null
    }
  }

  const content = selectTooltipContent(type)

  return (
    <RateDetailContainer>
      <RateDetailItem>
        <div>{type}</div>
        {content && <Tooltip content={content} />}
      </RateDetailItem>
      <RateDetailItem>
        <div>{data}</div>
      </RateDetailItem>
    </RateDetailContainer>
  )
}

const RateDetailContainer = styled.li`
  display: flex;
  justify-content: start;
  flex-direction: column-reverse;
  gap: 8px;
  flex: 50%;
  margin-bottom: 8px;
  padding: 8px 0;

  & > div:last-of-type {
    font-size: 20px;
    font-weight: 600;
  }
`

const RateDetailItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`
