import React from "react"
import styled from "styled-components"
import { Input } from "mojo-storybook-v2-library"

const List = styled.div`
  background: ${(props) => props.theme.shade[100]};
  border-radius: 4px;
  overflow: auto;
  border: 1px solid ${(props) => props.theme.shade[60]};
  max-height: 240px;
  :empty {
    display: none;
  }
`
const ListItem = styled.div`
  padding: 16px;
  cursor: pointer;
  border-top: 1px solid ${(props) => props.theme.shade[60]};
  :hover {
    background: ${(props) => props.theme.shade[80]};
  }
`

type Props = {
  value: string
  onInputChanged: Function
  onSelectionMade: Function
  comboSelections: { id?: string; text: string }[]
  isLoading?: boolean
  disabled?: boolean
  label?: string
  id: string
}

export function ComboBox({
  label,
  value,
  onInputChanged,
  comboSelections,
  onSelectionMade,
  isLoading,
  disabled,
  id,
}: Props) {
  return (
    <>
      <Input
        value={value}
        onChange={onInputChanged}
        label={label}
        isLoading={isLoading}
        disabled={disabled}
        id={`${id}-input`}
      />
      <List id={`${id}-list`}>
        {comboSelections &&
          comboSelections.map((selection, i) => (
            <ListItem
              id={`${id}-list-item-${selection.id || i}`}
              key={selection.text}
              onClick={() => onSelectionMade(selection)}
            >
              {selection.text}
            </ListItem>
          ))}
      </List>
    </>
  )
}
