import React, { useEffect, useState } from "react"
import axios from "axios"
import styled from "styled-components"

import { GetUrlParameter, SearchParams } from "lib/UrlParams"
import { IS_DEV, MY_MOJO_API } from "../../../Config"

import BackgroundWave from "templates/BackgroundWave"
import { TopNavTemplate } from "templates/TopNavTemplate"
import { Intercom } from "components/molecules/Intercom"
import { EmailInput } from "components/molecules/EmailInput"
import { Button } from "components/atoms"
import { useTrackNavigatedToPage } from "hooks/useTrackPageView"
import { LogGaEvent } from "lib/GoogleAnalytics"
import { EventAction, EventCategory } from "models/GoogleAnalytics"
import { SetToken } from "lib/Authentication"
import devices from "styles/devices"
import { Gap } from "components/_blueprint/Layout"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope } from "@fortawesome/free-solid-svg-icons"

const ContentBox = styled.div`
  border-radius: 12px;
  background: white;
  padding: 56px 25px;
  width: 85%;
  margin: 0 auto;

  @media ${devices.bp_sm} {
    width: 550px;
    padding: 56px 66px;
    margin-top: calc(20vh - 100px);
  }

  h1 {
    margin-bottom: 30px;
  }

  h4 {
    font-size: 20px;
  }

  p {
    margin-top: 10px;
    line-height: 1.6em;
  }
`

const Container = styled.div`
  display: flex;
`

const MessageContainer = styled.div`
  font-weight: bold;
  margin: 20px 0 10px 0;
`

const HrefLink = styled.a`
  text-decoration: underline;
  text-align: center;
  font-weight: 550;
  text-underline-offset: 2px;
`

export const RequestMagicLink = () => {
  const [email, setEmail] = useState<string>("")
  const [emailIsValid, setEmailIsValid] = useState<boolean>()
  const [hasSentEmail, setHasSentEmail] = useState<boolean>(false)
  const [isBusy, setIsBusy] = useState<boolean>(false)

  const queryParams = new URLSearchParams(location.search)

  useTrackNavigatedToPage("/login")

  useEffect(() => {
    LogGaEvent({
      action: EventAction.componentDisplay,
      event_category: EventCategory.siteInteraction,
      event_label: "Magic Link",
    })
  }, [])

  async function SendMagicEmail() {
    LogGaEvent({
      action: EventAction.buttonClicked,
      event_category: EventCategory.functionalInteraction,
      event_label: "Request Magic Link",
    })
    LogGaEvent({
      action: EventAction.magicLink,
      event_category: EventCategory.accountInteraction,
      event_label: "Request",
    })
    setIsBusy(true)
    try {
      await LoginRequest(email)
      setHasSentEmail(true)
      LogGaEvent({
        action: EventAction.magicLink,
        event_category: EventCategory.accountInteraction,
        event_label: "Success",
      })
    } finally {
      setIsBusy(false)
    }
  }

  async function DeveloperLogin() {
    try {
      const result = await axios.post(`${MY_MOJO_API}/auth`, {
        email: "mymojotest1@mojo.com",
        password: "P@ssword1",
      })
      try {
        await axios.get(
          `${process.env.PUBLIC_AUTH_API}/magic-login?magicToken=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6Im15bW9qb3Rlc3QxQG1vam8uY29tIiwidXNlcklkIjo0MTU2NSwiYXBwbGljYXRpb25JZFRvTGluayI6bnVsbCwiZXhwIjozMDExOTQxNjQwMywiaWF0IjoxNzE5NDE2NDAzfQ.S7bmSxEFs4xdnhHpRk9naPSpERUomlvlW-fk-gfHfEM`,
          {
            withCredentials: true,
          }
        )
      } catch (err) {
        console.log(err)
      }
      SetToken(result.data.token)
      location.assign("/dashboard")
    } catch {
      return null
    }
  }

  const disabled = !emailIsValid || hasSentEmail
  const wasExpired = queryParams.get("expired") === "true"

  return (
    <TopNavTemplate
      miniNav={true}
      content={
        <>
          <BackgroundWave />
          <Container>
            <Intercom style={{ zIndex: "100" }} />

            <ContentBox>
              <h1>Log in</h1>

              {wasExpired && <ExpiredWarning>The link you followed has expired.</ExpiredWarning>}
              <p>Enter your email and we’ll send you a link to log in</p>

              <EmailInput
                placeholder="Email address"
                value={email}
                onChange={(value) => setEmail(value)}
                onIsValidChange={(isValid) => setEmailIsValid(isValid)}
                onEnter={() => {
                  if (!disabled) SendMagicEmail()
                }}
                onLoaded={() =>
                  LogGaEvent({
                    action: EventAction.inputShown,
                    event_category: EventCategory.siteInteraction,
                    event_label: "Email Address",
                  })
                }
                onFocus={() =>
                  LogGaEvent({
                    action: EventAction.fieldFocus,
                    event_category: EventCategory.formInteraction,
                    event_label: "Email Address",
                  })
                }
                onBlur={() => {
                  LogGaEvent({
                    action: EventAction.fieldBlur,
                    event_category: EventCategory.formInteraction,
                    event_label: "Email Address",
                  })
                  !emailIsValid &&
                    LogGaEvent({
                      action: EventAction.validationMessageShown,
                      event_category: EventCategory.siteInteraction,
                      event_label: "Email Address - Email address is invalid",
                    })
                }}
              />

              {hasSentEmail && (
                <MessageContainer>Done! Check your inbox for a login link.</MessageContainer>
              )}
              <Gap x={0.5} />
              <Button
                disabled={disabled}
                text={
                  hasSentEmail ? (
                    <>
                      <ButtonIconWrapper>
                        <FontAwesomeIcon icon={faEnvelope} />
                      </ButtonIconWrapper>
                      Sent
                    </>
                  ) : (
                    "Send login link"
                  )
                }
                onClick={SendMagicEmail}
                showLoader={isBusy}
              />
              {hasSentEmail && (
                <p>
                  Can’t see it? <HrefLink onClick={SendMagicEmail}>Send it again</HrefLink>
                </p>
              )}

              {IS_DEV && (
                <MessageContainer>
                  <HrefLink style={{ textDecoration: "none" }} onClick={DeveloperLogin}>
                    💻 I am devloper
                  </HrefLink>
                </MessageContainer>
              )}
            </ContentBox>
          </Container>
        </>
      }
    />
  )
}

async function LoginRequest(emailAddress: string): Promise<string | null> {
  const appToCreate = GetUrlParameter("appData")
  let redirect = null

  if (appToCreate) {
    redirect = `/routing-summary?${SearchParams()}`
  }

  try {
    const result = await axios.post(`${MY_MOJO_API}/auth/send-magic-login`, {
      emailAddress,
      redirect,
    })
    return result.data.token
  } catch {
    return null
  }
}

const ButtonIconWrapper = styled.span`
  padding-right: 7px;
`

const ExpiredWarning = styled.p(({ theme }) => ({
  color: theme.colors.error[500],
}))
