import { Flag, FullApplication, Location } from "models/FullApplication"
import { DateDiff, ParseDateAuto } from "lib/Date"
import { HasCompletedFactFind } from "lib/Applications"
import { GetPartnerFromUrl } from "lib/GetPartnerFromUrl"
import { getExperienceTLS } from "hooks/useExperienceTLS"
import { ResultPage } from "models/ExperienceTLS"
import { GetCookie } from "lib/Cookie"
import { GetFeatureFlag } from "hooks/useAdminControls"
import { COMPARISON_TABLE_URL_V2 } from "../Config"
import toQueryString from "lib/QueryString"

/**
 * Creates an object from our application values so that it can be sent as query params.
 * @param fullApplication - The application object
 */
export async function GenerateTableUrl(
  fullApplication: FullApplication,
  hideClickouts = false
): Promise<string> {
  const {
    rule: { color: tlColor, tier: tlTier },
    resultPage,
  } = await getExperienceTLS(fullApplication.applicationId)
  const isAdviceRatesExperiment = GetIsInAdviceRatesExperiment(fullApplication)
  const isBrokerOnlyEnabled = await GetFeatureFlag("brokerOnlyTable")

  const queryParams = {
    style: BrandPascalCase(),
    friendlyId: fullApplication?.friendlyId,
    propertyValue: Math.floor(fullApplication.basicPropertyDetails?.propertyValue),
    repaymentPeriod: fullApplication.term,
    mortgageAmount: Math.floor(MortgageAmount()),
    rateType: rateType(),
    paymentType: mojoUswitchRepaymentType(),
    journeyCompleted: true,
    mortgageType: mortgageType(),
    timeframeForMortgage: mojoUswitchTimeframeMortgage(),
    currentProvider: mojoUswitchCurrentProvider(),
    remortgageReason: mojoUswitchRemortgageReason(),
    reasonForMortgage:
      fullApplication.applicationReason === "Remortgage" ? "Remortgage" : "Purchase",
    borrowMore: fullApplication.borrowingDetails?.additionalBorrowing ? "yes" : "no",
    additionalBorrowing: fullApplication.borrowingDetails?.additionalBorrowing
      ? Math.floor(fullApplication.borrowingDetails?.additionalBorrowing)
      : null,
    financialDifficulty: "no",
    location: mojoLocation(),
    brand: GetPartnerFromUrl() === "uswitch" ? "uswitch" : "mojo",
    shouldBookAppointment: HasCompletedFactFind(fullApplication),
    fullApplicationId: fullApplication.applicationId,
    currentLenderCode: fullApplication.remortgageDetails
      ? fullApplication.remortgageDetails.existingLenderCode
      : "",
    productTermPeriodFromMonths: initialPeriod().productTermPeriodFromMonths,
    productTermPeriodToMonths: initialPeriod().productTermPeriodToMonths,
    deposit: Math.floor(fullApplication?.borrowingDetails?.deposit),
    tlColor,
    tlTier,
    hideLenderClickouts: ShouldHideLenderClickouts(),
    hideAllClickouts: ShouldHideAllClickouts(hideClickouts),
  }

  return `${COMPARISON_TABLE_URL_V2}${toQueryString(queryParams)}`

  function ShouldHideLenderClickouts() {
    return (
      isAdviceRatesExperiment || (isBrokerOnlyEnabled && resultPage === ResultPage.TableBrokerOnly)
    )
  }

  function ShouldHideAllClickouts(hideClickouts) {
    return resultPage === ResultPage.TableNoClickout || hideClickouts
  }

  function BrandPascalCase(): string {
    const urlPartner = GetPartnerFromUrl()
    switch (urlPartner) {
      case "mojo":
        return "Mojo"
      case "uswitch":
        return "Uswitch"
      case "money":
        return "Money"
      case "confused":
        return "Confused"
      default:
        return "Mojo"
    }
  }

  function MortgageAmount(): number {
    if (fullApplication.applicationReason === "Remortgage") {
      return fullApplication.currentMortgage?.mortgageBalance ?? 0
    } else {
      return 0
    }
  }

  function initialPeriod(): {
    productTermPeriodFromMonths: number
    productTermPeriodToMonths: number
  } {
    const value = fullApplication.preferences?.initialPeriodPreference
    let productTermPeriodFromMonths = 24
    let productTermPeriodToMonths = 29

    switch (value) {
      case "two_years":
        productTermPeriodFromMonths = 24
        productTermPeriodToMonths = 29
        break
      case "three_years":
        productTermPeriodFromMonths = 36
        productTermPeriodToMonths = 39
        break
      case "five_years":
      case "ten_years":
        productTermPeriodFromMonths = 60
        productTermPeriodToMonths = 69
        break
    }

    return { productTermPeriodFromMonths, productTermPeriodToMonths }
  }

  function mortgageType(): string {
    const value = fullApplication.basicPropertyDetails?.occupancy
    switch (value) {
      case "Rent_it_out":
        return "Buy_To_Let"
      case "Live_in_property":
        return "Standard"
      default:
        return "Standard"
    }
  }

  function rateType(): string {
    const value = fullApplication.preferences?.mortgageTypePreference

    switch (value) {
      case "tracker":
        return "tracker"
      case "fixed_rate":
        return "fixed"
      default:
        return "fixed"
    }
  }

  function mojoUswitchRepaymentType(): string {
    const value = fullApplication.preferences?.repaymentMethodPreference

    switch (value) {
      case "interest_only":
        return "interestOnly"
      default:
        return "repayment"
    }
  }

  function mojoUswitchTimeframeMortgage() {
    const now = ParseDateAuto(new Date().toISOString())
    const endDate = fullApplication.remortgageDetails?.initialPeriodEndDate
    const months = DateDiff(now, endDate, "month")

    if (!endDate) {
      return null
    } else {
      if (months >= 7) return 6
      else if (months >= 4) return 2
      return 0
    }
  }

  function mojoUswitchCurrentProvider(): string {
    const currentLender = fullApplication.remortgageDetails?.existingLender
    switch (currentLender) {
      case "Barclays Bank":
        return "barclays"
      case "Halifax":
        return "halifax"
      case "HSBC":
        return "hsbc"
      case "Lloyds Bank":
        return "lloydsBank"
      case "Nationwide BS":
        return "nationwide"
      case "Leeds BS":
        return "leedsbuildingsociety"
      case "Santander UK Plc":
        return "santander"
      case "NatWest":
        return "natwest"
      default:
        return "other"
    }
  }

  function mojoLocation(): string {
    const postcode = fullApplication.completePropertyDetails?.propertyAddress?.postcode
    const location = fullApplication.basicPropertyDetails?.location

    if (location !== Location.unknown) {
      return location
    }
    if (postcode && postcode.substring(0, 2) === "BT") {
      return Location.Northern_Ireland
    } else {
      return Location.England
    }
  }

  function mojoUswitchRemortgageReason(): string {
    const remortgageReason = fullApplication?.remortgageReason
    switch (remortgageReason) {
      case "New_fixed_term":
        return "endingSoon"
      case "Better_rate":
        return "saveMoney"
      default:
        return "endingSoon"
    }
  }
}

function GetIsInAdviceRatesExperiment(application: FullApplication): boolean {
  if (application.metadata.flags.includes(Flag.IsInSecondaryRatesExperiment)) {
    return true
  }

  // Check Optimize Cookies
  const experimentsCookie = GetCookie("_gaexp")

  if (!experimentsCookie) {
    return false
  }

  const regex = /8LF9TFXeTP2QL0NGFtvy2Q\.\d+.1$/
  return regex.test(experimentsCookie)
}
