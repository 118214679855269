import React from "react"
import { StatusContentKey } from "models/Eligibility"
import devices from "styles/devices"

import styled from "styled-components"

const List = styled.ul`
  list-style: none;
`

const Completed = styled.span`
  &:after {
    border-left: ${(props) => props.theme.shade[100]} 2px solid;
    border-bottom: ${(props) => props.theme.shade[100]} 2px solid;
    left: -30px;
    top: 6px;
    width: 12px;
    height: 6px;
    margin: auto;
    position: absolute;
    content: "";
    transform: rotate(-45deg);
  }

  &:before {
    background: ${(props) => props.theme.primary};
    left: -36px;
    top: -2px;
    margin: auto;
    position: absolute;
    content: "";
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }
`

const NotCompleted = styled.div`
  border-radius: 50%;
  height: 24px;
  width: 24px;
  border: 8px solid #c1c5d1;
  position: absolute;
  left: -36px;
`
const ListItem = styled.li<{ color: string }>`
  position: relative;
  text-align: left;
  margin-bottom: 0;

  &:before {
    display: block;
    content: "";
    width: 4px;
    height: 3em;
    background: ${({ color }) => color};
    position: absolute;
    left: -28px;
    top: 30px;
    border-radius: 20px;
  }

  &:last-child:before {
    display: none;
  }
  @media screen and ${devices.bp_lg} {
    &:before {
      height: 4em;
    }
  }
`

const Text = styled.p`
  color: ${(props) => props.theme.shade50};
  font-size: 12px;
  line-height: 18px;
  margin: 0.5em 0 1.5em;
  min-height: 3em;
`

const Title = styled.h2`
  font-size: 16px;
  font-weight: 550;
  line-height: 24px;
`

const checklistData = [
  {
    id: [StatusContentKey.advise, StatusContentKey.booked],
    title: "Mortgage Advice",
    description: "Speak to an expert about what you want and need from a mortgage.",
  },
  {
    id: [StatusContentKey.recommendation],
    title: "Recommendation",
    description: "Your expert recommended deal from over 10,000 options with over 70 lenders.",
  },
  {
    id: [StatusContentKey.application],
    title: "Application",
    description: "Mojo handle your application with the lender for your preferred deal.",
  },
  {
    id: [StatusContentKey.offer, StatusContentKey.completionSet],
    title: "Offer",
    description: "The mortgage lender is prepared to offer you a mortgage.",
  },
  {
    id: [StatusContentKey.won],
    title: "Completed",
    description: "Your mortgage is in place.",
  },
]

export const Checklist = ({ stage }) => {
  const applicationIndex = checklistData.findIndex((x) => x.id.includes(stage))

  return (
    <List>
      {checklistData.map(({ title, description }, i) => {
        const color = i < applicationIndex ? "#073ED9" : "#C1C5D1"
        const isComplete = i <= applicationIndex
        return (
          <ListItem key={i} color={color}>
            {isComplete ? <Completed /> : <NotCompleted />}
            <div>
              <Title>{title}</Title>
              <Text>{description}</Text>
            </div>
          </ListItem>
        )
      })}
    </List>
  )
}
