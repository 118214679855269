import styled from "styled-components"

export const ContentContainer = styled.div`
  margin-left: 35px;
`

export const ChainedListUl = styled.ul`
  list-style: none;
  padding-left: 0;
  margin: 0;
`

export const ListItem = styled.li`
  position: relative;
  text-align: left;
  margin-bottom: 0;

  &:before {
    display: block;
    content: "";
    width: 2px;
    height: calc(100% + 20px);
    position: absolute;
    left: 8px;
    top: 20px;
    border-radius: 20px;
    background-color: #073ed9;
  }

  &:last-child:before {
    display: none;
  }
`

export const Title = styled.h2`
  font-size: 18px;
  font-weight: 550;
  line-height: 24px;
  margin: 0;
`

export const Text = styled.p`
  font-size: 14px;
  line-height: 18px;
  margin: 0.75rem 0 2rem;
  min-height: 3em;
`

export const Bullet = styled.span`
  position: relative;

  &:before {
    content: "";
    position: absolute;
    left: -4px;
    top: -2px;
    margin: auto;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #073ed9;
  }

  &:after {
    content: attr(data-bullet);
    position: absolute;
    left: -4px;
    top: -2px;
    margin: auto;
    width: 24px;
    height: 24px;
    text-align: center;
    line-height: 27px;
    font-weight: 600;
    color: #fff;
    border-radius: 50%;
  }
`
