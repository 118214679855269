import React from "react"
import styled from "styled-components"
import { Icon, IconType } from "components/atoms/Icon"

interface Props {
  name: string
  onClickDelete?: () => void
}

const Item = styled.div`
  background: ${(props) => props.theme.primaryThree};
  color: white;
  font-size: 12px;
  line-height: 18px;
  border-radius: 8px;
  padding: 1.333em;
  display: flex;
  flex: 1;
`

const Name = styled.div`
  width: 15em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: flex-end;
  & > img {
    height: 0.833em;
  }
`

export const UploadedItem = ({ name, onClickDelete }: Props) => {
  return (
    <Item>
      <Name title={name}>{name}</Name>
      <IconContainer>{/* <Icon onClick={onClickDelete} type={IconType.Cross2} /> */}</IconContainer>
    </Item>
  )
}
