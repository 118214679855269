import React, { useEffect, useState } from "react"
import merge from "deepmerge"
import { defaultTheme, ThemeConfig } from "styles/DefaultTheme"
import { GetPartnerFromUrl } from "lib/GetPartnerFromUrl"
import { themesData } from "styles/themes-data"

export const useConfig = () => {
  const [theme, setTheme] = useState(defaultTheme)
  const [isPartner, setIsPartner] = useState<boolean>(false)
  const [isLoaded, setIsLoaded] = useState<boolean>(false)

  useEffect(() => {
    const partnerString: string = GetPartnerFromUrl()
    const theme: ThemeConfig | undefined = themesData[partnerString]
    setIsPartner(!!theme)

    let currentTheme = defaultTheme
    if (theme) {
      currentTheme = merge(defaultTheme, theme)
    }
    setTheme(currentTheme)
    setIsLoaded(true)
  }, [isLoaded])

  return { theme, isPartner, isLoaded }
}
