import * as Sentry from "@sentry/browser"

export function LogDebug(log: string | object) {
  console.debug(log)
  Sentry.captureMessage(ParseLog(log), "debug")
}

export function LogInfo(log) {
  console.info(log)
  Sentry.captureMessage(ParseLog(log), "info")
}

export function LogWarning(log) {
  console.warn(log)
  Sentry.captureMessage(ParseLog(log), "warning")
}

/**
 * Logs an error to Sentry.
 * In certain situations this method has been used to log error strings, but to get as much out of
 * Sentry as possible we need to pass full Error objects to actually see the correct stack trace.
 *
 * So that we don't need to change each place `LogError` is called, we have different outcomes for each
 * type of argument that can be passed.
 * @param log A string or an Error.
 */
export function LogError(log) {
  if (log && log.stack && log.message && typeof log.stack === "string") {
    Sentry.captureException(log)
  } else if (typeof log === "string") {
    Sentry.captureMessage(ParseLog(log), "error")
  } else {
    Sentry.captureMessage(ParseLog(log))
  }
  console.error(log)
}

export function LogCritical(log) {
  console.error(log)
  Sentry.captureException(log)
}

/**
 * Could be circular, so we'll catch it just to be sure.
 */
function ParseLog(log: string | object): string {
  try {
    if (typeof log === "object") {
      return JSON.stringify(log)
    }

    return log
  } catch (err) {
    LogError(err)
  }
}
