import { ResultsAffordability, ResultsEligibility, ResultsQuotes } from "./Eligibility"
import { Flag } from "./FullApplication"

export enum ResultPage {
  AdviceAppt = "ADVICE_APPT",
  AdviceCallback = "ADVICE_CALLBACK",
  DefaultApprovedPostOffer = "DEFAULT_APPROVED_POSTOFFER",
  CSEAppt = "CSE_APPT",
  CSEFutureAppt = "CSE_FUTURE_APPT",
  ERC = "ERC",
  Rates = "RATES",
  Together = "TOGETHER",
  BadCredit = "BAD_CREDIT",
  Coach = "COACH",
  Unaffordable = "UNAFFORDABLE",
  Nurture = "NURTURE",
  TableNoClickout = "TABLE_NO_CLICKOUT",
  TableBrokerOnly = "TABLE_BROKER_ONLY",
}

export type PartnerRuleSets = PartnerRuleSet[]

export type PartnerRuleSet = {
  partnerId: string
  ruleSet: RuleSet
}

export type RuleSet = Rule[]

export type Rule = {
  id?: number
  name: string
  color: TLColor
  tier: TLTier
  appointmentUrl: string
  appointmentId: string
  copy: Copy
  team: Team
  flag: Flag
  offerAppointment: BooleanEnum
  filters: {
    status: Status
    oppType: OppType
    intentions: Intentions
    timeframe: Timeframe
    want: Want
    eligibilityTier: EligibilityTier[]
    affordable: Affordable
    maxLTV: number
    maxLTI: number
    minLTV: number
    minLTI: number
    minIncome: number
    maxIncome: number
    minLoanAmount: number
    purchaseFilters?: {
      personaStatus: PersonaStatus[]
      cohort: PurchaseCohort[]
      scheme: PurchaseSchemes[]
      minDeposit: number
    }
    remoFilters?: {
      remoStatus: RemoCohort[]
      ercStatus: BooleanEnum
    }
  }
}

export enum BooleanEnum {
  true = "TRUE",
  false = "FALSE",
}

export enum TLColor {
  green = "GREEN",
  amber = "AMBER",
  red = "RED",
  bad = "BAD",
}

export enum TLTier {
  a = "A",
  b = "B",
  c = "C",
  d = "D",
  e = "E",
  f = "F",
  x = "X",
}

export enum Status {
  active = "Active",
  inactive = "Inactive",
}

export enum OppType {
  purchase = "Purchase",
  remo = "Remortgage",
}

export enum Intentions {
  liveIn = "Live In",
  rentOut = "Rent Out",
}

export enum PersonaStatus {
  justLooking = "JL",
  viewingProperties = "VP",
  madeOffer = "MO",
  offerAccepted = "OA",
}

export enum PurchaseCohort {
  firstTimeBuyer = "First Time Buyer",
  unknown = "Unknown",
  homeMove = "Home Move",
  secondHome = "Second Home",
}

export enum PurchaseSchemes {
  unknown = "Unknown",
  mortgageGuarantee = "Mortgage Guarantee",
  sharedEquity = "Shared Equity",
  sharedOwnership = "Shared Ownership",
  helpToBuy = "Help To Buy",
  rightToBuy = "Right To Buy",
  starterHomes = "Starter Homes",
}

export enum RemoCohort {
  like4Like = "L4L",
  additionalBorrow = "AB",
  releaseMoney = "RM",
  other = "Other",
}

export enum Timeframe {
  unknown = "UNKNOWN",
  asap = "ASAP",
  three = "3",
  six = "6",
  twelve = "12",
  plus = "12+",
}

export enum Want {
  eligibility = "Eligibility",
  compare = "Compare",
  advice = "Advice",
  apply = "Apply",
}

export enum EligibilityTier {
  none = "None",
  zero = 0,
  one = 1,
  two = 2,
  three = 3,
  four = 4,
  five = 5,
}

export enum Affordable {
  affordable = "Affordable",
  notAffordable = "Not Affordable",
}

export enum Team {
  core = "Core",
  hbe = "HBE",
  impact = "Impact",
  coach = "Coach",
}

export type Copy = {
  resultPage: ResultPage
  heading: string
  copy1: string
  copy2: string
  ctaArray: CtaType[]
}

export enum CtaType {
  book = "Book",
  mip = "MIP",
  chat = "Chat",
  callback = "Callback",
  refer = "Refer",
  update = "Update",
}
