import styled from "styled-components"
import devices from "styles/devices"

type Props = {
  verticalAlignment?: "initial" | "center"
  verticalAlignmentMobile?: "initial" | "center"
  maxWidth?: number
}

export const ContentArea = styled.div<Props>`
  max-width: ${(props) => props.maxWidth ?? 1500}px;
  padding: 3.2rem 30px;
  margin: 0 auto;
  margin-bottom: 8rem;
  width: 100%;
  height: fit-content;
  align-self: ${(props) => props.verticalAlignmentMobile ?? "initial"};

  @media ${devices.bp_sm} {
    align-self: ${(props) => props.verticalAlignment ?? "initial"};
  }

  @media ${devices.bp_md} {
    padding: 2.4rem;
  }

  @media ${devices.bp_lg} {
    padding: 5rem 7rem 7rem;
  }
`
