import { useRecoilState } from "recoil"
import { hasApplicationError } from "state"
import { LogError } from "../Logging"
import { ErrorCode } from "../ApplicationError"

export function useHandleError() {
  const [applicationError, setApplicationError] = useRecoilState(hasApplicationError)

  function HandleError(errorCode: ErrorCode, errorData: any = null, showErrorPage = false) {
    LogError(errorData)

    if (showErrorPage) {
      setApplicationError({ data: errorCode, code: errorCode })
    }
  }

  return { HandleError }
}
