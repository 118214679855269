import styled from "styled-components"

import { AccordionPanel } from "pages/Application/Eligibility/Coach/Accordion"
import devices from "styles/devices"

export const Root = styled.div`
  width: 100%;
`

export const Header = styled.h1`
  font-size: 32px;
  font-weight: 550;
  margin: 1.5em 0 0 0.625em;
  @media screen and ${devices.bp_lg} {
    margin-top: 3em;
  }
`

export const SubHeader = styled.p`
  line-height: 1.5em;
  margin: 1.5em 1.25em;
  & button {
    font-weight: 550;
    text-decoration: underline;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
  }
  @media screen and ${devices.bp_lg} {
    width: 34em;
  }
`

export const ReassuranceBanner = styled.div`
  margin: 0 1.25em 6em;
  & > div {
    background: ${(props) => props.theme.secondaryOne};
    padding: 1em;
    display: flex;
    align-items: center;
    border-radius: 8px;
  }
  @media screen and ${devices.bp_lg} {
    width: 34em;
  }
`

export const ShieldContainer = styled.div`
  & svg {
    margin-right: 1em;
    & > path {
      fill: ${(props) => props.theme.primaryOne};
    }
  }
`

export const ReassuranceBannerTextContainer = styled.div`
  & p {
    margin: 0;
  }
  & strong {
    font-weight: 550;
  }
`

export const AccordionWrapper = styled.div`
  background: #fff;
  margin: 0 1em 2em;
  border-radius: 16px;
  padding: 1em;
  & h3 {
    margin: 3rem 0 3rem;
    font-weight: 550;
    font-size: 26px;
    line-height: 34px;
  }
  // override the accordion styles
  & .name {
    font-size: 16px;
  }
  @media screen and ${devices.bp_lg} {
    width: 64em;
    padding: 2.5em;
    margin-bottom: 4.5em;
    & .name {
      font-size: 20px;
    }
  }
`

export const AccordionContent = styled.div`
  margin: 1em 0;
  line-height: 1.5em;
  @media screen and ${devices.bp_lg} {
    display: flex;
  }
`

export const AccordionContentInfo = styled.div`
  & h4 {
    font-weight: 550;
    font-size: 16px;
    margin: 1.5em 0;
  }
  & > ul {
    padding-inline-start: 0;
    margin: 0;
    & li {
      list-style-type: none;
      margin-bottom: 1.25em;
      & strong {
        font-weight: 550;
      }
      & .tick {
        margin-right: 0.875em;
      }
    }
  }
  @media screen and ${devices.bp_lg} {
    flex: 1;
    margin-right: 3.375em;
  }
`

export const UploadBoxContainer = styled.div`
  @media screen and ${devices.bp_lg} {
    flex: 1;
  }
`

export const OtherDocuments = styled.div`
  & p {
    margin: 0 0 1em 0;
  }
  & ul {
    padding-inline-start: 1em;
    & li::marker {
      font-size: 0.75em;
    }
  }
`

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  @media screen and ${devices.bp_lg} {
    width: 34em;
  }
`

export const FaqContainer = styled.div`
  margin: 0 1em 10em;
  h3 {
    font-weight: 700;
    display: inline-block;
    font-size: 22px;
    padding-bottom: 0.2em;
    border-bottom: 4px solid blue;
    margin-bottom: 1.4em;
  }
  ${AccordionPanel} {
    border-bottom: 1px solid ${(props) => props.theme.shade60};
  }
  @media screen and ${devices.bp_lg} {
    width: 64em;
    margin-bottom: 2em;
  }
`

export const HeaderContent = styled.div`
  display: flex;
  @media screen and ${devices.bp_lg} {
    width: 65em;
  }
  @media screen and ${devices.bp_xl} {
    width: unset;
  }
  @media screen and (min-width: 1360px) {
    width: 65em;
  }
`

export const HeaderContentLHS = styled.div`
  flex: 1;
`
export const HeaderContentRHS = styled.div`
  @media screen and ${devices.bp_xl} {
    margin-right: 8em;
  }
  @media screen and (min-width: 1360px) {
    margin-right: 0;
  }
`

export const QRCodeContainer = styled.div`
  display: none;
  padding: 1.5em;
  background: #fff;
  border-radius: 8px;
  margin-top: 5.25em;
  p {
    line-height: 18px;
    font-size: 12px;
    width: 10.7em;
    margin: 1.3em 0 0 0;
  }
  @media screen and ${devices.bp_lg} {
    display: inline-block;
  }
`
