import { space } from "components/_blueprint/Layout"
import Typography, { Paragraph } from "components/_blueprint/Typography"
import * as React from "react"
import styled from "styled-components"
import { AnimatedCheck } from "./AnimatedCheck"
import { AnimatedProgressBar } from "./ProgressBar"

const rows = [
  "Calculating equity",
  "Checking your credit score",
  "Running affordability checks",
  "Evaluating options",
]

export const CrunchingNumbers = () => (
  <WhiteBox>
    <WideStack>
      <Typography.H1>Crunching your numbers</Typography.H1>
      <div />
      {rows.map((row, idx) => (
        <div key={row}>
          <Paragraph margin={0}>{row}</Paragraph>
          <Row>
            <AnimatedProgressBar initialDelay={idx * 2} />
            <AnimatedCheck initialDelay={idx * 2} />
          </Row>
        </div>
      ))}
    </WideStack>
  </WhiteBox>
)

const WhiteBox = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.white,
  borderRadius: theme.radius.default,
  padding: 2 * space,
}))

const WideStack = styled.div({
  display: "flex",
  flexDirection: "column",
  gap: space,
})

const Row = styled.div({
  alignItems: "center",
  display: "flex",
  gap: "1rem",
})
