import React from "react"

import styled from "styled-components"
import devices from "styles/devices"

import { MojoCircle } from "components/atoms"
import { Variant } from "pages/Application/Eligibility/Approved/Approved"
import { GetContent } from "lib/GetContent"

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  padding: 64px 24px 0 24px;
  background-color: white;
  border-radius: 24px;
  margin: 0 20px;
  @media screen and ${devices.bp_lg} {
    margin: 0 auto;
    padding: 64px 64px 32px 64px;
  }
`

const List = styled.ul`
  @media screen and ${devices.bp_lg} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  padding: 0;
`

const ListItem = styled.li`
  display: flex;
  margin-bottom: 26px;

  @media screen and ${devices.bp_lg} {
    margin-bottom: 36px;
  }
`

const ListText = styled.p`
  margin: 0;
  padding: 0 16px;
`

const BoldSpan = styled.span`
  font-weight: 550;
`

const IconWrapper = styled.div`
  width: 22px;
`

const StyledH2 = styled.h2`
  font-size: 23px;
  line-height: 31px;
  font-weight: 550;
  @media screen and ${devices.bp_lg} {
    max-width: 500px;
    font-size: 28px;
    line-height: 33px;
  }
`
const ListContent = [
  <ListText>
    <BoldSpan>Paperwork</BoldSpan>: Pulling all the documents you need together and checking they
    say everything a lender wants to hear
  </ListText>,
  <ListText>
    Update and issue your official <BoldSpan>Mortgage in Principle</BoldSpan>
  </ListText>,
  <ListText>
    Potentially <BoldSpan>saving</BoldSpan> £1000s by optimising your deposit and LTV and learning
    about mortgage fees
  </ListText>,
  <ListText>
    Confirm your <BoldSpan>eligibility</BoldSpan> for a mortgage and double check your details
  </ListText>,
  <ListText>
    Unlocking Help to Buy, Right to Buy and other schemes that could help you get on the ladder
  </ListText>,
  <ListText>
    Make sure there are no skeletons in the credit record closet – see how lenders see your finances
    (and make them look the best they can be)
  </ListText>,
  <ListText>Find out which lenders might be willing to lend you more</ListText>,
]

const ListContentAlt = [
  <ListText>
    <BoldSpan>Paperwork</BoldSpan>: Pulling all the documents you need together and checking they
    say everything a lender wants to hear
  </ListText>,
  <ListText>
    Update and issue your official <BoldSpan>Mortgage in Principle</BoldSpan>
  </ListText>,
  <ListText>
    Potentially <BoldSpan>saving</BoldSpan> £1000s by optimising your deposit and LTV and learning
    about mortgage fees
  </ListText>,
  <ListText>
    Confirm your <BoldSpan>eligibility</BoldSpan> for a mortgage and double check your details
  </ListText>,
  <ListText>
    Explain and help with Help to Buy, Right to Buy and other schemes that could help you get on the
    ladder
  </ListText>,
  <ListText>
    Make sure there are no skeletons in the credit record closet – see how lenders see your finances
    (and make them look the best they can be)
  </ListText>,
  <ListText>Find out which lenders might be willing to lend you more</ListText>,
]

interface Props {
  variant: Variant
}

export function BenefitsBox({ variant }: Props) {
  const variantContent = GetContent({
    default: {
      title: "Someone by your side from the start, to getting your keys",
      listContent: ListContent,
    },
    [Variant.VariantA]: {
      title: "You can have someone by your side from the start to getting your keys",
      listContent: ListContentAlt,
    },
  })(variant)
  return (
    <Container>
      <List>
        <ListItem>
          <StyledH2>{variantContent.title}</StyledH2>
        </ListItem>
        {variantContent.listContent.map((content) => (
          <ListItem>
            <IconWrapper>
              <MojoCircle type="tick" enabled={true} />
            </IconWrapper>
            {content}
          </ListItem>
        ))}
      </List>
    </Container>
  )
}
