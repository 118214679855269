import { createContext, Dispatch, SetStateAction } from "react"

export interface AppointmentBookingContextProps {
  acuityId: string
  setAcuityId: Dispatch<SetStateAction<string>>
}

export const AppointmentBookingContext = createContext<AppointmentBookingContextProps>({
  acuityId: null,
  setAcuityId: () => null,
})
