import React from "react"
import styled from "styled-components"
import devices from "styles/devices"
// @ts-ignore
import ChairImage from "url:assets/images/illustrations/chairs.png"

import { Trustpilot } from "../atoms/Trustpilot"

const Container = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 64px 0 0 0;
  text-align: center;
  width: 100%;

  border-radius: 24px;

  & > img {
    max-width: 300px;
    @media screen and ${devices.bp_lg} {
      max-width: 100%;
    }
  }
`

const Heading = styled.h5`
  font-weight: 600;
  font-size: 22px;

  @media screen and ${devices.bp_lg} {
    font-size: 1.66em;
  }
`

export function TrustSignals(props) {
  return (
    <Container>
      <img src={ChairImage} alt="chairs" style={props.imageStyle} />
      <Heading style={props.headingStyle}>
        “Great service as always. My new purchase was done and dusted in a few days and one call.”
      </Heading>
      <Trustpilot />
    </Container>
  )
}
