import React, { useEffect, useState } from "react"
import { Card } from "components/atoms/Card"
import { useApplicationFriendlyId } from "hooks/useApplication"
import styled from "styled-components"
import {
  GetApplicationTypeString,
  HasApplicationExpired,
  HasCompletedFactFind,
  IsRemo,
  NeedsMip,
} from "lib/Applications"
import { useHistory } from "react-router-dom"
import { AppStageBadge } from "components/molecules/AppStageBadge"
import { Loader, LoaderTheme } from "components/atoms/Loader"
import { Button } from "components/atoms/Button"
import { ToCurrency } from "lib/CurrencyFormatter"
import { LabeledValueView } from "components/molecules/LabeledValueView"
import { FormatDate } from "lib/Date"
import devices from "styles/devices"
import { Flag, PropertyAddress } from "models/FullApplication"
import { LogWarning } from "../../Logging"
import { useVisible } from "react-hooks-visible"
import { LogGaEvent } from "lib/GoogleAnalytics"
import { EventAction, EventCategory } from "models/GoogleAnalytics"

const SummaryCardHeaderLayout = styled.div`
  &.expired {
    opacity: 0.4;
  }
`

const SummaryCardHeaderMainInfo = styled.div`
  display: grid;
  grid-row-gap: 1rem;
  grid-template-areas:
    "apptype apptype"
    "appbadge friendlyid";
  @media ${devices.bp_md} {
    grid-template-areas:
      "apptype appbadge"
      "friendlyid .";
    align-items: center;
  }
`

const AppType = styled.div`
  grid-area: apptype;
  justify-self: left;
  font-weight: bold;
  color: ${({ theme }) => theme.grey_dark};
  font-size: 1.2em;
`

const FriendlyId = styled.div`
  grid-area: friendlyid;
  text-align: right;
  font-weight: bold;
  color: ${({ theme }) => theme.blue};
  font-family: Helvetica, Arial, sans-serif;
  @media ${devices.bp_md} {
    text-align: left;
  }
`

const AppDataContainer = styled.div`
  display: grid;
  grid-template-columns: 150px 1fr;
  grid-column-gap: 1em;
  padding-top: 35px;

  @media ${devices.bp_xl} {
    grid-template-columns: 1fr 1fr 1fr 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const SummarySeparator = styled.hr`
  border-top: 1px solid ${({ theme }) => theme.grey};
  border-bottom: none;
  margin: 30px 0;
`

const SummaryCardFooterLayout = styled.div`
  display: grid;
  grid-column-gap: 20px;
  grid-row-gap: 1.5rem;
  @media ${devices.bp_md} {
    grid-template-columns: 3fr 1fr;
    grid-row-gap: 0;
  }
`

const AppStageBadgeContainer = styled.div`
  grid-area: appbadge;
  display: flex;
  justify-content: flex-start;
  @media ${devices.bp_md} {
    justify-content: flex-end;
  }
`

const NextSteps = styled.div`
  div {
    margin: 0;
    padding: 0;
    font-size: 1.4em;
    font-weight: bold;
    color: ${({ theme }) => theme.grey_dark};
    max-width: 100%;
    display: flex;
    align-items: center;
    height: 100%;

    @media ${devices.bp_md} {
      max-width: 100%;
    }

    @media ${devices.bp_xl} {
      max-width: 90%;
    }

    .expired {
      color: ${({ theme }) => theme.error};
    }
  }
`

const NextActionContainer = styled.div`
  width: 100%;
  align-items: center;
  font-size: 0.9em;
  justify-self: right;
  display: grid;
  grid-template-columns: 1fr 1fr;
  flex-direction: column;
  grid-gap: 1rem;

  .dateCreated {
    margin-top: 0;
  }

  @media ${devices.bp_md} {
    min-height: 90px;
    width: 180px;
    grid-template-columns: 1fr;
    justify-content: center;
    text-align: center;
  }
`

const LabeledValueViewDesktop = styled.div`
  display: none;
  @media ${devices.bp_xl} {
    display: initial;
  }
`

export function ApplicationSummaryCard({ friendlyId }: { friendlyId: string }) {
  const [hasBeenSeen, setHasBeenSeen] = useState(false)
  const [targetRef, visible] = useVisible()

  useEffect(() => {
    if (!hasBeenSeen && visible > 0.5) {
      setHasBeenSeen(true)
    }
  }, [visible])

  if (!hasBeenSeen) {
    return (
      <>
        <div ref={targetRef}>
          <Card content={<></>} />
        </div>
      </>
    )
  }

  return <LoadedApplicationSummaryCard friendlyId={friendlyId} />
}

function LoadedApplicationSummaryCard({ friendlyId }: { friendlyId: string }) {
  const { application, hasLoaded } = useApplicationFriendlyId(friendlyId, false)
  const offeredAppointment = Boolean(application?.tls?.appointmentTypeId)
  const nextUrl: string | null = offeredAppointment ? `/application/${friendlyId}/status` : null

  const hasExpired = HasApplicationExpired(application)
  const history = useHistory()

  const mipUrl: string | null = application?.tls?.mipUrl ?? null
  const nextStepButtonText =
    application.appointments?.length > 0 ? "View Status" : "Speak to an expert"
  const cardAddress: PropertyAddress =
    application?.metadata?.opportunity?.mortgageAddress ??
    application?.completePropertyDetails?.propertyAddress ??
    null

  if (!hasLoaded) {
    return <Loading />
  }
  if (!HasCompletedFactFind(application)) return null

  return (
    <Card
      content={
        <div>
          <SummaryCardHeaderLayout className={hasExpired ? "expired" : ""}>
            <SummaryCardHeaderMainInfo>
              <AppType>{GetApplicationTypeString(application)}</AppType>
              <AppStageBadgeContainer>
                <AppStageBadge friendlyId={friendlyId} />
              </AppStageBadgeContainer>
              <FriendlyId>{friendlyId}</FriendlyId>
            </SummaryCardHeaderMainInfo>
            <AppDataContainer>
              <LabeledValueView
                label="Value"
                value={ToCurrency(application?.basicPropertyDetails?.propertyValue, "TBC")}
              />

              {IsRemo(application) ? (
                <LabeledValueViewDesktop>
                  <LabeledValueView
                    label="Balance"
                    value={ToCurrency(application?.currentMortgage?.mortgageBalance, "TBC")}
                  />
                </LabeledValueViewDesktop>
              ) : (
                <LabeledValueViewDesktop>
                  <LabeledValueView
                    label="Deposit"
                    value={ToCurrency(application?.borrowingDetails?.deposit, "TBC")}
                  />
                </LabeledValueViewDesktop>
              )}
              <LabeledValueViewDesktop>
                <LabeledValueView
                  label="Term"
                  value={application?.term ? `${application.term} years` : "TBC"}
                />
              </LabeledValueViewDesktop>
              <LabeledValueView
                label="Address"
                value={
                  cardAddress?.nameNumber || cardAddress?.street
                    ? `${cardAddress.nameNumber} ${cardAddress.street}`.trim()
                    : "TBC"
                }
              />
            </AppDataContainer>
          </SummaryCardHeaderLayout>
          <SummarySeparator aria-hidden="true" />
          <SummaryCardFooterLayout>
            <NextSteps>
              <div>{NextStepText()}</div>
            </NextSteps>
            <NextActionContainer>
              {nextUrl && (
                <Button
                  disabled={hasExpired}
                  onClick={() => {
                    LogGaEvent({
                      action: EventAction.buttonClicked,
                      event_category: EventCategory.functionalInteraction,
                      event_label: nextStepButtonText,
                    })
                    history.push(nextUrl)
                  }}
                  text={nextStepButtonText}
                />
              )}
              {mipUrl && (
                <Button
                  disabled={hasExpired}
                  onClick={() => window.open(mipUrl, "_blank")}
                  text="Download MIP"
                />
              )}

              <span className="dateCreated">
                {FormatDate(application?.createdOn, "Do MMMM YYYY")}
              </span>
            </NextActionContainer>
          </SummaryCardFooterLayout>
        </div>
      }
    />
  )

  function NextStepText(): string {
    if (HasApplicationExpired(application)) {
      return "Application expired"
    }

    const flags = application?.metadata?.flags ?? []

    if (
      flags.includes(Flag.EligibilityResultNoHelp) ||
      flags.includes(Flag.EligibilityResultCoach) ||
      flags.includes(Flag.EligibilityResultReferred)
    ) {
      return "You’ve got issues with credit so we can’t help with this one."
    }

    if (flags.includes(Flag.EligibilityResultUnaffordable)) {
      return "Consider a lower borrowing amount to continue"
    }

    if (flags.includes(Flag.HasQuote)) {
      return "Your personal mortgage recommendation is ready"
    }

    if (flags.includes(Flag.EligibilityResultApproved)) {
      if (NeedsMip(application)) {
        return "Speak to an expert and grab your MIP"
      }

      return "Speak to an expert and get your mortgage recommendation"
    }

    // Shouldn't reach this, but return something anyway just to be sure
    LogWarning(`Couldn't match 'Next Step' text for summary card.`)
    return ""
  }
}

function Loading() {
  return (
    <Card
      content={
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Loader theme={LoaderTheme.Outline} size={50} />
        </div>
      }
    />
  )
}
