export type Results = {
  affordability?: ResultsAffordability
  decision: ResultsDecision
  eligibility?: ResultsEligibility
  quotes?: ResultsQuotes[]
}

export type ResultsAffordability = {
  loanAmount: number
  affordableLTV: boolean
  affordableIncome: boolean
  affordableMinimumAmount: boolean
  maxBorrowing: number
  maxPropertyValue: number
  isAffordable: boolean
}

export type ResultsEligibility = {
  tier: number
}

export type ResultsDecision = {
  offer: boolean
  mip?: string
  maxLtv?: string
  maxBorrowing?: number
  notOfferedReason?: number
  detail?: number
  acuityAppointmentId?: string
}

export type ResultsQuotes = {
  initialPeriod: string
  mortgageClass: string
  initialMonthlyPayment: number
}

export enum StatusContentKey {
  advise = "advise",
  postRec = "postRec",
  booked = "booked",
  recommendation = "recommendation",
  application = "application",
  dip = "dip",
  successfulApplicaton = "successfulApplication",
  completionSet =  "completionSet",
  offer = "offer",
  wip = "wip",
  won = "won",
  lost = "lost",
}
