import { axiosInstance } from "lib/Api"
import { FeatureFlagsGet } from "models/FeatureFlags"
import useSWR from "swr"
import { AxiosResponse } from "axios"

const fetcher = (url) => axiosInstance.get(url).then((res) => res.data)

/**
 * Get whether the MAP select deal page is active or not
 */
export const useGetMapDealSelection = () => {
  const { data, error, isLoading } = useSWR<FeatureFlagsGet, Error>(
    `${process.env.ADMIN_API}/feature-flags/MAPDealSelection`,
    fetcher,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  )

  return {
    showDeals: data?.active,
    isLoading,
    isError: error,
  }
}

/**
 * Get whether the digital recommendation accept experiment is active or not
 */
export const useGetDigitalRecommendationAccept = () => {
  const { data, error, isLoading } = useSWR<FeatureFlagsGet, Error>(
    `${process.env.ADMIN_API}/feature-flags/acceptRecommendation`,
    fetcher,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  )

  return {
    recommendationAcceptActive: data?.active,
    isLoading,
    isError: error,
  }
}

export async function GetFeatureFlag(flag: string): Promise<FeatureFlagsGet> {
  const response: AxiosResponse<FeatureFlagsGet> = await axiosInstance.get(
    `${process.env.ADMIN_API}/feature-flags/${flag}`
  )
  return response.data
}
