import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from "react"
import styled from "styled-components"
import { Button, ButtonType } from "components/atoms"
import axios from "axios"
import Calendar, { CalendarProps } from "components/organisms/Calendar"
import { InlineCheck, Validation } from "mojo-storybook-v2-library"
import { Input } from "../../atoms/Input"
import { validatePhoneNumber } from "../../molecules/PhoneNumberInput"
import { Trustpilot } from "../../atoms"
import { formatAppointmentDate, formatAppointmentTime } from "lib/FormatDate"
import devices from "styles/devices"
import { GaObserver } from "lib/GaObserver"
import { LogGaEvent } from "lib/GoogleAnalytics"
import { EventAction, EventCategory, EventLabel } from "models/GoogleAnalytics"
import { FullApplication } from "models/FullApplication"
import { LogError, LogInfo } from "logging"
import { AppointmentBookingContext } from "contexts/appointment-booking-context"
import {
  useAvailableTimesExperimentContext,
  TimeslotsErrorBanner,
} from "../../../experiments/FreeAvailableTimeslots/availableTimesExperimentContext"
import dayjs from "dayjs"

import utc from "dayjs/plugin/utc"
import isBetween from "dayjs/plugin/isBetween"

dayjs.extend(utc)
dayjs.extend(isBetween)

const ConfirmContainer = styled.div<{
  isLoading: boolean
  isModal: boolean
}>`
  display: flex;
  opacity: ${(props) => (props.isLoading ? 0.4 : 1)};
  flex-direction: column;

  @media screen and ${devices.bp_lg} {
    flex-direction: row;
    padding: ${(props) => (props.isModal ? "1em" : "1.875em")};
    width: ${(props) => (props.isModal ? "36em" : "unset")};
  }
`

const ConfirmBody = styled.div<{ isModal: boolean }>`
  display: flex;
  flex-direction: column;
  margin-top: 32px;

  @media screen and ${devices.bp_lg} {
    width: 50%;
    padding: 0 ${(props) => (props.isModal ? "0" : "2.5em")};
  }

  div {
    label {
      padding: 0;
    }
  }
`

const ConfirmLabel = styled.label`
  font-weight: 550;
`

const InputWrapper = styled.div`
  padding: 12px 0;
`
const CalendarContentRoot = styled.div``

const CalendarDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  @media screen and ${devices.bp_lg} {
    flex-direction: row;
  }
`

const CalendarDescriptionHeader = styled.h3`
  font-weight: 550;
  margin-bottom: 1em;
`

const HorizontalLine = styled.div<{ isModal: boolean }>`
  border-bottom: 1px solid ${(props) => props.theme.shade[70]};
  margin-bottom: 2.5em;
  @media screen and ${devices.bp_lg} {
    margin-bottom: ${(props) => (props.isModal ? "0.5em" : "2.5em")};
  }
`

export const CalendarWrapper = styled.div<{
  isLoading: boolean
  isModal: boolean
}>`
  display: flex;
  min-width: 0;
  font-size: 16px;
  opacity: ${(props) => (props.isLoading ? 0.4 : 1)};
  flex-direction: column;
  @media screen and ${devices.bp_lg} {
    font-size: ${(props) => (props.isModal ? "16px" : "24px")};
    flex-direction: row;
    height: 22em;
    justify-content: space-between;
  }
`

const CalendarDescriptionWrapper = styled.div`
  display: flex;
  flex: 1;

  @media screen and ${devices.bp_lg} {
    margin: 0 16px;
  }
`

const TimeslotPlaceholder = styled.div<{ isModal: boolean }>`
  border: 1px solid ${(props) => props.theme.shade[70]};
  border-radius: 8px;
  font-size: 16px;
  padding: 1em;
  width: 100%;
  color: ${(props) => props.theme.shade[50]};
  margin: 1.5em auto 0;
  height: 100%;
  @media screen and ${devices.bp_lg} {
    width: ${(props) => (props.isModal ? "14em" : "22em")};
    margin: 0 auto 0 ${(props) => (props.isModal ? "1em" : "auto")};
  }
`

const TimeslotsContainer = styled.div<{ isModal: boolean }>`
  font-size: 16px;
  width: 100%;
  overflow-y: auto;
  margin: 1.5em auto 0;
  max-height: 300px;

  @media screen and ${devices.bp_lg} {
    max-height: 100%;
    width: ${(props) => (props.isModal ? "14em" : "22em")};
    margin: 0 auto 0 ${(props) => (props.isModal ? "1em" : "auto")};
  }
`

const Timeslot = styled.button<{ disableClicks: boolean }>`
  display: block;
  width: 100%;
  text-align: left;
  padding: 1em;
  border: 1px solid ${(props) => props.theme.shade[70]};
  border-radius: 8px;
  margin-bottom: 1em;
  cursor: ${(props) => (props.disableClicks ? "default" : "pointer")};
  background: transparent;

  &:active {
    background: ${(props) => (props.disableClicks ? "transparent" : props.theme.shade[70])};
  }
`

const ConfirmationContainer = styled.div<{ isModal: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 16px;
  border: 1px solid #dadee8;
  box-sizing: border-box;
  border-radius: 8px;
  p {
    font-size: ${(props) => (props.isModal ? "12px" : "16px")};
    line-height: ${(props) => (props.isModal ? "1.5em" : "1.125em")};
  }
  @media screen and ${devices.bp_lg} {
    width: 50%;
    padding: 2em;
    margin-right: 2em;
  }
`

const ConfirmationTitle = styled.h3<{ isModal: boolean }>`
  font-weight: 550;
  font-size: ${(props) => (props.isModal ? "18px" : "24px")};
`

const ChangeButtonContainer = styled.div`
  margin-bottom: 1em;
  @media screen and ${devices.bp_lg} {
    width: 8em;
  }
`

const SemiBoldLabel = styled.label`
  font-weight: 550;
`

const TrustpilotContainer = styled.div`
  height: 4em;
  display: flex;
  justify-content: center;
  @media screen and ${devices.bp_lg} {
    width: 8em;
    justify-content: flex-start;
  }
`

const ConfirmationBox = ({
  isLoading,
  appointmentTime,
  setAppointmentTime,
  booked = false,
  isModal = false,
}) => (
  <ConfirmationContainer isModal={isModal}>
    <ConfirmationTitle isModal={isModal}>
      {booked && <span>Done. </span>}
      <time dateTime={appointmentTime}>
        {formatAppointmentDate(appointmentTime)} at {formatAppointmentTime(appointmentTime)}
      </time>
    </ConfirmationTitle>
    <p>
      Your appointment slot is 1 hour, but we usually cover everything within 30 minutes. That way
      if you need the full hour, you’ll have our undivided attention.
    </p>
    {!booked && (
      <ChangeButtonContainer>
        <Button
          disabled={isLoading}
          onClick={() => {
            setAppointmentTime(null)
          }}
          type={ButtonType.Outline}
          text="Change"
        />
      </ChangeButtonContainer>
    )}

    <TrustpilotContainer>
      <Trustpilot />
    </TrustpilotContainer>
  </ConfirmationContainer>
)
const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 18px;
  @media screen and ${devices.bp_lg} {
    flex-direction: row;
    justify-content: space-between;
  }
`
const BookingConfirmedContainer = styled.div`
  display: flex;

  flex-direction: column;
  align-items: flex-start;
  padding: 32px;

  left: 0;
  top: 0;
  background: #000928;
  border-radius: 24px 24px 24px 0;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: 16px 0;

  p {
    color: white;
    margin: 0 0 0.5em 0;
  }

  @media screen and ${devices.bp_lg} {
    width: 408px;
  }
`

const SecondaryTextLabel = styled.label`
  line-height: 1.5;
`

const ContactSoonerContainer = styled.div<{ isModal: boolean }>`
  display: flex;
  height: 3.4em;
  margin-bottom: 1em;
  margin-top: ${(props) => (props.isModal ? "0" : "3em")};
`

const ContactSoonerTextContainer = styled.div<{ isModal: boolean }>`
  display: flex;
  flex-direction: column;
  label {
    font-size: ${(props) => (props.isModal ? "12px" : "16px")};
  }
  justify-content: ${(props) => (props.isModal ? "center" : "flex-end")};
`

const ContactSoonerInputTextLabels = styled.div<{ isModal: boolean }>`
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
  label:nth-child(2) {
    font-size: ${(props) => (props.isModal ? "12px" : "16px")};
  }
`

const ContactSoonerInputTextContainer = styled.div`
  margin-bottom: 1em;
`

const BookingConfirmed = ({ firstName, phoneNumber }) => {
  return (
    <BookingConfirmedContainer>
      <p>
        Hey {firstName} the Mojo team is looking forward to helping you save money on your mortgage.
      </p>

      <p>We'll call you on {phoneNumber} on your chosen date.</p>
      <p>
        Look out for our email reminder closer to the time, when we'll ask you to confirm you're
        still available for this call.
      </p>
    </BookingConfirmedContainer>
  )
}

export interface BookingCalendarContentProps {
  isLoading: boolean
  setIsLoading: React.Dispatch<boolean>
  /**
   * A full application object
   */
  application: FullApplication
  /**
   * Only dates specified in this array will be made clickable
   */
  dates: string[]
  /**
   * Callback when user selects the next month on the calendar
   */
  onSelectNextMonth: CalendarProps["onSelectNextMonth"]
  /**
   * Callback when user selects the previous month on the calendar
   */
  onSelectPreviousMonth: CalendarProps["onSelectPreviousMonth"]
  /**
   * The month currently selected by the user.
   * UPDATE THIS MANUALLY ON EVERY MONTH CHANGE!
   */
  currentDisplayedMonth: CalendarProps["currentDisplayedMonth"]
  /**
   * Callback when user clicks on a date
   */
  onSelectDate: CalendarProps["onSelectDate"]
  /**
   * The user's currently selected date
   * UPDATE THIS MANUALLY ON EVERY DATE CHANGE!
   */
  selectedDate: CalendarProps["selectedDate"]
  /**
   * A list of timeslots that will be displayed next to the calendar
   */
  times: Date[]
  /**
   * Callback in case there's already an appointment booked
   */
  setAppointmentTime: React.Dispatch<React.SetStateAction<Date>>
  /**
   * The selected appointment time
   */
  appointmentTime: Date
  crm?: boolean
  /**
   * The calendar to send the booked appointments for
   */
  calendarId: number
  /**
   * Callback when booking has succeeded
   */
  bookingComplete?: (value: boolean) => void
  /**
   * Callback when booking has succeeded
   */
  refreshApplication: (applicationToUpdate: FullApplication | null, silent: boolean) => void
  /**
   * Shows calendar in a modal
   * @default false
   */
  isModal?: boolean
  /**
   * Callback when booking has succeeded. Includes information about the appointment.
   */
  setAppointmentData?: Dispatch<
    SetStateAction<{
      appointmentDate: Date
      assignee: string
      calendlyEventName: string
    }>
  >
  appointmentTypeId: number
  /**
   * Text to show above the calendar.
   * @default "Book a call"
   */
  customHeader?: React.ReactNode
  utm?: {
    source?: string
    medium?: string
    campaign?: string
  }
  /**
   * Set the phone number to blank so they have to confirm it.
   */
  clearPhoneNumber?: boolean
}

/**
 * Contains all the logic related to booking a call, then renders the calendar UI.
 */
export const BookingCalendarContent = ({
  setIsLoading,
  isLoading,
  application,
  dates,
  onSelectNextMonth,
  onSelectPreviousMonth,
  currentDisplayedMonth,
  onSelectDate,
  selectedDate,
  times,
  setAppointmentTime,
  appointmentTime,
  crm = false,
  calendarId,
  appointmentTypeId,
  bookingComplete,
  refreshApplication,
  isModal = false,
  setAppointmentData = () => null,
  customHeader,
  utm = null,
  clearPhoneNumber,
}: BookingCalendarContentProps) => {
  const initialPhoneNumber = clearPhoneNumber ? "" : application?.applicants[0].telephone ?? ""
  const [phoneNumber, setPhoneNumber] = useState(initialPhoneNumber)
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(validatePhoneNumber(phoneNumber))
  const [phoneInputTouched, setPhoneInputTouched] = useState(false)
  const [contactSooner, setContactSooner] = useState(false)
  const [contactSoonerTime, setContactSoonerTime] = useState("")
  const [appointmentBooked, setAppointmentBooked] = useState(false)
  const { setAcuityId } = useContext(AppointmentBookingContext)
  const utmCampaign = clearPhoneNumber ? "review-number" : "NewBooking"

  const oldBooking = async (data: string, timeslot: Date) => {
    const url = crm
      ? new URL(
          `${process.env.MY_MOJO_API}/crm/schedule-remo-appointment/${application.friendlyId}`
        )
      : new URL(
          `${process.env.MY_MOJO_API}/appointments/${application.friendlyId}?calendarId=${calendarId}&appointmentTypeId=${appointmentTypeId}`
        )
    try {
      const config = {
        withCredentials: true,
      }
      const response = await axios.post(url.toString(), data, crm ? {} : config)

      if (response.status !== 200) {
        throw new Error("Unable to book appointment.")
      }
      setAcuityId(response?.data?.acuityAppointmentId)
      setAppointmentBooked(true)
      refreshApplication(null, true)
      bookingComplete?.(true)
      setAppointmentData({
        appointmentDate: new Date(timeslot),
        assignee: response.data.calendar,
        calendlyEventName: "Mojo Mortgage Appointment",
      })
      LogGaEvent({
        action: EventAction.appointmentBooked,
        event_category: EventCategory.functionalInteraction,
        event_label: EventLabel.appointmentBooked,
        appointment_type: "Mortgage Advisor - Appointment",
      })
    } catch (error) {
      LogError(error)
    }
  }

  const newBooking = async (data: any, timeslot: Date) => {
    try {
      const res = await axios.post(`${process.env.BOOKING_API_V2_URL}/book`, data)

      if (res.status !== 200) {
        throw new Error("Unable to book appointment.")
      }
      setAcuityId(res?.data?.acuityAppointmentId)
      setAppointmentBooked(true)
      refreshApplication(null, true)
      bookingComplete?.(true)
      setAppointmentData({
        appointmentDate: new Date(timeslot),
        assignee: res.data.calendar,
        calendlyEventName: "Mojo Mortgage Appointment",
      })
      LogGaEvent({
        action: EventAction.appointmentBooked,
        event_category: EventCategory.functionalInteraction,
        event_label: EventLabel.appointmentBooked,
        appointment_type: "Mortgage Advisor - Appointment",
      })
    } catch (error) {
      LogError(error)
    }
  }

  async function doBooking(
    timeslot: Date,
    phoneNumber: string,
    contactSooner: boolean,
    contactSoonerTime: string,
    utmSource: string,
    utmMedium: string,
    utmCampaign: string,
    setAppointmentData: Dispatch<
      SetStateAction<{
        appointmentDate: Date
        assignee: string
        calendlyEventName: string
      }>
    >,
    calendarId?: number,
    appointmentTypeId?: BookingCalendarContentProps["appointmentTypeId"],
    crm?: boolean
  ): Promise<void> {
    LogInfo(`Appointment Type: ${appointmentTypeId}`)

    if (calendarId === 0) {
      LogError(`Calendar attempted to book calendar 0 on page ${window.location.href}`)
    }

    const correctedCalendarId = calendarId === 0 ? null : calendarId

    await newBooking(
      {
        appointmentDatetime: dayjs(timeslot).utc().toISOString(),
        phoneNumber: phoneNumber.replace(/\s/g, ""),
        contactSooner,
        contactSoonerTime,
        utmSource,
        utmMedium,
        utmCampaign,
        calendarId: correctedCalendarId,
        appointmentTypeId,
        applicationId: application.applicationId,
      },
      timeslot
    )
  }

  const firstName = application?.applicants[0].forename

  // Check if application already has an appointment booked and set the value to be displayed in the calendar
  useEffect(() => {
    if (Object.keys(application).length === 0) return
    const bookedAppointment = application.appointments.find(
      ({ calendlyEventName, appointmentDate, isCancelled }) =>
        calendlyEventName === "Mojo Mortgage Appointment" &&
        new Date(appointmentDate) >= new Date() &&
        !isCancelled
    )

    if (bookedAppointment) {
      const apptDate = new Date(bookedAppointment.appointmentDate)
      setAppointmentTime(
        new Date(
          Date.UTC(
            apptDate.getFullYear(),
            apptDate.getMonth(),
            apptDate.getDate(),
            apptDate.getHours(),
            apptDate.getMinutes()
          )
        )
      )
      setAppointmentBooked(true)
    }
  }, [application])

  // Available times experiment
  const { experimentDates, experimentTimes, isARealTimeslotDate, dateError } =
    useAvailableTimesExperimentContext()

  return (
    <CalendarContentRoot>
      {!appointmentBooked && (
        <>
          <CalendarDescriptionContainer>
            <CalendarDescriptionWrapper>
              {customHeader || (
                <CalendarDescriptionHeader>Schedule a call</CalendarDescriptionHeader>
              )}
            </CalendarDescriptionWrapper>
          </CalendarDescriptionContainer>
          <HorizontalLine isModal={isModal} />
        </>
      )}
      {appointmentBooked && (
        <FlexContainer>
          <ConfirmationBox
            isLoading={isLoading}
            appointmentTime={appointmentTime}
            setAppointmentTime={setAppointmentTime}
            booked={true}
            isModal={isModal}
          />
          <div>
            <BookingConfirmed firstName={firstName} phoneNumber={phoneNumber} />
            <p>
              From <strong>Team Mojo</strong>
            </p>
          </div>
        </FlexContainer>
      )}
      {dateError && <TimeslotsErrorBanner />}
      {!appointmentTime && (
        <CalendarWrapper isLoading={isLoading} isModal={isModal}>
          <GaObserver
            category={EventCategory.siteInteraction}
            action={EventAction.componentDisplay}
            label="Calendar Start"
          >
            <Calendar
              errorMessage={""}
              dates={experimentDates}
              onSelectNextMonth={onSelectNextMonth}
              onSelectPreviousMonth={onSelectPreviousMonth}
              currentDisplayedMonth={currentDisplayedMonth}
              onSelectDate={onSelectDate}
              selectedDate={selectedDate}
              disableClicks={isLoading}
              isLoading={isLoading}
            />
          </GaObserver>
          {experimentTimes && experimentTimes.length ? (
            <TimeslotsContainer isModal={isModal}>
              {experimentTimes.map((time: Date) => {
                return (
                  <Timeslot
                    className="timeslot"
                    key={time.getTime()}
                    disableClicks={isLoading}
                    onClick={() => {
                      // Available times experiment
                      if (isARealTimeslotDate(time)) {
                        setAppointmentTime(time)
                      }
                    }}
                  >
                    {time.toLocaleString("default", {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </Timeslot>
                )
              })}
            </TimeslotsContainer>
          ) : (
            <TimeslotPlaceholder isModal={isModal}>
              Pick a date that works for you
            </TimeslotPlaceholder>
          )}
        </CalendarWrapper>
      )}

      {appointmentTime && !appointmentBooked && (
        <ConfirmContainer isLoading={isLoading} isModal={isModal}>
          <ConfirmationBox
            isLoading={isLoading}
            appointmentTime={appointmentTime}
            setAppointmentTime={setAppointmentTime}
            isModal={isModal}
          />
          <ConfirmBody isModal={isModal}>
            <ConfirmLabel>Preferred Contact Number:</ConfirmLabel>
            <InputWrapper>
              <Input
                id={"appointment-phone"}
                value={phoneNumber}
                className={"fs-exclude"}
                data-recording-ignore="mask"
                onBlur={() => setPhoneInputTouched(true)}
                onChange={(e) => {
                  setIsPhoneNumberValid(validatePhoneNumber(e.target.value))
                  setPhoneNumber(e.target.value)
                }}
              />
            </InputWrapper>

            {!isPhoneNumberValid && phoneInputTouched && (
              <Validation status="error">Please enter a valid phone number</Validation>
            )}

            <ContactSoonerContainer isModal={isModal}>
              <InputWrapper>
                <InlineCheck
                  checked={contactSooner}
                  onChange={() => {
                    setContactSooner(!contactSooner)
                    if (!contactSooner) {
                      LogGaEvent({
                        action: EventAction.buttonClicked,
                        event_category: EventCategory.functionalInteraction,
                        event_label: "Contact me sooner if possible",
                      })
                    }
                  }}
                  id={"contact-sooner"}
                />
              </InputWrapper>
              <ContactSoonerTextContainer isModal={isModal}>
                <label>Contact me sooner if possible, if we get a cancellation for example.</label>
              </ContactSoonerTextContainer>
            </ContactSoonerContainer>

            {contactSooner && (
              <ContactSoonerInputTextContainer>
                <ContactSoonerInputTextLabels isModal={isModal}>
                  <SemiBoldLabel>When's best for you?</SemiBoldLabel>
                  <SecondaryTextLabel>e.g. After 5pm any day</SecondaryTextLabel>
                </ContactSoonerInputTextLabels>
                <Input
                  id={"contact-sooner-time"}
                  value={contactSoonerTime}
                  onChange={(e) => setContactSoonerTime(e.target.value)}
                />
              </ContactSoonerInputTextContainer>
            )}

            <Button
              id={"confirm-booking"}
              showLoader={isLoading}
              disabled={isLoading || !isPhoneNumberValid}
              onClick={async () => {
                setIsLoading(true)
                await doBooking(
                  appointmentTime,
                  phoneNumber,
                  contactSooner,
                  contactSoonerTime,
                  utm?.source ?? "Online",
                  utm?.medium ?? "Results Page",
                  utmCampaign,
                  setAppointmentData,
                  calendarId,
                  appointmentTypeId,
                  crm
                )
                setIsLoading(false)
                LogGaEvent({
                  action: EventAction.buttonClicked,
                  event_category: EventCategory.functionalInteraction,
                  event_label: EventLabel.confirm,
                })
              }}
              text={"Confirm"}
            />
          </ConfirmBody>
        </ConfirmContainer>
      )}
    </CalendarContentRoot>
  )
}

function IsSlotFarEnoughInFutureToBeOptimised(timeSlot): boolean {
  const now = dayjs()
    .utc()
    .set("minute", 0)
    .set("second", 0)
    .set("millisecond", 0)
    .subtract(5, "minutes")
  const nextOptimisationSchedule = now.add(65, "minutes")

  const appointmentTime = dayjs(timeSlot).utc()

  LogInfo(`Now: ${now}`)
  LogInfo(`nextOptimisationSchedule: ${nextOptimisationSchedule}`)
  LogInfo(`appointmentTime: ${appointmentTime}`)
  LogInfo(`timeSlot: ${timeSlot}`)

  return !appointmentTime.isBetween(
    now.subtract(1, "minute"),
    nextOptimisationSchedule.add(1, "minute")
  )
}
