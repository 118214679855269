import React from "react"
import { TopNav } from "components/molecules/TopNav"
import { FooterBackground, LayoutGrid } from "components/_blueprint/Layout"
import { ExperienceRes } from "models/ExperienceTLS"
import { AdviceApptLaterCopy } from "./AdviceApptLaterCopy"
import { GreatWave } from "../../shared/WaveBackground"
import { ConfidenceFooter } from "../../shared/ConfidenceFooter"

type Props = {
  trafficLightResult: ExperienceRes
}

export const AdviceApptLater = ({ trafficLightResult }: Props) => {
  const mip = trafficLightResult?.mip

  return (
    <GreatWave>
      <TopNav />
      <LayoutGrid>
        <AdviceApptLaterCopy mip={mip} />
        <FooterBackground />
        <ConfidenceFooter mortgageType="mortgage" />
      </LayoutGrid>
    </GreatWave>
  )
}
