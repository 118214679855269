import useSWR from "swr"
import { getSession, getSessionQR, GetSessionQROptions } from "../client/session"

/**
 * Returns decoded session token
 */
export const useSession = () => {
  return useSWR(
    "session",
    async () => {
      try {
        const session = await getSession()
        return session.data
      } catch (err) {
        return null
      }
    },
    {
      // self explanatory
      shouldRetryOnError: false,
      // revalidates when window or tab is re-focused
      revalidateOnFocus: false,
      // revalidates if hook is re-rendered and the data is stale
      revalidateIfStale: false,
      // revalidate data after reconnecting to the network
      revalidateOnReconnect: false,
    }
  )
}

/**
 * Returns a base64 image of a QR code that, when scanned, shares the user's session to the device that scanned it
 */
export const useSessionQR = (options: GetSessionQROptions = {}) => {
  return useSWR(
    "session-qr",
    async () => {
      try {
        const sessionQr = await getSessionQR(options)
        return sessionQr.data
      } catch (err) {
        return null
      }
    },
    {
      // self explanatory
      shouldRetryOnError: false,
      // revalidates when window or tab is re-focused
      revalidateOnFocus: false,
      // revalidate data after reconnecting to the network
      revalidateOnReconnect: false,
    }
  )
}
