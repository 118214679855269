import { ApplicationSummary } from "./ApplicationSummary"

export type FullApplication = ApplicationSummary & {
  tls?: TLS;
  answeredAppointments?: number
  applicants?: Applicant[]
  appointments?: Appointment[]
  currentPropertyStatus?: CurrentPropertyStatus
  listedPropertyOfferStatus?: ListedPropertyOfferStatus
  applicationInProgress?: ApplicationInProgress
  numberOfOtherProperties?: number
  dependentsNumber?: number
  persona?: Persona
  isDeleted?: boolean
  referrer?: string
  brand?: string
  borrowingDetails?: {
    firstTimeBuyer?: boolean
    deposit?: number
    desiredTimeToProceed?: DesiredTimeToProceed
    additionalBorrowing?: number
    additionalBorrowingReasons?: {
      additionalBorrowingReason: AdditionalBorrowingReason
    }[]
    offeredADealByYourExistingLender?: boolean
    landlordType?: LandlordType
    requiredRepaymentMethod?: RepaymentMethod
    desiredMonthlyRepayment?: number
    offerAccepted?: boolean
  }
  purchaseType?: PurchaseType
  remortgageReason?: RemortgageReason
  purchaseReason?: PurchaseReason
  basicPropertyDetails?: {
    occupancy?: Occupancy
    propertyValue?: number
    location?: Location
  }
  completePropertyDetails?: {
    construction?: ConstructionType
    propertyAddress?: PropertyAddress
    purchaseSchemes?: { purchaseScheme: PurchaseScheme }[]
    buyToLetMultipleOccupancy?: TriState
    monthlyRentAmount?: number
    houseType?: HouseType
    overTenYearsOld?: TriState
    newBuild?: TriState
    flatType?: PropertyFlatType[]
    exLocalAuthorityOrCouncilOwned?: boolean
    propertyTenure?: PropertyTenure
    serviceCharge?: TriState
    monthlyServiceCharge?: number
    numberOfKitchens?: number
    numberOfBedrooms?: number
    numberOfBathrooms?: number
    numberOfReceptionRooms?: number
    equityPercentage?: number
    typeOfProperty: PropertyType
  }
  currentMortgage?: {
    mortgageBalance?: number
    monthlyPayment?: number
    yearsRemaining?: number
    sixMonthsOrMoreRemaining?: TriState
    currentLender?: string
    currentLenderCode?: string
    initialPeriodEndDate?: Date
  }
  remortgageDetails?: {
    initialPeriodEndDate?: Date
    existingLender?: string
    existingLenderCode?: string
    desiredTermChange?: DesiredTermChange
    requiredTerm?: number
    monthlyRentalIncome?: number
    remoResearchResults?: string
    remoPersona?: RemoPersona
  }
  preferences?: {
    initialPeriodPreference?: InitialPeriodPreference
    repaymentMethodPreference?: RepaymentMethodPreference
    mortgageTypePreference?: MortgageTypePreference
    underBudgetAdjustmentPreference?: UnderBudgetPreference
    feePaymentPreference?: FeePaymentPreference
    overpaymentsPreference?: TriState
    portableMortgagePreference?: TriState
    marketingPreferences?: string
  }
  income?: Income[]
  furloughStatus?: FurloughStatus
  householdExpenditure?: HouseholdExpenditure
  sufficientInsurance?: string
  metadata?: ApplicationMetadata
}

export enum ApplicationInProgress {
  No = "No",
  YesBroker = "YesBroker",
  YesLender = "YesLender",
}

export type Appointment = {
  id: number
  appointmentDate: string
  assignee: string
  createdOn: string
  calendlyEventName: string
  calendlyEventDuration: number
  mortageApplicationId: number
  isCancelled?: boolean
}

export type Income = {
  id?: number
  firstApplicant?: boolean
  employmentStatus?: EmploymentStatus
  employment: Employment
  contractor: Contractor
  soleTrader: SoleTrader
  limitedCompanyDirector: LimitedCompanyDirector
  llpPartner: LlpPartner
  agency: Agency
  additionalIncomes: AdditionalIncome[]
  incomeBenefits: IncomeBenefit[]
  salaryDeductions: SalaryDeduction[]
  salarySuppliments: SalarySupplement[]
}

export type SalarySupplement = {
  id?: number
  type: SalarySupplementType
  payFrequency: PayFrequency
  payPerPeriod?: number
  isProven: boolean
}

export type SalaryDeduction = {
  id?: number
  type: SalaryDeductionType
  payFrequency: PayFrequency
  payPerPeriod?: number
}

export enum SalarySupplementType {
  Unknown = "Unknown",
  Bonus = "Bonus",
  CarAllowance = "CarAllowance",
  Commission = "Commission",
  Overtime = "Overtime",
  RegionalAllowance = "RegionalAllowance",
  EmployerHousingAllowance = "EmployerHousingAllowance",
  Other = "Other",
  None = "None",
}

export enum SalaryDeductionType {
  Unknown = "Unknown",
  StudentLoan = "StudentLoan",
  Pension = "Pension",
  SalarySacrifice = "SalarySacrifice",
  GiftAidOrCharity = "GiftAidOrCharity",
  Other = "Other",
  None = "None",
}

export type IncomeBenefit = {
  id?: number
  type: IncomeBenefitType
  payFrequency: PayFrequency
  payPerPeriod?: number
}

export enum IncomeBenefitType {
  Unknown = "Unknown",
  CarersAllowance = "CarersAllowance",
  DisabilityLivingAllowance = "DisabilityLivingAllowance",
  FosterCarerAllowance = "FosterCarerAllowance",
  PersonalIndependencePayment = "PersonalIndependencePayment",
  StatePension = "StatePension",
  WarWidowPension = "WarWidowPension",
  AdoptionAllowance = "AdoptionAllowance",
  ChildBenefit = "ChildBenefit",
  ChildTaxCredit = "ChildTaxCredit",
  ConstantAttendanceAllowance = "ConstantAttendanceAllowance",
  GuardiansAllowance = "GuardiansAllowance",
  IndustrialInjuriesDisablementBenefit = "IndustrialInjuriesDisablementBenefit",
  PensionCredit = "PensionCredit",
  ReducedEarningsAllowance = "ReducedEarningsAllowance",
  WidowedParentAllowance = "WidowedParentAllowance",
  WidowsPension = "WidowsPension",
  WorkingTaxCredit = "WorkingTaxCredit",
  Other = "Other",
  None = "None",
}

export type AdditionalIncome = {
  id?: number
  type: AdditionalIncomeType
  payFrequency: PayFrequency
  payPerPeriod: number
  isProven: boolean
}

export enum AdditionalIncomeType {
  Unknown = "Unknown",
  SecondJob = "SecondJob",
  CsaChildMaintenance = "CsaChildMaintenance",
  RentalIncomeSurplus = "RentalIncomeSurplus",
  Other = "Other",
  None = "None",
}

export type Employment = {
  id?: number
  startDate?: Date
  grossSalary?: number
  onZeroHoursContract?: TriState
}

export type Contractor = {
  id?: number
  startDate?: Date
  payPerPeriod?: number
  payFrequency?: PayFrequency
}

export type Agency = {
  id?: number
  contractStartDate?: Date
  payPerPeriod?: number
  payFrequency?: PayFrequency
}

export type SoleTrader = {
  id?: number
  startDate?: Date
  netProfits: SoleTraderNetProfit[]
}

export type LimitedCompanyDirector = {
  id?: number
  startDate?: Date
  grossSalary?: number
  equityPercentage?: number
  dividends?: LimitedCompanyDirectorDividend[]
}

export type LlpPartner = {
  id?: number
  startDate?: Date
  equityPercentage?: number
  drawings?: LLPPartnerDrawing[]
  netProfits?: LLPPartnerNetProfit[]
}

export type LLPPartnerDrawing = {
  id?: number
  amount: number
}

export type LLPPartnerNetProfit = {
  id?: number
  amount: number
}

export type SoleTraderNetProfit = {
  id?: number
  amount: number
}

export type LimitedCompanyDirectorDividend = {
  id?: number
  amount: number
}

export enum FurloughStatus {
  Unknown = "Unknown",
  No = "No",
  Last18Months = "Last18Months",
}

export enum PayFrequency {
  unknown = "unknown",
  hourly = "hourly",
  daily = "daily",
  weekly = "weekly",
  bi_weekly = "bi_weekly",
  monthly = "monthly",
  quarterly = "quarterly",
  annually = "annually",
}

export enum EmploymentStatus {
  Unknown = "Unknown",
  PermanentEmployment = "PermanentEmployment",
  TemporaryEmployment = "TemporaryEmployment",
  Contractor = "Contractor",
  SoleTrader = "SoleTrader",
  DirectorLimitedCompany = "DirectorLimitedCompany",
  PartnerLlp = "PartnerLlp",
  Retired = "Retired",
  NotWorking = "NotWorking",
  Agency = "Agency",
}

export enum FeePaymentPreference {
  unknown = "unknown",
  pay_up_front = "pay_up_front",
  add_to_loan = "add_to_loan",
  not_sure = "not_sure",
}

export enum UnderBudgetPreference {
  unknown = "unknown",
  ReduceTerm = "ReduceTerm",
  PayLess = "PayLess",
}

export enum MortgageTypePreference {
  unknown = "unknown",
  fixed_rate = "fixed_rate",
  tracker = "tracker",
  no_preference = "no_preference",
}

export enum RepaymentMethod {
  unknown = "unknown",
  repayment = "repayment",
  interest_only = "interest_only",
  repayment_vehicle = "repayment_vehicle",
  part_and_part = "part_and_part",
}

export enum RepaymentMethodPreference {
  unknown = "unknown",
  capital_repayment = "capital_repayment",
  interest_only = "interest_only",
  not_sure = "not_sure",
}

export enum InitialPeriodPreference {
  unknown = "unknown",
  no_preference = "no_preference",
  two_years = "two_years",
  three_years = "three_years",
  five_years = "five_years",
  ten_years = "ten_years",
  over_ten_years = "over_ten_years",
  term = "term",
  tracker = "tracker",
}

export type PropertyFlatType = {
  flatType: FlatType
}

export enum PropertyTenure {
  Unknown = "Unknown",
  Freehold = "Freehold",
  Leasehold = "Leasehold",
}

export enum PropertyType {
  house = "house",
  flat = "flat",
  other = "other",
}

export enum Persona {
  StartedLooking = "StartedLooking",
  ActivelyLooking = "ActivelyLooking",
  FoundProperty = "FoundProperty",
  OfferAccepted = "OfferAccepted",
}

export enum FlatType {
  unknown = "unknown",
  purpose_built = "purpose_built",
  converted = "converted",
  studio = "studio",
  sitting_tenant = "sitting_tenant",
  over_commercial_premises = "over_commercial_premises",
  four_storeys_or_less = "four_storeys_or_less",
  over_four_storeys = "over_four_storeys",
  over_seven_storeys = "over_seven_storeys",
  over_ten_storeys = "over_ten_storeys",
}

export enum ConstructionType {
  unknown = "unknown",
  standard = "standard",
  thatched = "thatched",
  concrete = "concrete",
  timber_framed = "timber_framed",
  brick = "brick",
  pre_fabricated = "pre_fabricated",
  steel_frames = "steel_frames",
  other = "other",
  stone = "stone",
}

export enum HouseType {
  Unknown = "Unknown",
  DetachedHouse = "DetachedHouse",
  SemiDetachedHouse = "SemiDetachedHouse",
  TerracedHouse = "TerracedHouse",
  EndOfTerraceHouse = "EndOfTerraceHouse",
  Cottage = "Cottage",
  Bungalow = "Bungalow",
  Flat = "Flat",
  Other = "Other",
}

export type PropertyAddress = {
  addressId?: string
  nameNumber: string
  street: string
  city: string
  postcode: string
}

export type Applicant = {
  id?: number
  firstApplicant: boolean
  title: string
  forename: string
  surname: string
  dateOfBirth: Date
  email: string
  maritalStatus?: MaritalStatus
  nationality?: string
  applicantAddressHistories?: AddressHistory[]
  retirementAge?: number
  telephone?: string
  resetToken?: string
  insuranceBenefits?: ProtectionTypes[]
  employmentBenefits?: ProtectionTypes[]
}

export type AddressHistory = {
  from?: Date
  to?: Date
  nameNumber: string
  street: string
  city: string
  postcode: string
  currentAddress: boolean
}

export enum MaritalStatus {
  unknown = "unknown",
  single = "single",
  married_civil_partnership = "married_civil_partnership",
  cohabiting = "cohabiting",
  divorced_ex_civil_partnership = "divorced_ex_civil_partnership",
  engaged = "engaged",
  separated = "separated",
  widowed = "widowed",
}

export enum RemoChangePref {
  shorterTerm = "shorterTerm",
  longerTerm = "longerTerm",
  typeChange = "typeChange",
  strategyChange = "strategyChange",
  noPref = "noPref",
}

export enum TriState {
  unknown = "unknown",
  yes = "yes",
  no = "no",
  not_sure = "not_sure",
}

export enum LandlordType {
  Unknown = "Unknown",
  Private = "Private",
  Limited_company = "Limited_company",
}

export enum PurchaseScheme {
  unknown = "unknown",
  mortgage_guarantee = "mortgage_guarantee",
  shared_equity = "shared_equity",
  shared_ownership = "shared_ownership",
  help_to_buy = "help_to_buy",
  right_to_buy = "right_to_buy",
  starter_homes = "starter_homes",
}

export enum DesiredTermChange {
  unknown = "unknown",
  Keep_The_Same = "Keep_The_Same",
  Decrease = "Decrease",
  Increase = "Increase",
}

export enum PurchaseType {
  Unknown = "Unknown",
  FirstTimeBuyer = "FirstTimeBuyer",
  HomeMove = "HomeMove",
  SecondHome = "SecondHome",
}

export enum CurrentPropertyStatus {
  Unknown = "Unknown",
  ListedForSale = "ListedForSale",
  NotListedForSale = "NotListedForSale",
}

export enum ListedPropertyOfferStatus {
  Unknown = "Unknown",
  AcceptedOffer = "AcceptedOffer",
  ReceivedOffer = "ReceivedOffer",
  NoOffer = "NoOffer",
}

export enum DesiredTimeToProceed {
  Unknown = "Unknown",
  AsSoonAsPossible = "AsSoonAsPossible",
  ThreeToSixMonths = "ThreeToSixMonths",
  TwelveToEighteenMonths = "TwelveToEighteenMonths",
  NotSure = "NotSure",
  SixToTwelveMonths = "SixToTwelveMonths",
  NextThreeMonths = "NextThreeMonths",
  NextSixMonths = "NextSixMonths",
  NextTwelveMonths = "NextTwelveMonths",
  MoreThanTwelveMonths = "MoreThanTwelveMonths",
}

export enum PurchaseReason {
  Unknown = "Unknown",
  NeedMoreSpace = "NeedMoreSpace",
  Downsizing = "Downsizing",
  Relocating = "Relocating",
  MovingInWithPartner = "MovingInWithPartner",
  Other = "Other",
}

export enum RemortgageReason {
  Unknown = "Unknown",
  Better_rate = "Better_rate",
  Additional_borrowing = "Additional_borrowing",
  Divorce_or_relationship_split = "Divorce_or_relationship_split",
  Release_money = "Release_money",
  Other = "Other",
  New_fixed_term = "New_fixed_term",
}

export enum AdditionalBorrowingReason {
  Unknown = "Unknown",
  Home_improvements = "Home_improvements",
  Debt_consolidation = "Debt_consolidation",
  School_fees = "School_fees",
  Holiday = "Holiday",
  Car = "Car",
  Buy_another_property = "Buy_another_property",
  Business_purposes = "Business_purposes",
  Divorce_settlement = "Divorce_settlement",
  Wedding = "Wedding",
  Other = "Other",
}

export enum Occupancy {
  Unknown,
  LiveInProperty = "Live_in_property",
  RentItOut = "Rent_it_out",
  AdditionalProperty = "Additional_property",
}

export enum Location {
  unknown,
  England = "England",
  Scotland = "Scotland",
  Wales = "Wales",
  Northern_Ireland = "Northern_Ireland",
}

export type ApplicationMetadata = {
  flags: Flag[]
  documents: Document[]
  opportunity: OpportunityMeta
  edeFlag: Flag
  referredTo: string
}

export type OpportunityMeta = {
  status: OpportunityStage
  applicationManager: Contact
  advisor: Contact
  lender: string
  mortgageAddress: PropertyAddress
  answeredAppointments: number
}

export enum OpportunityStage {
  online = "Online",
  factfind = "Fact Find",
  quoted = "Quoted",
  returned = "Returned to Adviser",
  dip = "DIP",
  awaiting = "Awaiting Handover",
  presub = "Pre Submission",
  submitted = "Submitted",
  packaged = "Fully Packaged",
  offer = "Offer Received",
  exchanged = "Exchanged",
  closedwon = "Closed Won",
  closedlost = "Closed Lost",
  completionSet = "Completion Set",
  ineligable = "Ineligible",
  mortgageready = "Mortgage Ready",
  specialist = "Specialist Help",
  coach = "MortgageCoach",
}

export type Contact = {
  forename: string
  surname: string
  email: string
}

export enum Flag {
  Unknown = "Unknown",
  CompletedDiscovery = "CompletedDiscovery",
  CompletedFactFindAssumptions = "CompletedFactFindAssumptions",
  CompletedFactFindMortgage = "CompletedFactFindMortgage",
  CompletedFactFindProperty = "CompletedFactFindProperty",
  CompletedFactFindPersonal = "CompletedFactFindPersonal",
  CompletedFactFindIncome = "CompletedFactFindIncome",
  CompletedFactFindPreferences = "CompletedFactFindPreferences",

  EligibilityResultApproved = "EligibilityResultApproved",
  EligibilityResultUnaffordable = "EligibilityResultUnaffordable",
  EligibilityResultReferred = "EligibilityResultReferred",
  EligibilityResultCoach = "EligibilityResultCoach",
  EligibilityResultNoHelp = "EligibilityResultNoHelp",

  HasQuote = "HasQuote",
  SubmittedForDip = "SubmittedForDip",
  ClosedWon = "ClosedWon",
  ClosedLost = "ClosedLost",
  RequestedCallback = "RequestedCallback",
  HasSeenComparisonTable = "HasSeenComparisonTable",

  IsInSecondaryRatesExperiment = "IsInSecondaryRatesExperiment",
}

export type DocumentType = "ID" | "Income" | "BankStatements" | "Other"

export type Document = {
  applicantId: number
  currentFilename: string
  originalFilename: string
  docType: DocumentType
}

export type HouseholdExpenditure = {
  clothingFrequency?: ExpenditureFrequency
  clothingAmount?: number
  foodAndDrinkFrequency?: ExpenditureFrequency
  foodAndDrinkAmount?: number
  healthAndBeautyFrequency?: ExpenditureFrequency
  healthAndBeautyAmount?: number
  phoneAndInternetFrequency?: ExpenditureFrequency
  phoneAndInternetAmount?: number
  recreationalFrequency?: ExpenditureFrequency
  recreationalAmount?: number
  mediaFrequency?: ExpenditureFrequency
  mediaAmount?: number
  alcoholTobaccoFrequency?: ExpenditureFrequency
  alcoholTobaccoAmount?: number
  miscFrequency?: ExpenditureFrequency
  miscAmount?: number
  utilitiesFrequency?: ExpenditureFrequency
  utilitiesAmount?: number
  insuranceFrequency?: ExpenditureFrequency
  insuranceAmount?: number
  householdGoodsFrequency?: ExpenditureFrequency
  householdGoodsAmount?: number
  holidayFrequency?: ExpenditureFrequency
  holidayAmount?: number
}

export enum ExpenditureFrequency {
  Unknown = 0,
  Daily = 1,
  Weekly = 2,
  Monthly = 3,
  Yearly = 4,
}

export enum RemoPersona {
  Unknown = "Unknown",
  SeeCurrentInterestRate = "SeeCurrentInterestRate",
  CompareRemortgageDeals = "CompareRemortgageDeals",
  GetRemortgageAdvice = "GetRemortgageAdvice",
  ApplyForRemortgage = "ApplyForRemortgage",
}

export type QuotesFilter = {
  initialTerm?: number
  sortOrder?: number
  mortgageType?: number
  repaymentType?: number
  productTransfer: boolean
  numberOfQuotesPerProduct?: number
}

export enum ProtectionTypes {
  LifeInsurance = "LifeInsurance",
  CriticalIllness = "CriticalIllness",
  IncomeProtection = "IncomeProtection",
  FamilyIncome = "FamilyIncome",
  DeathInService = "DeathInService",
  None = "none",
  Unknown = "unknown",
}

export type TLS = {
  appointmentTypeId?: number;
  mipUrl?: string;
}
