import React, { KeyboardEvent } from "react"
import { Input } from "./Input"
import styled from "styled-components"

const Container = styled.div`
  font-family: Arial, sans-serif;
`

type Props = {
  value?: string
  placeholder?: string
  suffix?: string
  onBlur?: () => void
  onFocus?: () => void
  onChange?: (value: string) => void
  onKeyDown?: (event: KeyboardEvent) => void
  id?: string
}

export function TextInput({
  value,
  placeholder,
  suffix = null,
  onBlur = () => {},
  onFocus = () => {},
  onChange = (value) => {},
  onKeyDown = (key) => {},
  id = "",
}: Props) {
  return (
    <Container>
      <Input
        id={id}
        value={value}
        onBlur={onBlur}
        onFocus={onFocus}
        autoComplete="off"
        placeholder={placeholder}
        suffix={suffix}
        onChange={(e) => onChange(e.target.value)}
        onKeyDown={onKeyDown}
      />
    </Container>
  )
}
