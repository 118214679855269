import React, { useEffect, useState } from "react"
import { Checkbox } from "components/atoms"
import styled from "styled-components"
import { LogGaEvent } from "lib/GoogleAnalytics"
import { DateFlag, DateFormat, DateInput } from "./DateInput"
import { EventAction, EventCategory, EventLabel } from "models/GoogleAnalytics"
import dayjs from "dayjs"

const DateNotKnown = styled.div`
  display: flex;
  align-items: center;
  margin: 12px 0;
  font-size: 1.1em;

  label {
    cursor: pointer;
  }

  input[type="checkbox"] {
    width: 19px;
    height: 19px;
    margin-right: 10px;
    margin-bottom: 3px;
  }
`

type Props = {
  id?: string
  question?: any
  value?: string
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
  onDone?: (newValue?: string | Date, errors?: string[]) => void
}

export default function InitialPeriodEndDate({ id, question, value, onKeyDown, onDone }: Props) {
  const mappedQuestion = question
  const [notKnown, setNotKnown] = useState(false)
  const [dateValue, setDateValue] = useState(value)
  const [errors, setErrors] = useState([])

  useEffect(() => {
    if (notKnown) {
      LogGaEvent({
        action: EventAction.sectionCurrentDealEndDate,
        event_category: EventCategory.siteInteraction,
        event_label: EventLabel.unknownCheckbox,
      })

      onDone("NONE", [])
    } else {
      onDone(dateValue, errors)
    }
  }, [notKnown])

  useEffect(() => {
    onDone(dateValue, errors)
  }, [dateValue, errors])

  function HandleOnDateChange(value, errors) {
    const lastDayOfMonth = dayjs(value).endOf("month").set("hour", 14).toDate().toString()

    setDateValue(lastDayOfMonth)
    setErrors(errors)
  }

  return (
    <>
      <DateInput
        id={id}
        value={dateValue}
        dateFlags={[DateFlag.FutureDate]}
        format={DateFormat.Short}
        onKeyDown={onKeyDown}
        onDone={HandleOnDateChange}
        disabled={notKnown}
      />
      <DateNotKnown>
        <Checkbox
          id={`checkbox-dateUnknown-lookup-${question.id}`}
          checked={notKnown}
          onChange={() => setNotKnown(!notKnown)}
        />
        <label htmlFor={`checkbox-dateUnknown-lookup-${question.id}`}>I'm not sure</label>
      </DateNotKnown>
    </>
  )
}
