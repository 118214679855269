import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { SelectInput } from "components/atoms/SelectInput"
import { TextInput } from "components/atoms/TextInput"
import HandleEnter from "lib/HandleEnter"

const Layout = styled.div`
  display: grid;
  grid-gap: 8px;
`

enum Title {
  Mr = "Mr",
  Mrs = "Mrs",
  Miss = "Miss",
  Ms = "Ms",
  Mx = "Mx",
  Dr = "Dr",
  Professor = "Professor",
  Reverend = "Reverend",
  Sir = "Sir",
  Lord = "Lord",
  Other = "Other",
}

type PersonInfo = {
  title: string
  firstName: string
  lastName: string
}

type Props = {
  value?: PersonInfo
  onDone: (personInfo: PersonInfo) => void
  id?: string
}

const titleOptions: Array<{ text: string; value: Title }> = Object.values(Title).map((value) => ({
  text: value,
  value,
}))

export default function NameInput({ value, onDone, id = "" }: Props) {
  const [firstName, setFirstName] = useState(value?.firstName || "")
  const [lastName, setLastName] = useState(value?.lastName || "")
  const [title, setTitle] = useState(value?.title || "")

  useEffect(() => {
    handleComplete()
  }, [title, firstName, lastName])

  function handleComplete() {
    if (firstName?.trim().length > 0 && lastName?.trim().length > 0 && title?.trim().length > 0) {
      onDone({ firstName, lastName, title })
    } else {
      onDone(null)
    }
  }

  return (
    <Layout>
      <SelectInput<Title>
        defaultOptionText="Select title"
        id={id + "-title-select"}
        options={titleOptions}
        value={title as Title}
        onChange={setTitle}
      />
      <TextInput
        id={"firstName-" + id}
        value={firstName}
        onKeyDown={(e) => HandleEnter(e, handleComplete)}
        onChange={setFirstName}
        suffix="First Name"
        placeholder="First Name"
      />
      <TextInput
        id={"lastName-" + id}
        value={lastName}
        onKeyDown={(e) => HandleEnter(e, handleComplete)}
        onChange={setLastName}
        suffix="Last Name"
        placeholder="Last Name"
      />
    </Layout>
  )
}
