import React from "react"
import styled from "styled-components"
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from "pure-react-carousel"
import "pure-react-carousel/dist/react-carousel.es.css"
// @ts-ignore
import ChevronLeft from "url:assets/images/icons/ChevronLeft.svg"
// @ts-ignore
import ChevronRight from "url:assets/images/icons/ChevronRight.svg"
// @ts-ignore
import Slide01 from "url:assets/images/carousel/slide-01.jpeg"
// @ts-ignore
import Slide02 from "url:assets/images/carousel/slide-02.jpeg"
// @ts-ignore
import Slide03 from "url:assets/images/carousel/slide-03.jpeg"
// @ts-ignore
import Slide04 from "url:assets/images/carousel/slide-04.jpeg"

const Box = styled.section`
  background: ${(props) => props.background};
  background-size: 100%;
  @media (max-width: 800px) {
    padding-top: 64px;
  }
`
const Container = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
`
const CarouselContainer = styled.div`
  overflow: hidden;
  .carousel_provider {
    height: 600px !important;
  }
`
const SlideContainer = styled.div`
  position: relative;
`
const Image = styled.img`
  border-radius: 32px;
  width: 100%;
  height: 600px;
  object-fit: cover;
  @media (max-width: 800px) {
    border-radius: 0;
    width: 100%;
    height: 600px;
  }
`
const Card = styled.div`
  width: 480px;
  padding: 32px;
  border-radius: 24px;
  background: white;
  position: absolute;
  left: 32px;
  top: unset;
  bottom: 32px;
  margin: auto;
  @media (max-width: 800px) {
    width: calc(100% - 32px);
    left: 0;
    right: 0;
    margin: auto 16px;
    bottom: unset;
    bottom: 50px;
  }
`
const Footer = styled.div`
  display: flex;
  justify-content: space-between;
`
const BackButton = styled(ButtonBack)`
  width: 48px;
  height: 48px;
  background-color: #000928;
  border-radius: 100%;
  border: none;
  background-image: url(${ChevronLeft});
  background-size: 12px;
  background-position: 16px;
  background-repeat: no-repeat;
`
const NextButton = styled(ButtonNext)`
  width: 48px;
  height: 48px;
  background-color: #000928;
  border-radius: 100%;
  border: none;
  background-image: url(${ChevronRight});
  background-size: 12px;
  background-repeat: no-repeat;
  background-position: 20px;
`
const Quote = styled.p`
  font-size: 1.4em;
  margin-bottom: 24px;
  @media (max-width: 600px) {
    font-size: 1.1rem;
  }
`
const Reference = styled.p`
  font-weight: 600;
`

const SLIDES = [Slide01, Slide02, Slide03, Slide04]

const DEFAULT_SLIDES = [
  {
    quote: () => (
      <React.Fragment>
        Hi Charlotte,
        <br />
        Thanks so much for getting back to me and great news that everything looks right with the
        numbers!
        <br />
        Perfect timing too as I've booked a viewing at a property on Thursday afternoon so it's
        great to know I can put an offer in if it's the right place for me! It's up for offers over
        £150k so the better end of my budget too.
        <br />
        Thanks again and will let you know how I get on on Thursday.
        <br />
        Have a lovely weekend.
      </React.Fragment>
    ),
    reference: "Sarah",
  },
  {
    quote: () => (
      <React.Fragment>
        Hi Sophie,
        <br />
        Sorry for a delayed reply, it's been chaos at work with staff cover.
        <br />
        That all sounds fantastic. You've been a great help so far, and I'm looking forward to the
        next steps. You've really helped put my mind at ease and I'll be in touch if I've found
        somewhere to call home!
        <br />
        Have a wonderful day.
      </React.Fragment>
    ),
    reference: "Emma",
  },
  {
    quote: () => (
      <React.Fragment>
        Thank you so much Charlotte, I have been in contact with another shared ownership property
        company and they have suggested a house which is being built but I need another MIP to
        complete my application.
        <br />
        Sorry to ask again but I am trying to keep my options open and it seems you have to move
        fast with these shared ownership properties. Kind regards
      </React.Fragment>
    ),
    reference: "Tim",
  },
  {
    quote: () => (
      <React.Fragment>
        Hi Lucy,
        <br />
        Thanks so much for checking in. I was actually going to get in touch this week to clarify
        something!
        <br />
        Can you call?
      </React.Fragment>
    ),
    reference: "Holly",
  },
]

export default function Carousel({
  background = "linear-gradient(#f5f7fb, #f5f7fb 50%, #ffebdc 50%, #ffebdc);",
  slides = DEFAULT_SLIDES,
}) {
  return (
    <Box background={background}>
      <Container>
        <CarouselContainer>
          <CarouselProvider
            className="carousel_provider"
            naturalSlideWidth={1200}
            naturalSlideHeight={3500}
            totalSlides={slides.length}
            infinite
          >
            <Slider>
              {slides.map((slide, index) => (
                <Slide index={index} key={slide.reference + index}>
                  <SlideContainer>
                    <Image
                      src={`${SLIDES[index % slides.length]}`}
                      alt={`${slide.reference} testimonial`}
                    />
                    <Card>
                      <Quote>{slide.quote()}</Quote>
                      <Reference>{slide.reference}</Reference>
                      {slides.length > 1 && (
                        <Footer>
                          <BackButton />
                          <NextButton />
                        </Footer>
                      )}
                    </Card>
                  </SlideContainer>
                </Slide>
              ))}
            </Slider>
          </CarouselProvider>
        </CarouselContainer>
      </Container>
    </Box>
  )
}
