import React, { useEffect } from "react"
import { useEligibility } from "hooks/useEligibility/useEligibility"
import { ConfidenceFooter } from "../shared/ConfidenceFooter"
import { Illustration } from "../shared/Illustration"
import { TopNav } from "components/molecules/TopNav"
import { PhoneCheckCopy } from "./PhoneCheckCopy"
import BassGuy from "../../../../assets/images/illustrations/Guitar.png"
import { GreatWave } from "../shared/WaveBackground"
import { ContentGrid, FooterBackground, LayoutGrid } from "components/_blueprint/Layout"
import { useTrackNavigatedToPage } from "hooks/useTrackPageView"

export const PhoneCheck = () => {
  const {
    logoState: [_, setShowLogo],
  } = useEligibility()

  useEffect(() => {
    setShowLogo(false)
  }, [])

  useTrackNavigatedToPage("/application/eligibility/review-number")

  return (
    <GreatWave>
      <TopNav />
      <LayoutGrid>
        <ContentGrid>
          <PhoneCheckCopy />
          <Illustration alt="" src={BassGuy} leftOffset={-115} />
        </ContentGrid>
        <FooterBackground />
        <ConfidenceFooter mortgageType="mortgage" />
      </LayoutGrid>
    </GreatWave>
  )
}
