import React, { useState } from "react"
import styled from "styled-components"
import { TopNav } from "components/molecules/TopNav"
import { NavBarHeight, NavBarHeightMobile } from "styles/StyleVariabes"
import { BackButton } from "components/molecules/BackButton"
import devices from "styles/devices"
import { defaultTheme } from "styles/DefaultTheme"
import { useConfig } from "hooks/useConfig"

type TopNavProps = {
  miniNav: boolean
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
`

const TopNavContainer = styled.div<TopNavProps>`
  background: ${({ theme }) => theme.navColour};
  height: fit-content;
  position: sticky;
  top: 0;
  z-index: 999;
  border-bottom: 1px solid ${({ theme }) => theme.borderColor};
`

const BackButtonContainer = styled.div`
  width: 120px;
  margin: 20px 0 0 20px;
`

const FooterContainer = styled.div`
  margin-top: auto;
`

export function BaseTemplate({ content, backButtonAction = null, miniNav = false, footer = null }) {
  const { theme } = useConfig()

  return (
    <Root>
      <TopNavContainer miniNav={miniNav}>
        <TopNav showPoweredBy={theme.showPoweredBy} logoVariant={theme.poweredByVariant} />
      </TopNavContainer>
      {backButtonAction && (
        <BackButtonContainer>
          <BackButton id="button-go-back" onClick={backButtonAction} />
        </BackButtonContainer>
      )}
      {content}
      {footer && <FooterContainer>{footer}</FooterContainer>}
    </Root>
  )
}
