import { atom, atomFamily, DefaultValue, selectorFamily } from "recoil"
import { FullApplication } from "models/FullApplication"
import { ApplicationSummary } from "models/ApplicationSummary"
import { TooltipState } from "models/Tooltips"
import { ApplicationError } from "./ApplicationError"

export const hasApplicationError = atom<ApplicationError>({
  key: "hasApplicationError",
  default: null,
})

export const hasFetchedInitialApplications = atom<boolean>({
  key: "hasFetchedInitialApplications",
  default: false,
})

export const applicationSummariesState = atom<ApplicationSummary[]>({
  key: "applicationSummariesState",
  default: [],
})

export const fullApplicationsState = atomFamily({
  key: "fullApplicationsState",
  default: {},
})

export const fullApplications = selectorFamily<FullApplication, string>({
  key: "full-applications-access",
  get:
    (id) =>
    ({ get }) => {
      return get(fullApplicationsState(id)) as FullApplication
    },
  set:
    (id) =>
    ({ set, reset }, application: FullApplication) => {
      if (application instanceof DefaultValue) {
        reset(fullApplicationsState(id))
        return
      }

      set(fullApplicationsState(id), application)
    },
})

export const applicationLoadState = atomFamily({
  key: "application-load-state",
  default: false,
})

export const applicationLoadStateAccess = selectorFamily<boolean, string>({
  key: "application-load-state-access",
  get:
    (id) =>
    ({ get }) => {
      return get(applicationLoadState(id)) as boolean
    },
  set:
    (id) =>
    ({ set, reset }, value: boolean) => {
      set(applicationLoadState(id), value)
    },
})

export const tooltipState = atom<TooltipState[]>({
  key: "tooltipState",
  default: [],
})
