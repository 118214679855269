import * as Sentry from "@sentry/browser"
import { BrowserTracing } from "@sentry/tracing"
import { SENTRY_ENVIRONMENT, SENTRY_DSN, APP_NAME, IS_DEV } from "../Config"
import { version } from "../../package.json"

export default () => {
  if (IS_DEV) {
    console.log("Sentry is disabled because you're in development.")
    return
  }

  Sentry.init({
    dsn: SENTRY_DSN,
    release: `${APP_NAME}@${version}`,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.01,
    debug: IS_DEV,
    environment: SENTRY_ENVIRONMENT,
  })
}
