import React, { ReactElement, useEffect } from "react"
import { useParams } from "react-router-dom"
import { Card } from "components/atoms/Card"
import styled from "styled-components"
import { ContentArea } from "templates/ContentArea"
import { Button } from "components/atoms"
import { TrustSignals } from "components/organisms/TrustSignals"
import devices from "styles/devices"
// @ts-ignore
import MojoLogo from "url:assets/images/mojo-logo.png"
// @ts-ignore
import MojoMiniLogo from "url:assets/images/mojo-mini-logo.svg"
import { LogGaEvent } from "lib/GoogleAnalytics"
import { EventAction, EventCategory, EventLabel } from "models/GoogleAnalytics"
import { useTrackNavigatedToPage } from "hooks/useTrackPageView"
import lenders from "components/lenders"
import { AVATAR_CLOUDFRONT_URL, IS_DEV } from "../../Config"
import { axiosInstance } from "lib/Api"
import { Notifications } from "components/atoms/Notifications"
import toast from "react-hot-toast/headless"
import Typography from "components/_blueprint/Typography"

import listItem from "url:assets/images/icons/ListItem.svg"
import envelope from "url:assets/images/icons/Envelope.svg"

interface Deal {
  id: string
  logo: string
  title: string
  benefits: string[]
  monthlyPayment: number
  initialRate: number
  initialCostsAndFees: number
  standardVariableRate: number
  upFrontFees: number
  lenderUrl: string
  lender: string
  lenderCode: string
  notes?: string
  initialPeriodMonths: number
  mortgageClass: string
  recommended: boolean
  duration: string
  mortgageTerm: string
  loanAmount: number
  productCode?: string
}

interface DealCardProps extends Deal {
  handleDealSelection: (deal: Deal) => void
  isChosen: boolean
  complete: boolean
  loading: boolean
}
interface Base64JSON {
  timestamp: number
  applicantName: string
  propertyValue: number
  loanAmount: number
  mortgageTerm: string
  adviserName: string
  adviserEmail: string
  adviserRole: string
  opportunityId: string
  deals: Deal[]
  selectedDeal: Deal | null
}

interface SFData {
  Opp_Id__c: string
  Viewed_Date_Time__c?: string
  Feedback_Confidence__c?: string
  Feedback_Easy__c?: string
  Feedback_Useful__c?: string
}

const DEFAULT_VALUE = "Unknown"
const CHROME_EXTENSION_ID = "foffhdpjjnmidkaimgganegilhoanmog"

const ORANGE_COLOUR = "#FBBB5B"

const TextBox = styled.div`
  padding: 24px;
  border-radius: 24px 24px 24px 0;
  margin-top: 16px;
  background: ${ORANGE_COLOUR};
  p {
    margin: 0;
  }
`

const ChatText = styled.div`
  p {
    margin: 0;
    &:nth-child(2) {
      font-size: 12px;
    }
  }
`

const CoachWrapper = styled.div`
  display: flex;
  margin-top: 1em;
  margin-bottom: 1em;

  align-items: center;
  img {
    margin-right: 16px;
  }
`

const Logo = styled.img`
  width: 144px;
  margin: 20px;

  @media screen and (min-width: 1700px) {
    position: fixed;
  }
`

const MiniLogo = styled.div`
  width: 50px;
  height: 50px;
  margin-right: 20px;
  background-image: url(${({
    backgroundImage,
  }: {
    backgroundImage: string
    borderRadius: boolean
  }) => backgroundImage});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: ${({ borderRadius }) => (borderRadius ? "50%" : "0%")};
`

const Coach = ({ adviserName, adviserRole = "Mojo Mortgages", adviserEmail }) => {
  const [adviserAvatar, setAdviserAvatar] = React.useState<string>(MojoMiniLogo)
  useEffect(() => {
    const req = `${AVATAR_CLOUDFRONT_URL}/${adviserEmail}.jpg`
    doesAdviserHaveAvatar(req).then((valid) => (valid ? setAdviserAvatar(req) : null))
  }, [setAdviserAvatar, adviserEmail])

  return (
    <CoachWrapper>
      <MiniLogo backgroundImage={adviserAvatar} borderRadius={adviserAvatar !== MojoMiniLogo} />
      <ChatText>
        <Typography.Body>
          <strong>{adviserName || "Your adviser"}</strong>
        </Typography.Body>
        <Typography.Body>{adviserRole} – here to help you get a mortgage </Typography.Body>
      </ChatText>
    </CoachWrapper>
  )
}

const AdviserView = ({
  text,
  adviserName,
  adviserRole,
  adviserEmail,
}: {
  text: ReactElement<any, any>
  adviserName: string
  adviserRole: string
  adviserEmail: string
}) => {
  return (
    <div>
      <TextBox>
        <Typography.Body>{text}</Typography.Body>
      </TextBox>
      <Coach adviserName={adviserName} adviserRole={adviserRole} adviserEmail={adviserEmail} />
    </div>
  )
}

const Main = styled.main`
  position: relative;

  p,
  h1,
  h2,
  h3,
  h4,
  h5 {
    line-height: 180%;
  }
`

const Container = styled.div`
  display: flex;
  justify-items: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const Benefits = styled.ul<{ display: boolean }>`
  padding: 0;
  display: ${({ display }) => (display ? "flex" : "none")};
  list-style: none;
  justify-items: center;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
`

const Benefit = styled.li`
  background: rgba(0, 176, 113, 0.1);
  margin: 0 6px;
  padding: 10px 12px 6px;
  border-radius: 24px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
`

const MonthlyPayment = styled.div`
  background: ${(props) => props.theme.shade[80]};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  min-height: 111px;
  padding-top: 5px;
  margin: 2px 0;
`

const GridInfo = styled.div`
  margin-top: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  font-size: 12px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    border: solid thin ${({ theme }) => theme.shade100};
    top: 0;
    bottom: -1px;
    left: -1px;
    right: 0;
    pointer-events: none;
  }

  > div {
    border: 1px solid ${({ theme }) => theme.shade80};
    margin-left: -1px;
    margin-bottom: -1px;
  }
`

const Cost = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 120%;
  margin: 8px 0;
`

const GridSquareContainer = styled.div`
  padding: 10px 18px;
`

const Value = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
`

const Deals = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 20px;
  align-items: end;
  grid-column-gap: 15px;

  @media screen and ${devices.bp_sm} {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and ${devices.bp_xl} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`

const GridSquare = ({ title, value }: { title: string | ReactElement; value: string }) => {
  return (
    <GridSquareContainer>
      {title}
      <Value>{value}</Value>
    </GridSquareContainer>
  )
}

const DealTitle = styled(Typography.H3)`
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 23px;
`

const DealCardContainer = styled.div`
  margin-bottom: 40px;

  &:last-of-type {
    margin-bottom: 0;
  }

  @media screen and ${devices.bp_sm} {
    margin-bottom: 0;
  }

  @media screen and ${devices.bp_lg} {
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`

const DealLogoArea = styled.div`
  display: flex;
  align-items: center;
  height: 80px;
  margin-bottom: 10px;

  & > img {
    max-height: 64px;
    max-width: 80%;
    margin: auto;
  }
`

const SmallPrint = styled.p`
  font-size: 12px;
  text-align: left;
  width: 100%;
  color: ${({ theme }) => theme.shade40};
  margin-bottom: 0;
`

const Recommended = styled.div`
  border: solid
    ${({ active }: { active: boolean }) => (active ? `3px ${ORANGE_COLOUR}` : "0 transparent")};
  max-width: 350px;
  border-radius: 15px;
  background: ${({ active, theme }) => (active ? theme.shade100 : "transparent")};
  display: flex;
  flex-direction: column;
  align-items: center;
`

const RecommendedTitle = styled(Typography.Text)`
  display: inline-block;
  background: ${ORANGE_COLOUR};
  text-align: center;
  padding: 10px 15px;
  border-radius: 0 0 10px 10px;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 0.15rem;
`

const ToastText = styled.div`
  width: 100%;

  @media screen and ${devices.bp_sm} {
    max-width: 70%;
  }
`

const ToastButton = styled(Button)`
  width: 100%;

  @media screen and ${devices.bp_sm} {
    width: auto;
  }
`

const DealSelectedToast = ({ id, message }: { id?: string; message?: string }) => {
  return (
    <React.Fragment>
      <ToastText>{message}</ToastText>
      <ToastButton
        onClick={() => toast.dismiss(id)}
        width="auto"
        type={2}
        text="close"
        style={{
          background: "#D1DDFC",
          color: "#073ED9",
          alignSelf: "end",
        }}
      />
    </React.Fragment>
  )
}

const SUCCESS_GREEN = "#038352"
const ChooseDealButton = ({ handleDealSelection, isChosen, complete, loading }) => {
  const active = isChosen && !loading
  return (
    <Button
      type={active ? 2 : 0}
      text={active ? "Selected deal" : "I want this deal"}
      style={{
        marginTop: 10,
        width: "100%",
        background: active && "#E3FDF3",
        color: active && SUCCESS_GREEN,
        cursor: active ? "default" : "pointer",
      }}
      onClick={handleDealSelection}
      disabled={complete}
      showLoader={isChosen && loading}
    />
  )
}

const AlignedContent = styled.div`
  text-align: center;
  width: 100%;
`

const BorrowingOverYears = styled(AlignedContent)`
  display: block;
  padding: 15px 0;
  border-radius: 4px 4px 0 0;
  background: ${(props) => props.theme.shade[80]};
  font-size: 12px;
`

const DealTypeLength = styled(AlignedContent)`
  display: flex;
  justify-content: space-between;
  border-radius: 0 0 4px 4px;
  overflow: hidden;
  > div {
    padding: 15px 0;
    width: calc(50% - 1px);
    background: ${(props) => props.theme.shade[80]};
  }
`

const DealCard = ({
  handleDealSelection,
  isChosen,
  complete,
  loading,
  ...dealProps
}: DealCardProps) => {
  return (
    <DealCardContainer>
      <DealTitle>{dealProps.title}</DealTitle>
      <Recommended active={dealProps.recommended}>
        {dealProps.recommended && <RecommendedTitle>Mortgage Expert's Top Pick</RecommendedTitle>}
        <Card
          style={{
            paddingTop: dealProps.recommended && 15,
            maxWidth: 350,
            boxShadow: "0px 6px 14px rgba(0, 0, 0, 0.05)",
          }}
          content={
            <Container style={{ paddingTop: !dealProps.recommended ? "20px" : 0 }}>
              <DealLogoArea>
                {dealProps.lenderCode && lenders[dealProps.lenderCode] ? (
                  <img src={lenders[dealProps.lenderCode]} alt={dealProps.title} />
                ) : dealProps.lender ? (
                  <Typography.H4>{dealProps.lender}</Typography.H4>
                ) : (
                  <Typography.H4>{DEFAULT_VALUE}</Typography.H4>
                )}
              </DealLogoArea>
              <Benefits display={!!dealProps.benefits?.length || false}>
                {dealProps.benefits?.map((benefit: string) => (
                  <Benefit>{benefit}</Benefit>
                ))}
              </Benefits>
              {dealProps?.loanAmount && dealProps?.mortgageTerm && (
                <BorrowingOverYears>
                  <Typography.Text>
                    Borrowing <strong>{currencyFormat(dealProps.loanAmount)}</strong> over{" "}
                    <strong>{dealProps.mortgageTerm}</strong>
                  </Typography.Text>
                </BorrowingOverYears>
              )}
              <MonthlyPayment>
                <Typography.Text>Monthly payment</Typography.Text>
                <Cost>{currencyFormat(dealProps.monthlyPayment)}</Cost>
              </MonthlyPayment>
              <DealTypeLength>
                {dealProps?.mortgageClass && (
                  <div>
                    <Typography.Text>{dealProps.mortgageClass}</Typography.Text>
                  </div>
                )}
                {dealProps?.duration && (
                  <div>
                    <Typography.Text>{dealProps.duration}</Typography.Text>
                  </div>
                )}
              </DealTypeLength>
              <GridInfo>
                <GridSquare
                  title={
                    <Typography.Text>
                      Initial
                      <br /> rate
                    </Typography.Text>
                  }
                  value={String(percentageFormat(dealProps.initialRate))}
                />
                <GridSquare
                  title={<Typography.Text>Overall initial cost (incl fees)*</Typography.Text>}
                  value={String(currencyFormat(dealProps.initialCostsAndFees))}
                />
                <GridSquare
                  title={<Typography.Text>Standard Variable Rate</Typography.Text>}
                  value={String(percentageFormat(dealProps.standardVariableRate))}
                />
                <GridSquare
                  title={<Typography.Text>Arrangement fees</Typography.Text>}
                  value={String(currencyFormat(dealProps.upFrontFees))}
                />
              </GridInfo>
              <ChooseDealButton
                loading={loading}
                complete={complete}
                isChosen={isChosen}
                handleDealSelection={() => handleDealSelection(dealProps)}
              />
              {dealProps.initialPeriodMonths && (
                <SmallPrint style={{ textAlign: "center", marginBottom: -15, marginTop: 15 }}>
                  *over an initial period of {dealProps.initialPeriodMonths} months.
                </SmallPrint>
              )}
              {dealProps.notes && <Typography.Body>{dealProps.notes}</Typography.Body>}
            </Container>
          }
        />
      </Recommended>
    </DealCardContainer>
  )
}

const BigSection = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-shrink: 0;

  p {
    line-height: 180%;
  }

  @media screen and ${devices.bp_md} {
    grid-template-columns: 1fr 1fr;
    grid-gap: 50px;
  }

  @media screen and ${devices.bp_xl} {
    grid-template-columns: 2fr 1fr;
  }
`

const Title = styled(Typography.Text)`
  display: block;
  font-weight: 600;
  margin-bottom: 8px;
`
const Data = styled(Typography.Text)`
  display: block;
`

const DetailsContainer = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  margin-top: 20px;
`

const SmallText = styled(Typography.Body)`
  font-size: 12px;
  text-align: center;
`

const Footer = styled.div`
  text-align: center;
  margin-top: -80px;
  line-height: 180%;
`

const FooterText = styled(Typography.Body)`
  font-weight: 500;
  font-size: 16px;
`

const BackgroundsWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fee2cd;
  z-index: -9999;
`

const Trust = styled.div`
  @media screen and ${devices.bp_md} {
    margin-top: 20px;
  }
`

const RatesContainer = styled.div`
  text-align: center;
  margin: 50px auto;

  @media screen and ${devices.bp_md} {
    padding-right: 80px;
  }
`

const CardContainer = styled.div`
  padding: 15px;

  @media screen and ${devices.bp_md} {
    padding: 0;
  }
`

const PayAttention = styled.div`
  text-align: center;
  text-transform: uppercase;
  margin: 80px 0;
  font-size: 16px;
  line-height: 300%;

  & * > strong {
    font-weight: 600;
  }
`

const Attention = styled.div`
  @media screen and ${devices.bp_md} {
    background: #ffebdc;
    border-radius: 16px;
    padding: 50px;
  }
`

const List = styled.ul`
  line-height: 180%;
  padding-left: 0;

  & > li {
    &::before {
      content: "";
      min-width: 16px;
      min-height: 16px;
      background: url(${listItem});
      background-position: center center;
      background-repeat: no-repeat;
      display: block;
      margin-right: 24px;
    }

    display: flex;
    align-items: center;
    padding-left: 16px;
    margin-bottom: 24px;
  }
`

const QuestionsButton = styled(Button)`
  background: rgba(7, 62, 217, 0.05);
  color: ${({ theme }) => theme.ctaButton};
  display: flex;
  align-items: center;
`

const UserDetail = ({ data, title }) => {
  return (
    <div style={{ marginBottom: 28 }}>
      <Title>{title}</Title>
      <Data>{data}</Data>
    </div>
  )
}

const preparePageData = (base64: string): Base64JSON => {
  try {
    const InitialValues = {
      adviserName: "Your adviser",
      adviserEmail: "hello@mojomortgages.com",
      adviserRole: "Team Mojo",
    }

    return {
      ...InitialValues,
      ...JSON.parse(decodeURIComponent(window.atob(base64))),
    } as Base64JSON
  } catch (e) {
    console.log(e)
    return {} as Base64JSON
  }
}

const checkImageURLisValid = (url: string) => {
  return new Promise<boolean>((resolve) => {
    const image = document.createElement("img")
    image.src = url
    image.onload = () => resolve(true)
    image.onerror = () => resolve(false)
  })
}

const isChromeExtensionInstalled = (id: string) =>
  checkImageURLisValid(`chrome-extension://${id}/icon-128.png`)
const doesAdviserHaveAvatar = (url: string) => checkImageURLisValid(url)

const sendDataToSalesforce = async (sfData: SFData) => {
  const UPDATE_RECORD = "b06ogeu"
  try {
    await axiosInstance.post(
      `${process.env.MY_MOJO_API}/request/zapier-webhook-mop-tracking`,
      {
        data: sfData,
        webhookId: UPDATE_RECORD,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
  } catch (error) {
    console.log("error", error)
  }
}

const sendDataToSalesforceIfCustomer = (salesForceData) => {
  if (IS_DEV) return
  isChromeExtensionInstalled(CHROME_EXTENSION_ID).then(async (isInstalled) => {
    // Can infer that it is not the advisor based on the fact that the extension is not installed.
    // We can assume it is most likely the customer.
    if (!isInstalled) {
      await sendDataToSalesforce(salesForceData)
    }
  })
}

const currencyFormat = (v: number, noCurrency = false, minDigits = 2, maxDigits = 2): string => {
  const formatted = new Intl.NumberFormat("en-GB", {
    minimumFractionDigits: minDigits,
    maximumFractionDigits: maxDigits,
  }).format(v)

  return noCurrency ? formatted : `£${formatted}`
}

const percentageFormat = (v: number): string => `${v}%`

export const CallRecommendations = () => {
  useTrackNavigatedToPage("/recommended")
  const { id } = useParams() as { id: string }
  const queryStrings = new URLSearchParams(window.location.search)

  const pageData = preparePageData(id)
  const [selectedDeal, setSelectedDeal] = React.useState<Deal | undefined>(pageData?.selectedDeal)
  const [loading, setLoading] = React.useState(false)

  useEffect(() => {
    if (selectedDeal && !loading) {
      toast(
        <div>
          Great! Your Mortgage Expert will be in touch, as soon as they can, to discuss your
          selected deal.
        </div>,
        { id: "confirmed" }
      )
    }
  }, [selectedDeal, loading])

  const handleDealSelection = async (deal: Deal) => {
    if (!queryStrings.get("code"))
      return toast(
        <Typography.Body>
          Sorry we could not handle your request at this time.{" "}
          <strong>Try again using the short url.</strong>
        </Typography.Body>
      )

    try {
      setLoading(true)
      const res = await axiosInstance.put(`${process.env.MY_MOJO_API}/short-url/update`, {
        shortCode: queryStrings.get("code"),
        newUrl: `${window.location.origin}/recommended/${btoa(
          encodeURIComponent(
            JSON.stringify({
              ...pageData,
              selectedDeal: deal,
            })
          )
        )}?code=${queryStrings.get("code")}`,
      })

      if (res?.data?.url) {
        const nextURL = res?.data?.url
        const nextTitle = `Deal chosen! ${document.title}`
        const nextState = {}

        await sendDataToSalesforceIfCustomer({
          Opp_Id__c: pageData.opportunityId,
          Lender__c: deal.lender,
          Product_Code__c: deal.id?.split("-")?.[0] || "no-product-code",
          Initial_Monthly_Cost__c: deal.monthlyPayment,
          Total_Initial_Cost__c: deal.initialCostsAndFees,
          Arrangement_Fees__c: deal.upFrontFees,
          Initial_Rate__c: deal.initialRate,
          Deal_Selected_DateTime__c: new Date().toISOString(),
          Loan_Amount__c: deal.loanAmount,
          Overall_Mortgage_Term__c: deal.mortgageTerm,
          Applicant_Names__c: pageData?.applicantName,
        })

        LogGaEvent({
          action: EventAction.buttonClicked,
          event_category: EventCategory.functionalInteraction,
          event_label: EventLabel.selectDeal,
        })

        setLoading(false)
        window.history.replaceState(nextState, nextTitle, nextURL)

        setSelectedDeal(deal)
        return
      }
    } catch (error) {
      setLoading(false)
      setSelectedDeal(null)
      if (!error) return
      toast(
        <Typography.Body>
          Sorry, we could not handle your request at this time. (<strong>{error.message}</strong>)
        </Typography.Body>
      )
    }
  }

  useEffect(() => {
    if (!pageData?.deals?.length) return
    LogGaEvent({
      action: EventAction.numberOfProductsShown,
      event_category: EventCategory.siteInteraction,
      event_label: EventLabel.number,
      value: pageData.deals.length,
    })
  }, [pageData])

  useEffect(() => {
    const scriptTag = document.createElement("script")

    scriptTag.src = "//l.getsitecontrol.com/y79nm31w.js"
    scriptTag.type = "text/javascript"
    scriptTag.async = true

    document.body.appendChild(scriptTag)
    return () => {
      document.body.removeChild(scriptTag)
    }
  }, [])

  useEffect(() => {
    sendDataToSalesforceIfCustomer({
      Opp_Id__c: pageData.opportunityId,
      Viewed_Date_Time__c: new Date().toISOString(),
    })
  }, [])

  const handleClick = () => {
    navigator.clipboard.writeText(pageData?.adviserEmail)
    toast(
      <div>
        Copied "<strong>{pageData?.adviserEmail}</strong>" to your clipboard
      </div>,
      { id: "clipboard" }
    )
  }

  return (
    <Main>
      <Logo src={MojoLogo} alt="Mojo Mortgages" />
      <ContentArea>
        <div>
          <BackgroundsWrapper />
          <div>
            <Typography.H1
              style={{ marginBottom: 20, display: "block", fontWeight: 600, fontSize: 28 }}
            >
              Hey {pageData?.applicantName ? pageData?.applicantName.split(" ")[0] : ""}, here are
              some mortgages
            </Typography.H1>
            <BigSection>
              <AdviserView
                text={
                  <React.Fragment>
                    <Typography.Body>
                      I’ve assessed your circumstances and searched thousands of deals across the
                      market to find the best options for you.{" "}
                    </Typography.Body>
                  </React.Fragment>
                }
                adviserName={pageData?.adviserName || "Your adviser"}
                adviserRole={pageData?.adviserRole}
                adviserEmail={pageData?.adviserEmail}
              />
              <div>
                <DetailsContainer>
                  <UserDetail
                    data={pageData?.applicantName || DEFAULT_VALUE}
                    title="Applicant name"
                  />
                  <UserDetail
                    data={
                      pageData?.propertyValue
                        ? currencyFormat(pageData?.propertyValue)
                        : DEFAULT_VALUE
                    }
                    title="Property value"
                  />
                </DetailsContainer>
              </div>
            </BigSection>
            <Deals>
              {pageData?.deals?.map((deal: Deal) => (
                <DealCard
                  key={deal.monthlyPayment}
                  {...deal}
                  loading={loading}
                  handleDealSelection={handleDealSelection}
                  isChosen={
                    JSON.stringify(deal) === JSON.stringify(pageData.selectedDeal || selectedDeal)
                  }
                  complete={!!pageData.selectedDeal || !!selectedDeal}
                />
              ))}
            </Deals>
            <RatesContainer>
              <SmallText>
                We will need to confirm your full suitability before confirming your personal
                mortgage recommendation.
              </SmallText>
              <SmallText>
                These rates are subject to change. Lenders may withdraw product rates at short
                notice.
              </SmallText>
            </RatesContainer>
            <Attention>
              <BigSection>
                <div>
                  <Typography.H4
                    style={{ fontWeight: 600, fontSize: 26, lineHeight: "135%", marginBottom: 30 }}
                  >
                    What happens once you select a deal?
                  </Typography.H4>
                  <Typography.Body>
                    Your Mortgage Expert will get in touch to confirm your suitability for this deal
                    and make your official personalised mortgage recommendation. Once this is
                    sorted, it’ll go like this:
                  </Typography.Body>
                  <List>
                    <li>
                      <Typography.Text>
                        You get a copy of the recommendation to check through and accept
                      </Typography.Text>
                    </li>
                    <li>
                      <Typography.Text>
                        Accept the recommendation and we’ll get you a Decision in Principle from the
                        mortgage lender to confirm pre-approval for the deal
                      </Typography.Text>
                    </li>
                    <li>
                      <Typography.Text>
                        With a Decision in Principle in place, your Case Manager will package your
                        mortgage application, including any documents
                      </Typography.Text>
                    </li>
                    <li>
                      <Typography.Text>
                        Your application and documents will be submitted to the lender
                      </Typography.Text>
                    </li>
                  </List>
                  <Typography.Body>
                    Please note: We may recommend an alternative deal following your full
                    suitability review. Also, please be aware that selecting a deal does not
                    guarantee you’ll get it, or that the lender will accept your application.
                  </Typography.Body>
                </div>
                <div>
                  <Card
                    style={{ boxShadow: "0px 6px 14px rgba(0, 0, 0, 0.05)" }}
                    content={
                      <CardContainer>
                        <Typography.H4>Questions?</Typography.H4>
                        <Typography.Text>
                          If you have questions or you’re unsure about these deals, please get in
                          touch.
                        </Typography.Text>
                        <Coach
                          adviserName={pageData?.adviserName}
                          adviserRole={pageData?.adviserRole}
                          adviserEmail={pageData?.adviserEmail}
                        />
                        <QuestionsButton
                          type={2}
                          text={
                            <React.Fragment>
                              <img style={{ marginRight: 8 }} src={envelope} alt="Email" />
                              <Typography.Text>Copy email address</Typography.Text>
                            </React.Fragment>
                          }
                          onClick={handleClick}
                        />
                      </CardContainer>
                    }
                  />
                </div>
              </BigSection>
            </Attention>
            <Trust>
              <TrustSignals
                headingStyle={{ fontSize: 20, width: "100%" }}
                imageStyle={{ width: 275 }}
              />
            </Trust>
          </div>
          <Footer>
            <PayAttention>
              <Typography.Text>
                <strong>
                  THINK CAREFULLY BEFORE SECURING OTHER DEBTS AGAINST YOUR HOME/PROPERTY.
                </strong>
                <br />
              </Typography.Text>
              <Typography.Text>
                <strong>
                  YOUR HOME/PROPERTY MAY BE REPOSSESSED IF YOU DO NOT KEEP UP REPAYMENTS ON YOUR
                  MORTGAGE.
                </strong>
              </Typography.Text>
            </PayAttention>
            <FooterText>
              Mojo is a trading style of Life’s Great Limited which is registered in England and
              Wales (06246376).
            </FooterText>
            <FooterText>
              Our registered office is The Cooperage, 5 Copper Row, London SE1 2LH. We
              are authorised and regulated by the Financial Conduct Authority and are on the
              Financial Services Register (478215).
            </FooterText>
          </Footer>
          <Notifications notificationComponent={<DealSelectedToast />} />
        </div>
      </ContentArea>
    </Main>
  )
}
