import React from "react"
import styled from "styled-components"
import { Mirror } from "components/Illustrations"
import { AccordionItem } from "pages/Application/Eligibility/Coach/AccordionItem"
import { MortgageCoachCard } from "./YourCreditHistory"

const MirrorImage = styled.img`
  object-fit: contain;
  width: 200px;
  justify-self: center;
  align-self: center;

  @media (min-width: 1250px) {
    height: 300px;
    width: 300px;
  }
`

export const General = ({ cardTitle, cardText, cardBulletPoints = [] }: MortgageCoachCard) => {
  return (
    <AccordionItem
      cardTitle={cardTitle}
      cardText={cardText}
      cardImage={<MirrorImage src={Mirror} />}
      cardBulletPoints={cardBulletPoints}
    />
  )
}
