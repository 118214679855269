import React from "react"
import { RadioButton } from "mojo-storybook-v2-library"
import styled from "styled-components"
import { Paragraph } from "components/_blueprint/Typography"
import { space } from "components/_blueprint/Layout"

const Row = styled.div({
  display: "flex",
  gap: 8,
  justifyContent: "space-evenly",
})

const Wrapper = styled.div({
  flexGrow: 1,
})

type Props<Option> = {
  id: string
  label?: string
  left: {
    text: string
    value: Option
  }
  right: {
    text: string
    value: Option
  }
  value: Option | null
  parser?: (strValue: string) => Option
  onClick?: (value: Option) => void
}

export const InlineRadios = <Option extends string | boolean>({
  id,
  label,
  left,
  onClick,
  parser,
  right,
  value,
}: Props<Option>) => {
  const onChange = parser ? (e) => onClick(parser(e.target.value)) : (e) => onClick(e.target.value)
  return (
    <>
      {label && <Paragraph margin={`${space}px 0 0 0`}>{label}</Paragraph>}
      <Row>
        <Wrapper>
          <RadioButton
            name={id}
            value={left.value.toString()}
            checked={left.value === value}
            onChange={onChange}
            id={`${id}-left`}
          >
            {left.text}
          </RadioButton>
        </Wrapper>
        <Wrapper>
          <RadioButton
            name={id}
            value={right.value.toString()}
            checked={right.value === value}
            onChange={onChange}
            id={`${id}-right`}
          >
            {right.text}
          </RadioButton>
        </Wrapper>
      </Row>
    </>
  )
}
