import React, { useState, useEffect } from "react"
import { ComboBox } from "components/molecules/ComboBox"
import styled from "styled-components"
import { MY_MOJO_API } from "../../../Config"
import axios from "axios"
import { AddressInput } from "components/molecules/Address/Address"
import { normalisePostcode, validPostcode } from "lib/postcodes"

const DebounceTimeout = 900

const StyledComboBox = styled.div`
  label {
    top: -24px !important;
  }
`

export interface AddressLookupResponse {
  address: string
  id: number
}

interface Props {
  id: string
  /**
   * Runs when a user clicks an item from the address list
   */
  onAddressSelected: (selectedAddress: any) => void
  /**
   * Runs when postcode lookup has completed
   */
  onPostcodeLookup: (addresses: AddressLookupResponse[]) => void
  /**
   * Runs if the endpoint returns an error, indicating there's an issue with the API
   */
  onEndpointError?: () => void
  /**
   * Disables the input that loads the addresses
   */
  disabled?: boolean
}

/**
 * Renders an input which accepts postcodes. When a valid postcode is typed the component hits a postcode lookup endpoint and returns matching addresses which are then rendered in a selectable combo-box.
 */
export default function PostcodeLookup({
  onAddressSelected,
  id,
  onPostcodeLookup,
  disabled = false,
  onEndpointError = () => null,
}: Props) {
  const [postcode, setPostcode] = useState("")
  const [loading, setLoading] = useState(false)
  const [selectableAddresses, setSelectableAddresses] = useState([])

  let debounceTimeout = null

  useEffect(() => {
    const normalised = normalisePostcode(postcode)
    if (!validPostcode.test(normalised)) {
      setSelectableAddresses([])
      return
    }

    debounceTimeout = setTimeout(async () => {
      try {
        const addresses = await fetchAddresses(normalised)
        setSelectableAddresses(addresses)
        onPostcodeLookup(addresses)
      } catch (err) {
        onEndpointError()
      }
    }, DebounceTimeout)

    return () => {
      window.clearTimeout(debounceTimeout)
    }
  }, [postcode])

  return (
    <StyledComboBox>
      <ComboBox
        disabled={disabled}
        value={postcode}
        id={id}
        onInputChanged={(e) => handlePostcodeChanged(e.target.value)}
        label="Add postcode"
        isLoading={loading}
        onSelectionMade={handleAddressSelected}
        comboSelections={selectableAddresses?.map((x) => {
          return { id: x.id, text: x.address }
        })}
      />
    </StyledComboBox>
  )

  async function handleAddressSelected(address) {
    const selectedAddress = await getAddressById(address.id)
    setPostcode("")
    setSelectableAddresses([])
    onAddressSelected(selectedAddress)
  }

  function handlePostcodeChanged(postcode) {
    setPostcode(postcode)
  }

  async function fetchAddresses(postcode): Promise<AddressLookupResponse[]> {
    setLoading(true)

    const result = await axios.get(`${MY_MOJO_API}/address-autocomplete?postcode=${postcode}`, {
      withCredentials: true,
    })

    setLoading(false)

    return result.data
  }

  async function getAddressById(id: string): Promise<AddressInput> {
    const result = await axios.get(`${MY_MOJO_API}/addresses/${id}`, {
      withCredentials: true,
    })

    return result.data
  }
}
