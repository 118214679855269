import React from "react"
import { useApplicationFriendlyId } from "hooks/useApplication"
import { GenerateTableUrl } from "lib/MojoUrlObject"
import { useParams } from "react-router"
import { Loader, LoaderTheme } from "components/atoms/Loader"
import Typography from "components/_blueprint/Typography"
import { PushNewApplicationFlag } from "lib/Applications"
import { Flag } from "models/FullApplication"

/**
 * This page will generate a redirect link to the product table and then navigate to it.
 * It's different from the redirect page under /eligibility because it doesn't check if the
 * user has been shown pages before.
 *
 * This means that the user can use this link as many times as they want to go to their comparison table.
 */
const TableRedirectPage = () => {
  const { friendlyId } = useParams<{ friendlyId: string }>()
  const { application, hasLoaded } = useApplicationFriendlyId(friendlyId)

  // we want to make sure that any query parameters (like UTMs)
  // get sent to the link we're redirecting to as well
  const queryParams = new URLSearchParams(window.location.search).toString()

  React.useEffect(() => {
    const getUrlObject = async () => {
      if (hasLoaded) {
        // this flag is used by the /eligibility route so that the user
        // won't be shown a table again, and can instead book an appointment
        //
        // users will be redirected to their application when clicking on a clickout
        // within the table, so this logic prevents them going in a loop.
        await PushNewApplicationFlag(application, Flag.HasSeenComparisonTable)
        const tableUrl = await GenerateTableUrl(application)

        window.location.assign(`${tableUrl}&${queryParams}`)
      }
    }

    getUrlObject()
  }, [hasLoaded])

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          height: "100vh",
          alignItems: "center",
          gap: "0.5rem",
        }}
      >
        <Loader theme={LoaderTheme.Outline} size={28} />
        <Typography.SmallBody>Generating your products...</Typography.SmallBody>
      </div>
    </>
  )
}

export default TableRedirectPage
