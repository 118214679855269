import React from "react"
import styled from "styled-components"
import { TextInput } from "components/atoms/TextInput"
import { PasswordInput } from "components/atoms/PasswordInput"

const Container = styled.div``

const Label = styled.div`
  margin-bottom: 0.5em;
  font-size: 1.3em;
`

const Error = styled.div`
  padding-top: 15px;
  color: ${({ theme }) => theme.error};
  font-weight: 450;
`

type Props = {
  label?: string
  placeholder?: string
  value: string
  isValid?: boolean
  errorMessage?: string
  onBlur?: () => void
  onEnter?: () => void
  onFocus?: () => void
  onChange: (value) => void
  type: "text" | "password"
  id?: string
}

export function LabeledInput({
  label,
  placeholder,
  value,
  isValid = true,
  errorMessage = "",
  onBlur = () => {},
  onEnter = () => {},
  onFocus = () => {},
  onChange = (value) => {},
  type,
  id = "",
}: Props) {
  const component = componentFactory()

  return (
    <Container>
      {label && <Label>{label}</Label>}
      {component}
      {!isValid && errorMessage && <Error>{errorMessage}</Error>}
    </Container>
  )

  function componentFactory() {
    switch (type) {
      case "text":
        return (
          <TextInput
            id={id}
            placeholder={placeholder}
            onBlur={onBlur}
            value={value}
            onChange={onChange}
            onFocus={onFocus}
            onKeyDown={(e) => {
              if (e.key === "Enter") onEnter()
            }}
          />
        )
      case "password":
        return (
          <PasswordInput
            id={id}
            placeholder={placeholder}
            onBlur={onBlur}
            value={value}
            onChange={onChange}
            onFocus={onFocus}
          />
        )
    }
  }
}
