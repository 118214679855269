import React, { useEffect, useState } from "react"
import { useRequireAuthentication } from "hooks/useRequireAuthentication"
import { Redirect, Route, Switch, useHistory, useParams } from "react-router-dom"
import { Eligibility } from "pages/Application/Eligibility/Eligibility"
import { useApplicationFriendlyId } from "hooks/useApplication"
import { ErrorBoundary } from "react-error-boundary"
import { ErrorDisplay } from "components/molecules/ErrorDisplay"
import { GetApplicationCohorts } from "lib/Applications"
import { SetCookie } from "lib/Cookie"
import { StatusPage, StatusPageNavSelected } from "./Eligibility/Status"
import Smartlook from "lib/Smartlook"
import Conveyancing from "pages/Application/Conveyancing/Conveyancing"
import { AppointmentBookingContext } from "contexts/appointment-booking-context"
import RedirectPage from "./Redirect"

export default function () {
  useRequireAuthentication()
  const { friendlyId } = useParams<{ friendlyId: string }>()
  const [acuityId, setAcuityId] = useState(null)
  const { application, hasLoaded, applicationNotFound } = useApplicationFriendlyId(friendlyId)
  const history = useHistory()

  useEffect(() => {
    SetCookie("CohortsCookie", GetApplicationCohorts(application).join("."))
  }, [hasLoaded])

  useEffect(() => {
    Smartlook((smartLook) => {
      smartLook.track("properties", { friendlyId })
    })
  }, [friendlyId])

  useEffect(() => {
    if (applicationNotFound) {
      history.push("/dashboard")
    }
  }, [applicationNotFound])

  return (
    <>
      <ErrorBoundary
        FallbackComponent={({ error, resetErrorBoundary }) => (
          <ErrorDisplay resetError={resetErrorBoundary}>{error.message}</ErrorDisplay>
        )}
      >
        <Switch>
          <Route exact path="/application/:friendlyId/">
            <Redirect to={`/application/${friendlyId}/eligibility`} />
          </Route>
          <Route path="/application/:friendlyId/eligibility">
            <AppointmentBookingContext.Provider value={{ acuityId, setAcuityId }}>
              <Eligibility />
            </AppointmentBookingContext.Provider>
          </Route>
          <Route exact path="/application/:friendlyId/status">
            <StatusPage
              friendlyId={friendlyId}
              applicationStatus={application?.metadata?.opportunity?.status}
              selected={StatusPageNavSelected.Status}
            />
          </Route>
          <Route exact path="/application/:friendlyId/recommendation">
            <StatusPage
              friendlyId={friendlyId}
              applicationStatus={application?.metadata?.opportunity?.status}
              selected={StatusPageNavSelected.Recommendation}
            />
          </Route>
          <Route exact path="/application/:friendlyId/rate-check-promise">
            <StatusPage
              friendlyId={friendlyId}
              applicationStatus={application?.metadata?.opportunity?.status}
              selected={StatusPageNavSelected.RateChecker}
            />
          </Route>
          <Route exact path="/application/:friendlyId/documents">
            <StatusPage
              friendlyId={friendlyId}
              applicationStatus={application?.metadata?.opportunity?.status}
              selected={StatusPageNavSelected.Documents}
            />
          </Route>
          <Route path={"/application/:friendlyId/conveyancing"}>
            <Conveyancing />
          </Route>
          <Route path={"/application/:friendlyId/redirect"}>
            <RedirectPage />
          </Route>
          <Redirect to={`/application/${friendlyId}`} />
        </Switch>
      </ErrorBoundary>
    </>
  )
}
