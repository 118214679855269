export function ToCurrency(
  value: number,
  defaultValue: any | undefined = undefined,
  decimalPlaces = 0
) {
  if (!value && defaultValue !== undefined) {
    return defaultValue
  }

  return new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: "GBP",
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  }).format(value)
}
