import React from "react"
import styled, { css, DefaultTheme } from "styled-components"
import { ColorTypes, ColorVariantStyles } from "./Headings"

type ParagraphTypes = "large" | "default" | "small" | "caption"

export interface ParagraphProps extends React.HTMLAttributes<HTMLParagraphElement> {
  /**
   * The size we'd like the paragraph to be
   * @default default
   */
  size?: ParagraphTypes
  /**
   * Used to render the paragraph as another element instead of `p`
   */
  as?: "p" | "span" | "strong" | "small"
  /**
   * The font weight
   */
  weight?: 400 | 500 | 600 | 700
  /**
   * Set the text as italic
   */
  italic?: boolean
  /**
   * The color of the font.
   * @default "secondary"
   */
  color?: ColorTypes
  children: React.ReactNode
  margin?: string | number
}

/**
 * Renders a paragraph element. Its weight, italization, color can be customised.
 */
const StyledParagraph = styled.p<{
  $paragraphType: ParagraphTypes
  $weight?: ParagraphProps["weight"]
  $italic?: ParagraphProps["italic"]
  $as: ParagraphProps["as"]
  $color: ParagraphProps["color"]
  $margin: ParagraphProps["margin"]
}>`
  font-weight: ${({ $weight }) => $weight};
  font-style: ${({ $italic }) => ($italic ? "italic" : "normal")};
  margin: ${({ $as, $margin }) => $margin ?? ($as === "p" ? "0px 0px 16px 0px" : 0)};

  ${({ theme, $paragraphType, $weight }) => variantStyles(theme, $paragraphType)}
  ${({ theme, $color }) => ColorVariantStyles(theme, $color)};
`

StyledParagraph.defaultProps = {
  $weight: 500,
}

const variantStyles = (theme: DefaultTheme, variant: ParagraphTypes) =>
  ({
    large: css`
      & {
        font-size: 1.125em;
        line-height: 180%;
      }
    `,
    default: css`
      & {
        font-size: 1em;
        line-height: 180%;
      }
    `,
    small: css`
      & {
        font-size: 0.875em;
        line-height: 180%;
      }
    `,
    caption: css`
      & {
        font-size: 0.75em;
        line-height: 150%;
      }
    `,
  }[variant])

export const Paragraph = ({
  size = "default",
  as = "p",
  italic = false,
  weight,
  color = "secondary",
  children,
  margin = undefined,
  ...props
}: ParagraphProps) => {
  return (
    <StyledParagraph
      $paragraphType={size}
      as={"span"}
      $as={as}
      $italic={italic}
      $color={color}
      $margin={margin}
      $weight={weight}
      {...props}
    >
      {children}
    </StyledParagraph>
  )
}
