import React, { useLayoutEffect } from "react"
import {
  ContentContainer,
  ContentGrid,
  Gap,
  desktop,
  mobile,
  space,
  tablet,
} from "components/_blueprint/Layout"
import { useHistory, useParams } from "react-router-dom"
import { useApplicationFriendlyId } from "hooks/useApplication"
import { GenerateTableUrl } from "lib/MojoUrlObject"
import { LogGaEvent } from "lib/GoogleAnalytics"
import { EventAction, EventCategory, EventLabel } from "models/GoogleAnalytics"
import styled from "styled-components"
import devices from "styles/devices"
import { Heading, Paragraph } from "components/_blueprint/Typography"
import { Button, ButtonType, Icon } from "components/atoms"
import { IconType } from "components/atoms/Icon"
import Workout from "../../../../../assets/images/illustrations/home-workout.png"
import Paperwork from "../../../../../assets/images/illustrations/Paperwork.png"

type Props = {
  mip: string
}

export const AdviceApptLaterCopy = ({ mip }: Props) => {
  const { friendlyId } = useParams<{ friendlyId: string }>()
  const { application } = useApplicationFriendlyId(friendlyId)
  const history = useHistory()

  useLayoutEffect(() => {
    LogGaEvent({
      action: EventAction.componentDisplay,
      event_category: EventCategory.siteInteraction,
      event_label: EventLabel.downloadMip,
    })
    LogGaEvent({
      action: EventAction.componentDisplay,
      event_category: EventCategory.siteInteraction,
      event_label: EventLabel.hadOfferAccepted,
    })
  }, [])

  const handleClick = async () => {
    LogGaEvent({
      action: EventAction.buttonClicked,
      event_category: EventCategory.functionalInteraction,
      event_label: EventLabel.viewDeals,
    })
    const comparisonTableURL = await GenerateTableUrl(application, true)
    window.location.assign(comparisonTableURL)
  }

  return (
    <ContentGrid>
      <ContentContainer>
        <Heading size="h1">{text.headline}</Heading>
        <Heading size="h4" as="h2">
          {text.text1}
        </Heading>
        <Paragraph>{text.text2}</Paragraph>
        <Paragraph>{text.text3}</Paragraph>
        <ButtonContainer>
          <Button
            onClick={() => {
              LogGaEvent({
                action: EventAction.buttonClicked,
                event_category: EventCategory.functionalInteraction,
                event_label: EventLabel.downloadMip,
              })
              window.open(mip, "_blank")
            }}
            text={
              <ButtonTextLayout>
                {"Download my MIP"}
                <Icon type={IconType.ExternalLink} />
              </ButtonTextLayout>
            }
          />
        </ButtonContainer>
        <Gap x={3} />
        <Paragraph size="caption">{text.text4}</Paragraph>
      </ContentContainer>
      <PaperworkIllustration alt="" src={Paperwork} />
      <Gap x={4} />
      <DealsContainer>
        <DealsText>
          <Heading size="h2">{dealsText.headline}</Heading>
          <Paragraph>{dealsText.text1}</Paragraph>
          <ButtonContainer>
            <Button
              type={ButtonType.Outline}
              onClick={handleClick}
              text={"View latest mortgage deals"}
            />
          </ButtonContainer>
        </DealsText>
        <WorkoutIllustration alt="" src={Workout} />
      </DealsContainer>
    </ContentGrid>
  )
}

const text = {
  headline: "Use your Mojo Mortgage in Principle to make an offer and seal the deal!",
  text1: "Your Mojo-endorsed Mortgage in Principle (MIP) is now available",
  text2:
    "It confirms the amount we think you could potentially borrow and shows an estate agent you’re in a good position to buy.",
  text3:
    "Once you’ve found a property and had an offer accepted, tell us all about it and we’ll get you connected to one of our expert mortgage advisers to find you the best possible mortgage deal.",
  text4: "YOUR HOME/PROPERTY MAY BE REPOSSESSED IF YOU DO NOT KEEP UP REPAYMENTS ON YOUR MORTGAGE",
}

const dealsText = {
  headline: "Check out the latest mortgage deals",
  text1:
    "Get a flavour of what you could be working with based on the details you’ve provided. Your mortgage expert will give you a personalised recommendation once you have an offer accepted and everything is ready to go.",
}

const PaperworkIllustration = styled.img({
  pointerEvents: "none",
  width: 500,
  gridColumnStart: 9,
  gridColumnEnd: "span 4",
  [mobile]: {
    display: "none",
  },
  [tablet]: {
    display: "none",
  },
  [desktop]: {
    gridColumnEnd: "span 4",
  },
})

const ButtonTextLayout = styled.div({
  img: {
    display: "inline",
    verticalAlign: "middle",
    paddingLeft: "10px",
  },
})

const ButtonContainer = styled.div({
  width: "100%",
  [mobile]: {
    width: "50%",
  },
})

const DealsContainer = styled.div({
  backgroundColor: "#ffffff80",
  borderRadius: space,
  border: "none",
  gridColumnStart: 1,
  display: "grid",
  [mobile]: {
    gridColumnEnd: "span 3",
    gridTemplateColumns: "repeat(3, 1fr)",
  },
  [tablet]: {
    gridColumnEnd: "span 8",
    gridTemplateColumns: "repeat(8, 1fr)",
  },
  [desktop]: {
    gridColumnEnd: "span 12",
    gridTemplateColumns: "repeat(12, 1fr)",
  },
})

const DealsText = styled.div({
  padding: 32,
  gridColumnStart: 1,
  [mobile]: {
    gridColumnEnd: "span 3",
  },
  [tablet]: {
    gridColumnEnd: "span 8",
  },
  [desktop]: {
    gridColumnEnd: "span 7",
  },
})

const WorkoutIllustration = styled.img({
  width: 400,
  gridColumnStart: 8,
  gridColumnEnd: "span 5",
  [mobile]: {
    display: "none",
  },
  [tablet]: {
    display: "none",
  },
  [desktop]: {
    gridColumnEnd: "span 5",
  },
})
