type SeedData = {
  mortgageType: "purchase" | "remo"
  occupancy: "Rent_it_out" | "Live_in_property"
  purchaseType: "first-time-buyer" | "home-move" | "second-home"
}

export function GetSeedData(): SeedData {
  const seedData = new URLSearchParams(window.location.search)?.get("seedData")
  if (!seedData) return null

  const data = JSON.parse(atob(seedData))

  return {
    mortgageType: GetMortgageType(data.mortgageType),
    purchaseType: data.purchaseType,
    occupancy: data["basicPropertyDetails"]
      ? GetOccupancy(data["basicPropertyDetails.Occupancy"])
      : null,
  }

  function GetOccupancy(occupancyType: string): "Rent_it_out" | "Live_in_property" {
    switch (occupancyType) {
      case "Rent_it_out":
        return "Rent_it_out"
      case "Live_in_property":
        return "Live_in_property"
      default:
        return null
    }
  }

  function GetMortgageType(mortgageType: string): "purchase" | "remo" {
    switch (mortgageType) {
      case "purchase":
        return "purchase"
      case "remo":
      case "remortgage":
        return "remo"
      default:
        return null
    }
  }
}
