import React, { useEffect, useState, createContext, useContext } from "react"
import { CurrentExperiments, UpdateSalesforceExperiment } from "lib/UpdateSalesforceExperiment"
import Typography from "components/_blueprint/Typography"
import styled from "styled-components"
import { GaObserver } from "lib/GaObserver"
import { EventAction, EventCategory } from "models/GoogleAnalytics"
import { LogGaEvent } from "lib/GoogleAnalytics"
import { ZapierWebhook } from "lib/ZapierWebhook"

const TimeSlotErrorContainer = styled.div`
  margin-bottom: 1em;
  margin-left: ${({ withMargin }: { withMargin: boolean }) => (withMargin ? "4em" : "0")};
  margin-right: ${({ withMargin }) => (withMargin ? "4em" : "0")};
`

const TimeslotError = styled.div`
  background: ${({ theme }) => theme.colors.error[50]};
  width: 100%;
  padding: 2em;
  > * {
    color: ${({ theme }) => theme.colors.error[500]};
  }
`

interface ProviderProps {
  children: any
  applicationId: string
  times: Date[]
  dates: Date[]
  appointmentTypeId: string
  setSelectedDate: (date: Date) => void
  selectedDate: Date
}

const INITIAL_STATE = {
  experimentTimes: [],
  experimentDates: [],
  isARealTimeslotDate: (date: Date) => date,
  dateError: false,
}
const ExperimentContext = createContext(INITIAL_STATE)
export const useAvailableTimesExperimentContext = () => useContext(ExperimentContext)

export const TimeslotsErrorBanner = ({ withMargin }: { withMargin: boolean }) => {
  return (
    <GaObserver
      category={EventCategory.siteInteraction}
      action={EventAction.componentDisplay}
      label="Time not available banner"
    >
      <TimeSlotErrorContainer withMargin>
        <TimeslotError>
          <Typography.Text>
            <strong>
              Sorry, it looks like your chosen appointment time is no longer available.
            </strong>
            <br />
            Please select another time.
          </Typography.Text>
        </TimeslotError>
      </TimeSlotErrorContainer>
    </GaObserver>
  )
}

const Provider = ({
  children,
  applicationId,
  times,
  dates,
  appointmentTypeId,
  setSelectedDate,
  selectedDate,
}: ProviderProps) => {
  const formatDate = (date: Date): string => date.toISOString().slice(0, 10)
  //
  enum Bucket {
    control = "control",
    variant = "variant",
  }

  enum elegibleAppointmentTypeIds {
    BTL_Remortgage = "26973370",
    BTL_Purchase = "38652092",
    Purchase = "26973376",
    Remortgage = "26973384",
    Remortgage_Additional_Borrowing = "27529841",
    Mojo_test_default = "9907738",
  }

  const bucket = {
    isLoading: false,
    variant: Bucket.control,
    bucketed: true,
  }

  const active = bucket?.bucketed && bucket?.variant === Bucket.variant

  const [allTimes, setAllTimes] = useState([])
  const [experimentTimes, setExperimentTimes] = useState<string | Date[]>(times)
  const [experimentDates, setExperimentDates] = useState<Date[]>(dates)
  const [dateError, setDateError] = useState<boolean>(false)

  useEffect(() => {
    if (bucket.isLoading || !applicationId) return
    UpdateSalesforceExperiment(applicationId, [
      active
        ? CurrentExperiments.AVAILABLE_TIMES_EXPERIMENT_VARIANT
        : CurrentExperiments.AVAILABLE_TIMES_EXPERIMENT_CONTROL,
    ])
  }, [applicationId, active, bucket.isLoading])

  useEffect(() => {
    if (!active) {
      setExperimentDates([...dates])
      return
    }

    function getNextFourDays() {
      const nextFewDays = []
      const today = new Date()

      // Add today's date
      nextFewDays.push(formatDate(today))

      // Add the next 4 days
      for (let i = 1; i <= 3; i++) {
        const nextDay = new Date()
        nextDay.setDate(today.getDate() + i)
        // if not a Sunday
        if (nextDay.getDay() !== 0) {
          nextFewDays.push(formatDate(nextDay))
        }
      }

      return nextFewDays
    }

    const combinedDates = Array.from(new Set([...getNextFourDays(), ...dates].sort()))

    setExperimentDates(combinedDates)
  }, [setExperimentDates, dates, active])

  useEffect(() => {
    if (!active || !selectedDate) return
    let startHour = 8
    const endHour = 20

    const timesList = []
    if (isToday(selectedDate)) {
      const today = new Date()
      startHour = today.getHours()
    }
    for (let hour = startHour; hour <= endHour; hour++) {
      const dateForTime = new Date(selectedDate)
      dateForTime.setHours(hour)
      timesList.push(dateForTime)
    }
    setAllTimes(timesList)
  }, [setAllTimes, active, selectedDate])

  function isToday(date: Date) {
    const d = new Date(date)
    const today = new Date()
    return (
      d.getDate() === today.getDate() &&
      d.getMonth() === today.getMonth() &&
      d.getFullYear() === today.getFullYear()
    )
  }

  useEffect(() => {
    if (!active || dateError) {
      return setExperimentTimes(times)
    }

    const newTimeSet = [...allTimes]

    for (let i = 0; i < times.length; i++) {
      let found = false
      for (let j = 0; j < allTimes.length; j++) {
        if (times[i].getTime() === allTimes[j].getTime()) {
          allTimes[j] = times[i]
          found = true
          break
        }
      }

      if (!found) {
        newTimeSet.splice(i, 0, times[i])
      }
    }

    setExperimentTimes(newTimeSet)
  }, [times, active, allTimes, dateError])
  //

  const isARealTimeslotDate = (date: Date): boolean => {
    const selectedDate = new Date(date)
    const isSameDate = times.some((t) => t.getTime() === selectedDate.getTime())
    if (isSameDate) {
      LogGaEvent({
        action: EventAction.trueTimeslotSelected,
        event_category: EventCategory.siteInteraction,
        event_label: `True date and time: ${selectedDate}`,
      })
    } else {
      LogGaEvent({
        action: EventAction.choiceTimeslotSeleced,
        event_category: EventCategory.siteInteraction,
        event_label: `Choice date and time: ${selectedDate}`,
      })
      // send to SF here:
      ZapierWebhook(
        {
          applicationId,
          currentDateTime: new Date().toISOString(),
          selectedDateTime: selectedDate.toISOString(),
        },
        "32h24fv"
      )
      // Reset
      setSelectedDate(null)
      setExperimentDates(dates)
    }
    setDateError(!isSameDate)
    return isSameDate
  }

  return (
    <ExperimentContext.Provider
      value={{
        experimentTimes,
        experimentDates,
        isARealTimeslotDate,
        active,
        dateError,
      }}
    >
      {children}
    </ExperimentContext.Provider>
  )
}

export default Provider
