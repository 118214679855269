import { Button } from "components/atoms"
import { BookingCalendar } from "components/organisms/Calendar/BookingCalendar"
import dayjs from "dayjs"
import { useApplicationFriendlyId } from "hooks/useApplication"
import { HasAppointmentBooked } from "lib/HasAppointmentBooked"
import { tint } from "polished"
import React, { useEffect } from "react"
import { useHistory, useParams } from "react-router-dom"
import styled from "styled-components"
import devices from "styles/devices"
import { HeaderStyle, MyMojoTemplate, PageTheme } from "templates/MyMojoTemplate"
import PancakeFlip from "assets/images/illustrations/Pancake flip.png"
import { LogGaEvent } from "lib/GoogleAnalytics"
import { EventAction, EventCategory } from "models/GoogleAnalytics"
import { useTrackNavigatedToPage } from "hooks/useTrackPageView"
import Typography from "components/_blueprint/Typography"
import advisors from "assets/images/advisors2.png"

const ContentArea = styled.div`
  max-width: 1500px;
  padding: 3.2rem 0;
  margin: 0 auto;
  margin-bottom: 8rem;
  width: 100%;
  height: fit-content;

  @media ${devices.bp_md} {
    padding: 2.4rem;
  }

  @media ${devices.bp_lg} {
    padding: 5rem 7rem 7rem;
  }
`

const BookedAppointmentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  z-index: 1000;

  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;

  background-color: ${({ theme }) => theme.shade100};
  padding: 3.5rem;
  border-radius: ${({ theme }) => theme.radius.large};

  @media ${devices.bp_lg} {
    position: relative;
    width: calc(100% - 300px);

    & #buttonContainer {
      display: flex;
    }
  }
`

const AppointmentDateContainer = styled.div`
  background-color: ${(props) => tint(0.95, props.theme.primary)};
  border-radius: ${(props) => props.theme.radius.default};
  padding: 0.75rem 1.5rem;
`

const Image = styled.img`
  display: none;
  width: 630px;
  position: absolute;
  right: 0;
  margin-top: -100px;

  @media ${devices.bp_lg} {
    margin-top: -180px;
    display: block;
    right: 0;
    z-index: 1001;
  }
`

const AdvisorsImage = styled.img`
  display: none;
  align-self: flex-start;

  @media ${devices.bp_lg} {
    display: block;
  }
`

/**
 * Renders a page that displays a thank you if the user has an appointment booked or a calendar if they don't
 */
const Schedule = () => {
  const { friendlyId } = useParams<{ friendlyId: string }>()
  const history = useHistory()
  const { application, refreshApplication, hasLoaded } = useApplicationFriendlyId(friendlyId)
  const hasAppointment = HasAppointmentBooked(application)

  useTrackNavigatedToPage("/application/conveyancing/schedule")

  /**
   * When the user loads the page
   */
  useEffect(() => {
    LogGaEvent({
      action: EventAction.page,
      event_category: EventCategory.siteInteraction,
      event_label: "Conveyancing schedule",
    })
  }, [])

  /**
   * Sending events depending on whether the user has an appointment booked or not
   */
  useEffect(() => {
    if (hasLoaded) {
      if (hasAppointment) {
        LogGaEvent({
          action: EventAction.opportunityStage,
          event_category: EventCategory.siteInteraction,
          event_label: "Has booked appointment",
        })
      } else {
        LogGaEvent({
          action: EventAction.opportunityStage,
          event_category: EventCategory.siteInteraction,
          event_label: "No booked appointment",
        })
      }
    }
  }, [hasAppointment, hasLoaded])

  /**
   * Sending event to specify which page the user has seen:
   * - The calendar
   * - The thank you page
   */
  useEffect(() => {
    if (hasLoaded) {
      LogGaEvent({
        action: EventAction.page,
        event_category: EventCategory.siteInteraction,
        event_label: hasAppointment ? "Conveyancing thank you" : "Conveyancing calendar",
      })
    }
  }, [hasAppointment, hasLoaded])

  const isAppointmentInPast = hasAppointment
    ? dayjs(application.appointments[0].appointmentDate).isBefore(dayjs())
    : false

  return (
    <MyMojoTemplate
      backgroundWave
      stickyHeader={false}
      colourTheme={PageTheme.Alternate}
      headerStyle={HeaderStyle.Transparent}
      content={
        <ContentArea>
          {!hasAppointment ? (
            <BookingCalendar
              skipToCalendar
              friendlyId={friendlyId}
              application={application}
              calendarId={null}
              utm={{
                campaign: "Conveyancing",
                medium: "Email",
                source: "CRM",
              }}
              refreshApplication={refreshApplication}
              customHeader={
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 1,
                    }}
                  >
                    <Typography.H3>Book a call</Typography.H3>
                    <Typography.Body>
                      Speak to an expert about what you want and need from a mortgage and we can
                      help get everything in place, including a conveyancer.
                    </Typography.Body>
                  </div>
                  <AdvisorsImage src={advisors} />
                </div>
              }
            />
          ) : (
            <div
              style={{
                display: "flex",
              }}
            >
              <BookedAppointmentContainer>
                <Typography.H1>Thanks for letting us know</Typography.H1>
                <div>
                  <Typography.LargeBody>
                    We’ve let your Mortgage Expert know that you’re interested in these conveyancing
                    quotes and they’ll dicuss with you on your upcoming appointment.
                  </Typography.LargeBody>
                  {!isAppointmentInPast && (
                    <AppointmentDateContainer>
                      <Typography.Text size="large" style={{ marginBottom: 0 }}>
                        {dayjs(application.appointments[0].appointmentDate).format(
                          "dddd DD MMMM YYYY • h:mma"
                        )}
                      </Typography.Text>
                    </AppointmentDateContainer>
                  )}
                </div>
                <div id="buttonContainer">
                  <Button
                    onClick={() => {
                      history.push("/dashboard")
                    }}
                    text="Back to your account"
                  />
                </div>
              </BookedAppointmentContainer>

              <Image src={PancakeFlip} alt="person flipping pancakes" />
            </div>
          )}
        </ContentArea>
      }
    />
  )
}

export default Schedule
