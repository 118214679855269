import { space } from "components/_blueprint/Layout"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import CurrencyInput from "../CurrencyInput"

const Layout = styled.div({
  display: "grid",
  gap: 8,
})

const Label = styled.label({
  fontWeight: 500,
  marginTop: space,
})

type Income = {
  firstApplicant: number
  secondApplicant: number | null
}

type Props = {
  id: string
  incomeType: string
  onDone: (income: Income) => void
  secondApplicantName?: string
  value?: Income
}

export const Income = ({ id, incomeType, onDone, secondApplicantName, value }: Props) => {
  const [firstApplicant, setFirstApplicant] = useState<number | null>(value?.firstApplicant ?? null)
  const [secondApplicant, setSecondApplicant] = useState<number | null>(
    value?.secondApplicant ?? null
  )
  const answered =
    firstApplicant !== null && (secondApplicantName ? secondApplicant !== null : true)
  const handleComplete = () => {
    if (answered) {
      onDone({ firstApplicant, secondApplicant })
    } else {
      onDone(null)
    }
  }
  useEffect(() => {
    handleComplete()
  }, [firstApplicant, secondApplicant])

  const firstId = id + "_firstApplicant"
  const secondId = id + "_secondApplicant"
  return (
    <Layout>
      <Label htmlFor={firstId}>{`Your ${incomeType}`}</Label>
      <CurrencyInput
        id={firstId}
        value={firstApplicant}
        onChange={setFirstApplicant}
        onDone={onDone}
        label="per year"
        min={0}
        max={10000000}
      />
      {secondApplicantName && (
        <>
          <Label htmlFor={secondId}>{`${secondApplicantName}’s ${incomeType}`}</Label>
          <CurrencyInput
            id={secondId}
            value={secondApplicant}
            onChange={setSecondApplicant}
            onDone={onDone}
            label="per year"
            min={0}
            max={10000000}
          />
        </>
      )}
    </Layout>
  )
}
