export function GetPartnerFromUrl():
  | "gocompare"
  | "money"
  | "uswitch"
  | "oma"
  | "finder"
  | "bankrate"
  | "zoopla"
  | "confused"
  | string
  | null {
  const partner = window.location.host.split(".")[0]

  if (!partner || partner === "mymojo" || partner.includes("localhost")) {
    return null
  }

  return partner
}
