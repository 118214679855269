import { useEffect } from "react"
import { useRecoilState } from "recoil"
import { applicationSummariesState, hasFetchedInitialApplications } from "state"
import { ApplicationSummary } from "models/ApplicationSummary"
import { GetAllApplications } from "lib/Applications"
import { ErrorCode } from "../ApplicationError"
import { useHandleError } from "hooks/useHandleError"
import { useSession } from "lib/auth/react"

export function useApplicationSummaries() {
  const { data, isLoading } = useSession()
  const [hasFetchedSummaries, setHasFetchedSummaries] = useRecoilState<boolean>(
    hasFetchedInitialApplications
  )
  const [applicationSummaries, setApplications] =
    useRecoilState<ApplicationSummary[]>(applicationSummariesState)
  const { HandleError } = useHandleError()

  async function RefreshApplications(invalidateCache = true) {
    // try {
    //   await getSession()
    // } catch (err) {
    //   return
    // }

    if (isFetchingSummaries) {
      return
    }

    isFetchingSummaries = true

    try {
      const allApplications = await GetAllApplications(invalidateCache)
      setApplications(allApplications)
      setHasFetchedSummaries(true)
    } catch (err) {
      HandleError(ErrorCode.FetchingAllApplications, err, false)
    } finally {
      isFetchingSummaries = false
    }
  }

  useEffect(() => {
    if (!hasFetchedSummaries && data) {
      RefreshApplications(true)
    }
  }, [hasFetchedSummaries, data])

  return { applicationSummaries, hasFetchedSummaries, RefreshApplications }
}

// Blocks parallel requests to fetch all application summaries
let isFetchingSummaries = false
