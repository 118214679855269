import * as React from "react"
import styled from "styled-components"

export const RateRepresentativeExample = ({
  formattedBorrowing,
  formattedTrueCosts,
  AprLenders,
  formattedArrangementFee,
  feesOther,
  formattedFeesOther,
  InitialPayRate,
  InitialRatePeriodMonths,
  formattedMonthlyPayment,
  formattedInterest,
  formatMonthlyPaymentAfterInitialPeriod,
  StandardVariableRate,
  termMonths,
}: {
  formattedBorrowing: string
  formattedTrueCosts: string
  AprLenders: number
  formattedArrangementFee: string
  feesOther: number
  formattedFeesOther: string
  InitialPayRate: number
  InitialRatePeriodMonths: number
  formattedMonthlyPayment: string
  formattedInterest: string
  formatMonthlyPaymentAfterInitialPeriod: string
  StandardVariableRate: number
  termMonths: number
}) => {
  const displayYearsAndMonths = (months: number): string => {
    const years = Math.floor(months / 12)
    const remainingMonths = months % 12

    const yearStr = years ? `${years} ${years === 1 ? "year" : "years"}` : ""
    const monthStr = remainingMonths
      ? `${remainingMonths} ${remainingMonths === 1 ? "month" : "months"}`
      : ""

    return [yearStr, monthStr].filter(Boolean).join(" and ")
  }

  const remainingMonths = termMonths - InitialRatePeriodMonths
  const totalDuration = displayYearsAndMonths(termMonths)

  return (
    <RepresentativeExample>
      <b>Representative example:</b> a repayment mortgage amount of {formattedBorrowing} over{" "}
      {totalDuration}, representative APRC {AprLenders}%. Total amount payable {formattedTrueCosts}{" "}
      includes interest of {formattedInterest}, product fees of {formattedArrangementFee}
      {feesOther > 0 ? `and other fees of ${formattedFeesOther}.` : "."} Repayments:{" "}
      {InitialRatePeriodMonths} months of {formattedMonthlyPayment} at {InitialPayRate}% (fixed),
      then {remainingMonths} months of {formatMonthlyPaymentAfterInitialPeriod} at{" "}
      {StandardVariableRate}% (variable). Early repayment charges apply.
    </RepresentativeExample>
  )
}

const RepresentativeExample = styled.div({
    fontSize: "12px",
})
