import React, { useEffect } from "react"
import styled from "styled-components"
import { Loader, LoaderTheme } from "components/atoms/Loader"
import { LogGaEvent } from "lib/GoogleAnalytics"
import { EventAction, EventCategory, EventLabel } from "models/GoogleAnalytics"
import { CrunchingNumbers } from "components/molecules/CrunchingNumbers"

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 2rem;
  flex-direction: column;
`
const LoaderContainer = styled.div`
  margin-bottom: 3rem;
`

export function LoadingSpinner() {
  useEffect(() => {
    LogGaEvent({
      action: EventAction.componentDisplay,
      event_category: EventCategory.siteInteraction,
      event_label: EventLabel.eligibilityLoader,
    })
  }, [])

  return (
    <Container>
      <LoaderContainer>
        <Loader theme={LoaderTheme.Outline} size={120} />
      </LoaderContainer>
    </Container>
  )
}

export function EligibilityLoader() {
  useEffect(() => {
    LogGaEvent({
      action: EventAction.componentDisplay,
      event_category: EventCategory.siteInteraction,
      event_label: EventLabel.eligibilityLoader,
    })
  }, [])

  return (
    <Container>
      <CrunchingNumbers />
    </Container>
  )
}
