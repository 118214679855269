import React, { useEffect, useState } from "react"
import { ComboBox } from "components/molecules/ComboBox"
import { Checkbox } from "components/atoms"
import styled from "styled-components"
import { LogGaEvent } from "lib/GoogleAnalytics"
import { EventAction, EventCategory, EventLabel } from "models/GoogleAnalytics"

export const lenders = [
  {
    code: "ACC",
    name: "Accord",
  },
  {
    code: "FTB",
    name: "AIB",
  },
  {
    code: "ALD",
    name: "Aldermore Mortgages",
  },
  {
    code: "ABC",
    name: "Alternative Bridging Corporation",
  },
  {
    code: "BOC",
    name: "Bank Of China (UK)",
  },
  {
    code: "BOI",
    name: "Bank Of Ireland",
  },
  {
    code: "BWO",
    name: "Barclays Bank",
  },
  {
    code: "BBB",
    name: "Bath BS",
  },
  {
    code: "BPK",
    name: "Bespoke by BOI",
  },
  {
    code: "BBV",
    name: "Beverley BS",
  },
  {
    code: "BST",
    name: "Bluestone Mortgages",
  },
  {
    code: "CHY",
    name: "BM Solutions",
  },
  {
    code: "BBU",
    name: "Buckinghamshire BS",
  },
  {
    code: "CAM",
    name: "Cambridge BS",
  },
  {
    code: "CNT",
    name: "Central Trust Limited",
  },
  {
    code: "BCH",
    name: "Chelsea BS",
  },
  {
    code: "BCO",
    name: "Chorley BS",
  },
  {
    code: "CLY",
    name: "Clydesdale Bank",
  },
  {
    code: "BCV",
    name: "Coventry BS",
  },
  {
    code: "DAN",
    name: "Danske Bank",
  },
  {
    code: "BDA",
    name: "Darlington BS",
  },
  {
    code: "ATM",
    name: "Digital Mortgages",
  },
  {
    code: "DUD",
    name: "Dudley BS",
  },
  {
    code: "EOS",
    name: "Earl Shilton BS",
  },
  {
    code: "EQF",
    name: "Equifinance",
  },
  {
    code: "FAM",
    name: "Family Building Society (NCBS)",
  },
  {
    code: "FLE",
    name: "Fleet Mortgages",
  },
  {
    code: "FHL",
    name: "Foundation Home Loans",
  },
  {
    code: "BFU",
    name: "Furness BS",
  },
  {
    code: "GHB",
    name: "Gatehouse Bank",
  },
  {
    code: "GLH",
    name: "Glenhawk",
  },
  {
    code: "GOD",
    name: "Godiva Mortgages",
  },
  {
    code: "HBO",
    name: "Habito",
  },
  {
    code: "BHX",
    name: "Halifax",
  },
  {
    code: "HTB",
    name: "Hampshire Trust Bank",
  },
  {
    code: "HEC",
    name: "Hanley Economic BS",
  },
  {
    code: "BHP",
    name: "Harpenden BS",
  },
  {
    code: "BHI",
    name: "Hinckley & Rugby BS",
  },
  {
    code: "HLT",
    name: "Hodge",
  },
  {
    code: "HSB",
    name: "HSBC",
  },
  {
    code: "ITB",
    name: "Interbay",
  },
  {
    code: "BIP",
    name: "Ipswich BS",
  },
  {
    code: "JUS",
    name: "Just",
  },
  {
    code: "KMC",
    name: "Kensington Mortgages",
  },
  {
    code: "KBS",
    name: "Kent Reliance",
  },
  {
    code: "KEY",
    name: "Keystone",
  },
  {
    code: "LDB",
    name: "Landbay",
  },
  {
    code: "BLH",
    name: "Leeds BS",
  },
  {
    code: "LEE",
    name: "Leek United BS",
  },
  {
    code: "LAG",
    name: "Legal & General Home Finance",
  },
  {
    code: "LIV",
    name: "LendInvest",
  },
  {
    code: "LIM",
    name: "LiveMore",
  },
  {
    code: "CTE",
    name: "Lloyds Bank",
  },
  {
    code: "BLF",
    name: "Loughborough BS",
  },
  {
    code: "BMN",
    name: "Mansfield BS",
  },
  {
    code: "BMH",
    name: "Market Harborough BS",
  },
  {
    code: "BMS",
    name: "Marsden BS",
  },
  {
    code: "MSV",
    name: "Masthaven",
  },
  {
    code: "MBS",
    name: "MBS Lending Ltd",
  },
  {
    code: "MET",
    name: "Metro Bank",
  },
  {
    code: "MOL",
    name: "Molo Finance",
  },
  {
    code: "BMO",
    name: "Monmouthshire BS",
  },
  {
    code: "MQU",
    name: "MPowered Mortgages",
  },
  {
    code: "BNW",
    name: "Nationwide BS",
  },
  {
    code: "CNW",
    name: "NatWest",
  },
  {
    code: "BNE",
    name: "Newbury BS",
  },
  {
    code: "NBS",
    name: "Newcastle BS",
  },
  {
    code: "NTN",
    name: "Norton Home Loans",
  },
  {
    code: "BNT",
    name: "Nottingham BS",
  },
  {
    code: "OCT",
    name: "Octane Capital",
  },
  {
    code: "DGF",
    name: "Octopus Property",
  },
  {
    code: "FSH",
    name: "Oplo",
  },
  {
    code: "PPF",
    name: "Paragon Bank Plc",
  },
  {
    code: "PAR",
    name: "Paragon Banking Group PLC",
  },
  {
    code: "PET",
    name: "Penrith BS",
  },
  {
    code: "PEP",
    name: "Pepper Money",
  },
  {
    code: "CTS",
    name: "Platform",
  },
  {
    code: "POS",
    name: "Post Office",
  },
  {
    code: "PRE",
    name: "Precise Mortgages",
  },
  {
    code: "BPR",
    name: "Principality BS",
  },
  {
    code: "BPG",
    name: "Progressive BS",
  },
  {
    code: "REB",
    name: "Reliance Bank",
  },
  {
    code: "BSA",
    name: "Saffron BS",
  },
  {
    code: "CAN",
    name: "Santander UK Plc",
  },
  {
    code: "BSO",
    name: "Scottish BS",
  },
  {
    code: "BAA",
    name: "Scottish Widows Bank",
  },
  {
    code: "SEL",
    name: "Selina Finance",
  },
  {
    code: "SHB",
    name: "Shawbrook Bank",
  },
  {
    code: "SBS",
    name: "Skipton BS",
  },
  {
    code: "SKI",
    name: "Skipton International",
  },
  {
    code: "SRB",
    name: "Stafford Railway BS",
  },
  {
    code: "SBI",
    name: "State Bank of India UK",
  },
  {
    code: "SWA",
    name: "Swansea BS",
  },
  {
    code: "TBS",
    name: "Teachers BS",
  },
  {
    code: "BME",
    name: "The Melton BS",
  },
  {
    code: "TML",
    name: "The Mortgage Lender",
  },
  {
    code: "CCN",
    name: "The Mortgage Works",
  },
  {
    code: "TIP",
    name: "Tipton & Coseley BS",
  },
  {
    code: "TSB",
    name: "TSB",
  },
  {
    code: "UTB",
    name: "United Trust Bank",
  },
  {
    code: "VBS",
    name: "Vernon BS",
  },
  {
    code: "VDA",
    name: "Vida Homeloans",
  },
  {
    code: "BNR",
    name: "Virgin Money",
  },
  {
    code: "WBB",
    name: "West Bromwich Building Society",
  },
  {
    code: "WBC",
    name: "West Bromwich Commercial Limited",
  },
  {
    code: "WTO",
    name: "West One Secured Loans Ltd",
  },
  {
    code: "YOR",
    name: "Yorkshire Bank",
  },
  {
    code: "YBS",
    name: "Yorkshire Building Society",
  },
  {
    code: "ZPR",
    name: "Zephyr Homeloans",
  },
]

const LenderNotShown = styled.div`
  display: flex;
  align-items: center;
  margin: 12px 0;
  font-size: 1.1em;

  label {
    cursor: pointer;
  }

  input[type="checkbox"] {
    width: 19px;
    height: 19px;
    margin-right: 10px;
    margin-bottom: 3px;
  }
`

export default function LenderLookup({ question, value, onDone }) {
  const [input, setInput]: [string, Function] = useState(value?.name || "")
  const [lenderList, setLenderList] = useState([])
  const [notShown, setNotShown] = useState(false)

  useEffect(() => {
    setLenderList([])

    if (notShown) {
      LogGaEvent({
        action: EventAction.sectionLenderLookup,
        event_category: EventCategory.siteInteraction,
        event_label: EventLabel.notShownCheckbox,
      })
      onDone("NONE")
    } else {
      HandleInput(input)
    }
  }, [notShown])

  useEffect(() => {
    HandleInput(input)
  }, [input])

  function HandleInput(input: string) {
    const lender = lenders.find((x) => x.name === input)

    if (!lender) {
      onDone(null)
      return
    }

    onDone({ name: lender.name, code: lender.code })
  }

  function filterLenders(text: string) {
    setInput(text)

    if (text) {
      setLenderList(lenders.filter((x) => x.name.toLowerCase().indexOf(input.toLowerCase()) > -1))
    } else {
      setLenderList([])
    }
  }

  function HandleSelected(selection) {
    if (!selection) {
      onDone(null)
      return
    }

    setInput(selection.text)
    setLenderList([])
  }

  return (
    <>
      <ComboBox
        value={input}
        disabled={notShown}
        onInputChanged={(e) => filterLenders(e.target.value)}
        comboSelections={lenderList.map((x) => {
          const id: string = x.code
          const text: string = x.name
          return { id, text }
        })}
        onSelectionMade={(selection) => HandleSelected(selection)}
        id={"lookup-lender"}
      />
      <LenderNotShown>
        <Checkbox
          id={`checkbox-lender-lookup-${question.id}`}
          checked={notShown}
          onChange={() => setNotShown(!notShown)}
        />
        <label htmlFor={`checkbox-lender-lookup-${question.id}`}>My lender is not shown here</label>
      </LenderNotShown>
    </>
  )
}
