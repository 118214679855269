import React from "react"
import { Button, ButtonType, Trustpilot } from "components/atoms"
import { LogGaEvent } from "lib/GoogleAnalytics"
import { ButtonContainer, Buttons, Content, GhostWarning, Header, PriceEm, Text } from "./Shared"
import { EventAction, EventCategory, EventLabel } from "models/GoogleAnalytics"

interface Props {
  bookingComplete: boolean
  isGhostAppt: boolean
  onBookAppointment: () => void // Callback when user clicks on the "Book appointment" CTA
  mip?: string
}

export const IncreaseBookingsCopy = ({
  bookingComplete,
  isGhostAppt,
  onBookAppointment,
  mip,
}: Props) => (
  <Content>
    <Header>Your mortgage expert is ready to make your recommendation</Header>
    <Text>
      Right now, mortgages are complicated things, so your expert wants to review and approve your
      options to make sure we get you the best possible deal.
    </Text>
    <Text>Because we’re almost done, the call won’t take long at all.</Text>
    <Text bold={true}>
      It’s totally <PriceEm>free</PriceEm> and could save you £1000s.
    </Text>
    <ButtonContainer>
      <Buttons>
        <Button
          disabled={bookingComplete}
          onClick={onBookAppointment}
          text="Book your free review"
        />
        {mip && (
          <Button
            type={ButtonType.Outline}
            onClick={() => {
              LogGaEvent({
                action: EventAction.buttonClicked,
                event_category: EventCategory.functionalInteraction,
                event_label: EventLabel.downloadMip,
              })
              window.open(mip, "_blank")
            }}
            text={"Download your MIP"}
          />
        )}
      </Buttons>
    </ButtonContainer>
    {isGhostAppt && (
      <GhostWarning>
        This is a high demand callback slot. We aim to call within the 30 minute slot. If we cannot,
        we will email you to re-arrange.
      </GhostWarning>
    )}
    <Trustpilot />
  </Content>
)
