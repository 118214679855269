import axios from "axios"

/**
 * Logout method
 */
export const logout = async () => {
  const response = await axios(`${process.env.PUBLIC_AUTH_API}/logout`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    withCredentials: true,
  })

  return response
}
