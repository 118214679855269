import { GetApplicationCohorts, IsERC } from "lib/Applications"
import { Cohort } from "models/Cohort"
import { FullApplication } from "models/FullApplication"
import ReactGA, { ReactGAImplementation } from "react-ga4"
import { GOOGLE_ANALYTICS_TRACKING_ID, GA4_MEASUREMENT_ID } from "../Config"
import { EventArgs } from "models/GoogleAnalytics"

let initialised = false
const queue: EventArgs[] = []
const pageViews: string[] = []

/**
 * We extend the main `react-ga4` class to implement the `user_properties` scope.
 */
class CustomGAImplementation extends ReactGAImplementation {
  user = (fieldsObject: Record<string, string>) => {
    if (!fieldsObject) {
      console.warn("`fieldsObject` is required in .set()")

      return
    }

    if (typeof fieldsObject !== "object") {
      console.warn("Expected `fieldsObject` arg to be an Object")

      return
    }

    if (Object.keys(fieldsObject).length === 0) {
      console.warn("empty `fieldsObject` given to .set()")
    }

    this._gtag("set", "user_properties", fieldsObject)
  }
}

const CustomGA = new CustomGAImplementation()

export const initialiseGA = () => {
  ReactGA.initialize([
    { trackingId: GOOGLE_ANALYTICS_TRACKING_ID, gtagOptions: { send_page_view: false } },
    { trackingId: GA4_MEASUREMENT_ID, gtagOptions: { send_page_view: false } },
  ])
  initialised = true
  // Send any events that were called before GA was properly initialised.
  // TODO: this is a bit of a hack, this should probably go in React state
  queue.forEach((args) => Event(args.action, args))
  pageViews.forEach((path) =>
    ReactGA.send({ hitType: "pageview", page: path, page_location: path })
  )
}

export const LogGaEvent = (args: EventArgs) => {
  // For now, duplicate GA4 custom parameters as UA custom dimensions
  if ("field_value" in args) {
    if (typeof args.field_value === "object") {
      args.field_value = JSON.stringify(args.field_value)
    }
    args.dimension7 = args.field_value
  }
  if ("mortgage_type" in args) args.dimension8 = args.mortgage_type
  if ("user_stage" in args) args.dimension9 = args.user_stage
  if ("traffic_light_definition" in args) args.dimension10 = args.traffic_light_definition
  if ("full_page_url" in args) args.dimension12 = args.full_page_url
  if ("ltv_band" in args) args.dimension13 = args.ltv_band
  if ("internal_user" in args) args.dimension14 = args.internal_user
  if ("appointment_type" in args) args.dimension15 = args.appointment_type
  if ("eligibility_tier" in args) args.dimension16 = args.eligibility_tier
  if ("total_documents_uploaded" in args) args.dimension17 = args.total_documents_uploaded
  if ("product_comparison_status" in args) args.dimension18 = args.product_comparison_status
  if ("mortgage_coach_status" in args) args.dimension19 = args.mortgage_coach_status
  if ("friendlyId" in args) args.dimension20 = args.friendlyId
  if ("lender_name" in args) args.dimension21 = args.lender_name
  if ("product_name" in args) args.dimension22 = args.product_name
  if ("monthly_cost" in args) args.dimension23 = args.monthly_cost
  if ("initial_rate" in args) args.dimension24 = args.initial_rate
  if ("mortgage_product_type" in args) args.dimension25 = args.mortgage_product_type
  if ("initial_period_end_date" in args) args.dimension26 = args.initial_period_end_date
  if ("initial_fixed_period" in args) args.dimension27 = args.initial_fixed_period
  if ("arrangement_fee" in args) args.dimension28 = args.arrangement_fee
  if ("mortgage_payment_type" in args) args.dimension29 = args.mortgage_payment_type
  if ("offset" in args) args.dimension30 = args.offset
  if ("portable" in args) args.dimension31 = args.portable
  if ("overpayments_allowed" in args) args.dimension32 = args.overpayments_allowed
  if ("free_legals" in args) args.dimension33 = args.free_legals
  if ("free_valuation" in args) args.dimension34 = args.free_valuation
  if ("cashback" in args) args.dimension35 = args.cashback

  if (!initialised) {
    queue.push(args)
    return
  }
  Event(args.action, args)
}

/**
 * Send an event to the analytics
 * @param eventName The name of the event
 * @param params An object of any parameters
 */
const Event = (eventName: string, params: object) => {
  CustomGA.event(eventName, params)
}

export const PageView = (path: string) => {
  if (!initialised) {
    pageViews.push(path)
    return
  }
  // page_location is for GA4 as it does not seem to work with just "page". Keeping "page" for UA.
  ReactGA.send({ hitType: "pageview", page: path, page_location: path })
}

export function SetGaDimensions(args: Omit<EventArgs, "action" | "event_category">) {
  // For now, duplicate GA4 custom parameters as UA custom dimensions
  if ("field_value" in args) args.dimension7 = args.field_value
  if ("mortgage_type" in args) args.dimension8 = args.mortgage_type
  if ("user_stage" in args) args.dimension9 = args.user_stage
  if ("traffic_light_definition" in args) args.dimension10 = args.traffic_light_definition
  if ("ltv_band" in args) args.dimension13 = args.ltv_band
  if ("appointment_type" in args) args.dimension15 = args.appointment_type
  if ("eligibility_tier" in args) args.dimension16 = args.eligibility_tier
  if ("product_comparison_status" in args) args.dimension18 = args.product_comparison_status
  if ("mortgage_coach_status" in args) args.dimension19 = args.mortgage_coach_status

  ReactGA.set(args)
}

export function GetGaMortgageType(application: FullApplication): string {
  const cohorts = GetApplicationCohorts(application)

  // Residential
  if (!cohorts.includes(Cohort.BuyToLet)) {
    if (cohorts.includes(Cohort.FirstTimeBuyer)) return "Resi FTB"
    if (cohorts.includes(Cohort.MovingHome)) return "Resi Home Mover"
    if (cohorts.includes(Cohort.SecondHome)) return "Resi 2nd Property"
    if (cohorts.includes(Cohort.LikeForLike)) return "Resi Remo LFL"
    if (cohorts.includes(Cohort.AdditionalBorrowing)) return "Resi Remo AB"
    if (cohorts.includes(Cohort.ReleaseMoney)) return "Resi Remo ER"
  }

  // BTL
  if (cohorts.includes(Cohort.BuyToLet)) {
    if (cohorts.includes(Cohort.Remortgage)) {
      if (cohorts.includes(Cohort.LikeForLike)) return "BTL Remo LFL"
      if (cohorts.includes(Cohort.AdditionalBorrowing)) return "BTL Remo AB"
      if (cohorts.includes(Cohort.ReleaseMoney)) return "BTL Remo ER"
    }

    if (cohorts.includes(Cohort.Purchase)) return "BTL Purchase"
  }
}

export function GetGaUserStage(application: FullApplication): string {
  const cohorts = GetApplicationCohorts(application)
  const ercBool: boolean = IsERC(application) ?? false

  if (cohorts.includes(Cohort.PostOffer)) return "Post Offer"
  if (cohorts.includes(Cohort.PreOffer)) return "Pre Offer"
  if (cohorts.includes(Cohort.Remortgage) && ercBool === true) return "Non-Transactional Remo >6m"
  if (cohorts.includes(Cohort.Remortgage) && ercBool === false) return "Transactional Remo <6m"
}
