import React, { useEffect } from "react"
import styled from "styled-components"
import { Desk } from "components/Illustrations"
import { LogGaEvent } from "lib/GoogleAnalytics"
import { EventAction, EventCategory } from "models/GoogleAnalytics"
import { useEligibility } from "hooks/useEligibility/useEligibility"
import { useTrackNavigatedToPage } from "hooks/useTrackPageView"
import {
  ContentContainer,
  ContentGrid,
  Gap,
  LayoutGrid,
  desktop,
  mobile,
  space,
  tablet,
} from "components/_blueprint/Layout"
import { GreatWave } from "../shared/WaveBackground"
import { TopNav } from "components/molecules/TopNav"
import { Heading, Paragraph } from "components/_blueprint/Typography"

export const NoHelpSection = () => {
  useEffect(() => {
    LogGaEvent({
      action: EventAction.componentDisplay,
      event_category: EventCategory.siteInteraction,
      event_label: "AdverseCannotHelp",
    })
  }, [])

  useTrackNavigatedToPage("/application/eligibility/no-help")

  const {
    logoState: [_, setShowLogo],
  } = useEligibility()

  useEffect(() => {
    setShowLogo(false)
  }, [])

  return (
    <GreatWave>
      <TopNav />
      <LayoutGrid>
        <ContentGrid>
          <ContentContainer>
            <Heading size="h1" margin={0} style={{ lineHeight: 1.2 }}>
              You might find it hard to get a mortgage from a high street lender
            </Heading>
            <Gap x={2} />
            <Paragraph margin={0}>
              We’ve had a look at your situation. Based on your credit history, unfortunately we
              won’t be able to help you with a mortgage at this time.
            </Paragraph>
            <Gap x={2} />
            <HelpCard>
              <Heading as="h2" size="h3" margin={0}>
                Get help with money or credit issues
              </Heading>
              <List>
                <li>
                  For free impartial advice from the government about money and pensions, visit{" "}
                  <HelpLink href="https://www.moneyhelper.org.uk/en" target="_blank">
                    moneyhelper.org.uk
                  </HelpLink>
                </li>
                <li>
                  For free advice on how to be debt-free, visit{" "}
                  <HelpLink href="https://www.stepchange.org/" target="_blank">
                    stepchange.org
                  </HelpLink>{" "}
                </li>
                <li>
                  To find another broker who may be able to help you, visit{" "}
                  <HelpLink href="https://www.unbiased.co.uk/" target="_blank">
                    unbiased.co.uk
                  </HelpLink>
                </li>
                <li>
                  For general advice on a range of topics including money, debt and housing, visit{" "}
                  <HelpLink href="https://www.citizensadvice.org.uk/" target="_blank">
                    citizensadvice.org.uk
                  </HelpLink>
                </li>
              </List>
            </HelpCard>
          </ContentContainer>
          <Illustration alt="" src={Desk} />
        </ContentGrid>
        <Footer>
          <Paragraph size="small">
            Your property may be repossessed if you do not keep up repayments on your mortgage. You
            may have to pay a charge to exit an existing mortgage deal early. Think carefully before
            securing other debts against your property.
          </Paragraph>
        </Footer>
      </LayoutGrid>
    </GreatWave>
  )
}

export const Illustration = styled.img({
  gridColumnStart: 9,
  gridColumnEnd: "span 4",
  gridRowStart: 1,
  pointerEvents: "none",
  width: 500,
  [mobile]: {
    display: "none",
  },
  [tablet]: {
    display: "none",
  },
  [desktop]: {
    gridColumnEnd: "span 4",
  },
})

const List = styled.ul({
  color: "black",
  lineHeight: 1.8,
  margin: 0,
  paddingLeft: "16px",
})

const HelpLink = styled.a({
  textDecoration: "none",
  color: "blue",
})

const HelpCard = styled.div({
  backgroundColor: "rgba(255, 255, 255, 0.50)",
  borderRadius: "8px",
  color: "white",
  display: "grid",
  gap: space * 2,
  padding: space * 2,
})

const Footer = styled.div({
  alignSelf: "center",
  gridColumnStart: 2,
  gridRowStart: 2,
  [mobile]: {
    padding: "64px 24px",
  },
  [tablet]: {
    padding: "64px 40px",
  },
  [desktop]: {
    padding: "64px 40px",
  },
})
