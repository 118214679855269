import React from "react"
import styled from "styled-components"
import ChainedList from "components/_blueprint/ChainedList"
import { Heading } from "components/_blueprint/Typography"
import { desktop, Gap } from "components/_blueprint/Layout"
import { Guitar } from "components/Illustrations"
import devices from "styles/devices"

export const HowItWorksRateCheckPromise = () => {
  return (
    <Container>
      <Box>
        <Heading size="h3" as="h2">
          How it works
        </Heading>
        <Gap x={2} />
        <ChainedList
          data={[
            {
              title: "We’ll notify you of lower rates",
              description: (
                <>
                  <span>
                    Once you’ve had an offer accepted by a lender, we’ll check every day to see if
                    they have reduced rates for your deal.
                  </span>
                  <span>
                    You’ll hear from us via text message and email when a lower rate is found.
                  </span>
                </>
              ),
            },
            {
              title: "Request new deal ",
              description: (
                <span>
                  If you’d like to move onto the new deal with the lower rate, let us know quickly
                  by selecting ‘Request this rate’. We’ll submit the request to your chosen lender.
                  Occasionally, the lender might run a hard credit check.
                </span>
              ),
            },
            {
              title: "Move onto new rate & save ££",
              description: (
                <span>
                  You’ll complete as usual. This change won’t cost you anything. You’ll just pay
                  less for your mortgage.
                </span>
              ),
            },
          ]}
        />
        <span>
          If rates drop again, we’ll let you know. We’ll make every effort to move you onto your new
          rate, unless it will cause delays to completion.
        </span>
      </Box>
      <Image src={Guitar} alt="person playing guitar" />
    </Container>
  )
}

const Container = styled.div({
  position: "relative",
})

const Box = styled.div({
  padding: "2rem 1rem",
  [desktop]: {
    marginRight: 250,
  },
})

const Image = styled.img`
  display: none;

  @media ${devices.bp_lg} {
    z-index: 1000;
    display: block;
    width: 277px;
    right: 0;
    top: 0;
    margin-top: 1rem;
    position: absolute;
  }
`
