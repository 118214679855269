import React from "react"
import { BaseTemplate } from "templates/BaseTemplate"
import { ContentArea } from "templates/ContentArea"

export function TopNavTemplate({
  content,
  backButtonAction = null,
  miniNav = false,
  footer = null,
}) {
  return (
    <BaseTemplate
      miniNav={miniNav}
      backButtonAction={backButtonAction}
      footer={footer}
      content={<ContentArea>{content}</ContentArea>}
    />
  )
}
