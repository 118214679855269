import React from "react"
import styled from "styled-components"
import devices from "styles/devices"

export const BorderRadius = "12px"

const Container = styled.div`
  border-radius: ${BorderRadius};
  width: 100%;
  padding: 16px 16px;
  box-sizing: border-box;
  height: fit-content;
  position: relative;

  @media ${devices.bp_sm} {
    padding: 35px 35px;
  }

  &.default {
    background: white;
    color: black;
  }

  &.highlight {
    background: #f9ba5b;
    color: #444444;
  }
`

const FooterWrapper = styled.div`
  margin: -16px;

  > * {
    border-bottom-right-radius: ${BorderRadius};
    border-bottom-left-radius: ${BorderRadius};
  }

  @media ${devices.bp_sm} {
    margin: -35px;
  }
`

type Props = {
  theme?: "default" | "highlight"
  content: JSX.Element
  style?: object
  footer?: JSX.Element
}

export function Card({ theme = "default", content, style = null, footer = null }: Props) {
  return (
    <Container style={style} className={theme}>
      {content}
      {footer && <FooterWrapper>{footer}</FooterWrapper>}
    </Container>
  )
}
