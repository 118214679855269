import React from "react"
import styled from "styled-components"
import devices from "styles/devices"
import { Button, ButtonType } from "components/atoms"
import { useHistory } from "react-router-dom"
import { LogGaEvent } from "../../lib/GoogleAnalytics"
import { EventAction, EventCategory } from "models/GoogleAnalytics"

const BorderBox = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #dadee8;
  border-radius: 24px;
  background: white;
  padding: 32px;
  margin: 32px 0;

  @media screen and ${devices.bp_lg} {
    flex-direction: row;
    justify-content: space-between;
    padding: 48px;
  }
`

const TextContainer = styled.div`
  @media screen and ${devices.bp_lg} {
    margin-right: 1em;
    max-width: 70%;
  }
`

const Title = styled.h1`
  font-weight: 550;
  font-size: 22px;
`

const Text = styled.p`
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0;
  @media screen and ${devices.bp_lg} {
    font-size: 20px;
  }
`

interface Props {
  title: string
  text: string
  friendlyId: string
  taggingLabel: string
}

export const UpdateInfo = ({ title, text, friendlyId, taggingLabel }: Props) => {
  const history = useHistory()

  return (
    <BorderBox>
      <TextContainer>
        <Title>{title}</Title>
        <Text>{text}</Text>
      </TextContainer>
      <Button
        type={ButtonType.Dark}
        onClick={() => {
          LogGaEvent({
            action: EventAction.buttonClicked,
            event_category: EventCategory.functionalInteraction,
            event_label: `Update Info - ${taggingLabel}`,
          })
          history.push(`/application/${friendlyId}/eligibility/feature-in-test`)
        }}
        text="Update Info"
      />
    </BorderBox>
  )
}
