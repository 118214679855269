import React, { ReactElement } from "react"
import styled from "styled-components"
import { MojoListItem } from "components/molecules/MojoListItem"
import devices from "styles/devices"

const Container = styled.div`
  margin-bottom: 20px;
`

const TopText = styled.p`
  line-height: 150%;
`

const Box = styled.div`
  border-radius: 24px 24px 0 0;
  background-color: #ffebdc;
  padding: 40px 20px;

  @media ${devices.bp_xl} {
    padding: 40px;
  }

  h3 {
    line-height: 120%;
  }
  p {
    line-height: 150%;
  }
`

const Top = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 5px;

  @media (min-width: 1250px) {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
  }
`

const BottomBox = styled.div`
  border-radius: 0 0 24px 24px;
  background-color: #ffe2cd;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;

  @media ${devices.bp_xl} {
    padding: 40px;
  }

  ul {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    justify-content: left;
    grid-row-gap: 26px;
    grid-column-gap: 16px;
    padding: 0 10px;

    @media (min-width: 1250px) {
      grid-template-columns: 1fr 1fr;
    }
  }
`

type Props = {
  topText?: string
  cardTitle: string
  cardText: string
  children?: JSX.Element | JSX.Element[]
  cardImage: ReactElement
  cardBulletPoints: (JSX.Element | JSX.Element[] | string)[]
}

export function AccordionItem({
  children,
  topText,
  cardTitle,
  cardText,
  cardImage,
  cardBulletPoints,
}: Props) {
  return (
    <Container>
      {topText && <TopText>{topText}</TopText>}
      <Box>
        <Top>
          <div>
            <h3>{cardTitle}</h3>
            <p>{cardText}</p>
            {children}
          </div>
          {cardImage}
        </Top>
      </Box>
      <BottomBox>
        <ul>
          {cardBulletPoints.map((children, index) => {
            return <MojoListItem key={index}>{children}</MojoListItem>
          })}
        </ul>
      </BottomBox>
    </Container>
  )
}
