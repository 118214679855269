import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { LabeledInput } from "components/molecules/LabeledInput"
import { ValidateEmailAddress } from "lib/EmailAddress"
import useDidMount from "hooks/useDidMount"

const Container = styled.div`
  font-family: Arial, sans-serif;

  div > div > div {
    border-radius: 8px;
    border: 1px solid black !important;
    padding: 5px 5px !important;
  }
`

type Props = {
  label?: string
  placeholder?: string
  value?: string
  onChange?: (value: string) => void
  onBlur?: () => void
  onEnter?: () => void
  onFocus?: () => void
  onIsValidChange?: (isValid: boolean) => void
  onLoaded?: () => void
  allowBlank?: boolean
  id?: string
}

export function EmailInput({
  label = "",
  placeholder = "",
  value = "",
  onBlur = () => {},
  onEnter = () => {},
  onFocus = () => {},
  onChange = (value) => {},
  onIsValidChange = (isValid) => {},
  onLoaded = () => {},
  allowBlank = false,
  id = "",
}: Props) {
  const [hasTouched, setHasTouched] = useState(false)
  const isValid = ValidateEmailAddress(value)
  const didMount = useDidMount()

  useEffect(() => {
    onIsValidChange(ValidateEmailAddress(value))
    !didMount && onLoaded()
  }, [value])

  function handleOnBlur() {
    setHasTouched(true)
    onBlur()
  }

  return (
    <Container>
      <LabeledInput
        id={id}
        type="text"
        label={label}
        value={value ?? ""}
        isValid={isValid}
        placeholder={placeholder}
        onBlur={handleOnBlur}
        onFocus={onFocus}
        errorMessage={hasTouched && !allowBlank && "Email address is invalid"}
        onChange={onChange}
        onEnter={onEnter}
      />
    </Container>
  )
}
