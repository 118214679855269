import React from "react"
import { Bullet, ChainedListUl, ContentContainer, ListItem, Text, Title } from "./styles/base.css"

type ChainedListData = {
  /**
   * The title used for each item in the list
   */
  title: string
  /**
   * The text contained for each item in the list
   */
  description: React.ReactNode
}

export type ChainedListProps = {
  /**
   * Data used to populate chained list
   */
  data: ChainedListData[]
} & React.HTMLAttributes<HTMLUListElement>

/**
 * A list with each bullet point from each item chained together in a line, and each bullet point numbered
 */
export const ChainedList = ({ data }: ChainedListProps) => {
  return (
    <ChainedListUl>
      {data.map(({ title, description }, index) => (
        <ListItem key={index}>
          <Bullet data-bullet={index + 1} />
          <ContentContainer>
            <Title>{title}</Title>
            <Text>{description}</Text>
          </ContentContainer>
        </ListItem>
      ))}
    </ChainedListUl>
  )
}

export default ChainedList
