import styled from "styled-components"

export const topNavHeight = 60
export const space = 16
export const mobile = "@media (max-width: 767px)"
export const tablet = "@media (min-width: 768px) and (max-width: 1023px)"
export const desktop = "@media (min-width: 1024px)"

export const mobileContentPadding = 24
export const tabletContentPadding = 40
export const desktopContentPadding = 104

/*
The Layout Grid has a center column for content, with growable padding on either
side for wide screens. It two rows, one for content, one for the footer. The
footer row takes up as much size as it needs for its children, the content row
takes up all the rest.
┌──────┬────────┬─────┐
│      │        │     │
│      │        │     │
│      │        │     │
│      │        │     │
├──────┼────────┼─────┤
└─────────────────────┘
*/
export const LayoutGrid = styled.div<{ background?: string }>(({ background }) => ({
  display: "grid",
  gridTemplateRows: `[body] 1fr [footer]`,
  minHeight: `calc(100vh - ${topNavHeight}px)`,
  background: background,
  [mobile]: {
    gridTemplateColumns: "[left] 1fr [content] minmax(256px, 703px) [right] 1fr",
  },
  [tablet]: {
    gridTemplateColumns: "[left] 1fr [content] minmax(688px, 943px) [right] 1fr",
  },
  [desktop]: {
    gridTemplateColumns: "[left] 1fr [content] minmax(816px, 1232px) [right] 1fr",
  },
}))

/*
The Content Grid is for placing content in the main body of the page. It should
go inside the Layout Grid, where it will sit inside the center column (2) and
the top row (1). it has 12 columns on desktop, 6 on tablet, 3 on mobile.
┌─┬─┬─┬─┬─┬─┬─┬─┬─┬─┬─┬─┐  ┌─┬─┬─┬─┬─┬─┐  ┌──┬──┬──┐
│ │ │ │ │ │ │ │ │ │ │ │ │  │ │ │ │ │ │ │  │  │  │  │
│ │ │ │ │ │ │ │ │ │ │ │ │  │ │ │ │ │ │ │  │  │  │  │
│ │ │ │ │ │ │ │ │ │ │ │ │  │ │ │ │ │ │ │  │  │  │  │
│ │ │ │ │ │ │ │ │ │ │ │ │  │ │ │ │ │ │ │  │  │  │  │
└─┴─┴─┴─┴─┴─┴─┴─┴─┴─┴─┴─┘  └─┴─┴─┴─┴─┴─┘  └──┴──┴──┘
*/
export const ContentGrid = styled.div({
  display: "grid",
  gridColumnStart: 2,
  gridRowStart: 1,
  columnGap: space,
  [mobile]: {
    gridTemplateColumns: "repeat(3, 1fr)",
    padding: mobileContentPadding,
  },
  [tablet]: {
    gridTemplateColumns: "repeat(6, 1fr)",
    padding: tabletContentPadding,
  },
  [desktop]: {
    gridTemplateColumns: "repeat(12, 1fr)",
    padding: `${desktopContentPadding}px ${40}px`,
  },
})

/**
 * The Content Container contains the primary content of the result page. It
 * sits inside the ContentGrid, taking up more or fewer columns depending on
 * the width of the page.
 */
export const ContentContainer = styled.div({
  gridColumnStart: 1,
  [mobile]: {
    gridColumnEnd: "span 3",
  },
  [tablet]: {
    gridColumnEnd: "span 6",
  },
  [desktop]: {
    gridColumnEnd: "span 8",
  },
})

/**
 * The Footer Background spans all three Layout Grid columns so that it is as
 * wide as the full page.
 */
export const FooterBackground = styled.div((props) => ({
  backgroundColor: props.theme.footerBackground,
  gridColumnEnd: "span 3",
  gridColumnStart: 1,
  gridRowStart: 2,
}))

export const Gap = styled.div<{ x: number }>((props) => ({
  height: props.x * space,
}))
